import React from 'react';
import { LoadingSpinner } from '$cmp/loadingSpinner';
import { useCurrentUserContext } from '$contexts/currentUserContext';
import { usePaymentCardInfo } from '$hooks/usePaymentCardInfo';
import { usePaymentAccountSubscription } from '$hooks/usePaymentAccountSubscription';

import { ActiveMissingCardAlert } from './alerts/activeMissingCardAlert';
import { CanceledAlert } from './alerts/canceledAlert';
import { NoPaymentAccountSubscriptionAlert } from './alerts/noPaymentAccountSubscriptionAlert';
import { PastDueAlert } from './alerts/pastDueAlert';
import { PendingCancelAlert } from './alerts/pendingCancelAlert';
import { TrialAlert } from './alerts/trialAlert';

export interface PaymentAccountStatusAlertProps {
  updateTrigger?: Record<string, unknown>;
}

export const PaymentAccountStatusAlert: React.FunctionComponent<PaymentAccountStatusAlertProps> = (
  props
) => {
  const { updateTrigger } = props;

  const { currentUser } = useCurrentUserContext();
  const paymentAccountSubscription = usePaymentAccountSubscription(updateTrigger);
  const paymentCardInfo = usePaymentCardInfo(updateTrigger);

  const isFree = currentUser?.is_free ?? false;

  if (!currentUser?.is_single_subscriber_enabled) {
    return null;
  }

  if (paymentAccountSubscription === undefined || paymentCardInfo === undefined) {
    return <LoadingSpinner />;
  }

  if (isFree) {
    return null;
  }

  if (!paymentAccountSubscription) {
    return <NoPaymentAccountSubscriptionAlert />;
  }

  if (paymentAccountSubscription.cancel_at_period_end) {
    return <PendingCancelAlert updateTrigger={updateTrigger} />;
  }

  switch (paymentAccountSubscription.status) {
    case 'trialing':
      return <TrialAlert updateTrigger={updateTrigger} />;
    case 'active':
      return paymentCardInfo === null ? <ActiveMissingCardAlert /> : null;
    case 'past_due':
      return <PastDueAlert type="past_due" />;
    case 'unpaid':
      return <PastDueAlert type="unpaid" />;
    case 'canceled':
      return <CanceledAlert />;
    default:
      return null;
  }
};
