import React from 'react';
import { Field } from 'react-final-form';

export const LeoTrusteeAppointmentDocumentFormContents: React.FunctionComponent = () => {
  return (
    <>
      <p style={{ textAlign: 'center' }}>LEO Trustee Appointment Document</p>
      <p style={{ textAlign: 'center' }}>For</p>
      <p style={{ textAlign: 'center' }}>Life Estate Organizer</p>
      <p style={{ textAlign: 'center' }}>For</p>
      <p style={{ textAlign: 'center' }}>Head of Household</p>
      <p style={{ textAlign: 'center' }}>
        <Field
          name="agreementModel.name"
          component="input"
          placeholder="Name"
          autoComplete="headOfHouseholdName"
          className="form-control form-control-inline"
        />
      </p>
      <p>
        I,{' '}
        <Field
          name="agreementModel.name"
          component="input"
          placeholder="Name"
          autoComplete="headOfHouseholdName"
          className="form-control form-control-inline"
        />
        , of{' '}
        <Field
          name="agreementModel.county"
          component="input"
          placeholder="County"
          autoComplete="headOfHouseholdCounty"
          className="form-control form-control-inline"
        />
        County,{' '}
        <Field
          name="agreementModel.state"
          component="input"
          placeholder="State"
          autoComplete="headOfHouseholdState"
          className="form-control form-control-inline"
        />
        ,
        <Field
          name="agreementModel.country"
          component="input"
          placeholder="Country"
          autoComplete="headOfHouseholdCountry"
          className="form-control form-control-inline"
        />
        do hereby make, publish and declare this to be my Life Estate Organizer. I hereby revoke all
        previous Life Estate Organizers or writings made by me.
      </p>
      <div>
        <span style={{ textDecoration: 'underline' }}>ITEM I.</span>

        <ol style={{ listStyle: 'upper-alpha' }}>
          <li>
            I nominate and appoint
            <Field
              name="agreementModel.trusteeName"
              component="input"
              placeholder="Name"
              autoComplete="trusteeName"
              className="form-control form-control-inline"
            />
            as LEO Trustee on this date
            <Field
              name="agreementModel.trusteeDate"
              component="input"
              type="date"
              placeholder="Date"
              autoComplete="trusteeDate"
              className="form-control form-control-inline"
            />
          </li>
          <li>
            This appointment shall provide the LEO Trustee the following:
            <ol>
              <li>
                The LEO Trustee will have access to all or partial sections of my Life Estate
                Organizer by using the user ID and password provided to access sections of my Life
                Estate Organizer.
              </li>
              <li>
                Sections will be represented in the LEO Trustee Profile Scope Selection. The
                sections represented have been chosen by me, the Head of Household.
              </li>
              <li>
                This appointment authorizes the LEO Trustee to access and review the data input to
                the represented sections outlined in the LEO Trustees Profile Scope Selection only.
              </li>
              <li>
                The LEO Trustee does not have access to any other sections of the Life Estate
                Organizer other than those selected by me, Head of Household.
              </li>
            </ol>
          </li>
          <li>
            The LEO Trustee is not required to perform any duties with respect to the access granted
            by the Head of Household. Access granted is for information purposes only.
          </li>
          <li>
            In the event the LEO Trustee has an issue with any information contained within the Head
            of Household’s Life Estate Organizer, the LEO Trustee shall contact the Head of
            Household, if not deceased and if deceased, contact the Executor to address and resolve
            the issue.
          </li>
        </ol>
      </div>
      <p>
        I hereby check this block, type in my name and the date of agreement that this is my Life
        Estate Organizer, has been filled out by me to the best of my knowledge and ability, revoke
        all previous Life Estate Organizers or writings made by me and outlined the information
        accessible by my LEO Trustee and authorize my LEO Trustee to access this information.
      </p>
    </>
  );
};
