import React from 'react';
import { Link } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import { useIsRoute } from '$hooks/useIsRoute';

export const ActiveMissingCardAlert: React.VoidFunctionComponent = () => {
  const onManagePaymentAccountPage = useIsRoute('/manage-payment-account');

  return (
    <Alert severity="warning">
      <ul>
        <li>
          Your account is <strong>active</strong> but you have not provided your payment card
          information!
        </li>
        {!onManagePaymentAccountPage && (
          <li>
            Visit the <Link to="/manage-payment-account">Manage Payment Account page</Link> to add
            your payment card information.
          </li>
        )}
        <li>
          Without this information, your account will be locked and you will not be able to access
          profiles you own.
        </li>
      </ul>
    </Alert>
  );
};
