import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';

import { PetGuardianAgreementModal } from '$cmp/modals/petGuardianAgreementModal/petGuardianAgreementModal';
import type { PetGuardianAgreement } from '@quiet-sunset/leo-shared';
import { useProfileContext, usePetGuardianAgreementsService } from '@quiet-sunset/leo-shared';

export interface PetGuardianAgreementFieldProps {
  fieldId: string;
  profileFieldGroupListItemId: string | null;
}

export const PetGuardianAgreementField: React.FunctionComponent<PetGuardianAgreementFieldProps> =
  React.memo((props) => {
    const { fieldId, profileFieldGroupListItemId } = props;

    const PetGuardianAgreementsService = usePetGuardianAgreementsService();

    const { profileId } = useProfileContext();

    const [forceReloadToken, setForceReloadToken] = useState({});
    const [isLoaded, setIsLoaded] = useState(false);
    const [petGuardianAgreement, setPetGuardianAgreement] = useState(
      null as PetGuardianAgreement | null
    );
    const [showModal, setShowModal] = useState(false);

    const editPetGuardianAgreement = useCallback(() => {
      setShowModal(true);
    }, []);

    const onClosePetGuardianAgreementModal = useCallback(() => {
      setShowModal(false);
      setForceReloadToken({});
    }, []);

    const deletePetGuardianAgreement = useCallback(async () => {
      setIsLoaded(false);
      await PetGuardianAgreementsService.deletePetGuardianAgreement(
        profileId!,
        profileFieldGroupListItemId,
        fieldId
      );
      setIsLoaded(true);
      setForceReloadToken({});
    }, [fieldId, profileFieldGroupListItemId, profileId]);

    useEffect(() => {
      void (async () => {
        setIsLoaded(false);
        const _petGuardianAgreement = await PetGuardianAgreementsService.getPetGuardianAgreement(
          profileId!,
          profileFieldGroupListItemId,
          fieldId
        );
        setPetGuardianAgreement(_petGuardianAgreement);
        setIsLoaded(true);
      })();
    }, [fieldId, profileFieldGroupListItemId, profileId, forceReloadToken]);

    if (!isLoaded) {
      return null;
    }

    return (
      <>
        {showModal && (
          <PetGuardianAgreementModal
            fieldId={fieldId}
            profileFieldGroupListItemId={profileFieldGroupListItemId}
            onClose={onClosePetGuardianAgreementModal}
          />
        )}
        <div className="form-group col-md-12">
          {petGuardianAgreement != null && (
            <>
              <Button variant="default" onClick={editPetGuardianAgreement}>
                Edit
              </Button>
              <Button variant="danger" onClick={deletePetGuardianAgreement}>
                Delete
              </Button>
            </>
          )}
          {petGuardianAgreement == null && (
            <Button variant="primary" onClick={editPetGuardianAgreement}>
              Create
            </Button>
          )}
        </div>
      </>
    );
  });
