import React from 'react';
import { Button, Modal } from 'react-bootstrap';

export interface GlossaryModalProps {
  onClose: () => unknown;
}

export const GlossaryModal: React.FunctionComponent<GlossaryModalProps> = (props) => {
  const { onClose } = props;

  return (
    <Modal show backdrop="static" keyboard={false} size="lg">
      <Modal.Header>
        <Modal.Title>Glossary</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="glossary-body">
          <p>
            <span className="glossary-title">Actions in Progress</span> – the act or process of
            doing something for yourself, someone else, where you work, your church, community, etc.
            Whereby, you made a commitment to complete the action, but to date, it is not complete.
          </p>
          <p>
            <span className="glossary-title">Advanced Health Care Directive</span> – Also known as
            living will, personal directive, advance directive, medical directive or advanced
            decision, is a legal document which appoints a person to make end of life decisions and
            specify what actions should be taken for the health of another, if they are no longer
            able to make decisions for themselves.
          </p>
          <p>
            <span className="glossary-title">Agriculture</span> – is the science or practice of
            farming, including cultivation of the soil for the growing of crops and the rearing of
            animals to provide food, wool and other products. Agriculture is a subsection in LEO
            located under investments. It pertains to the investments a farmer has in the various
            stages of crops, equipment, animals, etc. and how to account for his success.
          </p>
          <p>
            <span className="glossary-title">Annuities</span> – a form of insurance or investment
            entitling the investor to a fixed sum of money to be paid each year typically for the
            rest of the investor’s life.
          </p>
          <p>
            <span className="glossary-title">Associations</span> – an organized group of people
            banded together for a specific purpose, having the same interest, job, etc. Examples:
            Airline Frequent Flyer Programs, Rental Car Programs, Hotel Programs, Golf and Tennis
            Clubs, etc.
          </p>
          <p>
            <span className="glossary-title">Autobiographical Information</span> – personal writings
            related to events in one’s life history, experiences, likes, dislikes, favorite items,
            issues, people, books, etc.
          </p>
          <p>
            <span className="glossary-title">Biological Parents</span> – a parent who has conceived,
            gave birth (woman) or sired, related by birth (man) rather than adopted a child and
            whose genes are therefore transmitted to the child.
          </p>
          <p>
            <span className="glossary-title">Body Donation to Science</span> – a form of
            non-transplant anatomical donation whereby at the time of death or immediately following
            a funeral, the deceased’s remains are received by the Anatomy Bequest Program and used
            for educational and research purposes.
          </p>
          <p>
            <span className="glossary-title">Brokerage Firms</span> – a business firm who charges a
            fee to act as an intermediary between buyer and seller. Typical brokerage businesses
            exist for people to purchase equities, commodities, currencies, etc.
          </p>
          <p>
            <span className="glossary-title">529/College Savings</span> – a tax advantaged savings
            plan designed to encourage saving for future educational costs.
          </p>
          <p>
            <span className="glossary-title">Creditors</span> – a person or company to whom money is
            owed and expects to be paid at a later date. Could be a bank, supplier or person who has
            provided money, goods or services.
          </p>
          <p>
            <span className="glossary-title">Data Storage</span> – data storage is the recording
            (storing) of information (data) in a storage medium. DNA and RNA, handwriting,
            photographic recording, magnetic tape and optical discs are all examples of storage
            media. Data storage in a digital, machine readable medium is sometimes called digital
            data. Computer data storage is one of the core functions of a general-purpose computer.
            Other examples of data storage are external drives and cloud storage.
          </p>
          <p>
            <span className="glossary-title">Digital Equipment</span> – electronic devices that
            allow individuals to express themselves, store, play, compute, etc. Examples are all
            types of computers, play stations, printers, copiers, storage devices, smart phones,
            smart watches, etc.
          </p>
          <p>
            <span className="glossary-title">Digital Services</span> – refers to the electronic
            delivery of information including data and content across multiple platforms and devices
            like web or mobile. Service providers deliver the network connection that enables a
            variety of communication services – primarily voice, video, messaging and data.
          </p>
          <p>
            <span className="glossary-title">Download PDF</span> – Download defines copying data
            from one computer system to another, typically over the internet. PDF is defined as a
            file format that provides an electronic image of text or text and graphics that looks
            like a printed document and can be viewed, printed and electronically transmitted.
            Downloading is the defined action to transfer a PDF file from your computer to Life
            Estate Organizer (LEO).
          </p>
          <p>
            <span className="glossary-title">Durable Power of Attorney</span> – A durable power of
            attorney for health care is a document that names your health care proxy. Your proxy is
            someone you trust to make health decisions for you if you are unable to do so.
          </p>
          <p>
            <span className="glossary-title">End of Life Service Type</span> – end of life is
            defined as the fact you are dead; the end of life of a person. Service type is a
            selection of the type of funeral service, memorial, etc. to be made by deceased prior to
            death or by a trusted individual after death.
          </p>
          <p>
            <span className="glossary-title">Estate Attorney</span> – is a bar certified attorney
            who specializes in estate planning and assists clients in drafting and implementing
            legal documents including wills and trusts. Estate attorneys often must work with
            related individuals who are involved with an estate.
          </p>
          <p>
            <span className="glossary-title">Estate Settlement</span> – in LEO, Estate Settlement
            identifies the legal and other documents relating to your health, death, estate and
            involved in settling an estate.
          </p>
          <p>
            <span className="glossary-title">Estate Trustee</span> – an individual person or member
            of a board given control or powers of administration of property in trust with a legal
            obligation to administer it solely for the purposes specified.
          </p>
          <p>
            <span className="glossary-title">Estate Wealth Plan</span> – a planning document that
            identifies the goals and actions to be taken in structuring your wealth while building
            it, preserving it, and transferring it to the next generation tax- optimized.
          </p>
          <p>
            <span className="glossary-title">Executor</span> – the representative who is designated
            by the deceased or by the Court if the person dies without a last will and testament to
            carry out the terms of a last will and testament and to wind up and execute the affairs
            of the deceased and distribute the estate to those entitled, acting in the best
            interests of the beneficiaries of the estate.
          </p>
          <p>
            <span className="glossary-title">Family</span> – a group consisting of parents and
            children living together in a household.
          </p>
          <p>
            <span className="glossary-title">Family DNA</span> – an analysis of autosomal DNA, Y-DNA
            and mitochondrial DNA to individuals for genealogical purpose.
          </p>
          <p>
            <span className="glossary-title">Family Tree</span> – a diagram showing the
            relationships between people in several generations of a family; a genealogical tree.
          </p>
          <p>
            <span className="glossary-title">Financial</span> – the study of money, investments and
            other financial instruments. The finances or financial situation of an individual.
          </p>
          <p>
            <span className="glossary-title">Financial Advisor</span> – a person who is employed by
            a client to provide financial services or guidance.
          </p>
          <p>
            <span className="glossary-title">Financial Decision Makers</span> – person or persons
            appointed by deceased responsible for making financial decisions regarding finances and
            investments.
          </p>
          <p>
            <span className="glossary-title">Generic Actions to Take Upon Death or Illness</span> –
            in LEO, examples of actions one could take in the event of death or illness of a loved
            one are outlined, not knowing the particular circumstances of the individual, therefore
            referring to them as generic, defined as characteristic of or related to a class or
            applied to a large segment of population, not specific to any one individual.
          </p>
          <p>
            <span className="glossary-title">Generic Probate Instructions</span> – probate
            instructions vary from state to state in the United States. LEO has outlined generic
            (characteristic of or related to a class or applied to a large segment of population,
            not specific to any one individual) steps that most states follow in the probate
            process.
          </p>
          <p>
            <span className="glossary-title">Head of household</span> – the person responsible for
            governing a group or family that lives together.
          </p>
          <p>
            <span className="glossary-title">Health Care Representative</span> – a person appointed
            by another person to be the sole decision maker, making medical choices and decisions in
            the event you become incapacitated.
          </p>
          <p>
            <span className="glossary-title">Insurance</span> – a practice or arrangement by which a
            company or government agency provides a guarantee of compensation for specific loss,
            damage, illness or death in return for payment of a premium.
          </p>
          <p>
            <span className="glossary-title">Intellectual Property</span> – refers to creations of
            the mind, a work or invention that is the result of creativity such as an invention,
            manuscript, design, literary and artistic works, symbols, names and images, etc., used
            in commerce to which one has rights and for which one may apply for a patent, copyright,
            trademark, etc.
          </p>
          <p>
            <span className="glossary-title">Investments</span> – the action or process of investing
            money for profit or material result.
          </p>
          <p>
            <span className="glossary-title">Keepsake</span> – an item kept or given to be kept as a
            token of friendship, affection or in remembrance of the person who gave it or originally
            owned it.
          </p>
          <p>
            <span className="glossary-title">Land Contract</span> – a form of seller financing. It
            is similar to a mortgage but rather than borrowing money from a lender or bank to buy
            real estate the buyer makes payments to the real estate owner or seller until the
            purchase price is paid in full.
          </p>
          <p>
            <span className="glossary-title">Last Will and Testament</span> – a legal document by
            which a person, the testator, expresses their final wishes as to how their property and
            possessions are to be distributed at death and names one or more persons, the executors,
            to manage the estate until the estate’s final distribution.
          </p>
          <p>
            <span className="glossary-title">Life Estate Organizer</span> – is a software program
            designed for you to input relative data regarding your life and estate.
          </p>
          <p>
            <span className="glossary-title">Life Estate Organizer Digital Trustee</span> – a
            trusted individual appointed by the deceased, prior to departure, to administer, manage,
            resolve and settle estate issues related to the deceased’s digital footprint. For
            example: computers, other digital equipment, digital services, data storage, etc.
          </p>
          <p>
            <span className="glossary-title">Life Estate Organizer PC Trustee</span> – a trusted
            individual appointed by the deceased, prior to departure to administer, manage, resolve
            and settle issues related to the deceased’s sensitive personal information.
          </p>
          <p>
            <span className="glossary-title">Life Estate Organizer Trustee</span> – a trusted
            individual appointed by the deceased, prior to departure, to have access to all or part
            of deceased’s Life Estate Organizer (LEO) as designated by the deceased. The Life Estate
            Organizer Trustee is usually one of deceased’s children, a family member or friend.
          </p>
          <p>
            <span className="glossary-title">Medical Information</span> – is protected health
            information, also referred to as personal health information, generally referring to
            demographic information, medical histories, test, laboratory results, mental health,
            medical conditions, insurance information and other data that a healthcare professional
            collects to identify an individual.
          </p>
          <p>
            <span className="glossary-title">Medical Information Release</span> – is a signed
            release form (signed by person who is the subject of the medical information) allowing
            their protected health information to be shared with other individuals or organizations
            as permitted by HIPAA Privacy Rule.
          </p>
          <p>
            <span className="glossary-title">Net Worth Statement</span> – is a snapshot of an
            individual’s financial health at a particular point in time. It is a summary of what is
            owned (assets) less what is owed to others (liabilities) equals Net Worth.
          </p>
          <p>
            <span className="glossary-title">Online Banking</span> – a method of banking giving you
            the ability to manage money online with your mobile device or computer in which
            transactions are conducted electronically via the internet.
          </p>
          <p>
            <span className="glossary-title">Organ Donor</span> – is a person who has agreed by
            signing an organ donor document to donate an organ, organs or tissue, as defined by the
            organ donor document whereby the organ, organs or tissue are surgically removed from the
            donor and placed into another person, the recipient, to replace a failed damaged,
            diseased or injured organ, organs or tissue.
          </p>
          <p>
            <span className="glossary-title">Pending Commitments</span> – is a commitment made by
            the deceased while still living by which an agreement was made between the deceased and
            the client by which the deceased would deliver a product or service to the client by a
            certain date for an agreed upon amount of money.
          </p>
          <p>
            <span className="glossary-title">Pending Lawsuits</span> – a lawsuit is said to be
            pending from its inception until the issuance of a final judgement by a court.
          </p>
          <p>
            <span className="glossary-title">Pension Funds</span> – a fund from which pensions are
            paid, accumulated from contributions from employers, employees or both.
          </p>
          <p>
            <span className="glossary-title">Permanent Memorialization</span> – a permanent place or
            item (such as a tree, engraved rock, etc.) where family and friends can go to remember
            loved ones and find comfort long after the deceased has passed for generations to come.
          </p>
          <p>
            <span className="glossary-title">Personal Information</span> – is information or an
            opinion, including information or an opinion forming part of a database, whether true or
            not, and recorded in material form or not, about an individual whose identity is
            apparent or can reasonably be ascertained from the information or opinion.
          </p>
          <p>
            <span className="glossary-title">Personal Property</span> – is anything besides land
            that may be subject to ownership. The main characteristic of personal property is that
            it is movable, unlike real property or real estate, which is not. There are two types of
            personal property: tangible and intangible. Tangible property is personal property that
            can be physically handled such as clothes, jewelry, furniture, etc. Intangible personal
            property is property that can’t be physically handled such as stocks, trust fund
            accounts, etc.
          </p>
          <p>
            <span className="glossary-title">Private Banker</span> – is a person at a banking
            institution who is assigned to provide banking services, investment and other financial
            services to high-net worth individuals with high levels of income or sizable assets.
          </p>
          <p>
            <span className="glossary-title">Profile Scope Selection</span> – the second step to be
            taken in documenting your Life Estate Organizer. The Profile Scope Selection is a list
            of sections and subsections to be reviewed by user and for user to select the sections
            and subsections that apply to their life and estate.
          </p>
          <p>
            <span className="glossary-title">Probate</span> – is a legal process that takes place
            after someone dies, proving in court that a deceased person’s will is valid; identifying
            and inventorying the deceased person’s property; having the property appraised; paying
            debts and taxes and distributing the remaining property as the will (or state law, if
            there is no will) directs.
          </p>
          <p>
            <span className="glossary-title">Profit Sharing Plan</span> – is a type of defined
            contribution plan that lets companies help employees save for retirement.
          </p>
          <p>
            <span className="glossary-title">Promissory Note</span> – a signed document containing a
            written promise by one party (the note’s issuer or maker) to pay another party (the
            note’s payee) a definite sum of money on a specified date or on demand.
          </p>
          <p>
            <span className="glossary-title">Property</span> – a thing or things belonging to
            someone consisting of possessions, belongings, worldly goods, personal effects, stuff,
            chattels, movables, etc.
          </p>
          <p>
            <span className="glossary-title">Property Caretaker</span> – is a person, group or
            organization that cares for real estate for trade or financial compensation and
            sometimes as a barter for rent-free living accommodations.
          </p>
          <p>
            <span className="glossary-title">Residence Expenses</span> – is the sum of a homeowner’s
            monthly mortgage principal and interest payments plus other monthly expenses for
            insurance, utilities, etc.
          </p>
          <p>
            <span className="glossary-title">Residence Maintenance</span> – is the sum of a
            homeowner’s monthly and variable expenses for home maintenance for plumbing, electrical,
            painting, landscaping, etc.
          </p>
          <p>
            <span className="glossary-title">Secondary Executor</span> – is a representative to be
            considered in case your primary executor choice is unwilling or unable to take on the
            responsibilities of an executor.
          </p>
          <p>
            <span className="glossary-title">Sensitive Personal Information</span> - is information
            as outlined in the sensitive information section of LEO. This information is generally
            been kept secret from other family members, friends and associates, could be harmful to
            the reputation of the deceased and requires special handling by a trusted individual
            such as an appointed Life Estate Organizer PC Trustee.
          </p>
          <p>
            <span className="glossary-title">SEP/SARSEP</span> – a SARSEP is a simplified employee
            pension (SEP) plan set up before 1997 that includes a salary reduction arrangement.
            Under a SARSEP, employees can choose to have the employer contribute part of their pay
            to their Individual Retirement Account (IRA).
          </p>
          <p>
            <span className="glossary-title">Serial Number</span> – sometimes abbreviated as Serial
            No., SN or S/N. A serial number is a unique number used for identification and inventory
            purposes. A serial number allows a company to identify a product and get additional
            information about it, for replacement or as a means of finding compatible parts.
          </p>
          <p>
            <span className="glossary-title">Sources of Income</span> – means the place or places or
            areas from which a person makes money.
          </p>
          <p>
            <span className="glossary-title">Stock Bonus Plan</span> – is a type of retirement plan
            designed to increase an employees’ vested interest in a company’s success. A stock bonus
            plan is a type of profit-sharing plan paid in employer stock instead of cash.
          </p>
          <p>
            <span className="glossary-title">
              Traded equities, commodities, currencies, bonds, etc.
            </span>{' '}
            – trading is defined as buying and selling different types of investments on different
            exchanges where investors work to purchase or trade investments in an attempt to
            generate a profit from the fluctuation of market prices ; equities are stocks or any
            other security representing an ownership interest, which might be in a private (private
            equity) or publicly traded company on an exchange; commodities are a raw material or
            primary agricultural product that can be bought and sold; currencies are defined as a
            system of money in any form in common use especially for people in a nation when in use
            or circulation as a medium of exchange that can be traded on an exchange; a bond is a
            fixed income instrument that represents a loan made by an investor to a borrower.
          </p>
          <p>
            <span className="glossary-title">Trusted Individual</span> – is a person whom another
            person has confidence and a firm belief in the integrity, ability or character of that
            individual.
          </p>
          <p>
            <span className="glossary-title">VIN Number</span> – a vehicle information number (VIN)
            is a unique code, including a serial number, used by the automotive industry to identify
            individual motor vehicles, towed vehicles, motorcycles, scooters and mopeds. VIN numbers
            are inscribed on vehicles in visual locations such as on the windshield, frame, motor,
            etc.
          </p>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="default" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
