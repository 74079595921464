var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable max-classes-per-file */
import { useRef } from 'react';
import { useAxiosService } from './axiosService';
import { useUserService } from './userService';
export class InvalidLoginCredentialsError extends Error {
}
export class EmailVerificationRequiredError extends Error {
}
export class ExpiredResetPasswordTokenError extends Error {
}
export class TooManyRequestsError extends Error {
}
export class InvalidMultiFactorCodeError extends Error {
}
export class UserNotFoundError extends Error {
}
export const useAuthService = () => {
    const axios = useAxiosService();
    const UserService = useUserService();
    const { current: AuthService } = useRef({
        getAuthState() {
            return __awaiter(this, void 0, void 0, function* () {
                const { data: { userId }, } = yield axios.get(`auth`);
                const currentUser = yield UserService.getUserById(userId);
                const loggedInUser = yield UserService.getUserById(userId);
                return { currentUser, loggedInUser };
            });
        },
    });
    return AuthService;
};
