import React, { useEffect, useState, VFC } from 'react';
import AddIcon from '@mui/icons-material/Add';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { MenuItem } from '@quiet-sunset/leo-shared';
// eslint-disable-next-line import/no-cycle
import { MenuItemTreeNode } from './menuItemTreeNode';
import {
  useAddFieldGroup,
  useAddFieldGroupFromClipboard,
  useAddMenuItem,
  useRetrieveMenuItems,
} from './hooks';
import { useProfileAdminClipboardContext } from '../profileAdminClipboardContext';

export type MenuItemTreeProps = {
  rootMenuItem?: MenuItem | null;
  onUpdate: () => Promise<void>;
};

export const MenuItemTree: VFC<MenuItemTreeProps> = ({ rootMenuItem = null, onUpdate }) => {
  const { clipboardContents } = useProfileAdminClipboardContext();
  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);

  const retrieveMenuItems = useRetrieveMenuItems(rootMenuItem?.id, setMenuItems);
  const addMenuItem = useAddMenuItem(rootMenuItem?.id, menuItems, retrieveMenuItems);
  const addFieldGroup = useAddFieldGroup(rootMenuItem, onUpdate);
  const addFieldGroupFromClipboard = useAddFieldGroupFromClipboard(rootMenuItem?.id, onUpdate);

  useEffect(() => {
    retrieveMenuItems().catch(console.error);
  }, [retrieveMenuItems]);

  return (
    <List sx={{ pl: rootMenuItem ? 4 : 0 }}>
      {menuItems?.map((_menuItem) => (
        <MenuItemTreeNode
          key={_menuItem.id}
          menuItemId={_menuItem.id}
          onUpdate={retrieveMenuItems}
        />
      ))}
      {rootMenuItem && (
        <ListItemButton onClick={addMenuItem}>
          <AddIcon />
          <ListItemText>Add menu item</ListItemText>
        </ListItemButton>
      )}
      {rootMenuItem && !rootMenuItem.field_group_id && !menuItems.length && (
        <>
          <ListItemButton onClick={addFieldGroup}>
            <AddIcon />
            <ListItemText>Add field group</ListItemText>
          </ListItemButton>
          {clipboardContents.length === 1 && clipboardContents[0].type === 'field_group' && (
            <ListItemButton onClick={addFieldGroupFromClipboard}>
              <AddIcon />
              <ListItemText>Add field group from clipboard</ListItemText>
            </ListItemButton>
          )}
        </>
      )}
    </List>
  );
};
