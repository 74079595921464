import React, { useCallback, useState, VFC } from 'react';
import { MenuItem } from '@quiet-sunset/leo-shared';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import { useUpdateMenuItem } from './hooks';

export type MenuItemEditDialogType = {
  menuItem: MenuItem;
  onClose: () => void;
};

export const MenuItemEditDialog: VFC<MenuItemEditDialogType> = ({ menuItem, onClose }) => {
  const [label, setLabel] = useState(menuItem.label);

  const updateMenuItem = useUpdateMenuItem(onClose);

  const submit = useCallback(() => {
    updateMenuItem({
      ...menuItem,
      label,
    });
  }, [updateMenuItem, menuItem, label]);

  return (
    <Dialog open fullWidth>
      <DialogTitle>Edit menu item</DialogTitle>
      <DialogContent>
        <Stack gap={5}>
          <TextField
            label="Name"
            fullWidth
            variant="standard"
            value={label}
            onChange={(e) => setLabel(e.target.value)}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={submit}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};
