import React from 'react';
import { Button, Modal } from 'react-bootstrap';

export interface HelpTextModalProps {
  title: string;
  message: string;
  onClose: () => unknown;
}

export const HelpTextModal: React.FunctionComponent<HelpTextModalProps> = (props) => {
  const { title, message, onClose } = props;

  return (
    <Modal show backdrop="static" keyboard={false}>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {/* eslint-disable-next-line react/no-danger */}
        <p dangerouslySetInnerHTML={{ __html: message }} />
      </Modal.Body>

      <Modal.Footer>
        <Button variant="default" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
