import React, { useCallback, useState } from 'react';
import { LoadingSpinner } from '$cmp/loadingSpinner';
import type { SupportFormValues } from '@quiet-sunset/leo-shared';
import { useSupportService } from '@quiet-sunset/leo-shared';
import { SupportForm } from './supportForm';

export const SupportRoute: React.FunctionComponent = () => {
  const SupportService = useSupportService();
  const [isLoaded, setIsLoaded] = useState(true);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const submitForm = useCallback(async (values: SupportFormValues) => {
    setIsLoaded(false);
    await SupportService.submitSupportRequest(values);
    setIsFormSubmitted(true);
    setIsLoaded(true);
  }, []);

  return (
    <>
      {!isLoaded && <LoadingSpinner />}
      {!isFormSubmitted && <SupportForm onSubmit={submitForm} />}
      {isFormSubmitted && (
        <div style={{ marginTop: '100px' }}>
          <p>
            Thank you for contacting us. A LEO representative will be in touch in regard to this
            matter.
          </p>
        </div>
      )}
    </>
  );
};
