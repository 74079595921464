import React from 'react';
import { TextField as StyledTextField } from '$cmp/form/textField';

export interface TextFieldProps {
  value: string;
  onChange: (value: string) => any;
  isDirty: boolean;
  disabled?: boolean;
}

export const TextField: React.FunctionComponent<TextFieldProps> = React.memo((props) => {
  const { value, onChange, isDirty, disabled } = props;

  return (
    <StyledTextField
      label=""
      value={value}
      onChange={(e) => onChange(e.target.value)}
      fullWidth
      my={2}
      disabled={disabled}
      focused={isDirty ? true : undefined}
    />
  );
});
