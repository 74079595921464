import React, { useCallback } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Field, Form } from 'react-final-form';

export interface CollectEmailAddressModalProps {
  title: string;
  fieldLabel?: string;
  onSubmit: (emailAddress: string) => any;
  onCancel: () => any;
}

export const CollectEmailAddressModal: React.FunctionComponent<CollectEmailAddressModalProps> = (
  props
) => {
  const { title, fieldLabel, onSubmit, onCancel } = props;

  const submit = useCallback(
    (values: { email: string }) => {
      onSubmit(values.email);
    },
    [onSubmit]
  );

  return (
    <Modal show backdrop="static" keyboard={false}>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Form<{ email: string }> onSubmit={submit}>
        {({ handleSubmit }) => (
          <form className="form-signin form-horizontal" onSubmit={handleSubmit}>
            <Modal.Body>
              <Field
                name="email"
                component="input"
                className="form-control"
                placeholder={fieldLabel ?? 'Email'}
                required
              />
            </Modal.Body>

            <Modal.Footer>
              <Button variant="default" onClick={onCancel}>
                Cancel
              </Button>
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Modal.Footer>
          </form>
        )}
      </Form>
    </Modal>
  );
};
