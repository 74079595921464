import React, { useCallback, useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { DefaultCheckmark } from '$cmp/defaultCheckmark';
import { LoadingSpinner } from '$cmp/loadingSpinner';
import { useCurrentUserContext } from '$contexts/currentUserContext';
import type { LeoProOrganization } from '@quiet-sunset/leo-shared';
import { useLeoProOrganizationsService, useUserService } from '@quiet-sunset/leo-shared';

export interface LeoProOrganizationListProps {
  updateTrigger?: Record<string, unknown>;
  onUpdate: () => unknown;
}

export const LeoProOrganizationList: React.FunctionComponent<LeoProOrganizationListProps> = (
  props
) => {
  const { updateTrigger, onUpdate } = props;

  const LeoProOrganizationsService = useLeoProOrganizationsService();
  const UserService = useUserService();

  const { currentUser, reloadCurrentUser } = useCurrentUserContext();

  const [isLoaded, setIsLoaded] = useState(false);
  const [leoProOrganizations, setLeoProOrganizations] = useState(
    null as LeoProOrganization[] | null
  );

  const makeDefault = useCallback(
    async (leoProOrganizationId: string | null) => {
      setIsLoaded(false);
      try {
        await UserService.setDefaultLeoProOrganization(leoProOrganizationId);
        await reloadCurrentUser();
        onUpdate();
      } finally {
        setIsLoaded(true);
      }
    },
    [UserService, reloadCurrentUser, onUpdate]
  );

  useEffect(() => {
    void (async () => {
      setIsLoaded(false);
      const newLeoProOrganizations =
        await LeoProOrganizationsService.getLeoProOrganizationsForCurrentUser();
      setLeoProOrganizations(newLeoProOrganizations);
      setIsLoaded(true);
    })();
  }, [LeoProOrganizationsService, updateTrigger]);

  return (
    <>
      <h3>LEO-PRO accounts</h3>
      {!isLoaded && <LoadingSpinner />}
      {isLoaded && (
        <Table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Default</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {leoProOrganizations == null && (
              <tr>
                <td colSpan={2}>Loading...</td>
              </tr>
            )}
            {leoProOrganizations != null && leoProOrganizations.length === 0 && (
              <tr>
                <td colSpan={2}>You are not part of any LEO-PRO accounts</td>
              </tr>
            )}
            {leoProOrganizations != null &&
              leoProOrganizations.length !== 0 &&
              leoProOrganizations.map((leoProOrganization) => (
                <tr key={leoProOrganization.id}>
                  <td>{leoProOrganization.name}</td>
                  <td>
                    {currentUser?.default_leo_pro_organization_id === leoProOrganization.id && (
                      <DefaultCheckmark />
                    )}
                  </td>
                  <td>
                    {currentUser?.default_leo_pro_organization_id === leoProOrganization.id ? (
                      <Button variant="secondary" onClick={() => makeDefault(null)}>
                        Remove default
                      </Button>
                    ) : (
                      <Button
                        variant="secondary"
                        onClick={() => makeDefault(leoProOrganization.id)}
                      >
                        Make default
                      </Button>
                    )}
                    <Link to={`/leo-pro/${leoProOrganization.id}`}>
                      <Button variant="primary">Manage</Button>
                    </Link>
                  </td>
                </tr>
              ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={3}>
                <Link to="/leo-pro/register">
                  <Button variant="success">Register new LEO-PRO account</Button>
                </Link>
              </td>
            </tr>
          </tfoot>
        </Table>
      )}
    </>
  );
};
