import React, { useMemo } from 'react';
import { YesNoModal } from '$cmp/modals/yesNoModal';
import type { ProfileAccessorUser } from '@quiet-sunset/leo-shared';

export interface TrustedUserDeclineInvitationModalProps {
  profileAccessorUserInvitation: ProfileAccessorUser;
  onConfirm: () => unknown;
  onCancel: () => unknown;
}

export const TrustedUserDeclineInvitationModal: React.FunctionComponent<TrustedUserDeclineInvitationModalProps> =
  (props) => {
    const { profileAccessorUserInvitation, onConfirm, onCancel } = props;

    const title = `Decline trusted user invitation`;

    const inviterName = useMemo(() => {
      const x = `${profileAccessorUserInvitation.invited_by_user_first_name ?? ''} ${
        profileAccessorUserInvitation.invited_by_user_last_name ?? ''
      } (${profileAccessorUserInvitation.invited_by_user_email ?? ''})`;
      return x.trim().length > 0 ? x : '(unknown)';
    }, [profileAccessorUserInvitation]);

    const headOfHouseholdName = useMemo(() => {
      const x = `${profileAccessorUserInvitation.head_of_household_name?.first_name ?? ''} ${
        profileAccessorUserInvitation.head_of_household_name?.middle_name ?? ''
      } ${profileAccessorUserInvitation.head_of_household_name?.last_name ?? ''}`;
      return x.trim().length > 0 ? x : '(unnamed)';
    }, [profileAccessorUserInvitation]);

    const message = useMemo(() => {
      return `Do you want to decline your invitation from ${inviterName} to the profile owned by ${headOfHouseholdName} as ${profileAccessorUserInvitation.trusted_user_type}?`;
    }, [headOfHouseholdName, inviterName, profileAccessorUserInvitation.trusted_user_type]);

    return <YesNoModal title={title} message={message} onYes={onConfirm} onNo={onCancel} />;
  };
