import React, { useCallback, useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { Redirect, useHistory } from 'react-router-dom';
import { useProfileContext, useProfilesService } from '@quiet-sunset/leo-shared';

import { LoadingSpinner } from '$cmp/loadingSpinner';
import { YesNoModal } from '$cmp/modals/yesNoModal';
import { UserName } from '$cmp/userName';

import { useCurrentUserContext } from '$contexts/currentUserContext';

import { useUpdateTrigger } from '$hooks/useUpdateTrigger';
import { useSetterUnsetter } from '$hooks/useSetterUnsetter';

export const ProfileManageLeoProAccessRoute: React.FunctionComponent = () => {
  const ProfilesService = useProfilesService();

  const history = useHistory();

  const { currentUser } = useCurrentUserContext();

  const { profileId, leoProOrganizationProfile, reloadProfile } = useProfileContext();
  const [updateTrigger, triggerUpdate] = useUpdateTrigger();
  const [disconnectProfileModalIsVisible, showDisconnectProfileModal, hideDisconnectProfileModal] =
    useSetterUnsetter();

  const [newClientEmail, setNewClientEmail] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);

  const addNewClient = useCallback(async () => {
    setIsLoaded(false);
    await ProfilesService.updateLeoProOrganizationProfileSubject(profileId, newClientEmail);
    triggerUpdate();
  }, [newClientEmail, profileId, triggerUpdate]);

  const removeClient = useCallback(async () => {
    setIsLoaded(false);
    await ProfilesService.removeLeoProOrganizationProfileSubject(profileId);
    triggerUpdate();
  }, [profileId, triggerUpdate]);

  const disconnectProfileFromLeoPro = useCallback(async () => {
    setIsLoaded(false);
    await ProfilesService.disconnectProfileFromLeoPro(profileId);
    history.push('/leo-pro');
  }, [history, profileId]);

  useEffect(() => {
    setIsLoaded(false);
    (async () => {
      await reloadProfile();
      setIsLoaded(true);
    })();

    return () => {
      setIsLoaded(false);
    };
  }, [updateTrigger]);

  if (!isLoaded || !currentUser) {
    return <LoadingSpinner />;
  }

  if (leoProOrganizationProfile == null) {
    return <Redirect to={`/profiles/${profileId}`} />;
  }

  const currentUserIsManager =
    leoProOrganizationProfile.manager?.user != null &&
    leoProOrganizationProfile.manager.user.id === currentUser.id;
  const currentUserIsSubjectUser =
    leoProOrganizationProfile.subject_user != null &&
    leoProOrganizationProfile.subject_user.id === currentUser.id;

  return (
    <div style={{ display: 'flex' }}>
      {disconnectProfileModalIsVisible && (
        <YesNoModal
          title="Disconnect profile from LEO-PRO"
          message="Are you sure you want to disconnect this profile from LEO-PRO? You will be responsible for billing."
          onNo={hideDisconnectProfileModal}
          onYes={disconnectProfileFromLeoPro}
        />
      )}
      <div style={{ flex: '1 0 15px' }} />
      <div
        id="leo-profile-content-body-container"
        style={{ width: '100%', flex: '0 1 1000px', margin: 0 }}
      >
        <h3>Manage LEO-PRO access</h3>
        <Table>
          <tbody>
            <tr>
              <th>LEO-PRO manager</th>
              <td>
                {leoProOrganizationProfile.manager?.user && (
                  <UserName user={leoProOrganizationProfile.manager.user} />
                )}
                {leoProOrganizationProfile.manager && !leoProOrganizationProfile.manager.user && (
                  <>{leoProOrganizationProfile.manager.invite_email}</>
                )}
                {!leoProOrganizationProfile.manager && <em>(none)</em>}
              </td>
            </tr>
            <tr>
              <th>Client</th>
              <td>
                {leoProOrganizationProfile.subject_user ? (
                  <>
                    <UserName user={leoProOrganizationProfile.subject_user} />
                    {currentUserIsManager && (
                      <Button onClick={removeClient} variant="danger">
                        Remove
                      </Button>
                    )}
                  </>
                ) : (
                  <>
                    <em>(none)</em>
                    {currentUserIsManager && (
                      <div style={{ display: 'flex' }}>
                        <input
                          className="form-control"
                          placeholder="Email"
                          value={newClientEmail}
                          onChange={(e) => setNewClientEmail(e.target.value)}
                        />
                        <Button onClick={addNewClient}>Add</Button>
                      </div>
                    )}
                  </>
                )}
              </td>
            </tr>
          </tbody>
        </Table>
        {currentUserIsSubjectUser && (
          <Button onClick={showDisconnectProfileModal} variant="danger">
            Disconnect profile from LEO-PRO
          </Button>
        )}
      </div>
      <div style={{ flex: '1 0 15px' }} />
    </div>
  );
};
