import React from 'react';
import { Button, Modal } from 'react-bootstrap';

export interface TipsModalProps {
  onClose: () => unknown;
}

export const TipsModal: React.FunctionComponent<TipsModalProps> = (props) => {
  const { onClose } = props;

  return (
    <Modal show backdrop="static" keyboard={false} size="lg">
      <Modal.Header>
        <Modal.Title>Tips</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <ol>
          <li>
            Remember the location of your important documents is very important to the settlement of
            your estate. LEO provides you the opportunity to upload and secure all your documents in
            one place (LEO) organized by subject.
          </li>
          <li>Follow the STEPS to set-up your Profile Scope Selection.</li>
          <li>
            Review all sections and subsections to determine if they are pertinent to your Life and
            Estate. Checkmark those that apply to your Life and Estate.
          </li>
          <li>
            Remember you can always go back to add, delete or change sections, sub-sections, data,
            etc. at any time.
          </li>
          <li>
            Before you start a subsection, review the HELP (represented next to subject by a
            question mark) screen to determine what information will be required to fill out this
            subsection.
          </li>
          <li>
            Consider uploading a copy or photo of a bill, statements, etc. instead of entering the
            requested data listed on the bill.
          </li>
          <li>
            Use your camera to take a photo and upload picture of represented items rather than
            describing them. This can be done for a host of items: educational documents, bank and
            brokerage statements, contractors and utility invoices showing account numbers and
            contact information, etc.
          </li>
          <li>
            Consider entering the URL of a company instead of entering name, address, city, state,
            zip code, telephone number, etc.
          </li>
          <li>Consider taking a picture of bills, statements and other items and upload to LEO.</li>
          <li>
            If you are unfamiliar with computers, software, typing, etc. you might want to obtain
            help from a trusted individual to help you fill out LEO until you are comfortable with
            the program and the process.
          </li>
          <li>
            You may want to ask for help or have your estate attorney, financial advisor, private
            banker, etc. help or fill out your LEO and those sections pertinent to what they do for
            you.
          </li>
          <li>
            When you are working in a section or sub-section think about what you would like to
            upload from your computer to LEO and the section it applies to.
          </li>
          <li>
            Obtain files from your estate attorney and others that you would like to upload to LEO.
            For example, photos, Last Will and Testament, Organ Donor, etc.
          </li>
          <li>
            Use LEO appointment documents to legally appoint trusted individuals (executor,
            secondary executor, LEO trustee, digital trustee, PC trustee, estate attorney, financial
            advisor, private banker, trustee, etc.) and pet guardians.
          </li>
          <li>
            Use LEO to store all your life and estate documents. See estate settlement documents,
            photos, copies of anything, etc. Keep them all in one place for reference by you and
            your trusted individuals.
          </li>
        </ol>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="default" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
