import React from 'react';
import styled from 'styled-components';

import { TextField as MuiTextField, TextFieldProps } from '@mui/material';
import { spacing } from '@mui/system';

const _TextField = (props: TextFieldProps) => {
  return <MuiTextField {...props} variant="standard" />;
};

export const TextField = styled(_TextField)<{ my?: number }>(spacing);
