import React from 'react';
import { Field } from 'react-final-form';

export const ExecutorAppointmentDocumentFormContents: React.FunctionComponent = () => {
  return (
    <>
      <p style={{ textAlign: 'center' }}>Executor Appointment Document</p>
      <p style={{ textAlign: 'center' }}>For</p>
      <p style={{ textAlign: 'center' }}>Life Estate Organizer</p>
      <p style={{ textAlign: 'center' }}>For</p>
      <p style={{ textAlign: 'center' }}>Head of Household</p>
      <p style={{ textAlign: 'center' }}>
        <Field
          name="agreementModel.name"
          component="input"
          placeholder="Name"
          autoComplete="headOfHouseholdName"
          className="form-control form-control-inline"
        />
      </p>
      <p>
        I,{' '}
        <Field
          name="agreementModel.name"
          component="input"
          placeholder="Name"
          autoComplete="headOfHouseholdName"
          className="form-control form-control-inline"
        />
        , of{' '}
        <Field
          name="agreementModel.county"
          component="input"
          placeholder="County"
          autoComplete="headOfHouseholdCounty"
          className="form-control form-control-inline"
        />
        County,{' '}
        <Field
          name="agreementModel.state"
          component="input"
          placeholder="State"
          autoComplete="headOfHouseholdState"
          className="form-control form-control-inline"
        />
        ,
        <Field
          name="agreementModel.country"
          component="input"
          placeholder="Country"
          autoComplete="headOfHouseholdCountry"
          className="form-control form-control-inline"
        />
        do hereby make, publish and declare this to be my Life Estate Organizer. I hereby revoke all
        previous Life Estate Organizers or writings made by me.
      </p>
      <p>
        <span style={{ textDecoration: 'underline' }}>ITEM I.</span> I bequeath all of my personal
        effects and other tangible personal property as listed in my Life Estate Organizer to my
        Executor in his fiduciary capacity to be divided in accordance with my wishes as outlined in
        my Life Estate Organizer. If any property is not described in my Life Estate Organizer, I
        direct my Executor to distribute all of my tangible personal property not included in my
        Life Estate Organizer to my spouse,{' '}
        <Field
          name="agreementModel.spouseName"
          component="input"
          placeholder="Spouse name"
          autoComplete="spouseName"
          className="form-control form-control-inline"
        />
        , if she survives me. If my spouse does not survive me, I direct my Executor to distribute
        such tangible personal property as outlined in my Life Estate Organizer or as my Executor
        deems to distribute such personal property.
      </p>
      <div>
        <span style={{ textDecoration: 'underline' }}>ITEM II.</span> In carrying out their duties
        under this Life Estate Organizer my Executor and any successor Executor shall have in
        addition to their common law and statutory powers and without court order the following
        powers:
        <ol style={{ listStyle: 'upper-alpha' }}>
          <li>
            To receive, hold, manage, improve, repair, sell, lease for a period of time, pledge,
            exchange or otherwise dispose of parts of my estate as listed in my Life Estate
            Organizer as deemed advisable or proper.
          </li>
          <li>
            To invest and reinvest any or all of the principal or income of the listed items in my
            Life Estate Organizer without restriction to which Executors are permitted by law.
          </li>
          <li>
            To employ brokers, agents and counsel as they deem advisable or proper in connection
            with their duties as Executor and to pay such brokers, agents and counsel a reasonable
            fee. My Executor shall not be liable for the acts of such brokers, agents or counsel for
            acts done in good faith and in reliance upon advice of such brokers, agents and counsel,
            provided they used reasonable care in their selection.
          </li>
          <li>To make distributions according to absolute discretion.</li>
          <li>
            To pay any property distributable to a minor directly to such minor personally, or to
            such minor’s legal guardian, or to a custodian selected by my Executor or any similar or
            successor statutory provision.
          </li>
          <li>
            To settle and adjust all of my personal and business interests outlined in my Life
            Estate Organizer, not covered in my Last Will and Testament.
          </li>
          <li>
            My Executor shall have the power to exercise any election they may have as to whether to
            claim any expenses of administration of my Life Estate Organizer as a deduction for any
            death tax or income tax purpose in such manner as they believe will minimize the total
            tax burden on my estate without any adjustments as between income and principal accounts
            of my estate. I hereby relieve my Executor of any liability to any beneficiary by reason
            of making or failing to make any such election.
          </li>
          <li>
            My Executor shall have the power to resolve all issues related to my estate as
            documented in my Life Estate Organizer. This includes but not restricted to issues
            related to maintenance, subscriptions, prescriptions, extensions, cancellations,
            assignment of services, utilities, associations, distribution and/or disposal of assets,
            settling liabilities, closing accounts, etc. of those items not specifically covered in
            my Last Will and Testament.
          </li>
        </ol>
      </div>
      <div>
        <span style={{ textDecoration: 'underline' }}>ITEM III.</span>
        <ol style={{ listStyle: 'upper-alpha' }}>
          <li>
            I nominate and appoint as Executor of my Life Estate Organizer the first-named of the
            following who can and will qualify to serve, and if the one qualifying cease to serve
            for any reason, the next in the order named who can and will qualify and serve.
            <ol>
              <li>
                My Spouse{' '}
                <Field
                  name="agreementModel.spouseName"
                  component="input"
                  placeholder="Spouse name"
                  autoComplete="spouseName"
                  className="form-control form-control-inline"
                />
              </li>
              <li>
                <Field
                  name="agreementModel.executor1"
                  component="input"
                  placeholder="Name"
                  autoComplete="executor1"
                  className="form-control form-control-inline"
                />
              </li>
              <li>
                <Field
                  name="agreementModel.executor2"
                  component="input"
                  placeholder="Name"
                  autoComplete="executor2"
                  className="form-control form-control-inline"
                />
              </li>
            </ol>
          </li>
          <li>
            I direct that my Executor, original, alternate or successor, shall not be required to
            give any bond for the performance of their duties. Purchasers from, Lenders to and
            others dealing with my Executor shall not be required to inquire as to the occasion of
            such sale, loan or other transaction, nor to see to the application of the purchase,
            loan or other money or property. Written receipts filed by my Executor shall be good and
            sufficient discharges for the sum therein stated to have been received. Transfer agents
            and other persons dealing with my Executor may deal with them as if they were sole owner
            of the property dealt with.
          </li>
          <li>
            All alternate or successor Executors shall have the same powers, authorities and duties
            herein conferred upon my original Executor; wherever the word “Executor” is used in the
            Life Estate Organizer, it shall be deemed to include my alternate or successor
            Executors.
          </li>
        </ol>
      </div>
      <p>
        I hereby check this block, type in my name and the date of agreement that this is my
        Executor Appointment Document and has been filled out by me to the best of my knowledge and
        ability, revoke all previous Executor Documents or writings made by me and direct my
        Executor to use my Life Estate Organizer and Last Will and Testament in the settlement of my
        estate.
      </p>
    </>
  );
};
