var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useRef } from 'react';
import { useAxiosService } from './axiosService';
export class MenuItemsService {
    constructor(axios) {
        this.axios = axios;
    }
    getUserById(userId) {
        return __awaiter(this, void 0, void 0, function* () {
            const { data } = yield this.axios.get(`users/${userId}`);
            return data;
        });
    }
    getAllMenuItems() {
        return __awaiter(this, void 0, void 0, function* () {
            const { data } = yield this.axios.get(`menu-items`);
            return data;
        });
    }
    getMenuItemsByParentId(menuItemId) {
        return __awaiter(this, void 0, void 0, function* () {
            const { data } = yield this.axios.get(`menu-items?parentId=${menuItemId != null ? menuItemId : 'null'}`);
            return data;
        });
    }
    getMenuItemsByParentIdForProfile(profileId, menuItemId) {
        return __awaiter(this, void 0, void 0, function* () {
            const { data } = yield this.axios.get(`profiles/${profileId}/menu-items?parentId=${menuItemId != null ? menuItemId : 'null'}`);
            return data;
        });
    }
    getMenuItemById(menuItemId) {
        return __awaiter(this, void 0, void 0, function* () {
            const { data } = yield this.axios.get(`menu-items/${menuItemId}`);
            return data;
        });
    }
    getAncestorMenuItemsByMenuItemId(menuItemId) {
        return __awaiter(this, void 0, void 0, function* () {
            const { data } = yield this.axios.get(`menu-items/${menuItemId}/ancestors`);
            return data;
        });
    }
    getDescendantMenuItemsByMenuItemId(menuItemId) {
        return __awaiter(this, void 0, void 0, function* () {
            const { data } = yield this.axios.get(`menu-items/${menuItemId}/descendants`);
            return data;
        });
    }
    createMenuItem(menuItem) {
        return __awaiter(this, void 0, void 0, function* () {
            const { data } = yield this.axios.post(`menu-items`, menuItem);
            return data;
        });
    }
    updateMenuItem(menuItem) {
        return __awaiter(this, void 0, void 0, function* () {
            const { data } = yield this.axios.put(`menu-items/${menuItem.id}`, menuItem);
            return data;
        });
    }
    moveMenuItemUp(menuItemId) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.axios.post(`menu-items/${menuItemId}/moveUp`);
        });
    }
    moveMenuItemDown(menuItemId) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.axios.post(`menu-items/${menuItemId}/moveDown`);
        });
    }
    deleteMenuItem(menuItemId) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.axios.delete(`menu-items/${menuItemId}`);
        });
    }
}
export const useMenuItemsService = () => {
    const axios = useAxiosService();
    const { current: service } = useRef(new MenuItemsService(axios));
    return service;
};
