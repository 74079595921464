import React, { useCallback, useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import dayjs from 'dayjs';
import type { TrustedUserType, TrustedUserEditModel } from '@quiet-sunset/leo-shared';
import { useProfileContext, useProfilesService } from '@quiet-sunset/leo-shared';

import { EditTrustedUserModal } from '$cmp/modals/editTrustedUserModal/editTrustedUserModal';
import { YesNoModal } from '$cmp/modals/yesNoModal';

export interface TrustedUserFieldProps {
  trustedUserType: TrustedUserType;
  profileFieldGroupListItemId: string | null;
}

export const TrustedUserField: React.FunctionComponent<TrustedUserFieldProps> = React.memo(
  (props) => {
    const { trustedUserType, profileFieldGroupListItemId } = props;

    const ProfilesService = useProfilesService();

    const { profileId } = useProfileContext();
    const hasUnrestrictedAccess = true;
    console.warn('implement hasUnrestrictedAccess');
    const [isLoaded, setIsLoaded] = useState(false);
    const [trustedUserEditModel, setTrustedUserEditModel] = useState<TrustedUserEditModel | null>(
      null
    );
    const [showEditTrustedUserModal, setShowEditTrustedUserModal] = useState(false);
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
    const [forceReloadTrigger, setForceReloadTrigger] = useState({});

    const loadTrustedUser = useCallback(async () => {
      if (profileId == null) {
        setTrustedUserEditModel(null);
        return;
      }

      setIsLoaded(false);
      const _trustedUserEditModel = await ProfilesService.getTrustedUserForProfile(
        profileId,
        trustedUserType,
        profileFieldGroupListItemId
      );
      setTrustedUserEditModel(_trustedUserEditModel);
      setIsLoaded(true);
    }, [profileFieldGroupListItemId, profileId, trustedUserType]);

    const createTrustedUser = useCallback(() => {
      setShowEditTrustedUserModal(true);
    }, []);

    const editTrustedUser = useCallback(() => {
      setShowEditTrustedUserModal(true);
    }, []);

    const closeTrustedUserModal = useCallback(async () => {
      setShowEditTrustedUserModal(false);
      await loadTrustedUser();
    }, [loadTrustedUser]);

    const showDeleteTrustedUserModal = useCallback(() => {
      setShowDeleteConfirmationModal(true);
    }, []);

    const confirmDelete = useCallback(async () => {
      if (profileId == null) {
        return;
      }

      await ProfilesService.deleteTrustedUserForProfile(
        profileId,
        trustedUserType,
        profileFieldGroupListItemId
      );
      setShowDeleteConfirmationModal(false);
      await loadTrustedUser();
    }, [loadTrustedUser, profileFieldGroupListItemId, profileId, trustedUserType]);

    const cancelDelete = useCallback(() => {
      setShowDeleteConfirmationModal(false);
    }, []);

    const unlockProfile = useCallback(async () => {
      if (profileId == null) {
        return;
      }

      setIsLoaded(false);
      await ProfilesService.unlockProtectedProfileForTrustedUser(
        profileId,
        trustedUserType,
        profileFieldGroupListItemId
      );
      setForceReloadTrigger({});
      setIsLoaded(true);
    }, [profileFieldGroupListItemId, profileId, trustedUserType]);

    const lockProfile = useCallback(async () => {
      if (profileId == null) {
        return;
      }

      setIsLoaded(false);
      await ProfilesService.lockProtectedProfileForTrustedUser(
        profileId,
        trustedUserType,
        profileFieldGroupListItemId
      );
      setForceReloadTrigger({});
      setIsLoaded(true);
    }, [profileFieldGroupListItemId, profileId, trustedUserType]);

    useEffect(() => {
      void loadTrustedUser();
    }, [loadTrustedUser, forceReloadTrigger]);

    if (!isLoaded) {
      return null;
    }

    return (
      <>
        {showEditTrustedUserModal && (
          <EditTrustedUserModal
            trustedUserType={trustedUserType}
            profileFieldGroupListItemId={profileFieldGroupListItemId}
            onClose={closeTrustedUserModal}
          />
        )}
        {showDeleteConfirmationModal && (
          <YesNoModal
            title={`Delete ${trustedUserType}`}
            message="Are you sure you want to delete this trusted user?"
            onYes={confirmDelete}
            onNo={cancelDelete}
          />
        )}
        {trustedUserEditModel && (
          <Table>
            <thead>
              <tr>
                <th>Email address</th>
                {trustedUserEditModel.enable_protected_access && (
                  <th>Protected access unlock status</th>
                )}
                {hasUnrestrictedAccess && <th>Actions</th>}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{trustedUserEditModel.email}</td>
                {trustedUserEditModel.enable_protected_access && (
                  <td>
                    {!trustedUserEditModel.protected_mode_access_active_at && 'Locked'}
                    {trustedUserEditModel.protected_mode_access_active_at && (
                      <>
                        {dayjs
                          .utc(trustedUserEditModel.protected_mode_access_active_at)
                          .isBefore(dayjs.utc()) && 'Unlocked'}
                        {!dayjs
                          .utc(trustedUserEditModel.protected_mode_access_active_at)
                          .isBefore(dayjs.utc()) &&
                          `Unlocking at ${dayjs(
                            trustedUserEditModel.protected_mode_access_active_at
                          )
                            .local()
                            .format('MM/DD/YY h:mm A')}`}
                      </>
                    )}
                  </td>
                )}
                {hasUnrestrictedAccess && (
                  <td>
                    <button className="btn btn-default" onClick={editTrustedUser}>
                      Edit
                    </button>
                    <button className="btn btn-danger" onClick={showDeleteTrustedUserModal}>
                      Delete
                    </button>
                    {trustedUserEditModel.enable_protected_access &&
                      (trustedUserEditModel.protected_mode_access_active_at == null ? (
                        <Button onClick={unlockProfile}>Unlock</Button>
                      ) : (
                        <Button onClick={lockProfile}>Lock</Button>
                      ))}
                  </td>
                )}
              </tr>
            </tbody>
          </Table>
        )}

        {!trustedUserEditModel && (
          <div className="col-md-12">
            <button className="btn btn-primary" onClick={createTrustedUser}>
              Create
            </button>
          </div>
        )}
      </>
    );
  }
);
