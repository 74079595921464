import React, { useCallback, useEffect, useRef, useState } from 'react';
import { EditorState, ContentState, convertFromHTML, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';

interface DisplayDivProps {
  value: string;
  onActivateEditor: () => unknown;
}

const DisplayDiv: React.FunctionComponent<DisplayDivProps> = React.memo((props) => {
  const { value, onActivateEditor } = props;

  return (
    <div
      className="html-field-display-div"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: !value || value.length === 0 ? '&nbsp;' : value }}
      onClick={onActivateEditor}
    />
  );
});

interface HtmlEditorProps {
  initialValue: string;
  onBlur: (value: string) => unknown;
}

const HtmlEditor: React.FunctionComponent<HtmlEditorProps> = React.memo((props) => {
  const { initialValue, onBlur } = props;

  const editorRef = useRef<Editor>(null);

  const blocksFromHTML = convertFromHTML(initialValue ?? '');
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(
      ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap)
    )
  );

  const _onBlur = useCallback(() => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const html = draftToHtml(rawContentState);
    onBlur(html);
  }, [editorState, onBlur]);

  useEffect(() => {
    editorRef.current?.focusEditor();
  }, []);

  useEffect(() => {
    const onBeforeUnload = (e: BeforeUnloadEvent) => {
      e.returnValue = 'Are you sure you want to leave this page without saving?';
      return e.returnValue;
    };

    window.addEventListener('beforeunload', onBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, []);

  return (
    <Editor
      ref={editorRef}
      editorState={editorState}
      onEditorStateChange={setEditorState}
      onBlur={_onBlur}
      editorStyle={{ backgroundColor: 'white', padding: '15px' }}
    />
  );
});

export interface HtmlFieldProps {
  value: string;
  onChange: (value: string) => any;
  isDirty: boolean;
  disabled?: boolean;
}

export const HtmlField: React.FunctionComponent<HtmlFieldProps> = React.memo((props) => {
  const { value, onChange, disabled } = props;

  const [inEditMode, setInEditMode] = useState(false);

  const activateEditor = useCallback(() => {
    if (!disabled) {
      setInEditMode(true);
    }
  }, [disabled]);

  const onHtmlEditorBlur = useCallback(
    (newValue: string) => {
      setInEditMode(false);
      onChange(newValue);
    },
    [onChange]
  );

  useEffect(() => {
    if (disabled) {
      setInEditMode(false);
    }
  }, [disabled]);

  return (
    <>
      {!inEditMode && <DisplayDiv value={value} onActivateEditor={activateEditor} />}
      {inEditMode && <HtmlEditor initialValue={value} onBlur={onHtmlEditorBlur} />}
    </>
  );
});
