import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { Button, Modal } from 'react-bootstrap';
import { MenuItemTreeField } from '$cmp/menuItemTreeField';

export interface EditProtectedAccessScreenProps {
  initialValues: EditProtectedAccessScreenForm;
  onClose: () => any;
  onBack: (formValues: EditProtectedAccessScreenForm) => any;
  onNext: (formValues: EditProtectedAccessScreenForm) => any;
}

export interface EditProtectedAccessScreenForm {
  delayHours: number;
  unlockCode: string;
  protectedMenuItemIds: string[];
  disableProtectedEditing: boolean;
}

export const EditProtectedAccessScreen: React.FunctionComponent<EditProtectedAccessScreenProps> = (
  props
) => {
  const { initialValues, onClose, onBack, onNext } = props;

  const [showPassword, setShowPassword] = useState(false);

  return (
    <Form<EditProtectedAccessScreenForm> onSubmit={onNext} initialValues={initialValues}>
      {({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit}>
          <Modal.Body>
            <div className="form-group">
              <label className="col-md-4 control-label">Unlock delay (hours)</label>
              <div className="col-md-8">
                <Field
                  name="delayHours"
                  component="input"
                  type="number"
                  className="form-control form-control-inline"
                  required
                />
              </div>
            </div>
            <div className="form-group">
              <label className="col-md-4 control-label">Unlock password</label>
              <div className="col-md-4">
                <Field
                  name="unlockCode"
                  parse={(x) => x}
                  component="input"
                  type={showPassword ? 'text' : 'password'}
                  className="form-control form-control-inline"
                  required
                />
              </div>
              <div className="col-md-4">
                <label>
                  <input type="checkbox" onChange={(e) => setShowPassword(e.target.checked)} /> Show
                  hidden
                </label>
              </div>
            </div>
            <div className="form-group">
              <label className="col-md-4 control-label">Unlocked accessible items</label>
              <div className="col-md-8">
                <Field name="protectedMenuItemIds" component={MenuItemTreeField} />
              </div>
            </div>
            <div className="form-group">
              <label className="col-md-4 control-label">Unlocked access type</label>
              <div className="col-md-8">
                <Field name="disableProtectedEditing" required>
                  {({ input }) => (
                    <select
                      className="form-control form-control-inline"
                      value={JSON.stringify(input.value)}
                      onChange={(e) => input.onChange(JSON.parse(e.target.value))}
                    >
                      <option value="true">View only</option>
                      <option value="false">View and edit</option>
                    </select>
                  )}
                </Field>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="default" onClick={onClose}>
              Close
            </Button>
            <Button variant="default" onClick={() => onBack(values)}>
              Back
            </Button>
            <Button variant="primary" type="submit">
              Next
            </Button>
          </Modal.Footer>
        </form>
      )}
    </Form>
  );
};
