import React, { useCallback, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import type { TrustedUserType, TrustedUserEditModel } from '@quiet-sunset/leo-shared';
import { useProfileContext, useProfilesService } from '@quiet-sunset/leo-shared';

import { LoadingSpinner } from '$cmp/loadingSpinner';

import { EditEmailScreen, EditEmailScreenForm } from './screens/editEmailScreen';
import {
  EditUnprotectedAccessScreen,
  EditUnprotectedAccessScreenForm,
} from './screens/editUnprotectedAccessScreen';
import {
  EditUseProtectedAccessQuestionScreen,
  EditUseProtectedAccessQuestionScreenForm,
} from './screens/editUseProtectedAccessQuestionScreen';
import {
  EditProtectedAccessScreen,
  EditProtectedAccessScreenForm,
} from './screens/editProtectedAccessScreen';
import { AgreementScreen, AgreementScreenForm } from './screens/agreementScreen';

interface EditTrustedUserModalProps {
  trustedUserType: TrustedUserType;
  profileFieldGroupListItemId: string | null;
  onClose: () => any;
}

type AllScreenForms = EditEmailScreenForm &
  EditUnprotectedAccessScreenForm &
  EditUseProtectedAccessQuestionScreenForm &
  EditProtectedAccessScreenForm &
  AgreementScreenForm;

type OneOfScreenForms =
  | EditEmailScreenForm
  | EditUnprotectedAccessScreenForm
  | EditUseProtectedAccessQuestionScreenForm
  | EditProtectedAccessScreenForm
  | AgreementScreenForm;

type ScreenState =
  | 'edit_email'
  | 'edit_unprotected_access'
  | 'edit_use_protected_access_question'
  | 'edit_protected_access'
  | 'agreement';

export const EditTrustedUserModal: React.FunctionComponent<EditTrustedUserModalProps> = (props) => {
  const { trustedUserType, profileFieldGroupListItemId, onClose } = props;

  const ProfilesService = useProfilesService();

  const { profileId } = useProfileContext();

  const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);
  const [doSubmit, setDoSubmit] = useState(false);
  const [screenState, setScreenState] = useState('edit_email' as ScreenState);
  const [formValues, setFormValues] = useState<AllScreenForms | null>(null);

  const updateFormValuesAndSetScreenState = useCallback(
    (newPartialFormValues: OneOfScreenForms, newScreenState: ScreenState) => {
      setFormValues((oldFormValues) => ({
        ...oldFormValues!,
        ...newPartialFormValues,
      }));
      setScreenState(newScreenState);
    },
    []
  );

  const updateFormValuesAndSubmit = useCallback((newPartialFormValues: OneOfScreenForms) => {
    setFormValues((oldFormValues) => ({
      ...oldFormValues!,
      ...newPartialFormValues,
    }));
    setDoSubmit(true);
  }, []);

  useEffect(() => {
    void (async () => {
      setShowLoadingSpinner(true);
      const trustedUserEditModel = await ProfilesService.getFullTrustedUserForProfile(
        profileId!,
        trustedUserType,
        profileFieldGroupListItemId
      );
      const newFormValues = {
        emailAddress: trustedUserEditModel?.email ?? '',
        disableUnprotectedEditing: trustedUserEditModel?.disable_unprotected_editing ?? false,
        unprotectedMenuItemIds: trustedUserEditModel?.unprotected_menu_item_ids ?? [],
        enableProtectedAccess: trustedUserEditModel?.enable_protected_access ?? false,
        delayHours: trustedUserEditModel?.delay_hours ?? 6,
        unlockCode: trustedUserEditModel?.unlock_code ?? '',
        protectedMenuItemIds: trustedUserEditModel?.protected_menu_item_ids ?? [],
        disableProtectedEditing: trustedUserEditModel?.disable_protected_editing ?? false,
        agreementType:
          trustedUserEditModel?.agreement_file_url != null ? 'custom' : ('leo' as 'leo' | 'custom'),
        agreementModel: trustedUserEditModel?.agreement_model ?? {},
        agreementFileName: trustedUserEditModel?.agreement_file_name ?? null,
        agreementFileUrl: trustedUserEditModel?.agreement_file_url ?? null,
        appointmentDocumentFile: null,
      };
      setFormValues(newFormValues);
      setShowLoadingSpinner(false);
    })();
  }, [profileFieldGroupListItemId, profileId, trustedUserType]);

  useEffect(() => {
    void (async () => {
      if (!doSubmit || formValues == null) {
        return;
      }

      setDoSubmit(false);
      setShowLoadingSpinner(true);
      const trustedUserEditModel: TrustedUserEditModel = {
        email: formValues.emailAddress,
        agreement_text: `${trustedUserType} agreement text`,
        agreement_model: formValues.agreementModel,

        unprotected_menu_item_ids: formValues.unprotectedMenuItemIds,
        disable_unprotected_editing: formValues.disableUnprotectedEditing,

        enable_protected_access: formValues.enableProtectedAccess,
        delay_hours: formValues.delayHours,
        unlock_code: formValues.unlockCode,
        protected_menu_item_ids: formValues.protectedMenuItemIds,
        disable_protected_editing: formValues.disableProtectedEditing,
      };

      await ProfilesService.saveTrustedUserForProfile(
        profileId!,
        trustedUserType,
        profileFieldGroupListItemId,
        trustedUserEditModel
      );

      if (formValues.appointmentDocumentFile != null) {
        await ProfilesService.uploadAppointmentDocumentFileForTrustedUserForProfile(
          profileId!,
          trustedUserType,
          profileFieldGroupListItemId,
          formValues.appointmentDocumentFile
        );
      } else if (formValues.agreementFileUrl == null) {
        await ProfilesService.deleteAppointmentDocumentFileForTrustedUserForProfile(
          profileId!,
          trustedUserType,
          profileFieldGroupListItemId
        );
      }

      setShowLoadingSpinner(false);
      onClose();
    })();
  }, [doSubmit, formValues, onClose, profileFieldGroupListItemId, profileId, trustedUserType]);

  const userTypeDisplay = trustedUserType;

  return (
    <Modal show backdrop="static" keyboard={false} size="lg">
      {showLoadingSpinner && <LoadingSpinner />}
      <Modal.Header>
        <Modal.Title>{userTypeDisplay} trusted user</Modal.Title>
      </Modal.Header>

      {formValues != null && (
        <>
          {screenState === 'edit_email' && (
            <EditEmailScreen
              initialValues={formValues}
              onClose={onClose}
              onNext={(newPartialFormValues) =>
                updateFormValuesAndSetScreenState(newPartialFormValues, 'edit_unprotected_access')
              }
            />
          )}

          {screenState === 'edit_unprotected_access' && (
            <EditUnprotectedAccessScreen
              initialValues={formValues}
              onClose={onClose}
              onBack={(newPartialFormValues) =>
                updateFormValuesAndSetScreenState(newPartialFormValues, 'edit_email')
              }
              onNext={(newPartialFormValues) =>
                updateFormValuesAndSetScreenState(
                  newPartialFormValues,
                  'edit_use_protected_access_question'
                )
              }
            />
          )}

          {screenState === 'edit_use_protected_access_question' && (
            <EditUseProtectedAccessQuestionScreen
              initialValues={formValues}
              onClose={onClose}
              onBack={(newPartialFormValues) =>
                updateFormValuesAndSetScreenState(newPartialFormValues, 'edit_unprotected_access')
              }
              onNext={(newPartialFormValues) =>
                updateFormValuesAndSetScreenState(
                  newPartialFormValues,
                  newPartialFormValues.enableProtectedAccess ? 'edit_protected_access' : 'agreement'
                )
              }
            />
          )}

          {screenState === 'edit_protected_access' && (
            <EditProtectedAccessScreen
              initialValues={formValues}
              onClose={onClose}
              onBack={(newPartialFormValues) =>
                updateFormValuesAndSetScreenState(
                  newPartialFormValues,
                  'edit_use_protected_access_question'
                )
              }
              onNext={(newPartialFormValues) =>
                updateFormValuesAndSetScreenState(newPartialFormValues, 'agreement')
              }
            />
          )}

          {screenState === 'agreement' && (
            <AgreementScreen
              trustedUserType={trustedUserType}
              initialValues={formValues}
              onClose={onClose}
              onBack={(newPartialFormValues) =>
                updateFormValuesAndSetScreenState(
                  newPartialFormValues,
                  'edit_use_protected_access_question'
                )
              }
              onSubmit={(newPartialFormValues) => updateFormValuesAndSubmit(newPartialFormValues)}
            />
          )}
        </>
      )}
    </Modal>
  );
};
