import Alert from '@mui/material/Alert';
import React from 'react';

export type ProfileLockedAlertProps = {
  onUnlock: () => void;
};

export const ProfileLockedAlert: React.VoidFunctionComponent<ProfileLockedAlertProps> = ({
  onUnlock,
}) => {
  return (
    <Alert severity="warning">
      Your LEO has been certified and locked, which means you cannot make any changes unless you
      unlock your profile.{' '}
      <a
        href="/"
        onClick={(e) => {
          onUnlock();
          e.preventDefault();
        }}
      >
        Click here
      </a>{' '}
      to unlock your profile.
    </Alert>
  );
};
