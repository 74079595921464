import React from 'react';
import Box from '@mui/material/Box';
import MuiCard from '@mui/material/Card';
import styled from 'styled-components';

const StyledBox = styled(Box)`
  height: 100%;
`;

export type CardProps = {
  className?: string;
  transparentBorder?: boolean;
};

export const Card: React.FC<CardProps> = ({ className, transparentBorder = false, children }) => {
  if (transparentBorder) {
    return (
      <Box px={5} py={5} className={className}>
        {children}
      </Box>
    );
  }
  return (
    <MuiCard className={className}>
      <StyledBox px={5} py={5}>
        {children}
      </StyledBox>
    </MuiCard>
  );
};
