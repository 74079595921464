import React from 'react';
import { Field, Form } from 'react-final-form';
import { Button, Modal } from 'react-bootstrap';

export interface EditUseProtectedAccessQuestionScreenProps {
  initialValues: EditUseProtectedAccessQuestionScreenForm;
  onClose: () => any;
  onBack: (formValues: EditUseProtectedAccessQuestionScreenForm) => any;
  onNext: (formValues: EditUseProtectedAccessQuestionScreenForm) => any;
}

export interface EditUseProtectedAccessQuestionScreenForm {
  enableProtectedAccess: boolean;
}

export const EditUseProtectedAccessQuestionScreen: React.FunctionComponent<EditUseProtectedAccessQuestionScreenProps> =
  (props) => {
    const { initialValues, onClose, onBack, onNext } = props;

    return (
      <Form<EditUseProtectedAccessQuestionScreenForm>
        onSubmit={onNext}
        initialValues={initialValues}
      >
        {({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Body>
              <p>
                Trusted users can optionally be given an unlock code which will grant them
                additional access to LEO.
              </p>
              <div className="form-group">
                <label className="col-md-4 control-label">Enable unlock code access</label>
                <div className="col-md-8">
                  <Field name="enableProtectedAccess" required>
                    {({ input }) => (
                      <select
                        className="form-control form-control-inline"
                        value={JSON.stringify(input.value)}
                        onChange={(e) => input.onChange(JSON.parse(e.target.value))}
                      >
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </select>
                    )}
                  </Field>
                </div>
              </div>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="default" onClick={onClose}>
                Close
              </Button>
              <Button variant="default" onClick={() => onBack(values)}>
                Back
              </Button>
              <Button variant="primary" type="submit">
                Next
              </Button>
            </Modal.Footer>
          </form>
        )}
      </Form>
    );
  };
