import React from 'react';
// eslint-disable-next-line import/no-cycle
import { ProfileContentFieldGroupVertical } from './vertical/profileContentFieldGroupVertical';
// eslint-disable-next-line import/no-cycle
import { ProfileContentFieldGroupVerticalListTabbed } from './vertical/profileContentFieldGroupVerticalListTabbed';
// eslint-disable-next-line import/no-cycle
import { ProfileContentFieldGroupHorizontal } from './horizontal/profileContentFieldGroupHorizontal';
// // eslint-disable-next-line import/no-cycle
// import { ProfileContentFieldGroupVerticalListStacked } from './verticalStacked/profileContentFieldGroupVerticalListStacked';
// // eslint-disable-next-line import/no-cycle
// import { ProfileContentFieldGroupSectioned } from './profileContentFieldGroupSectioned';
import { useFieldGroup } from '@quiet-sunset/leo-shared';

export interface ProfileContentFieldGroupProps {
  fieldGroupId: string;
  profileFieldGroupListItemId: string | null;
}

export const ProfileContentFieldGroup: React.FunctionComponent<ProfileContentFieldGroupProps> = (
  props
) => {
  const { fieldGroupId, profileFieldGroupListItemId } = props;

  const profileFieldGroup = useFieldGroup(fieldGroupId);

  if (!profileFieldGroup) {
    return null;
  }

  switch (profileFieldGroup.display_type) {
    case 'vertical':
    case 'menu_item':
      return (
        <ProfileContentFieldGroupVertical
          fieldGroupId={fieldGroupId}
          profileFieldGroupListItemId={profileFieldGroupListItemId}
        />
      );
    case 'vertical_tabbed':
      return (
        <ProfileContentFieldGroupVerticalListTabbed
          fieldGroupId={fieldGroupId}
          profileFieldGroupListItemId={profileFieldGroupListItemId}
        />
      );
    case 'horizontal':
      return (
        <ProfileContentFieldGroupHorizontal
          fieldGroupId={fieldGroupId}
          profileFieldGroupListItemId={profileFieldGroupListItemId}
        />
      );
    case 'vertical_stacked':
      return (
        // <ProfileContentFieldGroupVerticalListStacked
        //   fieldGroupId={fieldGroupId}
        //   profileFieldGroupListItemId={profileFieldGroupListItemId}
        // />
        <p>ProfileContentFieldGroupVerticalListStacked</p>
      );
    case 'sectioned':
      return (
        // <ProfileContentFieldGroupSectioned
        //   fieldGroupId={fieldGroupId}
        //   profileFieldGroupListItemId={profileFieldGroupListItemId}
        // />
        <p>ProfileContentFieldGroupSectioned</p>
      );
    default:
      return <p>Unknown field group display type {profileFieldGroup.display_type}</p>;
  }
};
