import React from 'react';
import type { TrustedUserType } from '@quiet-sunset/leo-shared';
import { ExecutorAppointmentDocumentFormContents } from './executorAppointmentDocumentFormContents';
import { EstateAttorneyAppointmentDocumentFormContents } from './estateAttorneyAppointmentDocumentFormContents';
import { EstateTrusteeAppointmentDocumentFormContents } from './estateTrusteeAppointmentDocumentFormContents';
import { FinancialAdvisorAppointmentDocumentFormContents } from './financialAdvisorAppointmentDocumentFormContents';
import { LeoDigitalTrusteeAppointmentDocumentFormContents } from './leoDigitalTrusteeAppointmentDocumentFormContents';
import { LeoPcTrusteeAppointmentDocumentFormContents } from './leoPcTrusteeAppointmentDocumentFormContents';
import { LeoTrusteeAppointmentDocumentFormContents } from './leoTrusteeAppointmentDocumentFormContents';
import { PrivateBankerAppointmentDocumentFormContents } from './privateBankerAppointmentDocumentFormContents';

export type AppointmentDocumentFormContents = React.FunctionComponent;

export function getAppointmentDocumentFormContentsComponent(
  trustedUserType: TrustedUserType
): AppointmentDocumentFormContents {
  switch (trustedUserType) {
    case 'executor':
    case 'secondary_executor':
      return ExecutorAppointmentDocumentFormContents;
    case 'leo_pc_trustee':
      return LeoPcTrusteeAppointmentDocumentFormContents;
    case 'leo_digital_trustee':
      return LeoDigitalTrusteeAppointmentDocumentFormContents;
    case 'estate_attorney':
      return EstateAttorneyAppointmentDocumentFormContents;
    case 'trustee':
      return EstateTrusteeAppointmentDocumentFormContents;
    case 'leo_trustee':
      return LeoTrusteeAppointmentDocumentFormContents;
    case 'financial_advisor':
      return FinancialAdvisorAppointmentDocumentFormContents;
    case 'private_banker':
      return PrivateBankerAppointmentDocumentFormContents;
    default:
      throw new Error(`Unknown trustedUserType ${trustedUserType}`);
  }
}
