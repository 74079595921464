import React from 'react';
import { Box, Button, Card, Typography } from '@mui/material';
import GroupIcon from '@mui/icons-material/Group';
import PersonIcon from '@mui/icons-material/Person';

import { useCurrentUserContext } from '$contexts/currentUserContext';
import { ActivateButton } from './activateButton';
import { Link } from 'react-router-dom';
import { CardBackdrop } from '$cmp/cardBackdrop';

export const ActivationRoute: React.FunctionComponent = () => {
  const { currentUser } = useCurrentUserContext();

  if (!currentUser) {
    return null;
  }

  return (
    <CardBackdrop>
      <Box style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
        <Box style={{ flex: '0 0 400px' }}>
          <Card style={{ margin: '20px', padding: '20px' }}>
            <Typography variant="h2" align="center">
              LEO
            </Typography>
            <PersonIcon style={{ width: '100%', height: '100%' }} />
            <Box style={{ display: 'flex', justifyContent: 'center' }}>
              {!currentUser.is_single_subscriber_enabled && (
                <ActivateButton type="single_subscriber" />
              )}
              {currentUser.is_single_subscriber_enabled && (
                <Button color="primary" component={Link} to="/profiles">
                  Launch
                </Button>
              )}
            </Box>
          </Card>
        </Box>
        <Box style={{ flex: '0 0 400px' }}>
          <Card style={{ margin: '20px', padding: '20px' }}>
            <Typography variant="h2" align="center">
              LEO-PRO
            </Typography>
            <GroupIcon style={{ width: '100%', height: '100%' }} />
            <Box style={{ display: 'flex', justifyContent: 'center' }}>
              {!currentUser.is_leo_pro_enabled && <ActivateButton type="leo_pro" />}
              {currentUser.is_leo_pro_enabled && (
                <Button color="primary" component={Link} to="/leo-pro">
                  Launch
                </Button>
              )}
            </Box>
          </Card>
        </Box>
      </Box>
    </CardBackdrop>
  );
};
