var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useProfilesService } from "../services";
const ProfileContext = React.createContext({
    baseRoute: null,
    profileId: null,
    ownerUserId: null,
    hasUnrestrictedAccess: null,
    hasUnlockableTrustedUserAccess: null,
    hasProfileCertificationModel: null,
    profileSelectedMenuItems: null,
    leoProOrganizationProfile: null,
    reloadProfile: null,
    loadProfileSelectedMenuItems: null,
    setProfileCertificationModel: null,
    clearProfileCertificationModel: null,
});
ProfileContext.displayName = 'ProfileContext';
export const useProfileContext = () => useContext(ProfileContext);
export const ProfileContextProvider = ({ profileId, baseRoute, Loading, children, }) => {
    var _a, _b;
    const ProfilesService = useProfilesService();
    const [profile, setProfile] = useState();
    const [hasProfileCertificationModel, setHasProfileCertificationModel] = useState();
    const [profileSelectedMenuItems, setProfileSelectedMenuItems] = useState();
    const [leoProOrganizationProfile, setLeoProOrganizationProfile] = useState();
    const reloadProfile = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        setProfile(undefined);
        setHasProfileCertificationModel(undefined);
        setProfileSelectedMenuItems(undefined);
        setLeoProOrganizationProfile(undefined);
        const [_profile, _hasProfileCertificationModel] = yield Promise.all([
            ProfilesService.getProfileById(profileId),
            ProfilesService.getHasProfileCertificationModelByProfileId(profileId),
        ]);
        setProfile(_profile);
        setHasProfileCertificationModel(_hasProfileCertificationModel);
        if (_profile.billing_leo_pro_organization_id != null) {
            const _leoProOrganizationProfile = yield ProfilesService.getLeoProOrganizationProfileForProfile(profileId);
            setLeoProOrganizationProfile(_leoProOrganizationProfile);
        }
        else {
            setLeoProOrganizationProfile(null);
        }
    }), [profileId]);
    const loadProfileSelectedMenuItems = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        const _profileSelectedMenuItems = yield ProfilesService.getProfileSelectedMenuItemsForProfile(profileId);
        setProfileSelectedMenuItems(_profileSelectedMenuItems);
    }), [profileId]);
    const setProfileCertificationModel = useCallback((profileCertificationModel) => __awaiter(void 0, void 0, void 0, function* () {
        yield ProfilesService.setProfileCertificationModelByProfileId(profileId, profileCertificationModel);
        setHasProfileCertificationModel(true);
    }), [profileId]);
    const clearProfileCertificationModel = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        yield ProfilesService.clearProfileCertificationModelByProfileId(profileId);
        setHasProfileCertificationModel(false);
    }), [profileId]);
    useEffect(() => {
        reloadProfile().catch(console.error);
    }, [profileId]);
    useEffect(() => {
        loadProfileSelectedMenuItems().catch(console.error);
    }, [profileId]);
    if (!profile ||
        hasProfileCertificationModel === undefined ||
        !profileSelectedMenuItems ||
        leoProOrganizationProfile === undefined) {
        return Loading ? _jsx(Loading, {}, void 0) : null;
    }
    const contextValue = {
        baseRoute,
        profileId,
        ownerUserId: profile.owner_user_id,
        hasUnrestrictedAccess: (_a = profile.has_unrestricted_access) !== null && _a !== void 0 ? _a : false,
        hasUnlockableTrustedUserAccess: (_b = profile.has_unlockable_trusted_user_access) !== null && _b !== void 0 ? _b : false,
        hasProfileCertificationModel,
        profileSelectedMenuItems,
        leoProOrganizationProfile,
        reloadProfile,
        loadProfileSelectedMenuItems,
        setProfileCertificationModel,
        clearProfileCertificationModel,
    };
    return _jsx(ProfileContext.Provider, Object.assign({ value: contextValue }, { children: children }), void 0);
};
export const ProfileContextConsumer = ProfileContext.Consumer;
