import React, { VFC, useCallback, useEffect, useState } from 'react';
import { Field } from '@quiet-sunset/leo-shared';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import IconButton from '@mui/material/IconButton';
import ListItemButton from '@mui/material/ListItemButton';
import { FieldEditDialog } from './fieldEditDialog';
import { FieldListItem } from './fieldListItem';
import { useRetrieveField, useCopyField, useDeleteField } from './hooks';
import { useMoveFieldGroupItemDown, useMoveFieldGroupItemUp } from '../field-group-item/hooks';

export type FieldTreeNodeProps = {
  fieldId: string;
  fieldGroupItemId: string;
  onUpdate: () => void;
};

export const FieldTreeNode: VFC<FieldTreeNodeProps> = ({ fieldId, fieldGroupItemId, onUpdate }) => {
  const [isEditVisible, setIsEditVisible] = useState(false);
  const [field, setField] = useState<Field>();

  const retrieveField = useRetrieveField(fieldId, setField);
  const copy = useCopyField(field, fieldGroupItemId, onUpdate);
  const moveUp = useMoveFieldGroupItemUp(fieldGroupItemId, onUpdate);
  const moveDown = useMoveFieldGroupItemDown(fieldGroupItemId, onUpdate);
  const deleteField = useDeleteField(fieldId, onUpdate);

  const handleEditClose = useCallback(async () => {
    await retrieveField();
    setIsEditVisible(false);
  }, [retrieveField]);

  useEffect(() => {
    retrieveField().catch(console.error);
  }, [retrieveField]);

  if (!field) {
    return null;
  }

  return (
    <>
      {isEditVisible && <FieldEditDialog field={field} onClose={handleEditClose} />}
      <ListItemButton>
        <FieldListItem field={field} />
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            moveUp();
          }}
        >
          <ArrowUpwardIcon />
        </IconButton>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            moveDown();
          }}
        >
          <ArrowDownwardIcon />
        </IconButton>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            setIsEditVisible(true);
          }}
        >
          <EditIcon />
        </IconButton>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            copy();
          }}
        >
          <CopyIcon />
        </IconButton>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            deleteField();
          }}
        >
          <DeleteIcon />
        </IconButton>
        <div style={{ visibility: 'hidden' }}>
          {/* strictly for alignment with other rows */}
          <ExpandLessIcon />
        </div>
      </ListItemButton>
    </>
  );
};
