import React from 'react';
import { Link } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import { useIsRoute } from '$hooks/useIsRoute';
import { usePaymentCardInfo } from '$hooks/usePaymentCardInfo';

export type PastDueAlertProps = {
  type: 'past_due' | 'unpaid';
  updateTrigger?: Record<string, unknown>;
};

export const PastDueAlert: React.VoidFunctionComponent<PastDueAlertProps> = ({
  type,
  updateTrigger,
}) => {
  const onManagePaymentAccountPage = useIsRoute('/manage-payment-account');
  const paymentCardInfo = usePaymentCardInfo(updateTrigger);

  return (
    <Alert severity="warning">
      <ul>
        <li>
          Your account is currently&nbsp;
          <strong>
            {type === 'past_due' && 'past due'}
            {type === 'unpaid' && 'unpaid'}
          </strong>
          !
        </li>
        <li>Your account has been locked and you are not be able to access your account.</li>
        {!onManagePaymentAccountPage && (
          <li>
            Visit the <Link to="/manage-payment-account">Manage Payment Account page</Link> to
            update your payment card information.
          </li>
        )}
        {paymentCardInfo == null && (
          <li>
            If you have recently updated your payment card information, please allow up to 24 hours
            for access to update.
          </li>
        )}
      </ul>
    </Alert>
  );
};
