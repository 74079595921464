var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { useAsyncMemo, useUpdateTrigger } from "../../hooks";
import { useGetNavigatorItemsForListFieldGroupNavigatorStateDescriptor, useGetNavigatorItemsForMenuItemNavigatorStateDescriptor, useGetNavigatorItemsForNonListFieldGroupNavigatorStateDescriptor, } from './hooks';
const ProfileNavigatorContext = createContext(undefined);
ProfileNavigatorContext.displayName = 'ProfileNavigatorContext';
export const useProfileNavigatorContext = () => useContext(ProfileNavigatorContext);
const initialNavigationStack = [
    { type: 'MenuItem', menuItemId: null },
];
export const ProfileNavigatorContextProvider = ({ children }) => {
    const [navigationStack, setNavigationStack] = useState(initialNavigationStack);
    const getNavigatorItemsForMenuItemNavigatorStateDescriptor = useGetNavigatorItemsForMenuItemNavigatorStateDescriptor();
    const getNavigatorItemsForListFieldGroupNavigatorStateDescriptor = useGetNavigatorItemsForListFieldGroupNavigatorStateDescriptor();
    const getNavigatorItemsForNonListFieldGroupNavigatorStateDescriptor = useGetNavigatorItemsForNonListFieldGroupNavigatorStateDescriptor();
    const pushNavigation = useCallback((profileNavigatorStateDescriptor) => {
        setNavigationStack((prevNavigationStack) => [
            ...prevNavigationStack,
            profileNavigatorStateDescriptor,
        ]);
    }, []);
    const popNavigation = useCallback(() => {
        setNavigationStack((prevNavigationStack) => prevNavigationStack.length > 1 ? prevNavigationStack.slice(0, -1) : prevNavigationStack);
    }, []);
    const [updateTrigger, triggerUpdate] = useUpdateTrigger();
    const [profileNavigatorState, isResolved, error] = useAsyncMemo(() => __awaiter(void 0, void 0, void 0, function* () {
        const depth = navigationStack.length - 1;
        const currentNavigatorStateDescriptor = navigationStack[depth];
        const allowPopNavigation = depth > 0;
        switch (currentNavigatorStateDescriptor.type) {
            case 'MenuItem': {
                const items = yield getNavigatorItemsForMenuItemNavigatorStateDescriptor(currentNavigatorStateDescriptor);
                return {
                    allowPopNavigation,
                    depth,
                    items,
                };
            }
            case 'FieldGroup': {
                const items = currentNavigatorStateDescriptor.isList
                    ? yield getNavigatorItemsForListFieldGroupNavigatorStateDescriptor(currentNavigatorStateDescriptor)
                    : yield getNavigatorItemsForNonListFieldGroupNavigatorStateDescriptor(currentNavigatorStateDescriptor);
                return {
                    allowPopNavigation,
                    depth,
                    items,
                };
            }
            default:
                throw new Error('Unknown type');
        }
    }), [
        navigationStack,
        getNavigatorItemsForMenuItemNavigatorStateDescriptor,
        getNavigatorItemsForListFieldGroupNavigatorStateDescriptor,
        getNavigatorItemsForNonListFieldGroupNavigatorStateDescriptor,
        updateTrigger,
    ]);
    const contextValue = useMemo(() => ({
        popNavigation,
        pushNavigation,
        reloadCurrentNavigatorState: triggerUpdate,
        navigationStackDepth: navigationStack.length,
        isLoading: !isResolved,
        error,
        profileNavigatorState,
    }), [popNavigation, pushNavigation, navigationStack, isResolved, error, profileNavigatorState]);
    return (_jsx(ProfileNavigatorContext.Provider, Object.assign({ value: contextValue }, { children: children }), void 0));
};
