import React from 'react';
import { TextField } from '$cmp/form/textField';

export interface ParagraphFieldProps {
  value: string;
  onChange: (value: string) => any;
  isDirty: boolean;
  disabled?: boolean;
}

export const ParagraphField: React.FunctionComponent<ParagraphFieldProps> = React.memo((props) => {
  const { value, onChange, isDirty, disabled } = props;

  return (
    <TextField
      label=""
      value={value}
      onChange={(e) => onChange(e.target.value)}
      fullWidth
      multiline
      minRows={4}
      my={2}
      disabled={disabled}
      focused={isDirty ? true : undefined}
    />
  );
});
