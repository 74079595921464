import React from 'react';
import { Field, Form } from 'react-final-form';
import { Button } from 'react-bootstrap';
import type { SupportFormValues } from '@quiet-sunset/leo-shared';

export interface SupportFormProps {
  onSubmit: (values: SupportFormValues) => any;
}

export const SupportForm: React.FunctionComponent<SupportFormProps> = (props) => {
  const { onSubmit } = props;

  return (
    <Form<SupportFormValues> onSubmit={onSubmit} initialValues={{ futureCommunication: true }}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} style={{ maxWidth: '800px', margin: '0 auto' }}>
          <h1>CONTACT US FOR TECHNICAL SUPPORT</h1>
          <p>
            If you have questions or need immediate help with your LEO, please contact Customer
            Support at
            <a href="tel:513-835-8046" style={{ fontWeight: 'bold' }}>
              513.835.8046
            </a>{' '}
            or use the form below, and we will help you with your issue or concern.
          </p>

          <div className="form-group">
            <label>Your Full Name</label>
            <Field name="fullName" component="input" className="form-control" required />
          </div>
          <div className="form-group">
            <label>Email address</label>
            <Field name="emailAddress" component="input" className="form-control" required />
          </div>
          <div className="form-group">
            <label>Phone</label>
            <Field name="phone" component="input" className="form-control" required />
          </div>
          <div className="form-group">
            <label>How did you hear about LEO?</label>
            <Field name="howDidYouHear" component="input" className="form-control" required />
          </div>
          <div className="form-group">
            <label>Reason for your email?</label>
            <Field name="reason" component="input" className="form-control" required />
          </div>
          <div className="form-group">
            <label>Your message</label>
            <Field name="message" component="textarea" className="form-control" required />
          </div>
          <div className="form-group">
            <label>LEO improvement suggestions</label>
            <Field
              name="improvementSuggestions"
              component="textarea"
              className="form-control"
              required
            />
          </div>
          <div className="form-group">
            <label>
              Would you like to receive future communications from Life Estate Organizer, including
              notices of product updates or other information?
            </label>
            <Field name="futureCommunication">
              {({ input }) => (
                <select
                  className="form-control form-control-inline"
                  value={JSON.stringify(input.value)}
                  onChange={(e) => input.onChange(JSON.parse(e.target.value))}
                >
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
              )}
            </Field>
          </div>
          <Button variant="primary" type="submit">
            Submit
          </Button>
        </form>
      )}
    </Form>
  );
};
