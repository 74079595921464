import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import dayjs from 'dayjs';
import Alert from '@mui/material/Alert';

import { LeoProOrganizationPaymentAccountEditableCardInformation } from '$cmp/leoProOrganizationPaymentAccountEditableCardInformation';
import { LoadingSpinner } from '$cmp/loadingSpinner';
import { CancelLeoProOrganizationConfirmationModal } from '$cmp/modals/cancelLeoProOrganizationConfirmationModal';

import { useUpdateTrigger } from '$hooks/useUpdateTrigger';

import type {
  PaymentAccountSubscription,
  LeoProOrganizationWithMemberType,
  PaymentCardInfo,
} from '@quiet-sunset/leo-shared';

import { useLeoProOrganizationsService } from '@quiet-sunset/leo-shared';

import { LeoProPaymentAccountStatusAlert } from './leoProPaymentAccountStatusAlert';
import { LeoProPaymentAccountBillingSummary } from './leoProPaymentAccountBillingSummary';
import { Redirect } from 'react-router-dom';
import { CardBackdrop } from '$cmp/cardBackdrop';
import { CardContainer } from '$cmp/cardContainer';
import { Card } from '$cmp/card';

export const LeoProPaymentAccountManager: React.FunctionComponent<{
  leoProOrganizationId: string;
}> = ({ leoProOrganizationId }) => {
  const LeoProOrganizationsService = useLeoProOrganizationsService();

  const [updateTrigger, triggerUpdate] = useUpdateTrigger();

  const [isLoaded, setIsLoaded] = useState(false);
  const [leoProOrganization, setLeoProOrganization] =
    useState<LeoProOrganizationWithMemberType | null>(null);
  const [paymentAccountSubscription, setPaymentAccountSubscription] = useState(
    null as null | PaymentAccountSubscription
  );
  const [paymentCardInfo, setPaymentCardInfo] = useState(null as null | PaymentCardInfo);
  const [cancelAccountConfirmationModalIsVisible, setCancelAccountConfirmationModalIsVisible] =
    useState(false);

  const isTrial = paymentAccountSubscription?.status === 'trialing';

  const leoProOrganizationCreatedDate = leoProOrganization?.created_date;
  const leoProOrganizationCreatedDateText = dayjs.utc(leoProOrganizationCreatedDate).format('L');

  const periodStartDateText =
    paymentAccountSubscription != null && paymentAccountSubscription.status !== 'canceled'
      ? dayjs.utc(paymentAccountSubscription.current_period_start * 1000).format('L')
      : null;
  const periodEndDateText =
    paymentAccountSubscription != null && paymentAccountSubscription.status !== 'canceled'
      ? dayjs.utc(paymentAccountSubscription.current_period_end * 1000).format('L')
      : null;

  const paymentAccountSubscriptionStatus = useMemo(() => {
    if (paymentAccountSubscription == null) {
      return 'new';
    }
    if (
      ['active', 'trialing'].includes(paymentAccountSubscription.status) &&
      paymentAccountSubscription.cancel_at_period_end
    ) {
      return 'pending_cancel';
    }
    return paymentAccountSubscription.status;
  }, [paymentAccountSubscription]);

  const showCancelAccountConfirmationModal = useCallback(() => {
    setCancelAccountConfirmationModalIsVisible(true);
  }, []);

  const hideCancelAccountConfirmationModal = useCallback(() => {
    setCancelAccountConfirmationModalIsVisible(false);
    triggerUpdate();
  }, [triggerUpdate]);

  const restoreAccount = useCallback(async () => {
    setIsLoaded(false);
    await LeoProOrganizationsService.restoreLeoProOrganization(leoProOrganizationId);
    setIsLoaded(true);
    triggerUpdate();
  }, [triggerUpdate, leoProOrganizationId]);

  useEffect(() => {
    void (async () => {
      setIsLoaded(false);
      const [_leoProOrganization, _leoProOrganizationBillingDetail, _cardInfo] = await Promise.all([
        LeoProOrganizationsService.getLeoProOrganizationById(leoProOrganizationId),
        LeoProOrganizationsService.getLeoProOrganizationBillingDetail(leoProOrganizationId),
        LeoProOrganizationsService.getLeoProOrganizationBillingCardInfo(leoProOrganizationId),
      ]);

      setLeoProOrganization(_leoProOrganization);
      setPaymentAccountSubscription(_leoProOrganizationBillingDetail);
      setPaymentCardInfo(_cardInfo);

      setIsLoaded(true);
    })();
  }, [leoProOrganizationId, updateTrigger]);

  if (!isLoaded) {
    return <LoadingSpinner />;
  }

  if (!leoProOrganization) {
    return <Redirect to="/leo-pro" />;
  }

  return (
    <>
      {cancelAccountConfirmationModalIsVisible && (
        <CancelLeoProOrganizationConfirmationModal
          leoProOrganizationId={leoProOrganizationId}
          onClose={hideCancelAccountConfirmationModal}
        />
      )}
      <CardBackdrop>
        <CardContainer>
          <LeoProPaymentAccountStatusAlert leoProOrganization={leoProOrganization} />
          <Card>
            <h3>Manage payment account</h3>
            <div className="form-group row">
              <label className="col-md-4 control-label">Account status</label>
              <div className="col-md-8">
                <p className="form-control-static">{paymentAccountSubscriptionStatus}</p>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-md-4 control-label">Signup date</label>
              <div className="col-md-8">
                <p className="form-control-static">{leoProOrganizationCreatedDateText}</p>
              </div>
            </div>
            {periodStartDateText != null && (
              <div className="form-group row">
                <label className="col-md-4 control-label">
                  {isTrial && <span>Trial start</span>}
                  {!isTrial && <span>Subscription period start</span>}
                </label>
                <div className="col-md-8">
                  <p className="form-control-static">{periodStartDateText}</p>
                </div>
              </div>
            )}
            {periodEndDateText != null && (
              <div className="form-group row">
                <label className="col-md-4 control-label">
                  {isTrial && <span>Trial end</span>}
                  {!isTrial && <span>Subscription period end</span>}
                </label>
                <div className="col-md-8">
                  <p className="form-control-static">{periodEndDateText}</p>
                </div>
              </div>
            )}
            <div className="form-group row">
              <label className="col-md-4 control-label">Card information</label>
              <div className="col-md-8">
                <LeoProOrganizationPaymentAccountEditableCardInformation
                  leoProOrganizationId={leoProOrganizationId}
                  onUpdate={triggerUpdate}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-md-4 control-label">Billing summary</label>
              <div className="col-md-8">
                <LeoProPaymentAccountBillingSummary leoProOrganizationId={leoProOrganizationId} />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-md-4 control-label">Actions</label>
              <div className="col-md-8">
                {!['canceled', 'pending_cancel'].includes(paymentAccountSubscriptionStatus) && (
                  <Button variant="danger" onClick={showCancelAccountConfirmationModal}>
                    Cancel account
                  </Button>
                )}
                {['canceled', 'pending_cancel'].includes(paymentAccountSubscriptionStatus) && (
                  <div style={{ display: 'flex' }}>
                    <div style={{ width: '150px' }}>
                      <Button
                        variant="primary"
                        onClick={restoreAccount}
                        disabled={paymentCardInfo == null}
                      >
                        Restore account
                      </Button>
                    </div>
                    {paymentCardInfo == null && (
                      <Alert severity="warning">
                        You must provide payment card information before restoring your account.
                      </Alert>
                    )}
                  </div>
                )}
              </div>
            </div>
          </Card>
        </CardContainer>
      </CardBackdrop>
    </>
  );
};
