import React from 'react';

import { Card } from '$cmp/card';
import { useUpdateTrigger } from '$hooks/useUpdateTrigger';
import { TrustedUserInvitationsList } from '$routes/trustedUser/trustedUserInvitationsList';

import { ProfileList } from './profileList';
import { PaymentAccountStatusAlert } from '../managePaymentAccount/paymentAccountStatusAlert';

export const ProfilesCard: React.FunctionComponent = () => {
  const [profilesUpdateTrigger, refreshProfileList] = useUpdateTrigger();

  return (
    <>
      <PaymentAccountStatusAlert updateTrigger={profilesUpdateTrigger} />
      <Card>
        <ProfileList profilesUpdateTrigger={profilesUpdateTrigger} onUpdate={refreshProfileList} />
        <TrustedUserInvitationsList onUpdate={refreshProfileList} />
      </Card>
    </>
  );
};
