var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export class ProfileFieldGroupListItemsMap extends Map {
    static initializeFromService(ProfileFieldGroupListItemsService, profileId, rootFieldGroupId) {
        return __awaiter(this, void 0, void 0, function* () {
            const profileFieldGroupListItems = yield ProfileFieldGroupListItemsService.getAllForProfile(profileId, rootFieldGroupId);
            const profileFieldGroupListItemsMap = new ProfileFieldGroupListItemsMap();
            for (const profileFieldGroupListItem of profileFieldGroupListItems) {
                const fieldGroupId = profileFieldGroupListItem.field_group_id;
                const parentProfileFieldGroupListItemId = profileFieldGroupListItem.parent_id;
                profileFieldGroupListItemsMap.pushToList({ fieldGroupId, parentProfileFieldGroupListItemId }, profileFieldGroupListItem);
            }
            return profileFieldGroupListItemsMap;
        });
    }
    static getKeyString(key) {
        var _a;
        return `${key.fieldGroupId}:${(_a = key.parentProfileFieldGroupListItemId) !== null && _a !== void 0 ? _a : ''}`;
    }
    has(key) {
        if (typeof key === 'string') {
            return super.has(key);
        }
        const keyString = ProfileFieldGroupListItemsMap.getKeyString(key);
        return super.has(keyString);
    }
    get(key) {
        if (typeof key === 'string') {
            return super.get(key);
        }
        const keyString = ProfileFieldGroupListItemsMap.getKeyString(key);
        return super.get(keyString);
    }
    set(key, value) {
        if (typeof key === 'string') {
            super.set(key, value);
        }
        else {
            const keyString = ProfileFieldGroupListItemsMap.getKeyString(key);
            super.set(keyString, value);
        }
        return this;
    }
    pushToList(key, value) {
        if (!this.has(key)) {
            this.set(key, []);
        }
        this.get(key).push(value);
    }
}
