import React, { useMemo } from 'react';
import { YesNoModal } from '$cmp/modals/yesNoModal';
import type { Profile } from '@quiet-sunset/leo-shared';

export interface ProfileDeleteConfirmationModalProps {
  profile: Profile;
  onConfirm: () => unknown;
  onCancel: () => unknown;
}

export const ProfileDeleteConfirmationModal: React.FunctionComponent<ProfileDeleteConfirmationModalProps> =
  (props) => {
    const { profile, onConfirm, onCancel } = props;

    const title = `Delete profile`;

    const headOfHouseholdName = useMemo(() => {
      const x = `${profile.head_of_household_name?.first_name ?? ''} ${
        profile.head_of_household_name?.middle_name ?? ''
      } ${profile.head_of_household_name?.last_name ?? ''}`;
      return x.trim().length > 0 ? x : '(unnamed)';
    }, [profile]);

    const message = useMemo(() => {
      return `Do you want to delete the profile named ${headOfHouseholdName}?`;
    }, [headOfHouseholdName]);

    return <YesNoModal title={title} message={message} onYes={onConfirm} onNo={onCancel} />;
  };
