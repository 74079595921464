import React from 'react';
import { useParams } from 'react-router';
import { ProfilePageContextProvider } from '@quiet-sunset/leo-shared';
import { LoadingSpinner } from '$cmp/loadingSpinner';
import { ProfileFieldGroupListManager } from './profileFieldGroupListManager';

export const ProfileFieldGroupListManagerRoute: React.FunctionComponent = () => {
  const { menuItemId, fieldGroupId } = useParams<{ menuItemId: string; fieldGroupId: string }>();

  return (
    <ProfilePageContextProvider
      menuItemId={menuItemId}
      rootFieldGroupId={fieldGroupId}
      rootFieldGroupListItemId={null}
      Loading={LoadingSpinner}
    >
      <ProfileFieldGroupListManager />
    </ProfilePageContextProvider>
  );
};
