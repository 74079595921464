import React, { memo, VFC } from 'react';
import { Field } from '@quiet-sunset/leo-shared';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';

export type FieldListItemProps = {
  field: Field;
};

export const FieldListItem: VFC<FieldListItemProps> = memo(({ field }) => {
  return (
    <>
      <Avatar sx={{ height: 16, width: 16, bgcolor: 'green', marginRight: 2 }}> </Avatar>
      <ListItemText primary={`Field: ${field.label}`} />
    </>
  );
});
