import React from 'react';
import { Table } from 'react-bootstrap';

import { useFieldGroup } from '@quiet-sunset/leo-shared';

import { ProfileContentFieldGroupHorizontalTableHeader } from './profileContentFieldGroupHorizontalTableHeader';
// eslint-disable-next-line import/no-cycle
import { ProfileContentFieldGroupHorizontalRow } from './profileContentFieldGroupHorizontalRow';
// eslint-disable-next-line import/no-cycle
import { ProfileContentFieldGroupHorizontalRowList } from './profileContentFieldGroupHorizontalRowList';
import { ProfileContentFieldGroupHorizontalListFooter } from './profileContentFieldGroupHorizontalListFooter';

export interface ProfileContentFieldGroupHorizontalProps {
  fieldGroupId: string;
  profileFieldGroupListItemId: string | null;
}

export const ProfileContentFieldGroupHorizontal: React.FunctionComponent<ProfileContentFieldGroupHorizontalProps> =
  (props) => {
    const { fieldGroupId, profileFieldGroupListItemId } = props;

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const launchHelpTextDialog = () => {};

    const fieldGroup = useFieldGroup(fieldGroupId);
    const fieldGroupColumnWidths = fieldGroup?.field_group_column_widths ?? [];

    if (fieldGroup == null) {
      return null;
    }

    return (
      <Table>
        <ProfileContentFieldGroupHorizontalTableHeader
          fieldGroupId={fieldGroupId}
          fieldGroupColumnWidths={fieldGroupColumnWidths}
          launchHelpTextDialog={launchHelpTextDialog}
        />
        <tbody>
          {!fieldGroup.is_list && (
            <ProfileContentFieldGroupHorizontalRow
              fieldGroupId={fieldGroupId}
              profileFieldGroupListItemId={profileFieldGroupListItemId}
            />
          )}
          {fieldGroup.is_list && (
            <ProfileContentFieldGroupHorizontalRowList
              fieldGroupId={fieldGroupId}
              profileFieldGroupListItemId={profileFieldGroupListItemId}
            />
          )}
        </tbody>
        {fieldGroup.is_list && (
          <ProfileContentFieldGroupHorizontalListFooter
            fieldGroupId={fieldGroupId}
            profileFieldGroupListItemId={profileFieldGroupListItemId}
          />
        )}
      </Table>
    );
  };
