import React from 'react';
import { Field } from 'react-final-form';
import { CardElement } from '@stripe/react-stripe-js';

export const PaymentCardInfoFormFields: React.FunctionComponent = () => {
  return (
    <>
      <div className="form-group">
        <label className="control-label">Card number</label>
        <CardElement />
      </div>
      <div className="form-group">
        <label className="control-label">Full name</label>
        <Field name="fullName" component="input" className="form-control input-md" />
      </div>
      <div className="form-group">
        <label className="control-label">Address line 1</label>
        <Field name="address1" component="input" className="form-control input-md" />
      </div>
      <div className="form-group">
        <label className="control-label">Address line 2</label>
        <Field name="address2" component="input" className="form-control input-md" />
      </div>
      <div className="form-group">
        <label className="control-label">City</label>
        <Field name="city" component="input" className="form-control input-md" />
      </div>
      <div className="form-group">
        <label className="control-label">State</label>
        <Field name="state" component="input" className="form-control input-md" />
      </div>
      <div className="form-group">
        <label className="control-label">Zip</label>
        <Field name="zip" component="input" className="form-control input-md" />
      </div>
    </>
  );
};
