import React, { useCallback, useState } from 'react';
import { ThemeProvider } from 'styled-components/macro';
import { CssBaseline } from '@mui/material';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import createTheme from '$material-app/theme';

import { AppContent } from './appContent';
import { EnvironmentAlertWrapper } from './environmentAlertWrapper';

export const App: React.FunctionComponent = () => {
  const [showSmallScreenWarning, setShowSmallScreenWarning] = useState(true);

  const dismissSmallScreenWarning = useCallback(() => {
    setShowSmallScreenWarning(false);
  }, []);

  const theme = createTheme('DEFAULT');

  return (
    <EnvironmentAlertWrapper>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <MuiThemeProvider theme={theme}>
          <ThemeProvider theme={theme}>
            <CssBaseline />

            {showSmallScreenWarning && (
              <div
                id="small-screen-popup"
                className="small-screen-warning"
                onClick={dismissSmallScreenWarning}
              >
                <div className="warning-text">
                  LEO works best on wider devices, please try using a bigger screen or turning your
                  phone sideways.
                </div>
              </div>
            )}

            <AppContent />
          </ThemeProvider>
        </MuiThemeProvider>
      </LocalizationProvider>
    </EnvironmentAlertWrapper>
  );
};
