import React from 'react';

export const LoadingSpinner: React.FunctionComponent = () => (
  <div className="full-overlay">
    <div>
      <div>
        <div className="loading" />
      </div>
    </div>
  </div>
);
