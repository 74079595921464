import { useCallback } from 'react';
import { Field, useFieldsService } from '@quiet-sunset/leo-shared';
import { useProfileAdminClipboardContext } from '../profileAdminClipboardContext';

export const useRetrieveField = (
  fieldId: string,
  setField: React.Dispatch<React.SetStateAction<Field | undefined>>
) => {
  const FieldsService = useFieldsService();

  return useCallback(async () => {
    const field = await FieldsService.getField(fieldId);
    setField(field);
  }, [FieldsService, setField, fieldId]);
};

export const useCopyField = (
  field: Field | undefined,
  fieldGroupItemId: string,
  onCopyComplete?: () => void
) => {
  const profileAdminContext = useProfileAdminClipboardContext();

  return useCallback(() => {
    if (!field) {
      return;
    }

    profileAdminContext.addToClipboard({
      type: 'field',
      fieldGroupItemId,
      field,
      onCopyComplete,
    });
  }, [field, fieldGroupItemId, profileAdminContext, onCopyComplete]);
};

export const useUpdateField = (onComplete: () => void) => {
  const FieldsService = useFieldsService();

  return useCallback(
    async (updateModel: Field) => {
      await FieldsService.updateField(updateModel);
      onComplete();
    },
    [FieldsService, onComplete]
  );
};

export const useDeleteField = (fieldId: string, onComplete: () => void) => {
  const FieldsService = useFieldsService();

  return useCallback(async () => {
    await FieldsService.deleteField(fieldId);
    onComplete();
  }, [FieldsService, fieldId, onComplete]);
};
