import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { DefaultCheckmark } from '$cmp/defaultCheckmark';
import { LeoProManagerRoleSelect } from '$cmp/leoProManagerRoleSelect';
import { LoadingSpinner } from '$cmp/loadingSpinner';

import { YesNoModal } from '$cmp/modals/yesNoModal';
import { AcknowledgeModal } from '$cmp/modals/acknowledgeModal';
import { CollectEmailAddressModal } from '$cmp/modals/collectEmailAddressModal';

import { useCurrentUserContext } from '$contexts/currentUserContext';

import type {
  LeoProOrganization,
  LeoProOrganizationWithMemberType,
} from '@quiet-sunset/leo-shared';

import {
  useLeoProOrganizationAdministratorsService,
  useLeoProOrganizationManagersService,
  useLeoProOrganizationAssistantsService,
  useLeoProOrganizationsService,
} from '@quiet-sunset/leo-shared';

interface LeoProOrganizationMember {
  id: string;
  leo_pro_organization_id: string;
  user_id: string | null;
  invite_email: string | null;

  user?: {
    id: string;
    first_name: string;
    last_name: string;
    email: string;
  };

  leo_pro_organization?: LeoProOrganization;

  role?: string | null;
}

export interface LeoProMemberListProps {
  leoProOrganization: LeoProOrganizationWithMemberType;
  memberType: 'Administrator' | 'Manager' | 'Assistant';
  allowActions: boolean;
  onChange?: () => any;
}

export const LeoProMemberList: React.FunctionComponent<LeoProMemberListProps> = ({
  leoProOrganization,
  memberType,
  allowActions,
  onChange,
}) => {
  const leoProOrganizationId = leoProOrganization.id;

  const LeoProOrganizationAdministratorsService = useLeoProOrganizationAdministratorsService();
  const LeoProOrganizationManagersService = useLeoProOrganizationManagersService();
  const LeoProOrganizationAssistantsService = useLeoProOrganizationAssistantsService();
  const LeoProOrganizationsService = useLeoProOrganizationsService();

  const [isLoaded, setIsLoaded] = useState(false);
  const [leoProOrganizationMembers, setLeoProOrganizationMembers] = useState(
    null as null | LeoProOrganizationMember[]
  );
  const [memberIdToRemove, setMemberIdToRemove] = useState(null as string | null);
  const [showAddMemberModal, setShowAddMemberModal] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);

  const { currentUser } = useCurrentUserContext();

  const addMemberButtonText = useMemo(() => {
    switch (memberType) {
      case 'Administrator':
        return 'Add administrator';
      case 'Manager':
        return 'Add manager';
      case 'Assistant':
        return 'Add assistant';
      default:
        throw new Error(`Unknown memberType ${memberType}`);
    }
  }, [memberType]);

  useEffect(() => {
    void (async () => {
      setIsLoaded(false);
      try {
        let members: LeoProOrganizationMember[] | null = null;

        switch (memberType) {
          case 'Administrator':
            members =
              await LeoProOrganizationAdministratorsService.getLeoProOrganizationAdministratorsForLeoProOrganization(
                leoProOrganizationId
              );
            break;
          case 'Manager':
            members =
              await LeoProOrganizationManagersService.getLeoProOrganizationManagersForLeoProOrganization(
                leoProOrganizationId
              );
            break;
          case 'Assistant':
            members =
              await LeoProOrganizationAssistantsService.getLeoProOrganizationAssistantsForLeoProOrganization(
                leoProOrganizationId
              );
            break;
          default:
        }
        setLeoProOrganizationMembers(members);
      } catch (e: any) {
        if (e.response?.status === 403) {
          setLeoProOrganizationMembers(null);
        } else {
          throw e;
        }
      } finally {
        setIsLoaded(true);
      }
    })();
  }, [leoProOrganizationId, memberType]);

  const confirmRemoveMember = useCallback(async () => {
    if (memberIdToRemove == null) {
      return;
    }

    try {
      setIsLoaded(false);
      switch (memberType) {
        case 'Administrator':
          await LeoProOrganizationAdministratorsService.deleteLeoProOrganizationAdministratorById(
            leoProOrganizationId,
            memberIdToRemove
          );
          break;
        case 'Manager':
          await LeoProOrganizationManagersService.deleteLeoProOrganizationManagerById(
            leoProOrganizationId,
            memberIdToRemove
          );
          break;
        case 'Assistant':
          await LeoProOrganizationAssistantsService.deleteLeoProOrganizationAssistantById(
            leoProOrganizationId,
            memberIdToRemove
          );
          break;
        default:
      }
      setMemberIdToRemove(null);
      onChange?.call(null);
    } catch (e: any) {
      if (
        e.isAxiosError &&
        e?.response?.status === 409 &&
        e.response.data?.message === 'Manager has profiles'
      ) {
        setMemberIdToRemove(null);
        setAlertMessage('Cannot delete manager because the manager has assigned profiles.');
      }
    } finally {
      setIsLoaded(true);
    }
  }, [memberIdToRemove, memberType, leoProOrganizationId, onChange]);

  const cancelRemoveMember = useCallback(() => {
    setMemberIdToRemove(null);
  }, []);

  const addMember = useCallback(
    async (email: string) => {
      try {
        setIsLoaded(false);
        switch (memberType) {
          case 'Administrator':
            await LeoProOrganizationAdministratorsService.addLeoProOrganizationAdministrator(
              leoProOrganizationId,
              email
            );
            break;
          case 'Manager':
            await LeoProOrganizationManagersService.addLeoProOrganizationManager(
              leoProOrganizationId,
              email
            );
            break;
          case 'Assistant':
            await LeoProOrganizationAssistantsService.addLeoProOrganizationAssistant(
              leoProOrganizationId,
              email
            );
            break;
          default:
        }
      } finally {
        setShowAddMemberModal(false);
        setIsLoaded(true);
        onChange?.call(null);
      }
    },
    [leoProOrganizationId, memberType, onChange]
  );

  const updateManagerRole = useCallback(
    async (leoProOrganizationManagerId: string, role: string | null) => {
      await LeoProOrganizationManagersService.updateLeoProOrganizationManagerRole(
        leoProOrganizationId,
        leoProOrganizationManagerId,
        role
      );
      onChange?.call(null);
    },
    [leoProOrganizationId, onChange]
  );

  const setBillingAdmin = useCallback(
    async (leoProOrganizationAdminId: string) => {
      setIsLoaded(false);
      try {
        await LeoProOrganizationsService.setBillingAdminForLeoProOrganization(
          leoProOrganizationId,
          leoProOrganizationAdminId
        );
      } finally {
        setIsLoaded(true);
        onChange?.call(null);
      }
    },
    [leoProOrganizationId, onChange]
  );

  const numColumns = 2 + (allowActions ? 2 : 0);

  return (
    <>
      {memberIdToRemove != null && (
        <YesNoModal
          title={`Remove LEO-PRO ${memberType}`}
          message={`Do you want to remove this LEO-PRO ${memberType}?`}
          onYes={confirmRemoveMember}
          onNo={cancelRemoveMember}
        />
      )}
      {showAddMemberModal && (
        <CollectEmailAddressModal
          title={`Add ${memberType}`}
          onSubmit={addMember}
          onCancel={() => setShowAddMemberModal(false)}
        />
      )}
      {alertMessage && (
        <AcknowledgeModal
          title="Alert"
          message={alertMessage}
          onAcknowledge={() => setAlertMessage(null)}
        />
      )}
      {!isLoaded && <LoadingSpinner />}
      {isLoaded && (
        <Table>
          <thead>
            <tr>
              <th>Name</th>
              {memberType === 'Manager' && <th>Role</th>}
              {memberType === 'Administrator' && <th>Billing admin</th>}
              {allowActions && <th>Status</th>}
              {allowActions && <th>Actions</th>}
            </tr>
          </thead>
          <tbody>
            {leoProOrganizationMembers == null && (
              <tr>
                <td colSpan={numColumns}>An error occurred</td>
              </tr>
            )}
            {leoProOrganizationMembers != null && leoProOrganizationMembers.length === 0 && (
              <tr>
                <td colSpan={numColumns}>No members</td>
              </tr>
            )}
            {leoProOrganizationMembers != null &&
              leoProOrganizationMembers.length !== 0 &&
              leoProOrganizationMembers.map((leoProOrganizationMember) => (
                <tr key={leoProOrganizationMember.id}>
                  <td>
                    {leoProOrganizationMember.user != null &&
                      `${leoProOrganizationMember.user.first_name} ${leoProOrganizationMember.user.last_name} (${leoProOrganizationMember.user.email})`}
                    {leoProOrganizationMember.user == null &&
                      `${leoProOrganizationMember.invite_email}`}
                  </td>
                  {memberType === 'Manager' && (
                    <td>
                      {allowActions && (
                        <LeoProManagerRoleSelect
                          value={leoProOrganizationMember.role as string | null}
                          onChange={(newRole) =>
                            updateManagerRole(leoProOrganizationMember.id, newRole)
                          }
                        />
                      )}
                      {!allowActions && (leoProOrganizationMember.role ?? <em>(none)</em>)}
                    </td>
                  )}
                  {memberType === 'Administrator' && (
                    <td>
                      {leoProOrganization.billing_administrator_id ===
                        leoProOrganizationMember.id && <DefaultCheckmark />}
                    </td>
                  )}
                  {allowActions && (
                    <td>{leoProOrganizationMember.user != null ? 'Active' : 'Pending'}</td>
                  )}
                  {allowActions && (
                    <td>
                      {(memberType !== 'Administrator' ||
                        leoProOrganizationMember.user_id !== currentUser?.id) && (
                        <Button
                          variant="danger"
                          onClick={() => setMemberIdToRemove(leoProOrganizationMember.id)}
                        >
                          Remove
                        </Button>
                      )}
                      {memberType === 'Administrator' &&
                        leoProOrganization.billing_administrator_id !==
                          leoProOrganizationMember.id && (
                          <Button
                            variant="secondary"
                            onClick={() => setBillingAdmin(leoProOrganizationMember.id)}
                          >
                            Make billing admin
                          </Button>
                        )}
                      {memberType === 'Administrator' &&
                        leoProOrganization.billing_administrator_id ===
                          leoProOrganizationMember.id &&
                        leoProOrganizationMember.user_id === currentUser?.id && (
                          <Link to={`/leo-pro/${leoProOrganization.id}/manage-payment-account`}>
                            <Button variant="secondary">Manage billing account</Button>
                          </Link>
                        )}
                    </td>
                  )}
                </tr>
              ))}
          </tbody>
          {allowActions && (
            <tfoot>
              <tr>
                <td colSpan={numColumns}>
                  <Button variant="success" onClick={() => setShowAddMemberModal(true)}>
                    {addMemberButtonText}
                  </Button>
                </td>
              </tr>
            </tfoot>
          )}
        </Table>
      )}
    </>
  );
};
