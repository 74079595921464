import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Alert, Button, Table } from 'react-bootstrap';
import dayjs from 'dayjs';

import { LoadingSpinner } from '$cmp/loadingSpinner';
import { CancelAccountConfirmationModal } from '$cmp/modals/cancelAccountConfirmationModal';
import { UserPaymentAccountEditableCardInformation } from '$cmp/userPaymentAccountEditableCardInformation';

import { useCurrentUserContext } from '$contexts/currentUserContext';

import { useUpdateTrigger } from '$hooks/useUpdateTrigger';

import type { PaymentAccountSubscription, PaymentCardInfo } from '@quiet-sunset/leo-shared';

import { usePaymentAccountService } from '@quiet-sunset/leo-shared';

import { PaymentAccountStatusAlert } from './paymentAccountStatusAlert';
import { BillingInterval, BillingIntervalSelector } from './billingIntervalSelector';

export const PaymentAccountManager: React.FunctionComponent = () => {
  const PaymentAccountService = usePaymentAccountService();

  const { currentUser, reloadCurrentUser } = useCurrentUserContext();

  const [updateTrigger, triggerUpdate] = useUpdateTrigger();

  const [isLoaded, setIsLoaded] = useState(false);
  const [paymentAccountSubscription, setPaymentAccountSubscription] = useState(
    null as null | PaymentAccountSubscription
  );
  const [paymentCardInfo, setPaymentCardInfo] = useState(null as null | PaymentCardInfo);
  const [cancelAccountConfirmationModalIsVisible, setCancelAccountConfirmationModalIsVisible] =
    useState(false);
  const userCreatedDate = currentUser?.created_date;
  const userCreatedDateText = dayjs.utc(userCreatedDate).format('L');

  const isTrial = paymentAccountSubscription?.status === 'trialing';

  const periodStartDateText =
    paymentAccountSubscription != null && paymentAccountSubscription.status !== 'canceled'
      ? dayjs.utc(paymentAccountSubscription.current_period_start * 1000).format('L')
      : null;
  const periodEndDateText =
    paymentAccountSubscription != null && paymentAccountSubscription.status !== 'canceled'
      ? dayjs.utc(paymentAccountSubscription.current_period_end * 1000).format('L')
      : null;

  const paymentAccountSubscriptionStatus = useMemo(() => {
    if (paymentAccountSubscription == null) {
      return 'new';
    }
    if (
      ['active', 'trialing'].includes(paymentAccountSubscription.status) &&
      paymentAccountSubscription.cancel_at_period_end
    ) {
      return 'pending_cancel';
    }
    return paymentAccountSubscription.status;
  }, [paymentAccountSubscription]);

  const showCancelAccountConfirmationModal = useCallback(() => {
    setCancelAccountConfirmationModalIsVisible(true);
  }, []);

  const hideCancelAccountConfirmationModal = useCallback(() => {
    setCancelAccountConfirmationModalIsVisible(false);
    triggerUpdate();
  }, [triggerUpdate]);

  const restoreAccount = useCallback(async () => {
    setIsLoaded(false);
    await PaymentAccountService.restoreAccountForCurrentUser();
    setIsLoaded(true);
    triggerUpdate();
  }, [triggerUpdate]);

  const updatePaymentAccountBillingInterval = useCallback(
    async (interval: 'monthly' | 'annual') => {
      setIsLoaded(false);
      await PaymentAccountService.updatePaymentAccountInterval(interval);
      await reloadCurrentUser();
      window.location.reload(); // absolutely horrible lazy cheat, you should feel bad about yourself
      setIsLoaded(true);
      triggerUpdate();
    },
    [reloadCurrentUser, triggerUpdate]
  );

  useEffect(() => {
    void (async () => {
      setIsLoaded(false);
      const [_paymentAccountSubscription, _paymentCardInfo] = await Promise.all([
        PaymentAccountService.getActivePaymentAccountSubscriptionForCurrentUser(),
        PaymentAccountService.getCardInfo(),
      ]);
      setPaymentAccountSubscription(_paymentAccountSubscription);
      setPaymentCardInfo(_paymentCardInfo);
      setIsLoaded(true);
    })();
  }, [updateTrigger]);

  if (!currentUser || !isLoaded) {
    return <LoadingSpinner />;
  }

  return (
    <div className="container">
      {cancelAccountConfirmationModalIsVisible && (
        <CancelAccountConfirmationModal onClose={hideCancelAccountConfirmationModal} />
      )}
      <h3 style={{ paddingTop: '15px', paddingBottom: '15px' }}>Manage payment account</h3>
      <PaymentAccountStatusAlert />
      <div className="form-group row">
        <label className="col-md-4 control-label">Account status</label>
        <div className="col-md-8">
          <p className="form-control-static">{paymentAccountSubscriptionStatus}</p>
        </div>
      </div>
      <div className="form-group row">
        <label className="col-md-4 control-label">Signup date</label>
        <div className="col-md-8">
          <p className="form-control-static">{userCreatedDateText}</p>
        </div>
      </div>
      {periodStartDateText != null && (
        <div className="form-group row">
          <label className="col-md-4 control-label">
            {isTrial && <span>Trial start</span>}
            {!isTrial && <span>Subscription period start</span>}
          </label>
          <div className="col-md-8">
            <p className="form-control-static">{periodStartDateText}</p>
          </div>
        </div>
      )}
      {periodEndDateText != null && (
        <div className="form-group row">
          <label className="col-md-4 control-label">
            {isTrial && <span>Trial end</span>}
            {!isTrial && <span>Subscription period end</span>}
          </label>
          <div className="col-md-8">
            <p className="form-control-static">{periodEndDateText}</p>
          </div>
        </div>
      )}
      {paymentAccountSubscription != null && (
        <>
          <div className="form-group row">
            <label className="col-md-4 control-label">Card information</label>
            <div className="col-md-8">
              <UserPaymentAccountEditableCardInformation onUpdate={triggerUpdate} />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-md-4 control-label">Billing plan</label>
            <div className="col-md-8">
              <BillingIntervalSelector
                isTrial={isTrial}
                hasPromotions={
                  currentUser.user_payment_account?.next_renewal_line_item_amount != null
                }
                initialInterval={paymentAccountSubscription.item.interval as BillingInterval}
                onIntervalChange={(interval) =>
                  updatePaymentAccountBillingInterval(interval === 'month' ? 'monthly' : 'annual')
                }
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-md-4 control-label">Promotions</label>
            <div className="col-md-8">
              <Table>
                <thead>
                  <tr>
                    <th>Description</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {currentUser.user_payment_account?.next_renewal_line_item_amount == null ? (
                    <tr>
                      <td colSpan={2}>
                        <em>You have no promotions active</em>
                      </td>
                    </tr>
                  ) : (
                    <tr>
                      <td>
                        {currentUser.user_payment_account?.next_renewal_line_item_description}
                      </td>
                      <td>
                        $
                        {(
                          currentUser.user_payment_account?.next_renewal_line_item_amount / -100
                        ).toFixed(2)}
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-md-4 control-label">Actions</label>
            <div className="col-md-8">
              {!['canceled', 'pending_cancel'].includes(paymentAccountSubscriptionStatus) && (
                <Button variant="danger" onClick={showCancelAccountConfirmationModal}>
                  Cancel account
                </Button>
              )}
              {['canceled', 'pending_cancel'].includes(paymentAccountSubscriptionStatus) && (
                <>
                  <Button
                    variant="primary"
                    onClick={restoreAccount}
                    disabled={paymentCardInfo == null}
                  >
                    Restore account
                  </Button>
                  {paymentCardInfo == null && (
                    <Alert variant="warning" style={{ display: 'inline-block' }}>
                      You must provide payment card information before restoring your account.
                    </Alert>
                  )}
                </>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
