var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useRef } from 'react';
import { useAxiosService } from './axiosService';
export const useLeoProOrganizationAdministratorsService = () => {
    const axios = useAxiosService();
    const { current: LeoProOrganizationAdministratorsService } = useRef({
        getLeoProOrganizationAdministratorsForLeoProOrganization(leoProOrganizationId) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`leo-pro-organizations/${leoProOrganizationId}/administrators`);
                return data;
            });
        },
        getLeoProOrganizationAdministratorsInvitationsForCurrentUser() {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`leo-pro-organizations/administrators/invitations`);
                return data;
            });
        },
        acceptLeoProOrganizationAdministratorsInvitation(leoProOrganizationAdministratorId) {
            return __awaiter(this, void 0, void 0, function* () {
                yield axios.post(`/leo-pro-organizations/administrators/invitations/${leoProOrganizationAdministratorId}/accept`);
            });
        },
        declineLeoProOrganizationAdministratorsInvitation(leoProOrganizationAdministratorId) {
            return __awaiter(this, void 0, void 0, function* () {
                yield axios.delete(`/leo-pro-organizations/administrators/invitations/${leoProOrganizationAdministratorId}`);
            });
        },
        addLeoProOrganizationAdministrator(leoProOrganizationId, email) {
            return __awaiter(this, void 0, void 0, function* () {
                yield axios.post(`leo-pro-organizations/${leoProOrganizationId}/administrators`, {
                    email,
                });
            });
        },
        deleteLeoProOrganizationAdministratorById(leoProOrganizationId, leoProOrganizationAdministratorId) {
            return __awaiter(this, void 0, void 0, function* () {
                yield axios.delete(`leo-pro-organizations/${leoProOrganizationId}/administrators/${leoProOrganizationAdministratorId}`);
            });
        },
    });
    return LeoProOrganizationAdministratorsService;
};
