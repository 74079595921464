import React from 'react';
import { useParams } from 'react-router';
import { ProfilePageContextProvider, ProfilePageAutosaver } from '@quiet-sunset/leo-shared';
import { ProfileContentPage } from './profileContentPage';
import { useQuery } from '$hooks/useQuery';
import { LoadingSpinner } from '$cmp/loadingSpinner';

export const ProfileContentRoute: React.FunctionComponent = () => {
  const { menuItemId, fieldGroupId } = useParams<{ menuItemId: string; fieldGroupId: string }>();
  const { listItemId } = useQuery<{ listItemId: string | undefined }>();

  return (
    <ProfilePageContextProvider
      menuItemId={menuItemId}
      rootFieldGroupId={fieldGroupId}
      rootFieldGroupListItemId={listItemId ?? null}
      key={`${menuItemId}|${fieldGroupId}|${listItemId ?? null}`}
      Loading={LoadingSpinner}
    >
      <ProfilePageAutosaver />
      <ProfileContentPage />
    </ProfilePageContextProvider>
  );
};
