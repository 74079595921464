import React, { useCallback, useEffect, useState, VFC } from 'react';
import { FieldGroup } from '@quiet-sunset/leo-shared';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import ListItemButton from '@mui/material/ListItemButton';
// eslint-disable-next-line import/no-cycle
import { FieldGroupItemsTree } from './fieldGroupItemsTree';
import { FieldGroupListItem } from './fieldGroupListItem';
import { FieldGroupEditDialog } from './fieldGroupEditDialog';
import { useCopyFieldGroup, useDeleteFieldGroup, useRetrieveFieldGroup } from './hooks';
import { useMoveFieldGroupItemDown, useMoveFieldGroupItemUp } from '../field-group-item/hooks';

export type FieldGroupTreeNodeProps = {
  fieldGroupId: string;
  fieldGroupItemId: string | null;
  onUpdate: () => void;
};

export const FieldGroupTreeNode: VFC<FieldGroupTreeNodeProps> = ({
  fieldGroupId,
  fieldGroupItemId,
  onUpdate,
}) => {
  const [open, setOpen] = useState(false);
  const [isEditVisible, setIsEditVisible] = useState(false);
  const [fieldGroup, setFieldGroup] = useState<FieldGroup>();

  const retrieveFieldGroup = useRetrieveFieldGroup(fieldGroupId, setFieldGroup);
  const handleMoveUp = useMoveFieldGroupItemUp(fieldGroupItemId, onUpdate);
  const handleMoveDown = useMoveFieldGroupItemDown(fieldGroupItemId, onUpdate);
  const handleDelete = useDeleteFieldGroup(fieldGroupId, onUpdate);
  const copy = useCopyFieldGroup(fieldGroup, fieldGroupItemId, onUpdate);

  const handleEditClose = useCallback(async () => {
    await retrieveFieldGroup();
    setIsEditVisible(false);
  }, [retrieveFieldGroup]);

  useEffect(() => {
    retrieveFieldGroup().catch(console.error);
  }, [retrieveFieldGroup]);

  if (!fieldGroup) {
    return null;
  }

  return (
    <>
      {isEditVisible && <FieldGroupEditDialog fieldGroup={fieldGroup} onClose={handleEditClose} />}
      <ListItemButton onClick={() => setOpen((prevOpen) => !prevOpen)}>
        <FieldGroupListItem fieldGroup={fieldGroup} />
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            handleMoveUp();
          }}
        >
          <ArrowUpwardIcon />
        </IconButton>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            handleMoveDown();
          }}
        >
          <ArrowDownwardIcon />
        </IconButton>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            setIsEditVisible(true);
          }}
        >
          <EditIcon />
        </IconButton>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            copy();
          }}
        >
          <CopyIcon />
        </IconButton>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            handleDelete();
          }}
        >
          <DeleteIcon />
        </IconButton>
        {open && <ExpandLessIcon />}
        {!open && <ExpandMoreIcon />}
      </ListItemButton>
      <Collapse in={open} mountOnEnter>
        <FieldGroupItemsTree rootFieldGroup={fieldGroup} />
      </Collapse>
    </>
  );
};
