import { useRef, useMemo, useState, useEffect, useCallback } from 'react';
const none = Symbol('none');
export const useLazyRef = (initializer) => {
    const ref = useRef(none);
    if (ref.current === none) {
        ref.current = initializer();
    }
    return ref;
};
export const useAsyncMemo = (fn, deps) => {
    const promise = useMemo(fn, deps);
    const [resolvedValue, setResolvedValue] = useState();
    const [isResolved, setIsResolved] = useState(false);
    const [error, setError] = useState(undefined);
    useEffect(() => {
        let isMounted = true;
        setIsResolved(false);
        setError(undefined);
        promise.then(value => {
            if (isMounted) {
                setResolvedValue(value);
                setIsResolved(true);
            }
        }).catch(e => {
            if (isMounted) {
                setError(e);
            }
        });
        return () => {
            isMounted = false;
        };
    }, [promise]);
    return [resolvedValue, isResolved, error];
};
export const useUpdateTrigger = (additionalUpdateTrigger) => {
    const [updateTrigger, setUpdateTrigger] = useState({});
    const triggerUpdate = useCallback(() => setUpdateTrigger({}), []);
    useEffect(() => {
        if (additionalUpdateTrigger != null) {
            triggerUpdate();
        }
    }, [additionalUpdateTrigger, triggerUpdate]);
    return [updateTrigger, triggerUpdate];
};
