import React, { useCallback, useState } from 'react';
import { Alert, Button } from 'react-bootstrap';

export type BillingInterval = 'month' | 'year';

export interface BillingIntervalSelectorProps {
  isTrial: boolean;
  hasPromotions: boolean;
  initialInterval: BillingInterval;
  onIntervalChange: (interval: BillingInterval) => unknown;
}

export const BillingIntervalSelector: React.FunctionComponent<BillingIntervalSelectorProps> = (
  props
) => {
  const { isTrial, hasPromotions, initialInterval, onIntervalChange } = props;

  const [interval, setInterval] = useState(initialInterval);

  const cancel = useCallback(() => {
    setInterval(initialInterval);
  }, [initialInterval]);

  const submit = useCallback(() => {
    onIntervalChange(interval);
  }, [interval, onIntervalChange]);

  return (
    <>
      <div style={{ display: 'flex' }}>
        <select
          className="form-control"
          value={interval}
          onChange={(e) => setInterval(e.target.value as BillingInterval)}
        >
          <option value="month">Monthly</option>
          <option value="year">Annual</option>
        </select>
        {interval !== initialInterval && (
          <>
            <Button variant="secondary" onClick={cancel}>
              <i className="fas fa-times" />
            </Button>
            <Button variant="success" onClick={submit}>
              <i className="fas fa-check" />
            </Button>
          </>
        )}
      </div>
      {!isTrial && hasPromotions && interval !== initialInterval && (
        <Alert variant="warning">
          <strong>Warning!</strong> By changing your billing plan, you may lose access to any
          current promotions you have.
        </Alert>
      )}
    </>
  );
};
