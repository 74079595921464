import React, { useMemo } from 'react';
import { Field, Form } from 'react-final-form';
import { Button, Modal } from 'react-bootstrap';
import type { TrustedUserType } from '@quiet-sunset/leo-shared';
import { AppointmentDocument } from '../appointmentDocuments/appointmentDocument';

export interface AgreementScreenProps {
  trustedUserType: TrustedUserType;
  initialValues: AgreementScreenForm;
  onClose: () => any;
  onBack: (formValues: AgreementScreenForm) => any;
  onSubmit: (formValues: AgreementScreenForm) => any;
}

export interface AgreementScreenForm {
  agreementType: 'leo' | 'custom';
  agreementModel: Record<string, unknown>;
  agreementFileName: string | null;
  agreementFileUrl: string | null;
  appointmentDocumentFile: File | null;
}

export const AgreementScreen: React.FunctionComponent<AgreementScreenProps> = (props) => {
  const { trustedUserType, initialValues, onClose, onBack, onSubmit } = props;

  const disableInputs = false;

  const initialValuesWithCertificationRemoved = useMemo(
    () => ({
      ...initialValues,
      agreementModel: {
        ...initialValues.agreementModel,
        certifiedFullName: null,
        date: null,
        certifiedAccepted: null,
      },
    }),
    [initialValues]
  );

  return (
    <Form<AgreementScreenForm>
      onSubmit={onSubmit}
      initialValues={initialValuesWithCertificationRemoved}
    >
      {({ handleSubmit, values, form }) => (
        <form onSubmit={handleSubmit}>
          <Modal.Body>
            <div className="form-group">
              <label className="col-md-3 control-label">Appointment document source</label>
              <div className="col-md-9">
                <Field
                  name="agreementType"
                  component="select"
                  className="form-control form-control-inline"
                  required
                >
                  <option value="leo">LEO Appointment Document</option>
                  <option value="custom">Upload Custom Document</option>
                </Field>
              </div>
            </div>
            {values.agreementType === 'custom' && (
              <div className="form-group">
                <label className="col-md-3 control-label">Custom appointment document</label>
                <div className="item">
                  {values.agreementFileUrl != null && (
                    <>
                      <a
                        href={values.agreementFileUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="input-field"
                      >
                        {values.agreementFileName ?? 'Download file'}
                      </a>
                      <button
                        type="button"
                        className="btn btn-default"
                        onClick={() => form.change('agreementFileUrl', null)}
                        disabled={disableInputs}
                      >
                        Remove
                      </button>
                    </>
                  )}
                  {values.agreementFileUrl == null && (
                    <Field name="appointmentDocumentFile" required>
                      {({ input: { value: _value, onChange, ...input } }) => {
                        const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
                          if (target.files == null) {
                            onChange(null);
                          } else {
                            const file = target.files.length > 0 ? target.files.item(0) : null;
                            onChange(file);
                          }
                        };

                        return <input {...input} type="file" onChange={handleChange} />;
                      }}
                    </Field>
                  )}
                </div>
              </div>
            )}
            {values.agreementType === 'leo' && (
              <div>
                <AppointmentDocument trustedUserType={trustedUserType} />
              </div>
            )}
            <div className="form-group">
              <label className="col-md-3 control-label">Full name</label>
              <div className="col-md-9">
                <Field
                  name="agreementModel.certifiedFullName"
                  component="input"
                  autoComplete="certifiedFullName"
                  className="form-control form-control-inline"
                  required
                />
              </div>
            </div>
            <div className="form-group">
              <label className="col-md-3 control-label">Date</label>
              <div className="col-md-9">
                <Field
                  name="agreementModel.date"
                  component="input"
                  type="date"
                  autoComplete="certifiedDate"
                  className="form-control form-control-inline"
                  required
                />
              </div>
            </div>

            <div className="form-group">
              <label className="col-md-3 control-label">I accept</label>
              <div className="col-md-9">
                <Field
                  name="agreementModel.certifiedAccepted"
                  component="input"
                  type="checkbox"
                  autoComplete="certifiedAccepted"
                  className="form-control form-control-inline"
                  required
                />
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="default" onClick={onClose}>
              Close
            </Button>
            <Button variant="default" onClick={() => onBack(values)}>
              Back
            </Button>
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Modal.Footer>
        </form>
      )}
    </Form>
  );
};
