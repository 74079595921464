import React, { useCallback, useEffect, useState } from 'react';

import { useUpdateTrigger } from '$hooks/useUpdateTrigger';
import type { PaymentCardInfo } from '@quiet-sunset/leo-shared';
import { useLeoProOrganizationsService } from '@quiet-sunset/leo-shared';

import { EditableCardInformation } from './editableCardInformation';
import { LoadingSpinner } from './loadingSpinner';

type LeoProorganizationPaymentAccountEditableCardInformationProps = {
  leoProOrganizationId: string;
  onUpdate?: () => unknown;
};

export const LeoProOrganizationPaymentAccountEditableCardInformation: React.FunctionComponent<LeoProorganizationPaymentAccountEditableCardInformationProps> =
  (props) => {
    const LeoProOrganizationsService = useLeoProOrganizationsService();

    const { leoProOrganizationId, onUpdate } = props;

    const [isLoaded, setIsLoaded] = useState(false);
    const [updateTrigger, triggerUpdate] = useUpdateTrigger();
    const [paymentCardInfo, setPaymentCardInfo] = useState<PaymentCardInfo | null>(null);

    const submit = useCallback(
      async (stripeSourceTokenId: string) => {
        setIsLoaded(false);
        try {
          await LeoProOrganizationsService.putStripeTokenForLeoProOrganization(
            leoProOrganizationId,
            stripeSourceTokenId
          );
        } finally {
          setIsLoaded(true);
          triggerUpdate();
          onUpdate?.call(null);
        }
      },
      [onUpdate, triggerUpdate, leoProOrganizationId]
    );

    const removeCardInfo = useCallback(async () => {
      setIsLoaded(false);
      await LeoProOrganizationsService.removeLeoProOrganizationBillingCardInfo(
        leoProOrganizationId
      );
      setIsLoaded(true);
      triggerUpdate();
      onUpdate?.call(null);
    }, [onUpdate, triggerUpdate, leoProOrganizationId]);

    useEffect(() => {
      void (async () => {
        setIsLoaded(false);
        const _paymentCardInfo =
          await LeoProOrganizationsService.getLeoProOrganizationBillingCardInfo(
            leoProOrganizationId
          );
        setPaymentCardInfo(_paymentCardInfo);
        setIsLoaded(true);
      })();
    }, [onUpdate, updateTrigger, leoProOrganizationId]);

    if (!isLoaded) {
      return <LoadingSpinner />;
    }

    return (
      <EditableCardInformation
        paymentCardInfo={paymentCardInfo}
        onUpdate={submit}
        onRemoveCardInfo={removeCardInfo}
      />
    );
  };
