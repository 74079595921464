import { useCallback, useState } from 'react';

export const useSetterUnsetter = (initialValue = false): [boolean, () => void, () => void] => {
  const [value, setValue] = useState(initialValue);

  const set = useCallback(() => setValue(true), []);
  const unset = useCallback(() => setValue(false), []);

  return [value, set, unset];
};
