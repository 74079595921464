var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useRef } from 'react';
import { useAxiosService } from './axiosService';
export const useLeoProOrganizationManagersService = () => {
    const axios = useAxiosService();
    const { current: LeoProOrganizationManagersService } = useRef({
        getLeoProOrganizationManagersForLeoProOrganization(leoProOrganizationId) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`leo-pro-organizations/${leoProOrganizationId}/managers`);
                return data;
            });
        },
        getLeoProOrganizationManagersInvitationsForCurrentUser() {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`leo-pro-organizations/managers/invitations`);
                return data;
            });
        },
        acceptLeoProOrganizationManagersInvitation(leoProOrganizationManagerId) {
            return __awaiter(this, void 0, void 0, function* () {
                yield axios.post(`/leo-pro-organizations/managers/invitations/${leoProOrganizationManagerId}/accept`);
            });
        },
        declineLeoProOrganizationManagersInvitation(leoProOrganizationManagerId) {
            return __awaiter(this, void 0, void 0, function* () {
                yield axios.delete(`/leo-pro-organizations/managers/invitations/${leoProOrganizationManagerId}`);
            });
        },
        addLeoProOrganizationManager(leoProOrganizationId, email) {
            return __awaiter(this, void 0, void 0, function* () {
                yield axios.post(`leo-pro-organizations/${leoProOrganizationId}/managers`, { email });
            });
        },
        deleteLeoProOrganizationManagerById(leoProOrganizationId, leoProOrganizationManagerId) {
            return __awaiter(this, void 0, void 0, function* () {
                yield axios.delete(`leo-pro-organizations/${leoProOrganizationId}/managers/${leoProOrganizationManagerId}`);
            });
        },
        updateLeoProOrganizationManagerRole(leoProOrganizationId, leoProOrganizationManagerId, role) {
            return __awaiter(this, void 0, void 0, function* () {
                yield axios.put(`leo-pro-organizations/${leoProOrganizationId}/managers/${leoProOrganizationManagerId}/role`, { role });
            });
        },
    });
    return LeoProOrganizationManagersService;
};
