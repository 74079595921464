import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import Alert from '@mui/material/Alert';
import { useIsRoute } from '$hooks/useIsRoute';
import { usePaymentAccountSubscription } from '$hooks/usePaymentAccountSubscription';

export type PendingCancelAlertProps = {
  updateTrigger?: Record<string, unknown>;
};

export const PendingCancelAlert: React.VoidFunctionComponent<PendingCancelAlertProps> = ({
  updateTrigger,
}) => {
  const onManagePaymentAccountPage = useIsRoute('/manage-payment-account');
  const paymentAccountSubscription = usePaymentAccountSubscription(updateTrigger);

  const currentPeriodEndDateString = useMemo(() => {
    if (!paymentAccountSubscription?.current_period_end) {
      return undefined;
    }
    return dayjs(paymentAccountSubscription.current_period_end * 1000).format('L');
  }, [paymentAccountSubscription]);

  return (
    <Alert severity="warning">
      <ul>
        <li>
          Your account is <strong>pending cancel</strong>.
        </li>
        <li>
          You will lose access to your account at the end of your current billing cycle (
          {currentPeriodEndDateString}).
        </li>
        <li>You may restore your account at any point up until that day without losing data.</li>
        <li>
          Beyond that day, access to your data will not be possible, as data for inactive accounts
          is removed for security and privacy concerns.
        </li>
        {!onManagePaymentAccountPage && (
          <li>
            Visit the <Link to="/manage-payment-account">Manage Payment Account page</Link> to
            restore your account.
          </li>
        )}
      </ul>
    </Alert>
  );
};
