import React, { useCallback, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Field, Form } from 'react-final-form';

import type { PetGuardianAgreement } from '@quiet-sunset/leo-shared';
import { useProfileContext, usePetGuardianAgreementsService } from '@quiet-sunset/leo-shared';

import { PetGuardianAgreementAppointmentDocument } from './petGuardianAgreementAppointmentDocument';

export interface PetGuardianAgreementModalProps {
  fieldId: string;
  profileFieldGroupListItemId: string | null;
  onClose: () => unknown;
}

export interface PetGuardianAgreementModalForm {
  agreementModel: PetGuardianAgreement;
}

export const PetGuardianAgreementModal: React.FunctionComponent<PetGuardianAgreementModalProps> = (
  props
) => {
  const { fieldId, profileFieldGroupListItemId, onClose } = props;

  const PetGuardianAgreementsService = usePetGuardianAgreementsService();

  const { profileId } = useProfileContext();
  const [isLoaded, setIsLoaded] = useState(false);
  const [petGuardianAgreementForm, setPetGuardianAgreementForm] = useState(
    {} as PetGuardianAgreementModalForm
  );

  const submit = useCallback(
    async (values: PetGuardianAgreementModalForm) => {
      await PetGuardianAgreementsService.setPetGuardianAgreement(
        profileId!,
        profileFieldGroupListItemId,
        fieldId,
        values.agreementModel
      );
      onClose();
    },
    [fieldId, onClose, profileFieldGroupListItemId, profileId]
  );

  useEffect(() => {
    void (async () => {
      setIsLoaded(false);
      const _petGuardianAgreement = await PetGuardianAgreementsService.getPetGuardianAgreement(
        profileId!,
        profileFieldGroupListItemId,
        fieldId
      );
      if (_petGuardianAgreement != null) {
        setPetGuardianAgreementForm({ agreementModel: _petGuardianAgreement });
      }
      setIsLoaded(true);
    })();
  }, [fieldId, profileFieldGroupListItemId, profileId]);

  if (!isLoaded) {
    return null;
  }

  return (
    <Modal show backdrop="static" keyboard={false} size="lg">
      <Form<PetGuardianAgreementModalForm>
        onSubmit={submit}
        initialValues={petGuardianAgreementForm}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Header>
              <Modal.Title>Pet Guardian Appointment Agreement</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <PetGuardianAgreementAppointmentDocument />
              <div className="form-group">
                <label className="col-md-3 control-label">Full name</label>
                <div className="col-md-9">
                  <Field
                    name="agreementModel.certifiedFullName"
                    component="input"
                    autoComplete="certifiedFullName"
                    className="form-control form-control-inline"
                    required
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="col-md-3 control-label">Date</label>
                <div className="col-md-9">
                  <Field
                    name="agreementModel.date"
                    component="input"
                    type="date"
                    autoComplete="certifiedDate"
                    className="form-control form-control-inline"
                    required
                  />
                </div>
              </div>

              <div className="form-group">
                <label className="col-md-3 control-label">I accept</label>
                <div className="col-md-9">
                  <Field
                    name="agreementModel.certifiedAccepted"
                    component="input"
                    type="checkbox"
                    autoComplete="certifiedAccepted"
                    className="form-control form-control-inline"
                    required
                  />
                </div>
              </div>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="default" onClick={onClose}>
                Close
              </Button>
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Modal.Footer>
          </form>
        )}
      </Form>
    </Modal>
  );
};
