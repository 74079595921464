import React from 'react';
import { Redirect } from 'react-router';
import { useCurrentUserContext } from '$contexts/currentUserContext';
import { FeatureFlags } from '$/featureFlags';

export const HomeRoute: React.FunctionComponent = () => {
  const { currentUser } = useCurrentUserContext();

  if (!FeatureFlags.ActivateFeaturesMenuItem) {
    return <Redirect to="/accounts" />;
  }

  if (currentUser?.is_single_subscriber_enabled && currentUser?.is_leo_pro_enabled) {
    return <Redirect to="/activate" />;
  }

  if (currentUser?.is_single_subscriber_enabled) {
    if (currentUser.default_profile_id != null) {
      return <Redirect to={`/profiles/${currentUser.default_profile_id}`} />;
    }

    return <Redirect to="/profiles" />;
  }

  if (currentUser?.is_leo_pro_enabled) {
    if (currentUser.default_leo_pro_organization_id != null) {
      return <Redirect to={`/leo-pro/${currentUser.default_leo_pro_organization_id}`} />;
    }

    return <Redirect to="/leo-pro" />;
  }

  return <Redirect to="/activate" />;
};
