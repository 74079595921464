import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import PerfectScrollbar from 'react-perfect-scrollbar';
import MuiDrawer from '@mui/material/Drawer';
import MuiList from '@mui/material/List';
import { useProfileContext } from '@quiet-sunset/leo-shared';

import '$/material-app/vendor/perfect-scrollbar.css';

import { SidebarButton } from '$cmp/sidebar/common/SidebarButton';
import { SidebarLink } from '$cmp/sidebar/common/SidebarLink';
import { SidebarSection } from '$cmp/sidebar/common/SidebarSection';

import { ProfileSectionsTree } from '$cmp/profileSectionsTree';

const Drawer = styled(MuiDrawer)`
  border-right: 0;
  position: relative;
  height: 100%;

  > div {
    border-right: 0;
    position: absolute;
  }
`;

const Scrollbar = styled(PerfectScrollbar)`
  background-color: ${(props) => props.theme.sidebar.background};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const List = styled(MuiList)`
  background-color: ${(props) => props.theme.sidebar.background};
`;

const Items = styled.div`
  padding-top: ${(props) => props.theme.spacing(2.5)}px;
  padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
`;

type SidebarPropsType = {
  classes?: string;
  staticContext: string;
  location: {
    pathname: string;
  };
  PaperProps: {
    style: {
      width: number | string;
    };
  };
  variant?: 'permanent' | 'persistent' | 'temporary';
  open?: boolean;
  onClose?: () => void;

  launchCertificationModal: () => void;
  showCertificationUnlockModal: () => void;
  showCertificationDownloadModal: () => void;
  launchPdfDownloadModal: () => void;
  launchUnlockProtectedModeModal: () => void;
};

const Sidebar: React.FC<RouteComponentProps & SidebarPropsType> = ({
  classes: _classes,
  staticContext: _staticContext,
  location: _location,
  launchCertificationModal,
  showCertificationUnlockModal,
  showCertificationDownloadModal,
  launchPdfDownloadModal,
  launchUnlockProtectedModeModal,
  ...rest
}) => {
  const {
    profileId,
    hasUnrestrictedAccess,
    hasUnlockableTrustedUserAccess,
    leoProOrganizationProfile,
    hasProfileCertificationModel,
  } = useProfileContext();

  return (
    <Drawer variant="permanent" {...rest}>
      <Scrollbar>
        <List disablePadding>
          <Items>
            <SidebarSection>Actions</SidebarSection>
            {hasUnrestrictedAccess && (
              <SidebarLink
                name="Profile Scope Selection"
                icon={
                  <div style={{ display: 'inline-block', width: '16px' }}>
                    <i className="fas fa-sitemap" />
                  </div>
                }
                to={`/profiles/${profileId}/sections`}
              />
            )}
            {hasUnrestrictedAccess && !hasProfileCertificationModel && (
              <SidebarButton
                name="Certify and lock LEO"
                icon={
                  <div style={{ display: 'inline-block', width: '16px' }}>
                    <i className="fas fa-lock" />
                  </div>
                }
                onClick={launchCertificationModal}
              />
            )}
            {hasUnrestrictedAccess && hasProfileCertificationModel && (
              <SidebarButton
                name="View certification"
                icon={
                  <div style={{ display: 'inline-block', width: '16px' }}>
                    <i className="fas fa-file-signature" />
                  </div>
                }
                onClick={showCertificationDownloadModal}
              />
            )}
            {hasUnrestrictedAccess && hasProfileCertificationModel && (
              <SidebarButton
                name="Unlock LEO"
                icon={
                  <div style={{ display: 'inline-block', width: '16px' }}>
                    <i className="fas fa-lock-open" />
                  </div>
                }
                onClick={showCertificationUnlockModal}
              />
            )}
            {leoProOrganizationProfile && (
              <SidebarLink
                name="Manage LEO-PRO access"
                icon={
                  <div style={{ display: 'inline-block', width: '16px' }}>
                    <i className="fas fa-user-tie" />
                  </div>
                }
                to={`/profiles/${profileId}/leo-pro-access`}
              />
            )}
            <SidebarButton
              name="Download PDF"
              icon={
                <div style={{ display: 'inline-block', width: '16px' }}>
                  <i className="fas fa-file-pdf" />
                </div>
              }
              onClick={launchPdfDownloadModal}
            />
            {hasUnlockableTrustedUserAccess && (
              <SidebarButton
                name="Unlock trusted user access"
                icon={
                  <div style={{ display: 'inline-block', width: '16px' }}>
                    <i className="fas fa-user-lock" />
                  </div>
                }
                onClick={launchUnlockProtectedModeModal}
              />
            )}
            <SidebarSection>Profile Navigator</SidebarSection>
            <ProfileSectionsTree />
          </Items>
        </List>
      </Scrollbar>
    </Drawer>
  );
};

export default withRouter(Sidebar);
