import React from 'react';
import type { TreeViewNodeModel } from './treeViewNodeModel';
// eslint-disable-next-line import/no-cycle
import { TreeViewNode } from './treeViewNode';

export interface TreeViewNodeListProps {
  show: boolean;
  treeViewNodeModels: TreeViewNodeModel<any>[];
  showMessageIfEmpty: boolean;
}

export const TreeViewNodeList: React.FunctionComponent<TreeViewNodeListProps> = (props) => {
  const { show, treeViewNodeModels, showMessageIfEmpty } = props;

  return (
    <div style={{ ...(show ? {} : { display: 'none' }) }}>
      {treeViewNodeModels.length === 0 && showMessageIfEmpty && (
        <p>There are no items in the list.</p>
      )}
      <ul>
        {treeViewNodeModels.map((treeViewNodeModel) => (
          <li key={treeViewNodeModel.id} className="tree-view-node">
            <TreeViewNode treeViewNodeModel={treeViewNodeModel} />
          </li>
        ))}
      </ul>
    </div>
  );
};
