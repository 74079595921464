import React, { useState } from 'react';
import { Button } from '@mui/material';
import { LoadingSpinner } from '$cmp/loadingSpinner';
import { useCurrentUserContext } from '$contexts/currentUserContext';
import type { User } from '@quiet-sunset/leo-shared';
import { useUserService } from '@quiet-sunset/leo-shared';

export type ActivateButtonProps = {
  type: 'single_subscriber' | 'leo_pro';
};

export const ActivateButton: React.FunctionComponent<ActivateButtonProps> = ({ type }) => {
  const UserService = useUserService();

  const { currentUser, reloadCurrentUser } = useCurrentUserContext();
  const [isLoading, setIsLoading] = useState(false);

  const activate = async () => {
    if (!currentUser) {
      return;
    }

    const field: keyof Pick<User, 'is_single_subscriber_enabled' | 'is_leo_pro_enabled'> =
      type === 'single_subscriber' ? 'is_single_subscriber_enabled' : 'is_leo_pro_enabled';

    try {
      setIsLoading(true);
      const newUser: User = {
        ...currentUser,
        [field]: true,
      };
      await UserService.updateUserSettings(newUser);
      await reloadCurrentUser();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <Button variant="contained" color="primary" onClick={activate}>
        Activate
      </Button>
    </>
  );
};
