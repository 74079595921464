import React, { useEffect, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import styled from 'styled-components';
import { ProfileNavigatorState, useProfileNavigatorContext } from '@quiet-sunset/leo-shared';
import { ProfileNavigatorScreen } from './profileNavigatorScreen';

const SliderViewport = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
`;

const SlidingComponentContainer = styled(animated.div)`
  position: relative;
  white-space: nowrap;
`;

const SlidingComponent = styled.div`
  width: 100%;
  display: inline-block;
  vertical-align: top;
`;

export const Slider = () => {
  const { popNavigation, pushNavigation, profileNavigatorState } = useProfileNavigatorContext();
  const [prevProfileNavigatorState, setPrevProfileNavigatorState] =
    useState<ProfileNavigatorState>();

  const slidingComponentContainerStyles = useSpring({
    left: `${(profileNavigatorState?.depth ?? 0) * -100}%`,
    onRest: () => {
      setPrevProfileNavigatorState(undefined);
    },
  });

  useEffect(() => {
    return () => {
      setPrevProfileNavigatorState(profileNavigatorState);
    };
  }, [profileNavigatorState]);

  if (!profileNavigatorState) {
    return null;
  }

  return (
    <SliderViewport>
      <SlidingComponentContainer
        className="slidingComponentContainer"
        style={slidingComponentContainerStyles}
      >
        {new Array(profileNavigatorState.depth).fill(undefined).map((_, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <SlidingComponent key={i}>
            {prevProfileNavigatorState && prevProfileNavigatorState.depth === i && (
              <ProfileNavigatorScreen
                profileNavigatorState={prevProfileNavigatorState}
                pushNavigation={pushNavigation}
                popNavigation={popNavigation}
              />
            )}
          </SlidingComponent>
        ))}
        <SlidingComponent>
          <ProfileNavigatorScreen
            profileNavigatorState={profileNavigatorState}
            pushNavigation={pushNavigation}
            popNavigation={popNavigation}
          />
        </SlidingComponent>
        {prevProfileNavigatorState &&
          prevProfileNavigatorState.depth === profileNavigatorState.depth + 1 && (
            <SlidingComponent>
              <ProfileNavigatorScreen
                profileNavigatorState={prevProfileNavigatorState}
                pushNavigation={pushNavigation}
                popNavigation={popNavigation}
              />
            </SlidingComponent>
          )}
      </SlidingComponentContainer>
    </SliderViewport>
  );
};
