import React from 'react';
import {
  FacebookLoginButton,
  GoogleLoginButton,
  MicrosoftLoginButton,
  ConnectedPasswordRegistrationForm,
} from '@sprice237/react-auth-dom';
import { Box, Button, Typography } from '@mui/material';
import { AuthContentWrapper } from '$cmp/authContentWrapper';
import { CheckList } from '$cmp/checkList';
import { LeoHeaderLogo } from '$cmp/leoHeaderLogo';
import { Link } from 'react-router-dom';
import { ButtonContainer } from '$cmp/buttonContainer';
import { AuthContentContainer } from '$cmp/authContentContainer';
import { FeatureFlags } from '$/featureFlags';

export const RegistrationRoute: React.FunctionComponent = React.memo(() => {
  return (
    <AuthContentWrapper>
      <AuthContentContainer>
        <div
          style={
            FeatureFlags.NewLogin ? { maxWidth: '900px' } : { maxWidth: '980px', padding: '40px' }
          }
        >
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <LeoHeaderLogo />
          </div>
          <div style={{ display: 'flex', gap: '20px' }}>
            <div
              className="leo-registration-description-container"
              style={{
                flex: '1 0 500px',
                paddingTop: '40px',
              }}
            >
              <div className="leo-registration-description">
                <Typography component="h1" variant="h1" gutterBottom>
                  Private, Secure Asset Organization
                </Typography>
                <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                  <CheckList>
                    <Typography component="h3" variant="h3" gutterBottom>
                      No Credit Card Required!
                    </Typography>
                    <Typography component="h3" variant="h3" gutterBottom>
                      Simply Upload Important Files &amp; Life Details
                    </Typography>
                    <Typography component="h3" variant="h3" gutterBottom>
                      Enter What You Can, When You Can, Update Any Time
                    </Typography>
                    <Typography component="h3" variant="h3" gutterBottom>
                      Share with Your Loved Ones
                    </Typography>
                  </CheckList>
                </div>
                <Typography component="h1" variant="h1" gutterBottom align="right">
                  ... For Greater Peace of Mind!
                </Typography>
              </div>
            </div>
            <div
              style={{
                flex: '1 0 400px',
                minWidth: '400px',
              }}
            >
              <Box mx={10} my={10}>
                <Typography component="h1" variant="h4" align="center" gutterBottom>
                  30 Day Free Trial
                  <br />
                  New User Registration
                </Typography>
                <ConnectedPasswordRegistrationForm />
                <ButtonContainer>
                  <Box>
                    <Button component={Link} to="/login" color="primary">
                      Back to login
                    </Button>
                  </Box>
                  {FeatureFlags.GoogleAuth && <GoogleLoginButton />}
                  {FeatureFlags.MicrosoftAuth && <MicrosoftLoginButton />}
                  {FeatureFlags.FacebookAuth && <FacebookLoginButton />}
                </ButtonContainer>
              </Box>
            </div>
          </div>
        </div>
      </AuthContentContainer>
    </AuthContentWrapper>
  );
});
