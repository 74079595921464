import React from 'react';

import { Card } from '$cmp/card';
import { useUpdateTrigger } from '$hooks/useUpdateTrigger';
import { LeoProInvitationsList } from '$routes/leoPro/invitations/leoProInvitationsList';
import { LeoProOrganizationList } from '$routes/leoPro/leoProOrganizationList';

export const LeoProManagementCard: React.FunctionComponent = () => {
  const [leoProOrganizationListUpdateTrigger, refreshLeoProOrganizationListUpdateTrigger] =
    useUpdateTrigger();

  return (
    <Card>
      <LeoProOrganizationList
        updateTrigger={leoProOrganizationListUpdateTrigger}
        onUpdate={refreshLeoProOrganizationListUpdateTrigger}
      />
      <LeoProInvitationsList onUpdate={refreshLeoProOrganizationListUpdateTrigger} />
    </Card>
  );
};
