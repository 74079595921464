import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import Alert from '@mui/material/Alert';
import { LoadingSpinner } from '$cmp/loadingSpinner';
import { usePaymentCardInfo } from '$hooks/usePaymentCardInfo';
import { useIsRoute } from '$hooks/useIsRoute';
import { usePaymentAccountSubscription } from '$hooks/usePaymentAccountSubscription';

export type TrialAlertProps = {
  updateTrigger?: Record<string, unknown>;
};

export const TrialAlert: React.VoidFunctionComponent<TrialAlertProps> = ({ updateTrigger }) => {
  const onManagePaymentAccountPage = useIsRoute('/manage-payment-account');
  const paymentCardInfo = usePaymentCardInfo(updateTrigger);
  const paymentAccountSubscription = usePaymentAccountSubscription(updateTrigger);

  const trialEndDateString = useMemo(() => {
    if (!paymentAccountSubscription?.trial_end) {
      return undefined;
    }
    return dayjs(paymentAccountSubscription.trial_end * 1000).format('L');
  }, [paymentAccountSubscription]);

  if (paymentCardInfo === undefined) {
    return <LoadingSpinner />;
  }

  return (
    <Alert severity="success">
      <ul>
        <li>Your trial has begun!</li>
        <li>
          Your trial will end on <strong>{trialEndDateString}</strong>.
        </li>
        {paymentCardInfo == null && !onManagePaymentAccountPage && (
          <li>
            Visit the <Link to="/manage-payment-account">Manage Payment Account page</Link> to add
            your payment card information before your trial expires.
          </li>
        )}
        {paymentCardInfo == null && onManagePaymentAccountPage && (
          <li>Add your payment card information before your trial expires.</li>
        )}
        {paymentCardInfo != null && (
          <>
            <li>After your trial is over, you will be billed per the agreed upon terms.</li>
            {!onManagePaymentAccountPage && (
              <li>
                Visit the <Link to="/manage-payment-account">Manage Payment Account page</Link> to
                see billing information.
              </li>
            )}
          </>
        )}
      </ul>
    </Alert>
  );
};
