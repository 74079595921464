import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useProfileContext, useProfilesService } from '@quiet-sunset/leo-shared';

import { Config } from '$/config';
import { LoadingSpinner } from '$cmp/loadingSpinner';

export const CertificationDownloadModal: React.FunctionComponent<{ onClose: () => unknown }> = (
  props
) => {
  const { onClose } = props;

  const ProfilesService = useProfilesService();

  const { profileId } = useProfileContext();

  const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState(null as null | string);

  useEffect(() => {
    void (async () => {
      setShowLoadingSpinner(true);
      const { fileId } = await ProfilesService.getPdfLinkForCertificationModelByProfileId(
        profileId
      );
      setDownloadUrl(`${Config.apiUrl}/pdfs/${fileId}`);
      setShowLoadingSpinner(false);
    })();
  }, [profileId]);

  return (
    <Modal show backdrop="static" keyboard={false}>
      {showLoadingSpinner && <LoadingSpinner />}
      <Modal.Header>
        <Modal.Title>Download certification</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {!downloadUrl && <p>Loading...</p>}
        {downloadUrl && (
          <a target="_blank" rel="noopener noreferrer" href={downloadUrl}>
            Download PDF
          </a>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button variant="default" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
