import React, { useCallback, useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { LoadingSpinner } from '$cmp/loadingSpinner';
import type { ProfileAccessorUser } from '@quiet-sunset/leo-shared';
import { useUserService } from '@quiet-sunset/leo-shared';
import { TrustedUserDeclineInvitationModal } from './trustedUserDeclineInvitationModal';
import { TrustedUserAcceptInvitationModal } from './trustedUserAcceptInvitationModal';

export interface TrustedUserInvitationsListProps {
  onUpdate: () => any;
}

export const TrustedUserInvitationsList: React.FunctionComponent<TrustedUserInvitationsListProps> =
  ({ onUpdate }) => {
    const UserService = useUserService();

    const [isLoaded, setIsLoaded] = useState(false);
    const [forceReloadTrigger, setForceReloadTrigger] = useState({});
    const [profileAccessorUserInvitations, setProfileAccessorUserInvitations] = useState<
      ProfileAccessorUser[] | null
    >(null);

    const [invitationToDecline, setInvitationToDecline] = useState(
      null as null | ProfileAccessorUser
    );
    const [invitationToAccept, setInvitationToAccept] = useState(
      null as null | ProfileAccessorUser
    );

    const acceptInvitation = useCallback((invitation: ProfileAccessorUser) => {
      setInvitationToAccept(invitation);
    }, []);

    const confirmAcceptInvitation = useCallback(async () => {
      if (invitationToAccept == null) {
        return;
      }

      setIsLoaded(false);
      try {
        await UserService.acceptTrustedUserInvitation(invitationToAccept.profile_accessor_id);
        setForceReloadTrigger({});
        setInvitationToAccept(null);
        onUpdate();
      } finally {
        setIsLoaded(true);
      }
    }, [invitationToAccept, onUpdate]);

    const cancelAcceptInvitation = useCallback(() => {
      setInvitationToAccept(null);
    }, []);

    const declineInvitation = useCallback((invitation: ProfileAccessorUser) => {
      setInvitationToDecline(invitation);
    }, []);

    const confirmDeclineInvitation = useCallback(async () => {
      if (invitationToDecline == null) {
        return;
      }

      setIsLoaded(false);
      try {
        await UserService.declineTrustedUserInvitation(invitationToDecline.profile_accessor_id);
        setForceReloadTrigger({});
        setInvitationToDecline(null);
      } finally {
        setIsLoaded(true);
      }
    }, [invitationToDecline]);

    const cancelDeclineInvitation = useCallback(() => {
      setInvitationToDecline(null);
    }, []);

    useEffect(() => {
      void (async () => {
        setIsLoaded(false);
        try {
          const _profileAccessorUserInvitations =
            await UserService.getTrustedUserInvitationsForCurrentUser();
          setProfileAccessorUserInvitations(_profileAccessorUserInvitations);
        } catch (e: any) {
          if (e.response?.status === 403) {
            setProfileAccessorUserInvitations(null);
          } else {
            throw e;
          }
        } finally {
          setIsLoaded(true);
        }
      })();
    }, [forceReloadTrigger]);

    return (
      <>
        {invitationToAccept != null && (
          <TrustedUserAcceptInvitationModal
            profileAccessorUserInvitation={invitationToAccept}
            onConfirm={confirmAcceptInvitation}
            onCancel={cancelAcceptInvitation}
          />
        )}
        {invitationToDecline != null && (
          <TrustedUserDeclineInvitationModal
            profileAccessorUserInvitation={invitationToDecline}
            onConfirm={confirmDeclineInvitation}
            onCancel={cancelDeclineInvitation}
          />
        )}
        {!isLoaded && <LoadingSpinner />}
        {isLoaded &&
          profileAccessorUserInvitations != null &&
          profileAccessorUserInvitations.length > 0 && (
            <>
              <h3>Trusted user invitations</h3>
              <Table>
                <thead>
                  <tr>
                    <th>Invited by</th>
                    <th>Profile</th>
                    <th>Trusted user type</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {profileAccessorUserInvitations.map((profileAccessorUserInvitation) => (
                    <tr key={profileAccessorUserInvitation.profile_accessor_id}>
                      <td>
                        {profileAccessorUserInvitation.invited_by_user_first_name}
                        &nbsp;
                        {profileAccessorUserInvitation.invited_by_user_last_name}
                        &nbsp; ({profileAccessorUserInvitation.invited_by_user_email})
                      </td>
                      <td>
                        {profileAccessorUserInvitation.head_of_household_name?.first_name}{' '}
                        {profileAccessorUserInvitation.head_of_household_name?.middle_name}{' '}
                        {profileAccessorUserInvitation.head_of_household_name?.last_name}
                      </td>
                      <td>{profileAccessorUserInvitation.trusted_user_type}</td>
                      <td>
                        <Button
                          variant="primary"
                          onClick={() => acceptInvitation(profileAccessorUserInvitation)}
                        >
                          Accept
                        </Button>
                        <Button
                          variant="danger"
                          onClick={() => declineInvitation(profileAccessorUserInvitation)}
                        >
                          Decline
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          )}
      </>
    );
  };
