var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useRef } from 'react';
import { useAxiosService } from './axiosService';
export const useLeoProOrganizationProfilesService = () => {
    const axios = useAxiosService();
    const { current: LeoProOrganizationProfilesService } = useRef({
        setManagerForLeoProOrganizationProfile(leoProOrganizationId, profileId, leoProOrganizationManagerId) {
            return __awaiter(this, void 0, void 0, function* () {
                const payload = { leoProOrganizationManagerId };
                yield axios.put(`leo-pro-organizations/${leoProOrganizationId}/profiles/${profileId}/manager`, payload);
            });
        },
        setAssistantForLeoProOrganizationProfile(leoProOrganizationId, profileId, leoProOrganizationAssistantId) {
            return __awaiter(this, void 0, void 0, function* () {
                const payload = { leoProOrganizationAssistantId };
                yield axios.put(`leo-pro-organizations/${leoProOrganizationId}/profiles/${profileId}/assistant`, payload);
            });
        },
    });
    return LeoProOrganizationProfilesService;
};
