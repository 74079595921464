import React from 'react';
import type { TrustedUserType } from '@quiet-sunset/leo-shared';
import { getAppointmentDocumentFormContentsComponent } from './appointmentDocumentFormContent';

export interface AppointmentDocumentProps {
  trustedUserType: TrustedUserType;
}

export const AppointmentDocument: React.FunctionComponent<AppointmentDocumentProps> = (props) => {
  const { trustedUserType } = props;

  const AppointmentDocumentFormContents =
    getAppointmentDocumentFormContentsComponent(trustedUserType);
  return <AppointmentDocumentFormContents />;
};
