import React, { useMemo } from 'react';
import { useFirebaseAuthContext, useSignOut } from '@sprice237/react-auth-dom';
import { ServiceContextProvider } from '@quiet-sunset/leo-shared';
import { Config } from '../config';

export const ServiceLoader: React.FunctionComponent = ({ children }) => {
  const { retrieveToken } = useFirebaseAuthContext();
  const signOut = useSignOut();

  const serviceContextProviderProps = useMemo(
    () => ({
      apiUrl: Config.apiUrl,
      retrieveToken,
      signOut,
    }),
    [retrieveToken, signOut]
  );

  return (
    <ServiceContextProvider {...serviceContextProviderProps}>{children}</ServiceContextProvider>
  );
};
