export const TrustedUserTypes = {
    executor: 'executor',
    secondary_executor: 'secondary_executor',
    leo_trustee: 'leo_trustee',
    leo_digital_trustee: 'leo_digital_trustee',
    leo_pc_trustee: 'leo_pc_trustee',
    estate_attorney: 'estate_attorney',
    financial_advisor: 'financial_advisor',
    private_banker: 'private_banker',
    trustee: 'trustee',
};
export const EditTrustedUserFieldTypes = {
    edit_executor: 'edit_executor',
    edit_secondary_executor: 'edit_secondary_executor',
    edit_leo_trustee: 'edit_leo_trustee',
    edit_leo_digital_trustee: 'edit_leo_digital_trustee',
    edit_leo_pc_trustee: 'edit_leo_pc_trustee',
    edit_estate_attorney: 'edit_estate_attorney',
    edit_financial_advisor: 'edit_financial_advisor',
    edit_private_banker: 'edit_private_banker',
    edit_trustee: 'edit_trustee',
};
export const isTrustedUserType = (x) => {
    return Object.keys(TrustedUserTypes).includes(x);
};
export const isEditTrustedUserFieldType = (x) => {
    return Object.keys(EditTrustedUserFieldTypes).includes(x);
};
export const convertFromEditTrustedUserFieldType = (editTrustedUserFieldType) => {
    return editTrustedUserFieldType.slice(5);
};
