var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useRef } from 'react';
import { useAxiosService } from './axiosService';
export const usePetGuardianAgreementsService = () => {
    const axios = useAxiosService();
    const { current: PetGuardianAgreementsService } = useRef({
        getPetGuardianAgreement(profileId, profileFieldGroupListItemId, fieldId) {
            var _a;
            return __awaiter(this, void 0, void 0, function* () {
                try {
                    const url = `profiles/${profileId}/pet-guardian-agreements?fieldId=${fieldId}&profileFieldGroupListItemId=${profileFieldGroupListItemId || 'null'}`;
                    const { data } = yield axios.get(url);
                    return data;
                }
                catch (e) {
                    if (((_a = e.response) === null || _a === void 0 ? void 0 : _a.status) === 404) {
                        return null;
                    }
                    throw e;
                }
            });
        },
        setPetGuardianAgreement(profileId, profileFieldGroupListItemId, fieldId, model) {
            return __awaiter(this, void 0, void 0, function* () {
                const url = `profiles/${profileId}/pet-guardian-agreements?fieldId=${fieldId}&profileFieldGroupListItemId=${profileFieldGroupListItemId || 'null'}`;
                yield axios.put(url, model);
            });
        },
        deletePetGuardianAgreement(profileId, profileFieldGroupListItemId, fieldId) {
            return __awaiter(this, void 0, void 0, function* () {
                const url = `profiles/${profileId}/pet-guardian-agreements?fieldId=${fieldId}&profileFieldGroupListItemId=${profileFieldGroupListItemId || 'null'}`;
                yield axios.delete(url);
            });
        },
    });
    return PetGuardianAgreementsService;
};
