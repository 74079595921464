import React from 'react';
import { Button, Modal } from 'react-bootstrap';

export interface FaqsModalProps {
  onClose: () => unknown;
}

export const FaqsModal: React.FunctionComponent<FaqsModalProps> = (props) => {
  const { onClose } = props;

  return (
    <Modal show backdrop="static" keyboard={false} size="lg">
      <Modal.Header>
        <Modal.Title>FAQs</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="faq-body">
          <p>
            <span className="faq-title">Ques. </span>
            What is Life Estate Organizer (LEO)?
            <br />
            <span className="faq-title">Ans.</span>
            LEO is an assurance policy providing a pathway for your loved ones to follow upon your
            departure, giving them a clear understanding of your life, the extent of your estate and
            the estate issues to be resolved.
            <br />
            <br />
            LEO is used to compliment a Last Will and Testament or to operate as a stand-alone
            document. LEO documents what you have been involved with throughout your life and what
            you have accumulated over the years that you would like to share and/or have handled in
            your absence.
            <br />
            <br />
            LEO is an investment whereby it continues to build information value for you, your loved
            ones and trusted individuals. They become the beneficiaries of your life information and
            have a clear understanding of your estate and the issues related to all aspects of your
            estate.
            <br />
            <br />
            LEO represents an activity we would like to put off until the last minute. However, we
            are never sure when that last minute will occur.
          </p>
          <p>
            <span className="faq-title">Ques. </span>
            How can I organize my estate?
            <br />
            <span className="faq-title">Ans.</span>
            Your estate can be organized in many different ways. What we have found is it is
            difficult to sit down and think about how you want to organize your life and estate. You
            will continue to put it off, possibly until it is too late. We created LEO to include as
            many possibilities of life and estate issues for a person to select from for your
            personal LEO. LEO is designed to deal with many issues but also gives you the ability to
            create and add something we may have no experience.
          </p>
          <p>
            <span className="faq-title">Ques. </span>
            What does Life Estate Organizer do?
            <br />
            <span className="faq-title">Ans.</span>
            Its purpose is to make the difficulty of settling your estate an easier process for
            those left behind assisting you in accounting for your life and estate.
          </p>
          <p>
            <span className="faq-title">Ques. </span>
            How does Life Estate Organizer work?
            <br />
            <span className="faq-title">Ans.</span>
            LEO requires a subscription which is easily obtained at lifeestateorganizer.com. LEO is
            a software program consisting of multiple preplanned templates organized into sections
            and subsections relating to life and estate issues. From a complete listing of life and
            estate issues, you select the issues that are pertinent to your life and estate which
            becomes your Profile Scope Selection. Once you have your Profile Scope Selection, you
            can begin to fill in your data to any of the screens from your computer. If you have any
            level of word processing skills, you have the ability to fill in the data. Once you have
            completed your LEO, you can print a PDF or continue to store and update your LEO as you
            live your life and your life and estate issues change.
          </p>
          <p>
            <span className="faq-title">Ques. </span>
            Who should have a Life Estate Organizer?
            <br />
            <span className="faq-title">Ans.</span>
            In our opinion everyone should have their own LEO. That is easy to say. Many people have
            similar life and estate issues that come to an end upon death and require resolution.
            LEO is designed to identify the common issues and allow the user to scale to their
            requirements. For example: I may have one house, but you may have three houses. We both
            have utilities, insurances, house expenses, etc. So, my LEO contains less of the same
            information.
          </p>
          <p>
            <span className="faq-title">Ques. </span>
            Why should I have a Life Estate Organizer?
            <br />
            <span className="faq-title">Ans.</span>
            LEO is an investment whereby it continues to build information value for you, your loved
            ones and trusted individuals. They become the beneficiaries of your life information and
            have a clear understanding of your estate and the issues related to all aspects of your
            estate. LEO is easy to use, comprehensive and inexpensive. You can update LEO as you
            proceed through life and be prepared to make the difficulty of settling your estate an
            easier process for those left behind. In addition, LEO communicates your life
            experiences to your loved ones, leaving them with family history, your personal
            experiences, advice, know-how and above all expressions of your love.
            <br />
            <br />
            Once you have finished your LEO you will have a Peace of Mind Experience knowing you are
            organized and prepared.
          </p>
          <p>
            <span className="faq-title">Ques. </span>
            How much does it cost to be a Life Estate Organizer subscriber?
            <br />
            <span className="faq-title">Ans.</span>
            LEO cost $5.00 per month or an annual payment of $57.00 per year.
          </p>
          <p>
            <span className="faq-title">Ques. </span>
            Does LEO save my data during the trial period?
            <br />
            <span className="faq-title">Ans.</span>
            Yes, it does. If you subscribe to LEO you would continue filling out the other issues in
            the program. If you decide not to subscribe, your information will be permanently
            deleted in 180 days.
          </p>
          <p>
            <span className="faq-title">Ques. </span>
            Do I own my LEO?
            <br />
            <span className="faq-title">Ans.</span>
            You own the data you entered into LEO. You can print a PDF of LEO. You can retain your
            LEO as long as you are a LEO subscriber.
          </p>
          <p>
            <span className="faq-title">Ques. </span>
            If I cancel my subscription what will happen to my data?
            <br />
            <span className="faq-title">Ans.</span>
            If you cancel your subscription, we will retain your data for 180 days. If you cancelled
            your subscription, we would recommend you print a PDF of your LEO.
          </p>
          <p>
            <span className="faq-title">Ques. </span>
            Doesn’t my Last Will and Testament (LWT) cover all that is in LEO?
            <br />
            <span className="faq-title">Ans.</span>
            No, it does not. An LWT does not address the number of Life and Estate issues as listed
            in LEO.
          </p>
          <p>
            <span className="faq-title">Ques. </span>
            Is LEO considered a legal document?
            <br />
            <span className="faq-title">Ans.</span>
            Yes, it is considered a legal document. There is a signature page that makes the
            document legal. On each page of the application, in the upper left-hand corner, you will
            see the words “Certify and lock LEO”. Click on Certify and lock LEO and proceed to read
            and fill out the Certification Document. Once this document is filled out with your name
            typed in the name block and the “I Accept “is checked (insert blocked check icon), your
            LEO becomes a legal document as written.
          </p>
          <p>
            <span className="faq-title">Ques. </span>
            Who inputs the data into the program?
            <br />
            <span className="faq-title">Ans.</span>
            Usually the subscriber would input the data. However, the data could be input by others
            in the event the subscriber could be physically unable to do so or if the subscriber
            assigned others to input data. For example: the subscriber could assign a private
            banker, financial advisor, estate attorney, family member, etc. to fill in specific
            sections of LEO.
          </p>
          <p>
            <span className="faq-title">Ques. </span>
            How is the data entered?
            <br />
            <span className="faq-title">Ans.</span>
            Data is entered from a smart phone, laptop computer or desktop computer using the
            keyboard as provided on each device.
          </p>
          <p>
            <span className="faq-title">Ques. </span>
            Where does the information come from?
            <br />
            <span className="faq-title">Ans.</span>
            The information input to LEO comes from the subscriber. The subscriber information comes
            from memory and documents that are the property of the subscriber or given to the
            subscriber from others.
          </p>
          <p>
            <span className="faq-title">Ques. </span>
            Do I need to know a lot about software in order to work with LEO?
            <br />
            <span className="faq-title">Ans.</span>
            No, you do not. You can fill out your LEO with any level of Word Processing Skills.
          </p>
          <p>
            <span className="faq-title">Ques. </span>
            How secure is Life Estate Organizer?
            <br />
            <span className="faq-title">Ans.</span>
            Your data is secure with LEO. Security and personal data should be synonymous. LEO has
            used the latest in encryption techniques to protect personal data entered into LEO and
            transmitted to application and database servers for storage and retrieval purposes. We
            take security very seriously deploying bank standards in the protection of your
            information. For more detailed information, go to the security section of our website or
            contact us for more information.
          </p>
          <p>
            <span className="faq-title">Ques. </span>
            What happens if there is a breach of my information?
            <br />
            <span className="faq-title">Ans.</span>
            If there is a breach of any subscriber or potential subscriber’s (one who is on a free
            trial) information, we have emergency escalation procedures in place to address multiple
            situations with predetermined methods to deal with the incident. You would be notified
            immediately if such an incident were to occur.
          </p>
          <p>
            <span className="faq-title">Ques. </span>
            Who has access to my information?
            <br />
            <span className="faq-title">Ans.</span>
            Your information will be encrypted and stored on servers and only accessible by you. No
            other individual will have access to your information, unless you decide to grant
            access, reference Estate Settlement Section, Trusted Individuals.
          </p>
          <p>
            <span className="faq-title">Ques. </span>
            Where is my data stored?
            <br />
            <span className="faq-title">Ans.</span>
            Your information is stored on a primary server system and a GEO-Redundant back-up server
            system in addition to the primary server system with the same level of security as the
            primary system.
          </p>
          <p>
            <span className="faq-title">Ques. </span>
            What happens to my LEO if something happens to me?
            <br />
            <span className="faq-title">Ans.</span>
            LEO provides a method to appoint trusted individuals to have access to your LEO or parts
            of your LEO. In the appointment documents, there is a procedure that outlines the steps
            to take to unlock your LEO to your trusted individuals in the event of your death or
            some other life event. For more information regarding this matter, go to Estate
            Settlement, Estate Trusted Individuals, select the type of trusted individual(s) you
            would select and follow the instructions to appoint them and see the terms of the
            appointment document and how the unlocking of your LEO is triggered.
          </p>
          <p>
            <span className="faq-title">Ques. </span>
            How long will it take to fill out my LEO?
            <br />
            <span className="faq-title">Ans.</span>
            This is a difficult question to answer because of the many variables that could exist.
            For example: If you had multiple residences, multiple vehicles, a very large family,
            multiple insurances, belonged to multiple associations, traded a number of stocks,
            commodities, bonds, owned multiple electronic devices, etc., etc. it will take you
            longer than another to fill out your LEO. If your estate and net worth is slightly above
            average, you could expect to invest 72 hours to complete your LEO.
          </p>
          <p>
            <span className="faq-title">Ques. </span>
            What does my estate consist of beyond my Last Will and Testament?
            <br />
            <span className="faq-title">Ans.</span>
            In order to answer this question, we would recommend you take a free trial to see how
            comprehensive LEO is and the issues it addresses beyond your Last Will and Testament.
          </p>
          <p>
            <span className="faq-title">Ques. </span>
            Can I customize LEO to suit my needs?
            <br />
            <span className="faq-title">Ans.</span>
            Yes, to some extent. There are fields that can add additional information.
          </p>

          <p>
            <span className="faq-title">Ques. </span>
            Will I be pleased with LEO?
            <br />
            <span className="faq-title">Ans.</span>
            Yes, we believe you will be pleased with LEO especially after you reach the data
            finishing line. We believe you will have a Peace of Mind Experience knowing you are
            prepared and organized.
          </p>
          <p>
            <span className="faq-title">Ques. </span>
            Why can’t I wait till later in life to fill out my LEO?
            <br />
            <span className="faq-title">Ans.</span>
            You never know when your time on Earth might come to an end. Your life events started at
            an early age and your estate grows as you entered the workplace and you acquired assets
            and liabilities; you are involved in stock bonus plans, IRAs, bonus plans, have
            brokerage accounts, trade investments on your own, you have a medical plan, etc. etc.
            You could be single living on your own with rental properties and become involved in an
            auto accident that resulted in being in a coma for 8 weeks. Who would know what needs to
            be addressed? You could be 69 years of age and have a lifetime of life and estate issues
            and suddenly die. If you did not have a LEO where would your loved ones start, what
            would they have to address? So, sure you can put it off. But, do you want to take that
            chance?
          </p>
          <p>
            <span className="faq-title">Ques. </span>
            How old should you be to have your own LEO?
            <br />
            <span className="faq-title">Ans.</span>
            There is no golden age to have your own LEO. Certainly, you should subscribe to LEO as
            you start working and acquiring assets and liabilities. Absolutely, 35 years of age and
            older.
          </p>
          <p>
            <span className="faq-title">Ques. </span>
            If I subscribe to LEO does my subscription apply to other family members?
            <br />
            <span className="faq-title">Ans.</span>
            No, it does not. It is specific to the Head of Household. However, LEO does collect Life
            information on the Head of Household’s family members.
          </p>
          <p>
            <span className="faq-title">Ques. </span>
            How often should I revise my LEO?
            <br />
            <span className="faq-title">Ans.</span>
            There are many sections and subsections of LEO that once you enter the data, you will
            not need to make any revisions such as birth dates, birth place, names of children,
            citizenship, education, parent’s names, etc. Then, there are sections that may change on
            a long-term or short-term basis. It is recommended to update your LEO when you purchase
            or sell assets; incur or retire liabilities; buy/sell investments; change banks/brokers,
            change/add pins and passwords. You will determine when it is time to make revisions as
            you work your way through LEO. The timing for updates could be immediately as a sale or
            purchase occurs or a change in the properties of a section occurs.
          </p>
          <p>
            <span className="faq-title">Ques. </span>
            How often should I review my LEO?
            <br />
            <span className="faq-title">Ans.</span>I would refer you to the answer to the question
            “How often should I revise my Leo?” Additionally, I would review elements once every six
            months.
          </p>
          <p>
            <span className="faq-title">Ques. </span>
            How easy will it be to settle my estate with LEO?
            <br />
            <span className="faq-title">Ans.</span>
            No matter the size of your estate, it would be much easier to settle your estate with
            LEO than to achieve settlement without LEO. LEO provides the pathway to an efficient
            resolution to estate settlement by identifying all estate issues in LEO and providing
            the information needed to resolve and with whom. And if you addressed the issues as
            represented in LEO, you stand a much better probability of not forgetting about that
            insurance policy bought 50 years ago that now has a value of $20,000.00 and you may
            avoid the disgruntled family issues that can occur by addressing issues like who gets
            what that is not in your Last Will and Testament. Take a good look at LEO. Investigate
            the family issues that can occur without a LEO. If you were involved in the settlement
            of anyone’s estate you will understand the value of subscribing to LEO.
          </p>
          <p>
            <span className="faq-title">Ques. </span>
            Is LEO more economical than working with an estate attorney?
            <br />
            <span className="faq-title">Ans.</span>
            Yes, there are elements of LEO that can be more economical than working with an estate
            attorney. For example: Pet Guardian Agreement, Trusted Individuals Appointment
            documents. CAUTION: If you ever have a legal question about any subject, LEO will not
            have an answer to your legal question. LEO is designed to have the subscriber provide
            the information. If you do not know the answer it is in your and the deceased or
            impaired person’s best interest to consult an attorney.
          </p>
          <p>
            <span className="faq-title">Ques. </span>
            Is LEO HIPPA compliant?
            <br />
            <span className="faq-title">Ans.</span>
            LEO does not need to be HIPPA compliant because the medical information listed in LEO is
            provided by the subscriber who is the authorized person to provide the medical
            information.
          </p>
          <p>
            <span className="faq-title">Ques. </span>
            Is there a limit to the storage capacity of files, photos, etc.?
            <br />
            <span className="faq-title">Ans.</span>
            Yes, you are limited to 10 gigabytes of storage space per subscriber.
          </p>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="default" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
