var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState } from 'react';
import { useAxiosService } from './axiosService';
export class ProfileFieldGroupListItemsService {
    constructor(axios) {
        this.axios = axios;
    }
    getAllForProfile(profileId, fieldGroupId) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = `profiles/${profileId}/profile-field-group-list-items`;
            const urlWithQueryParameter = fieldGroupId != null ? `${url}?fieldGroupId=${fieldGroupId}` : url;
            const { data } = yield this.axios.get(urlWithQueryParameter);
            return data;
        });
    }
    replaceAllForProfile(profileId, rootFieldGroupId, profileFieldGroupListItems) {
        return __awaiter(this, void 0, void 0, function* () {
            const { data } = yield this.axios.put(`profiles/${profileId}/profile-field-group-list-items?rootFieldGroupId=${rootFieldGroupId}`, profileFieldGroupListItems);
            return data;
        });
    }
    getAllTitlesForProfileFieldGroupListItems(profileId, fieldGroupId) {
        return __awaiter(this, void 0, void 0, function* () {
            const { data } = yield this.axios.get(`/profiles/${profileId}/field-groups/${fieldGroupId}/profile-field-group-list-items/titles`);
            return data;
        });
    }
    getTitleForProfileFieldGroupListItem(profileId, fieldGroupId, profileFieldGroupListItemId) {
        return __awaiter(this, void 0, void 0, function* () {
            const { data } = yield this.axios.get(`/profiles/${profileId}/field-groups/${fieldGroupId}/profile-field-group-list-items/${profileFieldGroupListItemId}/title`);
            return data;
        });
    }
    setTitleForProfileFieldGroupListItem(profileId, fieldGroupId, profileFieldGroupListItemId, title) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.axios.put(`/profiles/${profileId}/field-groups/${fieldGroupId}/profile-field-group-list-items/${profileFieldGroupListItemId}/title`, { title });
        });
    }
}
export const useProfileFieldGroupListItemsService = () => {
    const axios = useAxiosService();
    const [service] = useState(() => new ProfileFieldGroupListItemsService(axios));
    return service;
};
