import styled from 'styled-components';
import Box from '@mui/material/Box';

export const CardContainer = styled(Box)`
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 15px 0;
  gap: 15px;
`;
