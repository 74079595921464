import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Alert, MenuItem, Select } from '@mui/material';

import { Card } from '$cmp/card';
import { CardBackdrop } from '$cmp/cardBackdrop';
import { CardContainer } from '$cmp/cardContainer';
import { LoadingSpinner } from '$cmp/loadingSpinner';
import { NoUserModeEnabledAlert } from '$cmp/noUserModeEnabledAlert';
import { useCurrentUserContext } from '$contexts/currentUserContext';
import { useEnvironment } from '$hooks/useEnvironment';
import type { User } from '@quiet-sunset/leo-shared';
import { useUserService } from '@quiet-sunset/leo-shared';

import { LoginProviderContainer } from './loginProviderContainer';
import { FeatureFlags } from '$/featureFlags';

export interface UserSettingsForm {
  user: User;
}

export const UserSettingsRoute: React.FunctionComponent = () => {
  const environment = useEnvironment();
  const { currentUser, firebaseUser, redirectError, reloadCurrentUser } = useCurrentUserContext();

  const UserService = useUserService();

  const [isLoading, setIsLoading] = useState(false);

  const setIsSingleSubscriberEnabled = async (value: boolean) => {
    if (!currentUser) {
      return;
    }

    try {
      setIsLoading(true);
      const newUser: User = {
        ...currentUser,
        is_single_subscriber_enabled: value,
      };
      await UserService.updateUserSettings(newUser);
      await reloadCurrentUser();
    } finally {
      setIsLoading(false);
    }
  };

  const setIsLeoProEnabled = async (value: boolean) => {
    if (!currentUser) {
      return;
    }

    try {
      setIsLoading(true);
      const newUser: User = {
        ...currentUser,
        is_leo_pro_enabled: value,
      };
      await UserService.updateUserSettings(newUser);
      await reloadCurrentUser();
    } finally {
      setIsLoading(false);
    }
  };

  const setIsAdmin = async (value: boolean) => {
    if (!currentUser) {
      return;
    }

    try {
      setIsLoading(true);
      const newUser: User = {
        ...currentUser,
        is_admin: value,
      };
      await UserService.updateUserSettings(newUser);
      await reloadCurrentUser();
    } finally {
      setIsLoading(false);
    }
  };

  const setIsFree = async (value: boolean) => {
    if (!currentUser) {
      return;
    }

    try {
      setIsLoading(true);
      const newUser: User = {
        ...currentUser,
        is_free: value,
      };
      await UserService.updateUserSettings(newUser);
      await reloadCurrentUser();
    } finally {
      setIsLoading(false);
    }
  };

  if (!currentUser || !firebaseUser) {
    return <Redirect to="/" />;
  }

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <CardBackdrop>
        <CardContainer>
          <NoUserModeEnabledAlert />
          <Card>
            <h3 style={{ paddingTop: '15px', paddingBottom: '15px' }}>User settings</h3>
            <div className="form-group row">
              <label className="col-md-2" htmlFor="mName">
                Name
              </label>
              <div className="col-md-8">
                <p>{firebaseUser.displayName}</p>
              </div>
            </div>
            {FeatureFlags.NewLogin && (
              <div className="form-group row">
                <label className="col-md-2">Login methods</label>
                <div className="col-md-8">
                  {redirectError ? 'error' : ''}
                  <LoginProviderContainer />
                </div>
              </div>
            )}
            <div className="form-group row">
              <label className="col-md-2">LEO single subscriber</label>
              <div className="col-md-8">
                <Select
                  value={JSON.stringify(currentUser.is_single_subscriber_enabled)}
                  onChange={(e) =>
                    setIsSingleSubscriberEnabled(JSON.parse(e.target.value as string))
                  }
                >
                  <MenuItem value="true">Yes</MenuItem>
                  <MenuItem value="false">No</MenuItem>
                </Select>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-md-2">LEO PRO</label>
              <div className="col-md-8">
                <Select
                  value={JSON.stringify(currentUser.is_leo_pro_enabled)}
                  onChange={(e) => setIsLeoProEnabled(JSON.parse(e.target.value as string))}
                >
                  <MenuItem value="true">Yes</MenuItem>
                  <MenuItem value="false">No</MenuItem>
                </Select>
              </div>
            </div>
            {!!environment?.length && environment !== 'production' && (
              <>
                <Alert severity="info">
                  The following sections are only available because this is not the production
                  environment
                </Alert>
                <div className="form-group row">
                  <label className="col-md-2">Is admin</label>
                  <div className="col-md-8">
                    <Select
                      value={JSON.stringify(currentUser.is_admin)}
                      onChange={(e) => setIsAdmin(JSON.parse(e.target.value as string))}
                    >
                      <MenuItem value="true">Yes</MenuItem>
                      <MenuItem value="false">No</MenuItem>
                    </Select>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-2">Is free</label>
                  <div className="col-md-8">
                    <Select
                      value={JSON.stringify(currentUser.is_free)}
                      onChange={(e) => setIsFree(JSON.parse(e.target.value as string))}
                    >
                      <MenuItem value="true">Yes</MenuItem>
                      <MenuItem value="false">No</MenuItem>
                    </Select>
                  </div>
                </div>
              </>
            )}
          </Card>
        </CardContainer>
      </CardBackdrop>
    </>
  );
};
