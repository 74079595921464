import React from 'react';
import styled from 'styled-components';

import { Paper } from '@mui/material';
import { FeatureFlags } from '$/featureFlags';

const Backdrop = styled('div')`
  height: 100%;
  width: 100%;
  background-color: ${FeatureFlags.NewLogin ? '#121a40' : 'rgb(247, 249, 252)'};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)}px;

  ${(props) => props.theme.breakpoints.up('md')} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
`;

export const AuthContentWrapper: React.FC = ({ children }) => {
  return (
    <Backdrop>
      <Wrapper>{children}</Wrapper>
    </Backdrop>
  );
};
