import React, { useState } from 'react';
import dayjs from 'dayjs';
import { TextField } from '$cmp/form/textField';

export interface DateFieldProps {
  value: string | null;
  onChange: (value: string | null) => any;
  isDirty: boolean;
  disabled?: boolean;
}

export const DateField: React.FunctionComponent<DateFieldProps> = React.memo((props) => {
  const { value, onChange, isDirty, disabled } = props;

  const [dateStrValue, setDateStrValue] = useState(
    value != null ? dayjs.utc(value).format('YYYY-MM-DD') : ''
  );

  const updateValue = () => {
    const dateValue = dateStrValue === '' ? null : dayjs.utc(dateStrValue).format();
    onChange(dateValue);
  };

  return (
    <TextField
      label=""
      type="date"
      value={dateStrValue}
      onChange={(e) => setDateStrValue(e.target.value)}
      onBlur={updateValue}
      fullWidth
      my={2}
      disabled={disabled}
      focused={isDirty ? true : undefined}
    />
  );
});
