var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useRef } from 'react';
import dayjs from 'dayjs';
import { v4 as uuid } from 'uuid';
import { useUtilitiesService } from './utilitiesService';
import { useAxiosService } from './axiosService';
export const useProfileFieldValuesService = () => {
    const axios = useAxiosService();
    const UtilitiesService = useUtilitiesService();
    const { current: ProfileFieldValuesService } = useRef({
        getAllForProfile(profileId, fieldGroupId) {
            return __awaiter(this, void 0, void 0, function* () {
                const url = `profiles/${profileId}/profile-field-values`;
                const urlWithQueryParameter = fieldGroupId ? `${url}?fieldGroupId=${fieldGroupId}` : url;
                const { data } = yield axios.get(urlWithQueryParameter);
                data.forEach((x) => {
                    x.date_value =
                        x.date_value != null && !(x.date_value instanceof Date)
                            ? dayjs.utc(x.date_value).toDate()
                            : x.date_value;
                });
                return data;
            });
        },
        replaceAllForProfile(profileId, rootFieldGroupId, profileFieldValues) {
            return __awaiter(this, void 0, void 0, function* () {
                const profileFieldValuesJson = JSON.stringify(profileFieldValues);
                const formData = UtilitiesService.objectToFormData({
                    profileFieldValuesJson,
                });
                const { data } = yield axios.put(`profiles/${profileId}/profile-field-values?rootFieldGroupId=${rootFieldGroupId}`, formData);
                data.forEach((x) => {
                    x.date_value =
                        x.date_value != null && !(x.date_value instanceof Date)
                            ? dayjs.utc(x.date_value).toDate()
                            : x.date_value;
                });
                return data;
            });
        },
        uploadFiles(files) {
            return __awaiter(this, void 0, void 0, function* () {
                const items = files.map((file) => ({
                    fileDetail: {
                        id: uuid(),
                        name: file.name,
                        mime_type: file.type,
                        size: file.size,
                    },
                    file,
                    fileDownloadUrl: null,
                }));
                yield Promise.all(items.map((item) => __awaiter(this, void 0, void 0, function* () {
                    const { fileDetail, file } = item;
                    const fileDetailJson = JSON.stringify(fileDetail);
                    const formData = UtilitiesService.objectToFormData({ fileDetail: fileDetailJson, file });
                    const { data: { fileDownloadUrl }, } = yield axios.put(`files/${fileDetail.id}`, formData);
                    item.fileDownloadUrl = fileDownloadUrl;
                })));
                return items.map((x) => ({ fileDetails: x.fileDetail, fileDownloadUrl: x.fileDownloadUrl }));
            });
        },
    });
    return ProfileFieldValuesService;
};
