import React from 'react';

export const MenuItemIcon: React.VFC<{
  iconHtml: string | null | undefined;
}> = React.memo(({ iconHtml }) => {
  if (!iconHtml) {
    return null;
  }

  return (
    <div
      style={{ display: 'inline-block', width: '24px', height: '20px' }}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: iconHtml }}
    />
  );
});
