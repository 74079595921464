import React, { useCallback, useEffect, useState } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { MenuItemTree } from './menuItemTree';

export const MenuItemTreeField: React.FunctionComponent<FieldRenderProps<string[]>> = ({
  input,
}) => {
  const { value, onChange } = input;

  const [internalValue, setInternalValue] = useState(value);

  const onItemChecked = useCallback((itemId: string, isChecked: boolean) => {
    setInternalValue((oldValue) => [
      ...oldValue.filter((x) => x !== itemId),
      ...(isChecked ? [itemId] : []),
    ]);
  }, []);

  useEffect(() => {
    onChange(internalValue);
  }, [internalValue, onChange]);

  return (
    <MenuItemTree
      showCheckboxes
      autoManageChildrenCheckedState
      checkedItemIds={value ?? []}
      onItemChecked={onItemChecked}
    />
  );
};
