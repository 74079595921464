import React, { useCallback, useEffect, useState } from 'react';

export type LeoProManagerRoleSelectProps = {
  value: string | null;
  onChange: (value: string | null) => unknown;
};

const staticValues = [
  'Attorney',
  'Estate attorney',
  'Financial advisor',
  'Financial planner',
  'Administrator',
];

const isStaticValue = (value: string) => staticValues.includes(value);

const getSelectValue = (value: string | null) => {
  if (value == null) {
    return 'None';
  }
  if (isStaticValue(value)) {
    return value;
  }
  return 'Other';
};

const shouldShowTextbox = (value: string | null): value is string =>
  value != null && !isStaticValue(value);

export const LeoProManagerRoleSelect: React.FunctionComponent<LeoProManagerRoleSelectProps> = (
  props
) => {
  const { value, onChange } = props;
  const [textboxValue, setTextboxValue] = useState(value ?? '');

  const onSelectChange = useCallback(
    (newValue: string) => {
      if (newValue === 'None') {
        onChange(null);
      } else if (isStaticValue(newValue)) {
        onChange(newValue);
      } else {
        onChange('');
        setTextboxValue('');
      }
    },
    [onChange]
  );

  const commitTextboxValue = useCallback(() => {
    onChange(textboxValue);
  }, [onChange, textboxValue]);

  useEffect(() => {
    let func: ((e: Event) => void) | undefined;

    if (shouldShowTextbox(value) && textboxValue !== value) {
      func = (e: Event) => {
        e.preventDefault();
        e.returnValue = true;
      };
      window.addEventListener('beforeunload', func);
    }

    return () => {
      if (func) {
        window.removeEventListener('beforeunload', func);
      }
    };
  }, [value, textboxValue]);

  return (
    <div style={{ display: 'flex' }}>
      <select
        className="form-control"
        style={{ flex: 1 }}
        value={getSelectValue(value)}
        onChange={(e) => onSelectChange(e.target.value)}
      >
        <option>None</option>
        {staticValues.map((staticValue) => (
          <option key={staticValue}>{staticValue}</option>
        ))}
        <option>Other</option>
      </select>
      {shouldShowTextbox(value) && (
        <input
          className="form-control"
          style={{ flex: 1 }}
          value={textboxValue}
          onChange={(e) => setTextboxValue(e.target.value)}
          onBlur={commitTextboxValue}
        />
      )}
    </div>
  );
};
