import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import dayjs from 'dayjs';

import { LoadingSpinner } from '$cmp/loadingSpinner';
import type { PaymentAccountSubscription } from '@quiet-sunset/leo-shared';
import { useLeoProOrganizationsService } from '@quiet-sunset/leo-shared';

export interface CancelLeoProOrganizationConfirmationModalProps {
  leoProOrganizationId: string;
  onClose: () => unknown;
}

export const CancelLeoProOrganizationConfirmationModal: React.FunctionComponent<CancelLeoProOrganizationConfirmationModalProps> =
  (props) => {
    const LeoProOrganizationsService = useLeoProOrganizationsService();

    const { leoProOrganizationId, onClose } = props;

    const [isLoaded, setIsLoaded] = useState(false);
    const [paymentAccountSubscription, setPaymentAccountSubscription] = useState(
      null as null | PaymentAccountSubscription
    );

    const currentPeriodEnd = useMemo(
      () => paymentAccountSubscription?.current_period_end,
      [paymentAccountSubscription]
    );
    const currentPeriodEndText = useMemo(
      () => (currentPeriodEnd != null ? dayjs(currentPeriodEnd * 1000).format('L') : null),
      [currentPeriodEnd]
    );

    const cancelAccount = useCallback(async () => {
      setIsLoaded(false);
      await LeoProOrganizationsService.cancelLeoProOrganization(leoProOrganizationId);
      onClose();
    }, [leoProOrganizationId, onClose]);

    useEffect(() => {
      void (async () => {
        setIsLoaded(false);
        const _paymentAccountSubscription =
          await LeoProOrganizationsService.getLeoProOrganizationBillingDetail(leoProOrganizationId);
        setPaymentAccountSubscription(_paymentAccountSubscription);
        setIsLoaded(true);
      })();
    }, [leoProOrganizationId]);

    return (
      <Modal show backdrop="static" keyboard={false} size="lg">
        {!isLoaded && <LoadingSpinner />}
        <Modal.Header>
          <Modal.Title>Cancel account</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Are you sure you want to cancel your account?</p>
          <ul>
            <li>
              You will lose access to your account at the end of your current billing cycle (
              {currentPeriodEndText}).
            </li>
            <li>
              You may restore your account at any point up until that day without losing data.
            </li>
          </ul>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="default" onClick={onClose}>
            No
          </Button>
          <Button variant="danger" onClick={cancelAccount}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
