import React, { useCallback, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Config } from '$/config';
import { MenuItemTree } from '$cmp/menuItemTree';
import { useProfilesService } from '@quiet-sunset/leo-shared';
import { LoadingSpinner } from '$cmp/loadingSpinner';
import type { MenuItem } from '@quiet-sunset/leo-shared';

export interface PdfDownloadModalProps {
  profileId: string;
  onClose: () => any;
}

export const PdfDownloadModal: React.FunctionComponent<PdfDownloadModalProps> = (props) => {
  const { onClose, profileId } = props;

  const ProfilesService = useProfilesService();

  const [selectedMenuItemIds, setSelectedMenuItemIds] = useState([] as string[]);
  const [profileMenuItemIds, setProfileMenuItemIds] = useState(null as string[] | null);
  const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState(null as null | string);

  const onSectionChecked = useCallback((itemId: string, value: boolean) => {
    setSelectedMenuItemIds((x) => [...x.filter((xx) => xx !== itemId), ...(value ? [itemId] : [])]);
  }, []);

  const submit = useCallback(async () => {
    setShowLoadingSpinner(true);
    const { fileId } = await ProfilesService.getPdfLinkForProfileById(
      profileId,
      selectedMenuItemIds
    );
    setDownloadUrl(`${Config.apiUrl}/pdfs/${fileId}`);
    setShowLoadingSpinner(false);
  }, [profileId, selectedMenuItemIds]);

  useEffect(() => {
    void (async () => {
      setShowLoadingSpinner(true);
      const profileMenuItems = await ProfilesService.getProfileSelectedMenuItemsForProfile(
        profileId
      );
      const newProfileMenuItemIds = profileMenuItems.map((x) => x.id);
      setProfileMenuItemIds(newProfileMenuItemIds);
      setShowLoadingSpinner(false);
    })();
  }, [profileId]);

  const menuItemFilter = useCallback(
    (menuItem: MenuItem) => (profileMenuItemIds ?? []).includes(menuItem.id),
    [profileMenuItemIds]
  );

  return (
    <Modal show backdrop="static" keyboard={false}>
      {showLoadingSpinner && <LoadingSpinner />}
      <Modal.Header>
        <Modal.Title>Download PDF</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {!downloadUrl && (
          <MenuItemTree
            showCheckboxes
            menuItemFilter={menuItemFilter}
            autoManageChildrenCheckedState
            checkedItemIds={selectedMenuItemIds}
            onItemChecked={onSectionChecked}
          />
        )}
        {downloadUrl && (
          <a target="_blank" rel="noopener noreferrer" href={downloadUrl}>
            Download PDF
          </a>
        )}
      </Modal.Body>

      <Modal.Footer>
        {!downloadUrl && (
          <>
            <Button variant="primary" onClick={submit}>
              Submit
            </Button>
            <Button variant="default" onClick={onClose}>
              Cancel
            </Button>
          </>
        )}
        {downloadUrl && (
          <Button variant="default" onClick={onClose}>
            Close
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};
