import React from 'react';
import { Field } from 'react-final-form';

export const PetGuardianAgreementAppointmentDocument: React.FunctionComponent = () => {
  return (
    <>
      <p style={{ textAlign: 'center' }}>Life Estate Organizer</p>
      <p style={{ textAlign: 'center' }}>Pet Guardian Appointment Agreement</p>
      <p style={{ textAlign: 'center' }}>For</p>
      <p style={{ textAlign: 'center' }}>Household Pet(s)</p>
      <p style={{ textAlign: 'center' }}>For</p>
      <p style={{ textAlign: 'center' }}>Head of Household</p>
      <p style={{ textAlign: 'center' }}>
        <Field
          name="agreementModel.name"
          component="input"
          placeholder="Name"
          autoComplete="headOfHouseholdName"
          className="form-control form-control-inline"
        />
      </p>
      <p>
        I,{' '}
        <Field
          name="agreementModel.name"
          component="input"
          placeholder="Name"
          autoComplete="headOfHouseholdName"
          className="form-control form-control-inline"
        />
        , of{' '}
        <Field
          name="agreementModel.county"
          component="input"
          placeholder="County"
          autoComplete="headOfHouseholdCounty"
          className="form-control form-control-inline"
        />
        ,
        <Field
          name="agreementModel.city"
          component="input"
          placeholder="City"
          autoComplete="headOfHouseholdCity"
          className="form-control form-control-inline"
        />
        ,
        <Field
          name="agreementModel.state"
          component="input"
          placeholder="State"
          autoComplete="headOfHouseholdState"
          className="form-control form-control-inline"
        />
        , do hereby make, publish and declare this to be my Pet Guardian Appointment Agreement
        contained within my Life Estate Organizer. I hereby revoke all previous Pet Guardian
        Appointment Agreements or writings made by me.
      </p>
      <p>
        <span style={{ textDecoration: 'underline' }}>ITEM I.</span>
        In the event of my becoming ill, incapacitated or I die, I bequeath my pet
        <Field
          name="agreementModel.petType"
          component="input"
          placeholder="Type of pet"
          autoComplete="typeOfPet"
          className="form-control form-control-inline"
        />
        , Named
        <Field
          name="agreementModel.petName"
          component="input"
          placeholder="Pet name"
          autoComplete="petName"
          className="form-control form-control-inline"
        />
        , all of the pet&apos;s personal effects and other tangible pet property related to my pet
        <Field
          name="agreementModel.petName"
          component="input"
          placeholder="Pet name"
          autoComplete="petName"
          className="form-control form-control-inline"
        />
        as listed in my Life Estate Organizer, Family Section, Pets Subsection to my spouse,
        <Field
          name="agreementModel.spouseName"
          component="input"
          placeholder="Spouse name"
          autoComplete="spouseName"
          className="form-control form-control-inline"
        />
        , if she survives me. If my spouse does not survive me, I appoint
        <Field
          name="agreementModel.appointeeName"
          component="input"
          placeholder="Name"
          autoComplete="appointeeName"
          className="form-control form-control-inline"
        />
        ,
        <Field
          name="agreementModel.appointeeAddress"
          component="input"
          placeholder="Address"
          autoComplete="appointeeAddress"
          className="form-control form-control-inline"
        />
        ,
        <Field
          name="agreementModel.appointeeCity"
          component="input"
          placeholder="City"
          autoComplete="appointeeCity"
          className="form-control form-control-inline"
        />
        ,
        <Field
          name="agreementModel.appointeeState"
          component="input"
          placeholder="State"
          autoComplete="appointeeState"
          className="form-control form-control-inline"
        />
        ,
        <Field
          name="agreementModel.appointeeZipCode"
          component="input"
          placeholder="Zip code"
          autoComplete="appointeeZipCode"
          className="form-control form-control-inline"
        />
        , Telephone Number
        <Field
          name="agreementModel.appointeeTelephone"
          component="input"
          placeholder="Telephone number"
          autoComplete="appointeeTelephone"
          className="form-control form-control-inline"
        />
        , Email address
        <Field
          name="agreementModel.appointeeEmail"
          component="input"
          placeholder="Email address"
          autoComplete="appointeeEmail"
          className="form-control form-control-inline"
        />
        , as my Primary Pet&apos;s Guardian. I direct my Executor to immediately turnover my pet to
        my spouse or Pet Guardian as described above along with my pet&apos;s personal effects and
        other tangible pet property related to my pet and distribute all pet information as outlined
        in my Life Estate Organizer, Family Section, Pets Subsection.
      </p>
      <p>
        <span style={{ textDecoration: 'underline' }}>ITEM II.</span>
        In the event my Primary Pet Guardian is unable to fulfill the obligations as Pet Guardian or
        my pet outlives my Primary Guardian, I hereby appoint
        <Field
          name="agreementModel.secondaryAppointeeName"
          component="input"
          placeholder="Name"
          autoComplete="secondaryAppointeeName"
          className="form-control form-control-inline"
        />
        ,
        <Field
          name="agreementModel.secondaryAppointeeAddress"
          component="input"
          placeholder="Address"
          autoComplete="secondaryAppointeeAddress"
          className="form-control form-control-inline"
        />
        ,
        <Field
          name="agreementModel.secondaryAppointeeCity"
          component="input"
          placeholder="City"
          autoComplete="secondaryAppointeeCity"
          className="form-control form-control-inline"
        />
        ,
        <Field
          name="agreementModel.secondaryAppointeeState"
          component="input"
          placeholder="State"
          autoComplete="secondaryAppointeeState"
          className="form-control form-control-inline"
        />
        ,
        <Field
          name="agreementModel.secondaryAppointeeZipCode"
          component="input"
          placeholder="Zip code"
          autoComplete="secondaryAppointeeZipCode"
          className="form-control form-control-inline"
        />
        , Telephone Number
        <Field
          name="agreementModel.secondaryAppointeeTelephone"
          component="input"
          placeholder="Telephone number"
          autoComplete="secondaryAppointeeTelephone"
          className="form-control form-control-inline"
        />
        , Email address
        <Field
          name="agreementModel.secondaryAppointeeEmail"
          component="input"
          placeholder="Email address"
          autoComplete="secondaryAppointeeEmail"
          className="form-control form-control-inline"
        />
        , as my Secondary Pet Guardian.
      </p>
      <p>
        <span style={{ textDecoration: 'underline' }}>ITEM III.</span>
        In the event my Primary and Secondary Pet Guardians are unable to fulfill the obligations as
        Pet Guardians, I direct and trust my Executor to appoint a suitable Pet Guardian for my pet
        in my absence.
      </p>
      <div>
        <span style={{ textDecoration: 'underline' }}>ITEM IV.</span>
        In carrying out this appointment under this Life Estate Organizer my Pet Guardian and any
        successor Pet Guardians shall accomplish the following:
        <ol style={{ listStyle: 'upper-alpha' }}>
          <li>Provide a safe, caring home for my pet.</li>
          <li>Treat my pet as if they were a Family Member.</li>
          <li>
            Take care of my pet following the instructions listed in my Life Estate Organizer,
            Family Section, Pets Subsection.
          </li>
          <li>Resolve any health or other issues that may occur with respect to my pet.</li>
          <li>
            I understand and accept I have outlined how I have taken care of my pet. Over time you
            will find alternative ways of taking care of my pet that work for you and my pet.
          </li>
          <li>
            Please observe my pets end of life instructions in my Life Estate Organizer Family
            Section, Pets Subsection.
          </li>
          <li>I hope you enjoy my pet as much as I have.</li>
          <li>
            My Executor and all alternate or successor Pet Guardians shall have the same powers,
            authorities and duties herein conferred upon my Primary Pet Guardian; wherever the word
            &quot;Pet Guardian&quot; is used in the Life Estate Organizer, it shall be deemed to
            include my alternate or successor Pet Guardians.
          </li>
          <li>THANK YOU!</li>
        </ol>
      </div>
      <p>
        I hereby check this block, type in my name and the date of agreement that this is my Life
        Estate Organizer, has been filled out by me to the best of my knowledge and ability, revoke
        all previous Life Estate Organizers or writings made by me and direct my Estate Attorney to
        work in conjunction with my Executor in the settlement of my estate.
      </p>
    </>
  );
};
