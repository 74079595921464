import React, { useCallback } from 'react';
import { MenuItem, useFieldGroupsService, useMenuItemsService } from '@quiet-sunset/leo-shared';
import { useProfileAdminClipboardContext } from '../profileAdminClipboardContext';

export const useRetrieveMenuItems = (
  rootMenuItemId: string | null | undefined,
  setMenuItems: React.Dispatch<React.SetStateAction<MenuItem[]>>
) => {
  const MenuItemsService = useMenuItemsService();

  return useCallback(async () => {
    const menuItems = await MenuItemsService.getMenuItemsByParentId(rootMenuItemId ?? null);
    setMenuItems(menuItems);
  }, [MenuItemsService, setMenuItems, rootMenuItemId]);
};

export const useAddMenuItem = (
  rootMenuItemId: string | null | undefined,
  menuItems: MenuItem[],
  onComplete: () => void
) => {
  const MenuItemsService = useMenuItemsService();

  return useCallback(async () => {
    if (!rootMenuItemId) {
      return;
    }
    await MenuItemsService.createMenuItem({
      parent_menu_item_id: rootMenuItemId,
      label: 'New menu item',
      field_group_id: null,
      order_index: Math.max(...(menuItems?.map((menuItem) => menuItem.order_index), [0])),
      icon_html: null,
    });
    onComplete();
  }, [rootMenuItemId, MenuItemsService, menuItems, onComplete]);
};

export const useAddFieldGroup = (
  rootMenuItem: MenuItem | null | undefined,
  onComplete: () => void
) => {
  const FieldGroupsService = useFieldGroupsService();
  const MenuItemsService = useMenuItemsService();

  return useCallback(async () => {
    if (!rootMenuItem) {
      return;
    }

    const newFieldGroup = await FieldGroupsService.createFieldGroup({
      name: '',
      label: 'New field group',
      display_type: 'menu_item',
      is_list: false,
      title_field_id: null,
      default_values: null,
      help_text: null,
      field_group_items: [],
      field_group_column_widths: [],
    });

    await MenuItemsService.updateMenuItem({
      ...rootMenuItem,
      field_group_id: newFieldGroup.id,
    });

    onComplete();
  }, [FieldGroupsService, MenuItemsService, rootMenuItem, onComplete]);
};

export const useAddFieldGroupFromClipboard = (
  rootMenuItemId: string | null | undefined,
  onComplete: () => void
) => {
  const { pasteFieldGroupToMenuItem } = useProfileAdminClipboardContext();

  return useCallback(async () => {
    if (!rootMenuItemId) {
      return;
    }
    await pasteFieldGroupToMenuItem(rootMenuItemId);
    onComplete();
  }, [pasteFieldGroupToMenuItem, rootMenuItemId, onComplete]);
};

export const useRetrieveMenuItem = (
  menuItemId: string,
  setMenuItem: React.Dispatch<React.SetStateAction<MenuItem | undefined>>
) => {
  const MenuItemsService = useMenuItemsService();

  return useCallback(async () => {
    const menuItem = await MenuItemsService.getMenuItemById(menuItemId);
    setMenuItem(menuItem);
  }, [MenuItemsService, setMenuItem, menuItemId]);
};

export const useUpdateMenuItem = (onComplete: () => void) => {
  const MenuItemsService = useMenuItemsService();

  return useCallback(
    async (updateModel: MenuItem) => {
      await MenuItemsService.updateMenuItem(updateModel);
      onComplete();
    },
    [MenuItemsService, onComplete]
  );
};

export const useMoveMenuItemUp = (menuItemId: string, onComplete: () => void) => {
  const MenuItemsService = useMenuItemsService();

  return useCallback(async () => {
    await MenuItemsService.moveMenuItemUp(menuItemId);
    onComplete();
  }, [MenuItemsService, menuItemId, onComplete]);
};

export const useMoveMenuItemDown = (menuItemId: string, onComplete: () => void) => {
  const MenuItemsService = useMenuItemsService();

  return useCallback(async () => {
    await MenuItemsService.moveMenuItemDown(menuItemId);
    onComplete();
  }, [MenuItemsService, menuItemId, onComplete]);
};

export const useDeleteMenuItem = (menuItemId: string, onComplete: () => void) => {
  const MenuItemsService = useMenuItemsService();

  return useCallback(async () => {
    await MenuItemsService.deleteMenuItem(menuItemId);
    onComplete();
  }, [MenuItemsService, menuItemId, onComplete]);
};
