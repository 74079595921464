import React from 'react';
import { useHistory } from 'react-router';

import { CardBackdrop } from '$cmp/cardBackdrop';
import { CardContainer } from '$cmp/cardContainer';
import { useCurrentUserContext } from '$contexts/currentUserContext';
import { LeoProManagementCard } from './leoProManagementCard';

export const LeoProManagementRoute: React.FunctionComponent = () => {
  const history = useHistory();
  const { currentUser } = useCurrentUserContext();

  if (!currentUser?.is_leo_pro_enabled) {
    history.push('/activate');
    return null;
  }

  return (
    <CardBackdrop>
      <CardContainer>
        <LeoProManagementCard />
      </CardContainer>
    </CardBackdrop>
  );
};
