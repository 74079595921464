import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  ProfileContextConsumer,
  ProfileContextProvider,
  ProfileNavigatorContextProvider,
} from '@quiet-sunset/leo-shared';

import { AcknowledgeModal } from '$cmp/modals/acknowledgeModal';

import { ProfileContainer } from './profileContainer';

export const ProfileViewerRoute: React.FunctionComponent = () => {
  const {
    params: { profileId },
    url: baseRoute,
  } = useRouteMatch<{ profileId: string }>();

  const history = useHistory();

  return (
    <ProfileContextProvider baseRoute={baseRoute} profileId={profileId}>
      <ProfileNavigatorContextProvider>
        <ProfileContextConsumer>
          {({ profileId: profileLoadedId }) => (
            <>
              {!profileLoadedId && (
                <AcknowledgeModal
                  title="Access denied"
                  message="You do not have access to this profile, possibly due to payment issues."
                  onAcknowledge={() => history.goBack()}
                />
              )}
              {profileLoadedId && <ProfileContainer />}
            </>
          )}
        </ProfileContextConsumer>
      </ProfileNavigatorContextProvider>
    </ProfileContextProvider>
  );
};
