import React, { useCallback, VFC } from 'react';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { FieldListItem } from './field/fieldListItem';
import { FieldGroupListItem } from './field-group/fieldGroupListItem';
import {
  ClipboardAction,
  ClipboardItem,
  useProfileAdminClipboardContext,
} from './profileAdminClipboardContext';

export const Clipboard: VFC = () => {
  const {
    clipboardAction,
    setClipboardAction,
    clipboardContents,
    removeFromClipboard,
    moveClipboardItem,
  } = useProfileAdminClipboardContext();

  const doMoveUp = useCallback(
    (item: ClipboardItem) => {
      moveClipboardItem(item, -1);
    },
    [moveClipboardItem]
  );

  const doMoveDown = useCallback(
    (item: ClipboardItem) => {
      moveClipboardItem(item, 1);
    },
    [moveClipboardItem]
  );

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
      <h2>Clipboard</h2>
      {!!clipboardContents.length && (
        <>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Action</TableCell>
                <TableCell>
                  <Select
                    variant="standard"
                    value={clipboardAction}
                    onChange={(e) => setClipboardAction(e.target.value as ClipboardAction)}
                  >
                    <MenuItem value="move">Move</MenuItem>
                    <MenuItem value="clone">Clone</MenuItem>
                  </Select>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>

          <h3>Contents</h3>
          <Table>
            <TableBody>
              {clipboardContents.map((clipboardItem) => (
                <TableRow key={clipboardItem.id}>
                  <TableCell>
                    <Stack direction="row" alignItems="center">
                      {clipboardItem.type === 'field' && (
                        <FieldListItem field={clipboardItem.field} />
                      )}
                      {clipboardItem.type === 'field_group' && (
                        <FieldGroupListItem fieldGroup={clipboardItem.fieldGroup} />
                      )}
                    </Stack>
                  </TableCell>
                  <TableCell>
                    {' '}
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        doMoveUp(clipboardItem);
                      }}
                    >
                      <ArrowUpwardIcon />
                    </IconButton>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        doMoveDown(clipboardItem);
                      }}
                    >
                      <ArrowDownwardIcon />
                    </IconButton>
                    <IconButton onClick={() => removeFromClipboard(clipboardItem)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </>
      )}
    </div>
  );
};
