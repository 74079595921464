var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState, useMemo, useRef, useCallback } from 'react';
import { v4 as uuid } from 'uuid';
import { useProfileContext } from "../profileContext";
import { useProfileFieldGroupListItemsService, useProfileFieldValuesService } from "../../services";
import { ProfileFieldGroupListItemsChangeEvent, ProfileFieldValueChangeEvent, ProfileSaveEvent, useEventTargetHandler, } from './events';
import { useProfilePageContext } from './profilePageContext';
const useDestructuredRootFieldGroup = () => {
    const { rootFieldGroup } = useProfilePageContext();
    return useMemo(() => {
        const fieldGroupItemsByParentFieldGroupId = new Map();
        const fieldsById = new Map();
        const fieldGroupsById = new Map();
        const destructureFieldGroup = (fieldGroup) => {
            var _a;
            fieldGroupsById.set(fieldGroup.id, fieldGroup);
            const fieldGroupItems = (_a = fieldGroup.field_group_items) !== null && _a !== void 0 ? _a : [];
            for (const fieldGroupItem of fieldGroupItems) {
                if (!fieldGroupItemsByParentFieldGroupId.has(fieldGroup.id)) {
                    fieldGroupItemsByParentFieldGroupId.set(fieldGroup.id, []);
                }
                fieldGroupItemsByParentFieldGroupId.get(fieldGroup.id).push(fieldGroupItem);
                if (fieldGroupItem.field) {
                    fieldsById.set(fieldGroupItem.field.id, fieldGroupItem.field);
                }
                if (fieldGroupItem.field_group) {
                    destructureFieldGroup(fieldGroupItem.field_group);
                }
            }
        };
        destructureFieldGroup(rootFieldGroup);
        return { fieldGroupItemsByParentFieldGroupId, fieldsById, fieldGroupsById };
    }, [rootFieldGroup]);
};
export const useField = (fieldId) => {
    const { fieldsById } = useDestructuredRootFieldGroup();
    return fieldsById.get(fieldId);
};
export const useFieldGroup = (fieldGroupId) => {
    const { fieldGroupsById } = useDestructuredRootFieldGroup();
    return fieldGroupsById.get(fieldGroupId);
};
export const useFieldGroupItems = (fieldGroupId) => {
    const { fieldGroupItemsByParentFieldGroupId } = useDestructuredRootFieldGroup();
    return fieldGroupItemsByParentFieldGroupId.get(fieldGroupId);
};
export const useProfileFieldValue = (fieldId, profileFieldGroupListItemId) => {
    const { profileFieldValuesMap, eventTarget } = useProfilePageContext();
    const field = useField(fieldId);
    if (!field) {
        throw new Error(`field ${fieldId} does not exist`);
    }
    const [profileFieldValue, _setProfileFieldValue] = useState(() => profileFieldValuesMap.get({ fieldId, profileFieldGroupListItemId }));
    const [isDirty, setIsDirty] = useState(() => profileFieldValuesMap.isDirty({ fieldId, profileFieldGroupListItemId }));
    const { current: setProfileFieldValue } = useRef((newProfileFieldValue) => {
        _setProfileFieldValue(newProfileFieldValue);
        profileFieldValuesMap.set({ fieldId, profileFieldGroupListItemId }, newProfileFieldValue);
        eventTarget.dispatchEvent(new ProfileFieldValueChangeEvent({ fieldId, profileFieldGroupListItemId }));
    });
    useEventTargetHandler(eventTarget, ProfileFieldValueChangeEvent.EVENT_NAME, () => (event) => {
        if (event.key.fieldId === fieldId &&
            event.key.profileFieldGroupListItemId === profileFieldGroupListItemId) {
            _setProfileFieldValue(profileFieldValuesMap.get({ fieldId, profileFieldGroupListItemId }));
            setIsDirty(profileFieldValuesMap.isDirty({ fieldId, profileFieldGroupListItemId }));
        }
    });
    useEventTargetHandler(eventTarget, ProfileSaveEvent.EVENT_NAME, () => () => {
        setIsDirty(profileFieldValuesMap.isDirty({ fieldId, profileFieldGroupListItemId }));
    });
    return [profileFieldValue, setProfileFieldValue, isDirty];
};
export const useProfileFieldGroupListItems = (fieldGroupId, parentProfileFieldGroupListItemId) => {
    const { profileId } = useProfileContext();
    const { profileFieldGroupListItemsMap, eventTarget } = useProfilePageContext();
    const fieldGroup = useFieldGroup(fieldGroupId);
    if (!fieldGroup) {
        throw new Error(`fieldGroup ${fieldGroupId} does not exist`);
    }
    const [profileFieldGroupListItems, _setProfileFieldGroupListItems] = useState(() => profileFieldGroupListItemsMap.get({ fieldGroupId, parentProfileFieldGroupListItemId }));
    const addProfileFieldGroupListItem = useCallback(() => {
        const newProfileFieldGroupListItem = {
            id: uuid(),
            profile_id: profileId,
            field_group_id: fieldGroupId,
            parent_id: parentProfileFieldGroupListItemId,
            order_index: (profileFieldGroupListItems !== null && profileFieldGroupListItems !== void 0 ? profileFieldGroupListItems : []).length,
        };
        const newProfileFieldGroupListItems = [
            ...(profileFieldGroupListItems !== null && profileFieldGroupListItems !== void 0 ? profileFieldGroupListItems : []),
            newProfileFieldGroupListItem,
        ];
        _setProfileFieldGroupListItems(newProfileFieldGroupListItems);
        profileFieldGroupListItemsMap.set({ fieldGroupId, parentProfileFieldGroupListItemId }, newProfileFieldGroupListItems);
        eventTarget.dispatchEvent(new ProfileFieldGroupListItemsChangeEvent({ fieldGroupId, parentProfileFieldGroupListItemId }));
    }, [profileId, fieldGroupId, parentProfileFieldGroupListItemId, profileFieldGroupListItems]);
    const removeProfileFieldGroupListItem = useCallback((profileFieldGroupListItemId) => {
        const newProfileFieldGroupListItems = (profileFieldGroupListItems !== null && profileFieldGroupListItems !== void 0 ? profileFieldGroupListItems : []).filter(({ id }) => id !== profileFieldGroupListItemId);
        _setProfileFieldGroupListItems(newProfileFieldGroupListItems);
        profileFieldGroupListItemsMap.set({ fieldGroupId, parentProfileFieldGroupListItemId }, newProfileFieldGroupListItems);
        eventTarget.dispatchEvent(new ProfileFieldGroupListItemsChangeEvent({
            fieldGroupId,
            parentProfileFieldGroupListItemId,
        }));
    }, [profileId, fieldGroupId, parentProfileFieldGroupListItemId, profileFieldGroupListItems]);
    useEventTargetHandler(eventTarget, ProfileFieldGroupListItemsChangeEvent.EVENT_NAME, () => (event) => {
        if (event.key.fieldGroupId === fieldGroupId &&
            event.key.parentProfileFieldGroupListItemId === parentProfileFieldGroupListItemId) {
            _setProfileFieldGroupListItems(profileFieldGroupListItemsMap.get({ fieldGroupId, parentProfileFieldGroupListItemId }));
        }
    });
    return [
        profileFieldGroupListItems,
        addProfileFieldGroupListItem,
        removeProfileFieldGroupListItem,
    ];
};
export const useSave = () => {
    const ProfileFieldGroupListItemsService = useProfileFieldGroupListItemsService();
    const ProfileFieldValuesService = useProfileFieldValuesService();
    const { profileId } = useProfileContext();
    const { eventTarget, disableSaving, rootFieldGroupId, setIsSaving, setLastSaveDate, profileFieldValuesMap, profileFieldGroupListItemsMap, } = useProfilePageContext();
    return () => __awaiter(void 0, void 0, void 0, function* () {
        if (disableSaving || !rootFieldGroupId) {
            return;
        }
        setIsSaving(true);
        const profileFieldGroupListItems = [...profileFieldGroupListItemsMap.values()].flat();
        const profileFieldValues = [...profileFieldValuesMap.values()].filter((x) => x != null);
        yield ProfileFieldGroupListItemsService.replaceAllForProfile(profileId, rootFieldGroupId, profileFieldGroupListItems);
        yield ProfileFieldValuesService.replaceAllForProfile(profileId, rootFieldGroupId, profileFieldValues);
        setLastSaveDate(new Date());
        setIsSaving(false);
        profileFieldValuesMap.clearDirty();
        eventTarget.dispatchEvent(new ProfileSaveEvent());
    });
};
