import React, { useEffect, useState, VFC } from 'react';
import AddIcon from '@mui/icons-material/Add';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import { FieldGroup, FieldGroupItem } from '@quiet-sunset/leo-shared';
// eslint-disable-next-line import/no-cycle
import { FieldGroupTreeNode } from './fieldGroupTreeNode';
import { FieldTreeNode } from '../field/fieldTreeNode';
import { ListItemText } from '@mui/material';
import {
  useAddFieldGroup,
  useAddField,
  useRetrieveFieldGroupItems,
  useAddFieldGroupItemsFromClipboard,
} from './hooks';
import { useProfileAdminClipboardContext } from '../profileAdminClipboardContext';

export type FieldGroupItemsTreeProps = {
  rootFieldGroup: FieldGroup;
};

export const FieldGroupItemsTree: VFC<FieldGroupItemsTreeProps> = ({ rootFieldGroup }) => {
  const { clipboardContents } = useProfileAdminClipboardContext();
  const [fieldGroupItems, setFieldGroupItems] = useState<FieldGroupItem[]>([]);

  const retrieveFieldGroupItems = useRetrieveFieldGroupItems(rootFieldGroup.id, setFieldGroupItems);
  const addFieldGroup = useAddFieldGroup(rootFieldGroup.id, retrieveFieldGroupItems);
  const addField = useAddField(rootFieldGroup.id, retrieveFieldGroupItems);
  const addFieldGroupItemsFromClipboard = useAddFieldGroupItemsFromClipboard(
    rootFieldGroup.id,
    retrieveFieldGroupItems
  );

  useEffect(() => {
    retrieveFieldGroupItems().catch(console.error);
  }, [rootFieldGroup]);

  return (
    <List sx={{ pl: 4 }}>
      {fieldGroupItems.map((fieldGroupItem) => (
        <React.Fragment key={fieldGroupItem.id}>
          {fieldGroupItem.field_group_id && (
            <FieldGroupTreeNode
              fieldGroupId={fieldGroupItem.field_group_id}
              fieldGroupItemId={fieldGroupItem.id}
              onUpdate={retrieveFieldGroupItems}
            />
          )}
          {fieldGroupItem.field_id && (
            <FieldTreeNode
              fieldId={fieldGroupItem.field_id}
              fieldGroupItemId={fieldGroupItem.id}
              onUpdate={retrieveFieldGroupItems}
            />
          )}
        </React.Fragment>
      ))}
      <ListItemButton
        onClick={(e) => {
          e.stopPropagation();
          addFieldGroup();
        }}
      >
        <AddIcon />
        <ListItemText>Add field group</ListItemText>
      </ListItemButton>
      <ListItemButton
        onClick={(e) => {
          e.stopPropagation();
          addField();
        }}
      >
        <AddIcon />
        <ListItemText>Add field</ListItemText>
      </ListItemButton>
      {!!clipboardContents.length && (
        <ListItemButton onClick={addFieldGroupItemsFromClipboard}>
          <AddIcon />
          <ListItemText>Add field group items from clipboard</ListItemText>
        </ListItemButton>
      )}
    </List>
  );
};
