import React, { useCallback, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Form } from 'react-final-form';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';

import { LoadingSpinner } from '$cmp/loadingSpinner';
import { PaymentCardInfoFormFields } from './paymentCardInfoFormFields';

export interface PaymentCardUpdateModalProps {
  onSubmit: (stripeSourceToken: string) => unknown;
  onCancel: () => unknown;
}

export const PaymentCardUpdateModal: React.FunctionComponent<PaymentCardUpdateModalProps> = (
  props
) => {
  const { onSubmit, onCancel } = props;

  const stripe = useStripe();
  const elements = useElements();

  const [isLoaded, setIsLoaded] = useState(true);
  const [errors, setErrors] = useState([] as string[]);

  const submit = useCallback(async () => {
    setErrors([]);
    setIsLoaded(false);
    try {
      const cardElement = elements?.getElement(CardElement);

      if (stripe == null || elements == null || cardElement == null) {
        console.error('One of the following was unexpectedly null:', {
          stripe,
          elements,
          cardElement,
        });
        setErrors(['An unexpected error occurred with the payment processor']);
        return;
      }

      const { error, source } = await stripe.createSource(cardElement, {
        type: 'card',
      });

      if (error != null) {
        console.error(error);
        if (error.type === 'validation_error') {
          setErrors([error.message ?? 'An unexpected error occurred with the payment processor']);
        } else {
          setErrors(['An unexpected error occurred with the payment processor']);
        }
        return;
      }

      if (source == null) {
        console.error('source was unexpectedly null');
        setErrors(['An unexpected error occurred with the payment processor']);
        return;
      }

      onSubmit(source.id);
    } catch (e: any) {
      if (e.response?.status === 403) {
        setErrors(['Incorrect unlock code']);
      } else {
        setErrors(['An unknown error occurred']);
        throw e;
      }
    } finally {
      setIsLoaded(true);
    }
  }, [elements, onSubmit, stripe]);

  return (
    <Modal show backdrop="static" keyboard={false}>
      {!isLoaded && <LoadingSpinner />}
      <Form onSubmit={submit}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Header>
              <Modal.Title>Update payment card information</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              {errors.length > 0 && (
                <div className="leo-login-row">
                  <div className="alert alert-danger">
                    <p>The following validation errors have occurred:</p>
                    <ul>
                      {errors.map((error) => (
                        <li key={error}>{error}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
              <PaymentCardInfoFormFields />
            </Modal.Body>

            <Modal.Footer>
              <Button variant="default" onClick={onCancel}>
                Cancel
              </Button>
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Modal.Footer>
          </form>
        )}
      </Form>
    </Modal>
  );
};
