import React, { useCallback, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Field, Form } from 'react-final-form';
import { useProfileContext, useProfilesService } from '@quiet-sunset/leo-shared';

export interface UnlockProtectedModeModalProps {
  onClose: () => unknown;
}

export interface UnlockProtectedModeModalForm {
  unlockCode: string;
}

export const UnlockProtectedModeModal: React.FunctionComponent<UnlockProtectedModeModalProps> = (
  props
) => {
  const { onClose } = props;

  const ProfilesService = useProfilesService();

  const { profileId, reloadProfile } = useProfileContext();
  const [errors, setErrors] = useState([] as string[]);

  const submit = useCallback(
    async (values: UnlockProtectedModeModalForm) => {
      setErrors([]);
      try {
        await ProfilesService.unlockProtectedProfileForSelf(profileId, values.unlockCode);
        await reloadProfile();
        onClose();
      } catch (e: any) {
        if (e.response?.status === 403) {
          setErrors(['Incorrect unlock code']);
        } else {
          setErrors(['An unknown error occurred']);
          throw e;
        }
      }
    },
    [reloadProfile, onClose, profileId]
  );

  return (
    <Form<UnlockProtectedModeModalForm> onSubmit={submit}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Modal show backdrop="static" keyboard={false}>
            <Modal.Header>
              <Modal.Title>Enter unlock code</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              {errors.length > 0 && (
                <div className="leo-login-row">
                  <div className="alert alert-danger">
                    <p>The following validation errors have occurred:</p>
                    <ul>
                      {errors.map((error) => (
                        <li key={error}>{error}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
              <Field
                name="unlockCode"
                component="input"
                type="password"
                className="form-control form-control-inline"
                placeholder="Unlock code"
                autoFocus
              />
            </Modal.Body>

            <Modal.Footer>
              <Button variant="default" onClick={onClose}>
                Cancel
              </Button>
              <Button variant="primary" type="submit" onClick={handleSubmit}>
                Submit
              </Button>
            </Modal.Footer>
          </Modal>
        </form>
      )}
    </Form>
  );
};
