import React, { useCallback, useEffect, useState, VFC } from 'react';
import { MenuItem } from '@quiet-sunset/leo-shared';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Avatar from '@mui/material/Avatar';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
// eslint-disable-next-line import/no-cycle
import { MenuItemTree } from './menuItemTree';
import { FieldGroupTreeNode } from '../field-group/fieldGroupTreeNode';
import { MenuItemEditDialog } from './menuItemEditDialog';
import {
  useDeleteMenuItem,
  useMoveMenuItemDown,
  useMoveMenuItemUp,
  useRetrieveMenuItem,
} from './hooks';

export type MenuItemTreeNodeProps = {
  menuItemId: string;
  onUpdate: () => void;
};

export const MenuItemTreeNode: VFC<MenuItemTreeNodeProps> = ({ menuItemId, onUpdate }) => {
  const [menuItem, setMenuItem] = useState<MenuItem>();
  const [open, setOpen] = useState(false);
  const [isEditVisible, setIsEditVisible] = useState(false);

  const retrieveMenuItem = useRetrieveMenuItem(menuItemId, setMenuItem);
  const handleMoveUp = useMoveMenuItemUp(menuItemId, onUpdate);
  const handleMoveDown = useMoveMenuItemDown(menuItemId, onUpdate);
  const handleDelete = useDeleteMenuItem(menuItemId, onUpdate);

  const handleEditClose = useCallback(async () => {
    await retrieveMenuItem();
    setIsEditVisible(false);
  }, [retrieveMenuItem]);

  useEffect(() => {
    retrieveMenuItem().catch(console.error);
  }, [retrieveMenuItem]);

  if (!menuItem) {
    return null;
  }

  return (
    <>
      {isEditVisible && <MenuItemEditDialog menuItem={menuItem} onClose={handleEditClose} />}
      <ListItemButton onClick={() => setOpen((prevOpen) => !prevOpen)}>
        <Avatar sx={{ height: 16, width: 16, bgcolor: 'red', marginRight: 2 }}> </Avatar>
        <ListItemText primary={`Menu item: ${menuItem.label}`} />
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            handleMoveUp();
          }}
        >
          <ArrowUpwardIcon />
        </IconButton>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            handleMoveDown();
          }}
        >
          <ArrowDownwardIcon />
        </IconButton>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            setIsEditVisible(true);
          }}
        >
          <EditIcon />
        </IconButton>
        <IconButton disabled>
          <CopyIcon />
        </IconButton>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            handleDelete();
          }}
        >
          <DeleteIcon />
        </IconButton>
        {open && <ExpandLessIcon />}
        {!open && <ExpandMoreIcon />}
      </ListItemButton>
      <Collapse in={open} mountOnEnter>
        {!menuItem.field_group_id && (
          <MenuItemTree rootMenuItem={menuItem} onUpdate={retrieveMenuItem} />
        )}
        {menuItem.field_group_id && (
          <List sx={{ pl: 4 }}>
            <FieldGroupTreeNode
              fieldGroupId={menuItem.field_group_id}
              fieldGroupItemId={null}
              onUpdate={retrieveMenuItem}
            />
          </List>
        )}
      </Collapse>
    </>
  );
};
