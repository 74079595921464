import React, { useCallback, useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { LoadingSpinner } from '$cmp/loadingSpinner';
import { AddProfileConfirmationModal } from '$cmp/modals/addProfileConfirmationModal';
import { useCurrentUserContext } from '$contexts/currentUserContext';
import { useUpdateTrigger } from '$hooks/useUpdateTrigger';
import type { Profile } from '@quiet-sunset/leo-shared';
import { useProfilesService, useUserService } from '@quiet-sunset/leo-shared';

import { ProfileDeleteConfirmationModal } from './profileDeleteConfirmationModal';
import { ProfileTableItem } from './profileTableItem';

export interface ProfileListProps {
  profilesUpdateTrigger: Record<string, unknown>;
  onUpdate: () => unknown;
}

export const ProfileList: React.FunctionComponent<ProfileListProps> = (props) => {
  const { profilesUpdateTrigger: externalUpdateTrigger, onUpdate } = props;

  const ProfilesService = useProfilesService();
  const UserService = useUserService();
  const { currentUser, reloadCurrentUser } = useCurrentUserContext();
  const [isLoaded, setIsLoaded] = useState(false);
  const [profilesUpdateTrigger, profilesTriggerUpdate] = useUpdateTrigger(externalUpdateTrigger);
  const [profiles, setProfiles] = useState(null as Profile[] | null);
  const [profileToDelete, setProfileToDelete] = useState<Profile | null>(null);
  const [addProfileConfirmationModalIsVisible, setAddProfileConfirmationModalIsVisible] =
    useState(false);

  const showProfileDeleteConfirmationModal = useCallback((profile: Profile) => {
    setProfileToDelete(profile);
  }, []);

  const makeDefault = useCallback(
    async (profileId: string | null) => {
      setIsLoaded(false);
      try {
        await UserService.setDefaultProfile(profileId);
        await reloadCurrentUser();
        onUpdate();
      } finally {
        setIsLoaded(true);
      }
    },
    [onUpdate, reloadCurrentUser]
  );

  const confirmDeleteProfile = useCallback(async () => {
    if (profileToDelete == null) {
      return;
    }

    setIsLoaded(false);
    try {
      await ProfilesService.deleteProfileById(profileToDelete.id);
      setProfileToDelete(null);
      profilesTriggerUpdate();
      onUpdate();
    } finally {
      setIsLoaded(true);
    }
  }, [onUpdate, profileToDelete, profilesTriggerUpdate]);

  const cancelDeleteProfile = useCallback(async () => {
    setProfileToDelete(null);
  }, []);

  const showAddProfileConfirmationModal = useCallback(() => {
    setAddProfileConfirmationModalIsVisible(true);
  }, []);

  const hideAddProfileConfirmationModal = useCallback(() => {
    setAddProfileConfirmationModalIsVisible(false);
    profilesTriggerUpdate();
    onUpdate();
  }, [onUpdate, profilesTriggerUpdate]);

  useEffect(() => {
    void (async () => {
      setIsLoaded(false);
      const _profiles = await ProfilesService.getProfiles();
      setProfiles(_profiles);
      setIsLoaded(true);
    })();
  }, [profilesUpdateTrigger]);

  return (
    <>
      {!isLoaded && <LoadingSpinner />}
      {addProfileConfirmationModalIsVisible && (
        <AddProfileConfirmationModal onClose={hideAddProfileConfirmationModal} />
      )}
      {profileToDelete != null && (
        <ProfileDeleteConfirmationModal
          profile={profileToDelete}
          onConfirm={confirmDeleteProfile}
          onCancel={cancelDeleteProfile}
        />
      )}
      <h3>Profiles</h3>
      <Table>
        <thead>
          <tr>
            <th>Head of household</th>
            <th>Your access</th>
            <th>Default</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {(profiles == null || profiles.length === 0) && (
            <tr>
              <td colSpan={3}>You have no profiles</td>
            </tr>
          )}
          {profiles != null &&
            profiles.length > 0 &&
            profiles.map((profile) => (
              <ProfileTableItem
                key={profile.id}
                profile={profile}
                showAccess
                showDefaultActionsAndOptions
                showLaunch
                isDefault={currentUser?.default_profile_id === profile.id}
                onMakeDefault={() => makeDefault(profile.id)}
                onRemoveDefault={() => makeDefault(null)}
                onDelete={
                  profile.billing_user_id != null && profile.billing_user_id === currentUser?.id
                    ? () => showProfileDeleteConfirmationModal(profile)
                    : undefined
                }
              />
            ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={4}>
              <Button variant="primary" onClick={showAddProfileConfirmationModal}>
                Create new profile
              </Button>
            </td>
          </tr>
        </tfoot>
      </Table>
    </>
  );
};
