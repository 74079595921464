import React, { useCallback, useState } from 'react';

import { LoadingSpinner } from '$cmp/loadingSpinner';
import { TreeView } from '$cmp/treeView/treeView';
import { TreeViewNodeModel } from '$cmp/treeView/treeViewNodeModel';

import type { MenuItem } from '@quiet-sunset/leo-shared';

import { useMenuItemsService } from '@quiet-sunset/leo-shared';

export interface MenuItemTreeProps {
  showCheckboxes: boolean;
  checkedItemIds?: string[];
  onItemChecked?: (itemId: string, value: boolean) => any;
  menuItemFilter?: (menuItem: MenuItem) => boolean;
  onItemClicked?: (item: TreeViewNodeModel<any>) => any;
  doItemTransformation?: (item: TreeViewNodeModel<any>) => React.ReactChild;
  autoManageChildrenCheckedState?: boolean;
}

export const MenuItemTree: React.FunctionComponent<MenuItemTreeProps> = (props) => {
  const {
    showCheckboxes,
    checkedItemIds,
    onItemChecked,
    menuItemFilter,
    onItemClicked,
    doItemTransformation,
    autoManageChildrenCheckedState,
  } = props;

  const MenuItemsService = useMenuItemsService();

  const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);

  const treeViewNodeRetrievalFunction = useCallback(
    async (treeViewNodeId: string | null): Promise<TreeViewNodeModel<MenuItem>[]> => {
      const menuItems = await MenuItemsService.getMenuItemsByParentId(treeViewNodeId);
      const treeViewNodes = menuItems
        .filter((x) => menuItemFilter?.call(null, x) ?? true)
        .map((x) => ({
          id: x.id,
          parentId: x.parent_menu_item_id,
          name: x.label,
          isExpanded: false,
          hasChildren: null,
          childNodes: null,
          parentNode: null,
          data: x,
        }));
      return treeViewNodes;
    },
    [menuItemFilter]
  );

  const itemChecked = useCallback(
    async (itemId: string, value: boolean) => {
      if (!onItemChecked) {
        return;
      }

      setShowLoadingSpinner(true);

      const applyCheckedStatusToDescendants = async (_itemId: string) => {
        onItemChecked(_itemId, value);

        if (autoManageChildrenCheckedState) {
          const childMenuItems = await MenuItemsService.getMenuItemsByParentId(_itemId);
          await Promise.all(
            childMenuItems.map((childMenuItem) => applyCheckedStatusToDescendants(childMenuItem.id))
          );
        }
      };

      await applyCheckedStatusToDescendants(itemId);

      setShowLoadingSpinner(false);

      onItemChecked(itemId, value);
    },
    [autoManageChildrenCheckedState, onItemChecked]
  );

  return (
    <>
      {showLoadingSpinner && <LoadingSpinner />}
      <TreeView
        showCheckboxes={showCheckboxes}
        datasource={treeViewNodeRetrievalFunction}
        checkedItemIds={checkedItemIds}
        onItemChecked={itemChecked}
        onItemClicked={onItemClicked}
        doItemTransformation={doItemTransformation}
      />
    </>
  );
};
