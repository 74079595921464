import { useEffect, useState } from 'react';
import type { PaymentAccountSubscription } from '@quiet-sunset/leo-shared';
import { usePaymentAccountService } from '@quiet-sunset/leo-shared';

export const usePaymentAccountSubscription = (updateTrigger?: Record<string, unknown>) => {
  const PaymentAccountService = usePaymentAccountService();
  const [paymentAccountSubscription, setPaymentAccountSubscription] = useState<
    PaymentAccountSubscription | null | undefined
  >();

  useEffect(() => {
    void (async () => {
      setPaymentAccountSubscription(undefined);
      const _paymentAccountSubscription =
        await PaymentAccountService.getActivePaymentAccountSubscriptionForCurrentUser();
      setPaymentAccountSubscription(_paymentAccountSubscription);
    })();
  }, [updateTrigger]);

  return paymentAccountSubscription;
};
