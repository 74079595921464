import React, { useCallback, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { LoadingSpinner } from '$cmp/loadingSpinner';
import { useCurrentUserContext } from '$contexts/currentUserContext';
import type { PaymentAccountSubscription } from '@quiet-sunset/leo-shared';
import { usePaymentAccountService, useProfilesService } from '@quiet-sunset/leo-shared';

export interface AddProfileConfirmationModalProps {
  onClose: () => unknown;
}

export const AddProfileConfirmationModal: React.FunctionComponent<AddProfileConfirmationModalProps> =
  (props) => {
    const { onClose } = props;

    const PaymentAccountService = usePaymentAccountService();
    const ProfilesService = useProfilesService();
    const { currentUser, reloadCurrentUser } = useCurrentUserContext();
    const [isLoaded, setIsLoaded] = useState(false);
    const [paymentAccountSubscription, setPaymentAccountSubscription] = useState(
      null as null | PaymentAccountSubscription
    );

    const createNewProfile = useCallback(async () => {
      setIsLoaded(false);
      await ProfilesService.createProfile();
      await reloadCurrentUser();
      onClose();
    }, [onClose, reloadCurrentUser]);

    useEffect(() => {
      void (async () => {
        setIsLoaded(false);
        const _paymentAccountSubscription =
          await PaymentAccountService.getActivePaymentAccountSubscriptionForCurrentUser();
        setPaymentAccountSubscription(_paymentAccountSubscription);
        setIsLoaded(true);
      })();
    }, []);

    return (
      <Modal show backdrop="static" keyboard={false}>
        {!isLoaded && <LoadingSpinner />}
        <Modal.Header>
          <Modal.Title>Add new profile</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {isLoaded && (
            <>
              {!currentUser?.is_free && paymentAccountSubscription == null && (
                <p>Creating this profile will start your trial.</p>
              )}
              {!currentUser?.is_free && paymentAccountSubscription != null && (
                <p>Creating this profile will increase your subscription cost.</p>
              )}
              <p>Do you want to continue?</p>
            </>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="default" onClick={onClose}>
            No
          </Button>
          <Button variant="primary" onClick={createNewProfile}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
