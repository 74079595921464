import React, { VFC } from 'react';
import styled from 'styled-components';
import { Card } from '$cmp/card';
import { CardContainer } from '$cmp/cardContainer';
import { CardBackdrop } from '$cmp/cardBackdrop';

import { Clipboard } from './clipboard';
import { MenuItemTree } from './menu-item/menuItemTree';
import { ProfileAdminClipboardContextProvider } from './profileAdminClipboardContext';

const ScrollCard = styled(Card)`
  overflow: auto !important;
`;

const ModifiedCardContainer = styled(CardContainer)`
  max-width: unset;
  padding: 15px;
  height: 100%;
`;

export const GridContainer = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template: auto / 1fr 1fr;
  gap: 15px;
`;

export const ProfileAdminRoute: VFC = () => {
  return (
    <ProfileAdminClipboardContextProvider>
      <CardBackdrop>
        <ModifiedCardContainer>
          <GridContainer>
            <ScrollCard>
              <h2>Profile menu</h2>
              <MenuItemTree onUpdate={async () => undefined} />
            </ScrollCard>
            <ScrollCard>
              <Clipboard />
            </ScrollCard>
          </GridContainer>
        </ModifiedCardContainer>
      </CardBackdrop>
    </ProfileAdminClipboardContextProvider>
  );
};
