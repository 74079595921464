var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useRef } from 'react';
import { useFieldOptionListsService } from './fieldOptionListsService';
let fieldOptionListsPromise = null;
let fieldOptionListsByIdPromise = null;
export const useFieldOptionListsCacheService = () => {
    const FieldOptionListsService = useFieldOptionListsService();
    const { current: FieldOptionListsCacheService } = useRef({
        buildLists() {
            return __awaiter(this, void 0, void 0, function* () {
                fieldOptionListsPromise = FieldOptionListsService.getAllFieldOptionLists();
                // eslint-disable-next-line no-async-promise-executor
                fieldOptionListsByIdPromise = new Promise((resolve, reject) => __awaiter(this, void 0, void 0, function* () {
                    try {
                        const fieldOptionLists = yield fieldOptionListsPromise;
                        const fieldOptionListsById = new Map();
                        for (const fieldOptionList of fieldOptionLists) {
                            fieldOptionListsById.set(fieldOptionList.id, fieldOptionList);
                        }
                        resolve(fieldOptionListsById);
                    }
                    catch (e) {
                        reject(e);
                    }
                }));
            });
        },
        getFieldOptionLists() {
            return __awaiter(this, void 0, void 0, function* () {
                if (fieldOptionListsPromise == null) {
                    yield FieldOptionListsCacheService.buildLists();
                }
                return yield fieldOptionListsPromise;
            });
        },
        getFieldOptionListsById() {
            return __awaiter(this, void 0, void 0, function* () {
                if (fieldOptionListsByIdPromise == null) {
                    yield FieldOptionListsCacheService.buildLists();
                }
                return yield fieldOptionListsByIdPromise;
            });
        },
    });
    return FieldOptionListsCacheService;
};
