import React, { VFC } from 'react';
import { useFormik } from 'formik';
import { Field } from '@quiet-sunset/leo-shared';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import { useUpdateField } from './hooks';

export type FieldEditDialogType = {
  field: Field;
  onClose: () => void;
};

export const FieldEditDialog: VFC<FieldEditDialogType> = ({ field, onClose }) => {
  const updateField = useUpdateField(onClose);

  const formik = useFormik({
    initialValues: field,
    onSubmit: (values) => {
      updateField({
        ...field,
        ...values,
      });
    },
  });

  return (
    <Dialog open fullWidth>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>Edit field</DialogTitle>
        <DialogContent>
          <Stack gap={5}>
            <TextField
              label="Name"
              fullWidth
              variant="standard"
              name="label"
              value={formik.values.label}
              onChange={formik.handleChange}
            />
            <FormControl>
              <InputLabel>Field type</InputLabel>
              <Select
                variant="standard"
                name="field_type"
                value={formik.values.field_type}
                onChange={formik.handleChange}
              >
                <MenuItem value="text">text</MenuItem>
                <MenuItem value="paragraph">paragraph</MenuItem>
                <MenuItem value="html">html</MenuItem>
                <MenuItem value="numeric">numeric</MenuItem>
                <MenuItem value="decimal">decimal</MenuItem>
                <MenuItem value="date">date</MenuItem>
                <MenuItem value="single_select">single_select</MenuItem>
                <MenuItem value="multi_select">multi_select</MenuItem>
                <MenuItem value="contact">contact</MenuItem>
                <MenuItem value="file">file</MenuItem>
                <MenuItem value="multi_file">multi_file</MenuItem>
                <MenuItem value="password">password</MenuItem>
                <MenuItem value="single_select_field_group">single_select_field_group</MenuItem>
                <MenuItem value="html_display">html_display</MenuItem>
                <MenuItem value="edit_executor">edit_executor</MenuItem>
                <MenuItem value="edit_secondary_executor">edit_secondary_executor</MenuItem>
                <MenuItem value="edit_leo_trustee">edit_leo_trustee</MenuItem>
                <MenuItem value="edit_leo_digital_trustee">edit_leo_digital_trustee</MenuItem>
                <MenuItem value="edit_leo_pc_trustee">edit_leo_pc_trustee</MenuItem>
                <MenuItem value="edit_estate_attorney">edit_estate_attorney</MenuItem>
                <MenuItem value="edit_financial_advisor">edit_financial_advisor</MenuItem>
                <MenuItem value="edit_private_banker">edit_private_banker</MenuItem>
                <MenuItem value="edit_trustee">edit_trustee</MenuItem>
                <MenuItem value="edit_pet_guardian_agreement">edit_pet_guardian_agreement</MenuItem>
              </Select>
            </FormControl>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit">Submit</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
