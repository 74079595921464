import React from 'react';
import splash from '$assets/splash.png';
import splashHeader from '$assets/splash-header.png';
import splashStep1 from '$assets/splash-step-1.png';
import splashStep2 from '$assets/splash-step-2.png';
import splashStep3 from '$assets/splash-step-3.png';
import splashStep4 from '$assets/splash-step-4.png';

export const ProfileSplashPageRoute: React.FunctionComponent = () => (
  <>
    <div className="leo-empty-profile-wide">
      <div className="leo-empty-profile-left" />
      <div className="leo-empty-profile-center">
        <img alt="LEO" src={splash} style={{ width: '100%' }} />
      </div>
      <div className="leo-empty-profile-right" />
    </div>

    <div className="leo-empty-profile-narrow">
      <div>
        <img alt="LEO" src={splashHeader} style={{ width: '100%' }} />
        <img alt="LEO" src={splashStep1} style={{ width: '100%' }} />
        <img alt="LEO" src={splashStep2} style={{ width: '100%' }} />
        <img alt="LEO" src={splashStep3} style={{ width: '100%' }} />
        <img alt="LEO" src={splashStep4} style={{ width: '100%' }} />
      </div>
    </div>
  </>
);
