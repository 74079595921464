import React from 'react';
import { useField } from '@quiet-sunset/leo-shared';
import { HelpTextButton } from '$cmp/helpTextButton/helpTextButton';

export const FieldTitle: React.FunctionComponent<{
  fieldId: string;
  // eslint-disable-next-line react/no-unused-prop-types
  launchHelpTextDialog: (params: { title: string; body: string }) => any;
}> = ({ fieldId }) => {
  const field = useField(fieldId);

  if (!field) {
    return null;
  }

  return (
    <div>
      {field.label}
      {field.help_text != null && <HelpTextButton title={field.label} helpText={field.help_text} />}
    </div>
  );
};
