import React, { VFC, memo } from 'react';
import { ProfileNavigatorState, ProfileNavigatorStateDescriptor } from '@quiet-sunset/leo-shared';
import { SidebarButton } from '../sidebar/common/SidebarButton';
import { SidebarLink } from '../sidebar/common/SidebarLink';
import { SidebarText } from '../sidebar/common/SidebarText';
import { MenuItemIcon } from './menuItemIcon';

export type ProfileNavigatorScreenProps = {
  profileNavigatorState: ProfileNavigatorState;
  pushNavigation: (profileNavigatorState: ProfileNavigatorStateDescriptor) => void;
  popNavigation: () => void;
};

export const ProfileNavigatorScreen: VFC<ProfileNavigatorScreenProps> = memo(
  ({ profileNavigatorState, pushNavigation, popNavigation }) => {
    if (profileNavigatorState.depth === 0 && !profileNavigatorState.items.length) {
      return (
        <SidebarText
          name="Your profile navigator is empty, visit Profile Scope Selection to add sections to your
          profile"
        />
      );
    }
    return (
      <>
        {profileNavigatorState.allowPopNavigation && (
          <SidebarButton
            name="Back"
            icon={<i className="fas fa-angle-left" />}
            onClick={popNavigation}
          />
        )}
        {profileNavigatorState.items.map((navigationItem, i) => {
          switch (navigationItem.type) {
            case 'NavigationButton':
              return (
                <SidebarButton
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  icon={<MenuItemIcon iconHtml={navigationItem.iconHtml} />}
                  noBadgeStyling
                  badge={<i className="fas fa-angle-right" />}
                  name={navigationItem.label}
                  onClick={() => pushNavigation(navigationItem.navigatorState)}
                />
              );
            case 'NavigationLink':
              return (
                <SidebarLink
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  name={navigationItem.label}
                  to={navigationItem.to}
                />
              );
            default:
              return (
                <SidebarText
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  name={navigationItem.label}
                />
              );
          }
        })}
      </>
    );
  }
);
