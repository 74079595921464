import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { Redirect } from 'react-router-dom';
import { CardBackdrop } from '$cmp/cardBackdrop';
import { LoadingSpinner } from '$cmp/loadingSpinner';
import type { LeoProOrganizationWithMemberType } from '@quiet-sunset/leo-shared';
import { LeoProPaymentAccountStatusAlert } from '$routes/manageLeoProPaymentAccount/leoProPaymentAccountStatusAlert';
import { useLeoProOrganizationsService } from '@quiet-sunset/leo-shared';
import { LeoProMemberList } from './leoProMemberList';
import { LeoProProfileList } from './leoProProfileList';
import { CardContainer } from '$cmp/cardContainer';
import { Card } from '$cmp/card';

export const LeoProControlPanelRoute: React.FunctionComponent<
  RouteComponentProps<{ leoProOrganizationId: string }>
> = (props) => {
  const { leoProOrganizationId } = props.match.params;

  const LeoProOrganizationsService = useLeoProOrganizationsService();

  const [isLoaded, setIsLoaded] = useState(false);
  const [forceReloadTrigger, setForceReloadTrigger] = useState({});
  const [leoProOrganization, setLeoProOrganization] = useState(
    null as null | LeoProOrganizationWithMemberType
  );

  useEffect(() => {
    void (async () => {
      setIsLoaded(false);
      try {
        const _leoProOrganization = await LeoProOrganizationsService.getLeoProOrganizationById(
          leoProOrganizationId
        );
        setLeoProOrganization(_leoProOrganization);
      } catch (e: any) {
        if (e.response?.status === 403) {
          setLeoProOrganization(null);
        } else {
          throw e;
        }
      } finally {
        setIsLoaded(true);
      }
    })();
  }, [LeoProOrganizationsService, leoProOrganizationId, forceReloadTrigger]);

  const administratorId = leoProOrganization?.leo_pro_organization_administrator_id ?? null;
  const managerId = leoProOrganization?.leo_pro_organization_manager_id ?? null;
  const assistantId = leoProOrganization?.leo_pro_organization_assistant_id ?? null;

  if (!isLoaded) {
    return <LoadingSpinner />;
  }

  if (!leoProOrganization) {
    return <Redirect to="/leo-pro" />;
  }

  return (
    <CardBackdrop>
      <CardContainer>
        <h2>LEO-PRO organization manager - {leoProOrganization.name}</h2>
        <LeoProPaymentAccountStatusAlert leoProOrganization={leoProOrganization} />
        <Card>
          <h3>Administrators</h3>
          <LeoProMemberList
            leoProOrganization={leoProOrganization}
            memberType="Administrator"
            allowActions={administratorId != null}
            onChange={() => setForceReloadTrigger({})}
          />
        </Card>
        <Card>
          <h3>Managers</h3>
          <LeoProMemberList
            leoProOrganization={leoProOrganization}
            memberType="Manager"
            allowActions={administratorId != null}
            onChange={() => setForceReloadTrigger({})}
          />
        </Card>
        <Card>
          <h3>Assistants</h3>
          <LeoProMemberList
            leoProOrganization={leoProOrganization}
            memberType="Assistant"
            allowActions={administratorId != null}
            onChange={() => setForceReloadTrigger({})}
          />
        </Card>
        <Card>
          <h3>Profiles</h3>
          <LeoProProfileList
            leoProOrganizationId={leoProOrganizationId}
            administratorId={administratorId}
            managerId={managerId}
            assistantId={assistantId}
          />
        </Card>
      </CardContainer>
    </CardBackdrop>
  );
};
