import React from 'react';
import { useProfileFieldValue } from '@quiet-sunset/leo-shared';
import type { FieldGroupItem } from '@quiet-sunset/leo-shared';

type ProfileContentFieldGroupItemDependencyCheckerInnerProps = {
  dependentFieldId: string;
  profileFieldGroupListItemId: string | null;
  fieldGroupItemDependentFieldOptionId: string;
};

const ProfileContentFieldGroupItemDependencyCheckerInner: React.FunctionComponent<ProfileContentFieldGroupItemDependencyCheckerInnerProps> =
  (props) => {
    const {
      dependentFieldId,
      profileFieldGroupListItemId,
      fieldGroupItemDependentFieldOptionId,
      children,
    } = props;

    const [dependentFieldValue] = useProfileFieldValue(
      dependentFieldId,
      profileFieldGroupListItemId
    );

    if (
      dependentFieldId != null &&
      fieldGroupItemDependentFieldOptionId !== dependentFieldValue?.single_select_option_id
    ) {
      return null;
    }

    return <>{children}</>;
  };

export interface ProfileContentFieldGroupItemDependencyCheckerProps {
  fieldGroupItem: FieldGroupItem;
  profileFieldGroupListItemId: string | null;
}

export const ProfileContentFieldGroupItemDependencyChecker: React.FunctionComponent<ProfileContentFieldGroupItemDependencyCheckerProps> =
  (props) => {
    const { fieldGroupItem, profileFieldGroupListItemId, children } = props;

    if (!fieldGroupItem.dependent_field_id) {
      return <>{children}</>;
    }

    return (
      <ProfileContentFieldGroupItemDependencyCheckerInner
        dependentFieldId={fieldGroupItem.dependent_field_id}
        profileFieldGroupListItemId={profileFieldGroupListItemId}
        fieldGroupItemDependentFieldOptionId={fieldGroupItem.dependent_field_option_id}
      >
        {children}
      </ProfileContentFieldGroupItemDependencyCheckerInner>
    );
  };
