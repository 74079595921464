import React from 'react';
import { Link } from 'react-router-dom';
import { useCurrentUserContext } from '$contexts/currentUserContext';
import { useIsRoute } from '$hooks/useIsRoute';
import { Alert } from '@mui/material';

export const NoUserModeEnabledAlert: React.FunctionComponent = React.memo(() => {
  const { currentUser } = useCurrentUserContext();
  const isOnUserSettingsPage = useIsRoute('/userSettings');

  if (
    currentUser == null ||
    currentUser.is_single_subscriber_enabled ||
    currentUser.is_leo_pro_enabled
  ) {
    return null;
  }

  return (
    <Alert severity="warning">
      <ul>
        <li>
          You have activated your LEO account, but you need to enable access to either the single
          subscriber mode or LEO-PRO.
        </li>
        {!isOnUserSettingsPage && (
          <li>
            Visit the <Link to="/userSettings">User settings page</Link> to enable your desired
            account mode.
          </li>
        )}
      </ul>
    </Alert>
  );
});
