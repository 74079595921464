import React from 'react';
import dayjs from 'dayjs';
import { useProfilePageContext } from '@quiet-sunset/leo-shared';
import { HelpTextButton } from '$cmp/helpTextButton/helpTextButton';
import { ProfileContentFieldGroup } from './fieldGroups/profileContentFieldGroup';
import { ProfileContentViewport } from '../profileContentViewport';

export const ProfileContentPage: React.FunctionComponent = () => {
  const { lastSaveDate, isSaving, rootFieldGroupListItemId, rootFieldGroup } =
    useProfilePageContext();

  return (
    <ProfileContentViewport>
      <h3>
        {rootFieldGroup.label}
        {rootFieldGroup.help_text != null && (
          <HelpTextButton title={rootFieldGroup.label} helpText={rootFieldGroup.help_text} />
        )}
      </h3>
      <div>
        {!isSaving && (
          <span>
            Last Save: {lastSaveDate != null ? dayjs(lastSaveDate).format('LLL') : 'never'}
          </span>
        )}
        {isSaving && (
          <div>
            <i className="far fa-save animate-flicker leo-save-icon" />
          </div>
        )}
      </div>
      <ProfileContentFieldGroup
        // isTopLevel={true}
        fieldGroupId={rootFieldGroup.id}
        profileFieldGroupListItemId={rootFieldGroupListItemId}
        // profileSaved={true} // ???
        // onSave={save}
        // pauseAutosave={pauseAutosave}
      />
    </ProfileContentViewport>
  );
};
