import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import type { PaymentCardInfo } from '@quiet-sunset/leo-shared';
import { PaymentCardUpdateModal } from './modals/paymentCardUpdateModal/paymentCardUpdateModal';

export interface EditableCardInformationProps {
  paymentCardInfo: PaymentCardInfo | null;
  onUpdate: (stripeSourceTokenId: string) => unknown;
  onRemoveCardInfo: () => unknown;
}

export const EditableCardInformation: React.FunctionComponent<EditableCardInformationProps> = (
  props
) => {
  const { paymentCardInfo, onUpdate, onRemoveCardInfo } = props;

  const [paymentCardUpdateModalIsVisible, setPaymentCardUpdateModalIsVisible] = useState(false);

  const showPaymentCardUpdateModal = useCallback(() => {
    setPaymentCardUpdateModalIsVisible(true);
  }, []);

  const hidePaymentCardUpdateModal = useCallback(() => {
    setPaymentCardUpdateModalIsVisible(false);
  }, []);

  useEffect(() => hidePaymentCardUpdateModal(), [hidePaymentCardUpdateModal, paymentCardInfo]);

  return (
    <>
      {paymentCardUpdateModalIsVisible && (
        <PaymentCardUpdateModal onSubmit={onUpdate} onCancel={hidePaymentCardUpdateModal} />
      )}
      <div className="form-control-static">
        <span>
          {paymentCardInfo == null && 'No card information present'}
          {paymentCardInfo != null && (
            <>
              **** {paymentCardInfo.last4} (exp {paymentCardInfo.exp_month}/
              {paymentCardInfo.exp_year})
            </>
          )}
        </span>
        <span style={{ marginLeft: '10px' }}>
          <Button onClick={showPaymentCardUpdateModal}>Update card info</Button>
        </span>
        {paymentCardInfo && (
          <span style={{ marginLeft: '10px' }}>
            <Button variant="danger" onClick={onRemoveCardInfo}>
              Remove card
            </Button>
          </span>
        )}
      </div>
    </>
  );
};
