import React from 'react';
import { TextField } from '$cmp/form/textField';

export interface NumericFieldProps {
  value: number | null;
  onChange: (value: number | null) => any;
  isDirty: boolean;
  disabled?: boolean;
}

export const NumericField: React.FunctionComponent<NumericFieldProps> = React.memo((props) => {
  const { value, onChange, isDirty, disabled } = props;

  return (
    <TextField
      label=""
      type="number"
      value={value}
      onChange={(e) => (e.target.value === '' ? null : onChange(parseFloat(e.target.value)))}
      fullWidth
      my={2}
      disabled={disabled}
      focused={isDirty ? true : undefined}
    />
  );
});
