var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useRef } from 'react';
import { useAxiosService } from './axiosService';
export const useUserService = () => {
    const axios = useAxiosService();
    const { current: UserService } = useRef({
        getAllUsers() {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`users`);
                return data;
            });
        },
        getUserById(userId) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`users/${userId}`);
                return data;
            });
        },
        updateUserSettings(user) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.put(`users/user-settings`, user);
                return data;
            });
        },
        setUserAccessType(userId, accessType) {
            return __awaiter(this, void 0, void 0, function* () {
                yield axios.put(`users/${userId}/access-type`, { access_type: accessType });
            });
        },
        getTrustedUserInvitationsForCurrentUser() {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`trusted-user-invitations`);
                return data;
            });
        },
        acceptTrustedUserInvitation(profileAccessorUserId) {
            return __awaiter(this, void 0, void 0, function* () {
                yield axios.post(`/trusted-user-invitations/${profileAccessorUserId}/accept`);
            });
        },
        declineTrustedUserInvitation(profileAccessorUserId) {
            return __awaiter(this, void 0, void 0, function* () {
                yield axios.delete(`/trusted-user-invitations/${profileAccessorUserId}`);
            });
        },
        setDefaultProfile(profileId) {
            return __awaiter(this, void 0, void 0, function* () {
                if (profileId != null) {
                    yield axios.put(`users/default-profile`, { profileId });
                }
                else {
                    yield axios.delete(`users/default-profile`);
                }
            });
        },
        setDefaultLeoProOrganization(leoProOrganizationId) {
            return __awaiter(this, void 0, void 0, function* () {
                if (leoProOrganizationId != null) {
                    yield axios.put(`users/default-leo-pro-organization`, { leoProOrganizationId });
                }
                else {
                    yield axios.delete(`users/default-leo-pro-organization`);
                }
            });
        },
    });
    return UserService;
};
