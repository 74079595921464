import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { ButtonVariant } from 'react-bootstrap/types';

export interface YesNoModalProps {
  title: string;
  message?: string;
  yesButtonText?: string;
  noButtonText?: string;
  yesButtonVariant?: ButtonVariant;
  noButtonVariant?: ButtonVariant;
  onYes: () => any;
  onNo: () => any;
}

export const YesNoModal: React.FunctionComponent<YesNoModalProps> = (props) => {
  const {
    title,
    message,
    yesButtonText,
    noButtonText,
    yesButtonVariant,
    noButtonVariant,
    onYes,
    onNo,
    children,
  } = props;

  return (
    <Modal show backdrop="static" keyboard={false}>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {message && <p>{message}</p>}
        {children}
      </Modal.Body>

      <Modal.Footer>
        <Button variant={noButtonVariant ?? 'default'} onClick={onNo}>
          {noButtonText ?? 'No'}
        </Button>
        <Button variant={yesButtonVariant ?? 'primary'} type="submit" onClick={onYes}>
          {yesButtonText ?? 'Yes'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
