import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import dayjs from 'dayjs';
import Alert from '@mui/material/Alert';

import { LoadingSpinner } from '$cmp/loadingSpinner';

import { useCurrentUserContext } from '$contexts/currentUserContext';

import type {
  LeoProOrganizationWithMemberType,
  PaymentAccountSubscription,
  PaymentCardInfo,
} from '@quiet-sunset/leo-shared';

import { useLeoProOrganizationsService } from '@quiet-sunset/leo-shared';

const LeoProManagePaymentAccountLink: React.FunctionComponent<{ leoProOrganizationId: string }> = ({
  leoProOrganizationId,
}) => (
  <Link to={`/leo-pro/${leoProOrganizationId}/manage-payment-account`}>
    Manage Payment Account page
  </Link>
);

export interface LeoProPaymentAccountStatusAlertProps {
  leoProOrganization: LeoProOrganizationWithMemberType;
  updateTrigger?: Record<string, unknown>;
  children?: (x: ReactNode) => ReactNode;
}

const InternalLeoProPaymentAccountStatusAlert: React.FunctionComponent<LeoProPaymentAccountStatusAlertProps> =
  (props) => {
    const LeoProOrganizationsService = useLeoProOrganizationsService();

    const { leoProOrganization, updateTrigger, children } = props;

    const leoProOrganizationId = leoProOrganization.id;

    const match = useRouteMatch();
    const { currentUser } = useCurrentUserContext();
    const [isLoaded, setIsLoaded] = useState(false);
    const [paymentAccountSubscription, setPaymentAccountSubscription] = useState(
      null as null | PaymentAccountSubscription
    );
    const [paymentCardInfo, setPaymentCardInfo] = useState(null as null | PaymentCardInfo);
    const onManagePaymentAccountPage =
      match.path === '/leo-pro/:leoProOrganizationId/manage-payment-account';

    const trialEnd = useMemo(
      () => paymentAccountSubscription?.trial_end ?? null,
      [paymentAccountSubscription]
    );
    const trialEndString = useMemo(
      () => (trialEnd != null ? dayjs(trialEnd * 1000).format('L') : null),
      [trialEnd]
    );

    const currentPeriodEnd = useMemo(
      () => paymentAccountSubscription?.current_period_end ?? null,
      [paymentAccountSubscription]
    );
    const currentPeriodEndString = useMemo(
      () => (currentPeriodEnd != null ? dayjs(currentPeriodEnd * 1000).format('L') : null),
      [currentPeriodEnd]
    );

    const isBillingAdministrator =
      leoProOrganization.leo_pro_organization_administrator_id ===
      leoProOrganization.billing_administrator_id;

    useEffect(() => {
      void (async () => {
        setIsLoaded(false);

        const [_leoProOrganizationBillingDetail, _paymentCardInfo] = await Promise.all([
          LeoProOrganizationsService.getLeoProOrganizationBillingDetail(leoProOrganizationId),
          LeoProOrganizationsService.getLeoProOrganizationBillingCardInfo(leoProOrganizationId),
        ]);

        setPaymentAccountSubscription(_leoProOrganizationBillingDetail);
        setPaymentCardInfo(_paymentCardInfo);
        setIsLoaded(true);
      })();
    }, [leoProOrganizationId, updateTrigger]);

    const renderFunc = useMemo(() => {
      return children == null ? (x: ReactNode) => x : children;
    }, [children]);

    if (currentUser == null) {
      return null;
    }

    if (!currentUser.is_leo_pro_enabled) {
      return null;
    }

    if (!isLoaded) {
      return <LoadingSpinner />;
    }

    return (
      <>
        {!isLoaded && <LoadingSpinner />}
        {renderFunc(
          <>
            {paymentAccountSubscription?.cancel_at_period_end ? (
              <Alert severity="warning">
                <ul>
                  <li>
                    Your account is <strong>pending cancel</strong>.
                  </li>
                  <li>
                    You will lose access to your account at the end of your current billing cycle (
                    {currentPeriodEndString}).
                  </li>
                  <li>
                    You may restore your account at any point up until that day without losing data.
                  </li>
                  <li>
                    Beyond that day, access to your data will not be possible, as data for inactive
                    accounts is removed for security and privacy concerns.
                  </li>
                  {!onManagePaymentAccountPage && isBillingAdministrator && (
                    <li>
                      Visit the{' '}
                      <LeoProManagePaymentAccountLink leoProOrganizationId={leoProOrganizationId} />{' '}
                      to restore your account.
                    </li>
                  )}
                  {!onManagePaymentAccountPage && !isBillingAdministrator && (
                    <li>
                      Ask the LEO-PRO organization billing administrator to restore your account.
                    </li>
                  )}
                </ul>
              </Alert>
            ) : (
              <>
                {paymentAccountSubscription?.status === 'trialing' && (
                  <Alert severity="success">
                    <ul>
                      <li>Your trial has begun!</li>
                      <li>
                        Your trial will end on <strong>{trialEndString}</strong>.
                      </li>
                      {paymentCardInfo == null &&
                        !onManagePaymentAccountPage &&
                        isBillingAdministrator && (
                          <li>
                            Visit the{' '}
                            <LeoProManagePaymentAccountLink
                              leoProOrganizationId={leoProOrganizationId}
                            />{' '}
                            to add your payment card information before your trial expires.
                          </li>
                        )}
                      {paymentCardInfo == null &&
                        !onManagePaymentAccountPage &&
                        !isBillingAdministrator && (
                          <li>
                            Ask the LEO-PRO organization billing administrator to add your payment
                            card information before your trial expires.
                          </li>
                        )}
                      {paymentCardInfo == null && onManagePaymentAccountPage && (
                        <li>Add your payment card information before your trial expires.</li>
                      )}
                      {paymentCardInfo != null && isBillingAdministrator && (
                        <>
                          <li>
                            After your trial is over, you will be billed per the agreed upon terms.
                          </li>
                          {!onManagePaymentAccountPage && (
                            <li>
                              Visit the{' '}
                              <LeoProManagePaymentAccountLink
                                leoProOrganizationId={leoProOrganizationId}
                              />{' '}
                              to see billing information.
                            </li>
                          )}
                        </>
                      )}
                    </ul>
                  </Alert>
                )}
                {paymentAccountSubscription?.status === 'active' && paymentCardInfo == null && (
                  <Alert severity="warning">
                    <ul>
                      <li>
                        Your account is <strong>active</strong> but you have not provided your
                        payment card information!
                      </li>
                      {!onManagePaymentAccountPage && isBillingAdministrator && (
                        <li>
                          Visit the{' '}
                          <LeoProManagePaymentAccountLink
                            leoProOrganizationId={leoProOrganizationId}
                          />{' '}
                          to add your payment card information.
                        </li>
                      )}
                      {!onManagePaymentAccountPage && !isBillingAdministrator && (
                        <li>
                          Ask the LEO-PRO organization billing administrator to add your payment
                          card information.
                        </li>
                      )}
                      <li>
                        Without this information, your account will be locked and you will not be
                        able to access profiles you own.
                      </li>
                    </ul>
                  </Alert>
                )}
                {(paymentAccountSubscription?.status === 'past_due' ||
                  paymentAccountSubscription?.status === 'unpaid') && (
                  <Alert severity="warning">
                    <ul>
                      <li>
                        Your account is currently&nbsp;
                        <strong>
                          {paymentAccountSubscription?.status === 'past_due' && 'past due'}
                          {paymentAccountSubscription?.status === 'unpaid' && 'unpaid'}
                        </strong>
                        !
                      </li>
                      <li>
                        Your account has been locked and you are not be able to access your account.
                      </li>
                      {!onManagePaymentAccountPage && isBillingAdministrator && (
                        <li>
                          Visit the{' '}
                          <LeoProManagePaymentAccountLink
                            leoProOrganizationId={leoProOrganizationId}
                          />{' '}
                          to update your payment card information.
                        </li>
                      )}
                      {!onManagePaymentAccountPage && !isBillingAdministrator && (
                        <li>
                          Ask the LEO-PRO organization billing administrator to update your payment
                          card information.
                        </li>
                      )}
                      {paymentCardInfo == null && isBillingAdministrator && (
                        <li>
                          If you have recently updated your payment card information, please allow
                          up to 24 hours for access to update.
                        </li>
                      )}
                    </ul>
                  </Alert>
                )}
              </>
            )}
            {paymentAccountSubscription?.status === 'canceled' && (
              <Alert severity="warning">
                <ul>
                  <li>
                    Your account is currently <strong>canceled</strong>.
                  </li>
                  {!onManagePaymentAccountPage && isBillingAdministrator && (
                    <li>
                      Visit the{' '}
                      <LeoProManagePaymentAccountLink leoProOrganizationId={leoProOrganizationId} />{' '}
                      to re-activate your account.
                    </li>
                  )}
                  {!onManagePaymentAccountPage && !isBillingAdministrator && (
                    <li>
                      Ask the LEO-PRO organization billing administrator to re-activate your
                      account.
                    </li>
                  )}
                  <li>
                    Your account has been locked and you are not be able to access your account.
                  </li>
                </ul>
              </Alert>
            )}
          </>
        )}
      </>
    );
  };

export const LeoProPaymentAccountStatusAlert: React.FunctionComponent<LeoProPaymentAccountStatusAlertProps> =
  (props) => {
    const { leoProOrganization } = props;

    if (leoProOrganization.leo_pro_organization_administrator_id == null) {
      return null;
    }

    return <InternalLeoProPaymentAccountStatusAlert {...props} />;
  };
