import React from 'react';
import { useFieldGroup } from '@quiet-sunset/leo-shared';
import { HelpTextButton } from '$cmp/helpTextButton/helpTextButton';

export const FieldGroupTitle: React.FunctionComponent<{
  fieldGroupId: string;
  // eslint-disable-next-line react/no-unused-prop-types
  launchHelpTextDialog: (params: { title: string; body: string }) => any;
}> = ({ fieldGroupId }) => {
  const fieldGroup = useFieldGroup(fieldGroupId);

  if (!fieldGroup) {
    return null;
  }

  return (
    <div>
      {fieldGroup.label}
      {fieldGroup.help_text != null && (
        <HelpTextButton title={fieldGroup.label} helpText={fieldGroup.help_text} />
      )}
    </div>
  );
};
