import React from 'react';
import { Button } from 'react-bootstrap';

import { useFieldGroupItems } from '@quiet-sunset/leo-shared';

// eslint-disable-next-line import/no-cycle
import { ProfileContentFieldGroup } from '../profileContentFieldGroup';
import { ProfileContentField } from '../../fields/profileContentField';
import { ProfileContentFieldGroupItemDependencyChecker } from '../profileContentFieldGroupItemDependencyChecker';

export interface ProfileContentFieldGroupHorizontalRowProps {
  fieldGroupId: string;
  profileFieldGroupListItemId: string | null;
  removeProfileFieldGroupListItem?: () => any;
}

export const ProfileContentFieldGroupHorizontalRow: React.FunctionComponent<ProfileContentFieldGroupHorizontalRowProps> =
  (props) => {
    const { fieldGroupId, profileFieldGroupListItemId, removeProfileFieldGroupListItem } = props;

    const fieldGroupItems = useFieldGroupItems(fieldGroupId);

    if (!fieldGroupItems) {
      return null;
    }

    return (
      <tr>
        {fieldGroupItems.map((fieldGroupItem) => (
          <ProfileContentFieldGroupItemDependencyChecker
            key={fieldGroupItem.id}
            fieldGroupItem={fieldGroupItem}
            profileFieldGroupListItemId={profileFieldGroupListItemId}
          >
            <td>
              {fieldGroupItem.field_id != null && (
                <ProfileContentField
                  fieldId={fieldGroupItem.field_id}
                  profileFieldGroupListItemId={profileFieldGroupListItemId}
                />
              )}
              {fieldGroupItem.field_group_id != null && (
                <ProfileContentFieldGroup
                  fieldGroupId={fieldGroupItem.field_group_id}
                  profileFieldGroupListItemId={profileFieldGroupListItemId}
                />
              )}
            </td>
          </ProfileContentFieldGroupItemDependencyChecker>
        ))}
        {removeProfileFieldGroupListItem != null && (
          <td>
            <Button
              onClick={removeProfileFieldGroupListItem}
              className="btn btn-default input-field"
            >
              Remove
            </Button>
          </td>
        )}
      </tr>
    );
  };
