import React, { useCallback, useState } from 'react';
import { HelpTextModal } from './helpTextModal';

export interface HelpTextButtonProps {
  title: string;
  helpText: string;
}

export const HelpTextButton: React.FunctionComponent<HelpTextButtonProps> = (props) => {
  const { title, helpText } = props;

  const [showModal, setShowModal] = useState(false);

  const launchHelpTextModal = useCallback(() => {
    setShowModal(true);
  }, []);

  const hideHelpTextModal = useCallback(() => {
    setShowModal(false);
  }, []);

  return (
    <>
      {showModal && <HelpTextModal title={title} message={helpText} onClose={hideHelpTextModal} />}
      <span onClick={launchHelpTextModal} style={{ color: '#007bff', cursor: 'pointer' }}>
        <i className="far fa-question-circle" />
      </span>
    </>
  );
};
