import React from 'react';
import { Field, Form } from 'react-final-form';
import { Button, Modal } from 'react-bootstrap';

export interface EditEmailScreenProps {
  initialValues: EditEmailScreenForm;
  onClose: () => any;
  onNext: (formValues: EditEmailScreenForm) => any;
}

export interface EditEmailScreenForm {
  emailAddress: string;
}

export const EditEmailScreen: React.FunctionComponent<EditEmailScreenProps> = (props) => {
  const { initialValues, onClose, onNext } = props;

  return (
    <Form<EditEmailScreenForm> onSubmit={onNext} initialValues={initialValues}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Modal.Body>
            <div className="form-group">
              <label className="col-md-4 control-label">Email</label>
              <div className="col-md-8">
                <Field
                  name="emailAddress"
                  component="input"
                  className="form-control form-control-inline"
                  required
                />
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="default" onClick={onClose}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Next
            </Button>
          </Modal.Footer>
        </form>
      )}
    </Form>
  );
};
