import React, { useEffect, useState } from 'react';
import { LoadingSpinner } from '$cmp/loadingSpinner';
import type { LeoProOrganization } from '@quiet-sunset/leo-shared';
import {
  useLeoProOrganizationAdministratorsService,
  useLeoProOrganizationManagersService,
  useLeoProOrganizationAssistantsService,
} from '@quiet-sunset/leo-shared';

interface LeoProOrganizationMember {
  id: string;
  leo_pro_organization_id: string;
  user_id: string | null;
  invite_email: string | null;

  user?: {
    id: string;
    first_name: string;
    last_name: string;
    email: string;
  };

  leo_pro_organization?: LeoProOrganization;
}

export interface LeoProMemberSelectorProps {
  leoProOrganizationId: string;
  memberType: 'Administrator' | 'Manager' | 'Assistant';
  showEmptyOption?: boolean;
  selectedMemberId: string | null;
  onSelectedMemberIdChange: (memberId: string | null) => unknown;
}

export const LeoProMemberSelector: React.FunctionComponent<LeoProMemberSelectorProps> = (props) => {
  const {
    leoProOrganizationId,
    memberType,
    showEmptyOption,
    selectedMemberId,
    onSelectedMemberIdChange,
  } = props;

  const LeoProOrganizationAdministratorsService = useLeoProOrganizationAdministratorsService();
  const LeoProOrganizationManagersService = useLeoProOrganizationManagersService();
  const LeoProOrganizationAssistantsService = useLeoProOrganizationAssistantsService();

  const [isLoaded, setIsLoaded] = useState(false);
  const [leoProOrganizationMembers, setLeoProOrganizationMembers] = useState(
    null as null | LeoProOrganizationMember[]
  );

  useEffect(() => {
    void (async () => {
      setIsLoaded(false);
      try {
        let members: LeoProOrganizationMember[] | null = null;

        switch (memberType) {
          case 'Administrator':
            members =
              await LeoProOrganizationAdministratorsService.getLeoProOrganizationAdministratorsForLeoProOrganization(
                leoProOrganizationId
              );
            break;
          case 'Manager':
            members =
              await LeoProOrganizationManagersService.getLeoProOrganizationManagersForLeoProOrganization(
                leoProOrganizationId
              );
            break;
          case 'Assistant':
            members =
              await LeoProOrganizationAssistantsService.getLeoProOrganizationAssistantsForLeoProOrganization(
                leoProOrganizationId
              );
            break;
          default:
        }
        setLeoProOrganizationMembers(members);
      } catch (e: any) {
        if (e.response?.status === 403) {
          setLeoProOrganizationMembers(null);
        } else {
          throw e;
        }
      } finally {
        setIsLoaded(true);
      }
    })();
  }, [
    LeoProOrganizationAdministratorsService,
    LeoProOrganizationManagersService,
    LeoProOrganizationAssistantsService,
    leoProOrganizationId,
    memberType,
  ]);

  return (
    <>
      {!isLoaded && <LoadingSpinner />}
      {isLoaded && (
        <select
          className="form-control"
          value={selectedMemberId ?? ''}
          onChange={(e) => onSelectedMemberIdChange(e.target.value === '' ? null : e.target.value)}
        >
          {(showEmptyOption ?? true) && <option value="">(none)</option>}
          {leoProOrganizationMembers!.map((leoProOrganizationMember) => (
            <option key={leoProOrganizationMember.id} value={leoProOrganizationMember.id}>
              {leoProOrganizationMember.user &&
                `${leoProOrganizationMember.user.first_name} ${leoProOrganizationMember.user.last_name} (${leoProOrganizationMember.user.email})`}
              {!leoProOrganizationMember.user && leoProOrganizationMember.invite_email}
            </option>
          ))}
        </select>
      )}
    </>
  );
};
