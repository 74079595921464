var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import dayjs from 'dayjs';
export class ProfileFieldValuesMap extends Map {
    constructor() {
        super(...arguments);
        this.dirtyKeys = new Map();
    }
    static initializeFromService(ProfileFieldValuesService, profileId, rootFieldGroupId) {
        return __awaiter(this, void 0, void 0, function* () {
            const profileFieldValues = yield ProfileFieldValuesService.getAllForProfile(profileId, rootFieldGroupId);
            profileFieldValues.forEach((profileFieldValue) => {
                profileFieldValue.date_value =
                    profileFieldValue.date_value != null
                        ? dayjs.utc(profileFieldValue.date_value).format()
                        : null;
            });
            const profileFieldValuesMap = new ProfileFieldValuesMap();
            for (const profileFieldValue of profileFieldValues) {
                const fieldId = profileFieldValue.field_id;
                const profileFieldGroupListItemId = profileFieldValue.profile_field_group_list_item_id;
                profileFieldValuesMap.set({ fieldId, profileFieldGroupListItemId }, profileFieldValue);
            }
            profileFieldValuesMap.dirtyKeys.clear();
            return profileFieldValuesMap;
        });
    }
    static getKeyString(key) {
        var _a;
        return `${key.fieldId}:${(_a = key.profileFieldGroupListItemId) !== null && _a !== void 0 ? _a : ''}`;
    }
    clearDirty() {
        this.dirtyKeys.clear();
    }
    has(key) {
        if (typeof key === 'string') {
            return super.has(key);
        }
        const keyString = ProfileFieldValuesMap.getKeyString(key);
        return super.has(keyString);
    }
    isDirty(key) {
        if (typeof key === 'string') {
            return this.dirtyKeys.has(key);
        }
        const keyString = ProfileFieldValuesMap.getKeyString(key);
        return this.dirtyKeys.has(keyString);
    }
    get(key) {
        if (typeof key === 'string') {
            return super.get(key);
        }
        const keyString = ProfileFieldValuesMap.getKeyString(key);
        return super.get(keyString);
    }
    set(key, value, isDirty) {
        if (typeof key === 'string') {
            super.set(key, value);
            if (isDirty === false) {
                this.dirtyKeys.delete(key);
            }
            else {
                this.dirtyKeys.set(key, true);
            }
        }
        else {
            const keyString = ProfileFieldValuesMap.getKeyString(key);
            super.set(keyString, value);
            if (isDirty === false) {
                this.dirtyKeys.delete(keyString);
            }
            else {
                this.dirtyKeys.set(keyString, true);
            }
        }
        return this;
    }
}
