import { useEffect, useRef } from 'react';
import { debounceAsync } from "../../utils";
import { ProfileFieldGroupListItemsChangeEvent, ProfileFieldValueChangeEvent } from './events';
import { useProfilePageContext } from './profilePageContext';
import { useSave } from './profilePageContextHooks';
export const ProfilePageAutosaver = () => {
    const { eventTarget } = useProfilePageContext();
    const save = useSave();
    const { current: debouncedSave } = useRef(debounceAsync(save, 1000));
    useEffect(() => {
        eventTarget.addEventListener(ProfileFieldValueChangeEvent.EVENT_NAME, debouncedSave);
        eventTarget.addEventListener(ProfileFieldGroupListItemsChangeEvent.EVENT_NAME, debouncedSave);
        return () => {
            eventTarget.removeEventListener(ProfileFieldValueChangeEvent.EVENT_NAME, debouncedSave);
            eventTarget.removeEventListener(ProfileFieldGroupListItemsChangeEvent.EVENT_NAME, debouncedSave);
        };
    }, []);
    return null;
};
