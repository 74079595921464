import { useEffect, useState } from 'react';
import type { PaymentCardInfo } from '@quiet-sunset/leo-shared';
import { usePaymentAccountService } from '@quiet-sunset/leo-shared';

export const usePaymentCardInfo = (updateTrigger?: Record<string, unknown>) => {
  const PaymentAccountService = usePaymentAccountService();
  const [paymentCardInfo, setPaymentCardInfo] = useState<PaymentCardInfo | null | undefined>();

  useEffect(() => {
    void (async () => {
      setPaymentCardInfo(undefined);
      const _paymentCardInfo = await PaymentAccountService.getCardInfo();
      setPaymentCardInfo(_paymentCardInfo);
    })();
  }, [updateTrigger]);

  return paymentCardInfo;
};
