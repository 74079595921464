import React from 'react';

import { useProfileFieldGroupListItems } from '@quiet-sunset/leo-shared';

// eslint-disable-next-line import/no-cycle
import { ProfileContentFieldGroupHorizontalRow } from './profileContentFieldGroupHorizontalRow';

export interface ProfileContentFieldGroupHorizontalRowListProps {
  fieldGroupId: string;
  profileFieldGroupListItemId: string | null;
}

export const ProfileContentFieldGroupHorizontalRowList: React.FunctionComponent<ProfileContentFieldGroupHorizontalRowListProps> =
  (props) => {
    const { fieldGroupId, profileFieldGroupListItemId: parentProfileFieldGroupListItemId } = props;

    const [profileFieldGroupListItems, , removeProfileFieldGroupListItem] =
      useProfileFieldGroupListItems(fieldGroupId, parentProfileFieldGroupListItemId);

    if (!profileFieldGroupListItems) {
      return null;
    }

    return (
      <>
        {profileFieldGroupListItems.map((profileFieldGroupListItem) => (
          <ProfileContentFieldGroupHorizontalRow
            key={profileFieldGroupListItem.id}
            fieldGroupId={fieldGroupId}
            profileFieldGroupListItemId={profileFieldGroupListItem.id}
            removeProfileFieldGroupListItem={() =>
              removeProfileFieldGroupListItem(profileFieldGroupListItem.id)
            }
          />
        ))}
      </>
    );
  };
