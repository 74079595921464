import React, { useCallback, useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { LoadingSpinner } from '$cmp/loadingSpinner';
import { LeoProfileDeleteConfirmationModal } from '$cmp/modals/leoProfileDeleteConfirmationModal';
import type { LeoProOrganizationProfile } from '@quiet-sunset/leo-shared';
import { ProfileTableItem } from '$routes/accounts/profileTableItem';
import {
  useLeoProOrganizationProfilesService,
  useLeoProOrganizationsService,
  useProfilesService,
} from '@quiet-sunset/leo-shared';

import { LeoProMemberSelector } from './leoProMemberSelector';

export interface LeoProProfileListProps {
  leoProOrganizationId: string;
  administratorId: string | null;
  managerId: string | null;
  assistantId: string | null;
}

export const LeoProProfileList: React.FunctionComponent<LeoProProfileListProps> = ({
  leoProOrganizationId,
  administratorId,
  managerId,
  assistantId,
}) => {
  const LeoProOrganizationProfilesService = useLeoProOrganizationProfilesService();
  const LeoProOrganizationsService = useLeoProOrganizationsService();
  const ProfilesService = useProfilesService();

  const showManagers = administratorId != null;
  const showAssistants = administratorId != null || managerId != null;

  const [isLoaded, setIsLoaded] = useState(false);
  const [forceReloadTrigger, setForceReloadTrigger] = useState({});
  const [leoProOrganizationProfiles, setLeoProOrganizationProfiles] = useState(
    null as null | LeoProOrganizationProfile[]
  );
  const [profileIdToDelete, setProfileIdToDelete] = useState<string | null>(null);

  useEffect(() => {
    void (async () => {
      setIsLoaded(false);
      try {
        const _leoProOrganizationProfiles =
          await LeoProOrganizationsService.getLeoProOrganizationProfilesForLeoProOrganization(
            leoProOrganizationId
          );
        setLeoProOrganizationProfiles(_leoProOrganizationProfiles);
      } catch (e) {
        setLeoProOrganizationProfiles(null);
        throw e;
      } finally {
        setIsLoaded(true);
      }
    })();
  }, [leoProOrganizationId, forceReloadTrigger]);

  const addProfile = useCallback(async () => {
    setIsLoaded(false);
    try {
      await LeoProOrganizationsService.createLeoProOrganizationProfileForLeoProOrganization(
        leoProOrganizationId
      );
    } finally {
      setIsLoaded(true);
      setForceReloadTrigger({});
    }
  }, [leoProOrganizationId]);

  const setProfileManager = useCallback(
    async (
      _leoProOrganizationId: string,
      profileId: string,
      leoProOrganizationManagerId: string | null
    ) => {
      await LeoProOrganizationProfilesService.setManagerForLeoProOrganizationProfile(
        _leoProOrganizationId,
        profileId,
        leoProOrganizationManagerId
      );
      setForceReloadTrigger({});
    },
    []
  );

  const setProfileAssistant = useCallback(
    async (
      _leoProOrganizationId: string,
      profileId: string,
      leoProOrganizationAssistantId: string | null
    ) => {
      await LeoProOrganizationProfilesService.setAssistantForLeoProOrganizationProfile(
        _leoProOrganizationId,
        profileId,
        leoProOrganizationAssistantId
      );
      setForceReloadTrigger({});
    },
    []
  );

  const confirmProfileDelete = useCallback(async () => {
    try {
      setIsLoaded(false);
      if (profileIdToDelete != null) {
        await ProfilesService.deleteProfileById(profileIdToDelete);
      }
      setProfileIdToDelete(null);
      setForceReloadTrigger({});
    } finally {
      setIsLoaded(true);
    }
  }, [profileIdToDelete]);

  const hideProfileDeleteModal = useCallback(() => {
    setProfileIdToDelete(null);
  }, []);

  const numColumns = 2 + (showManagers ? 1 : 0) + (showAssistants ? 1 : 0);

  return (
    <>
      {!isLoaded && <LoadingSpinner />}
      {profileIdToDelete != null && (
        <LeoProfileDeleteConfirmationModal
          onYes={confirmProfileDelete}
          onNo={hideProfileDeleteModal}
        />
      )}
      <Table>
        <thead>
          <tr>
            <th>Head of household</th>
            {showManagers && <th>Manager</th>}
            {showAssistants && <th>Assistant</th>}
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {leoProOrganizationProfiles == null && (
            <tr>
              <td colSpan={numColumns}>An error occurred</td>
            </tr>
          )}
          {leoProOrganizationProfiles != null && leoProOrganizationProfiles.length === 0 && (
            <tr>
              <td colSpan={numColumns}>No profiles</td>
            </tr>
          )}

          {leoProOrganizationProfiles != null &&
            leoProOrganizationProfiles.length !== 0 &&
            leoProOrganizationProfiles.map((leoProOrganizationProfile) => (
              <ProfileTableItem
                key={leoProOrganizationProfile.profile_id}
                profile={leoProOrganizationProfile.profile!}
                showAccess={false}
                manager={
                  showManagers ? (
                    <LeoProMemberSelector
                      leoProOrganizationId={leoProOrganizationId}
                      memberType="Manager"
                      showEmptyOption={false}
                      selectedMemberId={leoProOrganizationProfile.manager_id}
                      onSelectedMemberIdChange={(leoProOrganizationManagerId) =>
                        setProfileManager(
                          leoProOrganizationProfile.leo_pro_organization_id,
                          leoProOrganizationProfile.profile_id,
                          leoProOrganizationManagerId
                        )
                      }
                    />
                  ) : undefined
                }
                assistant={
                  showAssistants ? (
                    <LeoProMemberSelector
                      leoProOrganizationId={leoProOrganizationId}
                      memberType="Assistant"
                      selectedMemberId={leoProOrganizationProfile.assistant_id}
                      onSelectedMemberIdChange={(leoProOrganizationAssistantId) =>
                        setProfileAssistant(
                          leoProOrganizationProfile.leo_pro_organization_id,
                          leoProOrganizationProfile.profile_id,
                          leoProOrganizationAssistantId
                        )
                      }
                    />
                  ) : undefined
                }
                isDefault={false}
                showDefaultActionsAndOptions={false}
                showLaunch={
                  (leoProOrganizationProfile.manager_id != null &&
                    managerId === leoProOrganizationProfile.manager_id) ||
                  (leoProOrganizationProfile.assistant_id != null &&
                    assistantId === leoProOrganizationProfile.assistant_id)
                }
                onDelete={
                  managerId === leoProOrganizationProfile.manager_id
                    ? () => setProfileIdToDelete(leoProOrganizationProfile.profile_id)
                    : undefined
                }
              />
            ))}
        </tbody>
        {managerId != null && (
          <tfoot>
            <tr>
              <td colSpan={numColumns}>
                <Button variant="success" onClick={addProfile}>
                  Add profile
                </Button>
              </td>
            </tr>
          </tfoot>
        )}
      </Table>
    </>
  );
};
