var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
const { setTimeout, clearTimeout } = window;
/**
 * Define an asynchronous function that will debounce its calls. Calling the debounced function
 * will -- instead of calling the provided function immediately -- delay a certain amount of time
 * before calling the provided function. If the debounced function is called again within this
 * delay period, the delay will restart. If the debounced function is called after the provided
 * function has been called but before the provided function's promise has completed, an additional
 * call to the provided function will be scheduled after the promise completes.
 * @param fn The provided function to debounce
 * @param delay The debouce delay in milliseconds
 * @returns The provided function wrapped in debounce logic
 */
export const debounceAsync = (fn, delay) => {
    let currentTimeout;
    let isExecuting = false;
    let reinvokeUponCompletion = false;
    const debouncedFn = () => __awaiter(void 0, void 0, void 0, function* () {
        clearTimeout(currentTimeout);
        currentTimeout = undefined;
        isExecuting = true;
        let fnReturnValue;
        try {
            fnReturnValue = fn();
        }
        finally {
            if (fnReturnValue instanceof Promise) {
                try {
                    yield fnReturnValue;
                }
                finally {
                    isExecuting = false;
                }
            }
            else {
                isExecuting = false;
            }
            if (reinvokeUponCompletion) {
                reinvokeUponCompletion = false;
                currentTimeout = setTimeout(debouncedFn, delay);
            }
        }
    });
    const callFn = () => {
        if (isExecuting) {
            // function is currently executing, invoke it again
            // when complete
            reinvokeUponCompletion = true;
        }
        if (!isExecuting && currentTimeout) {
            // function call has been scheduled but not currently executing
            // cancel the scheduled call
            // this will cause the next if condition to evaluate to true,
            // and a new function call will be scheduled
            clearTimeout(currentTimeout);
            currentTimeout = undefined;
        }
        if (!isExecuting && !currentTimeout) {
            // function call has not been scheduled yet
            currentTimeout = setTimeout(debouncedFn, delay);
        }
        // if the function call is currently executing, nothing will happen
    };
    callFn.cancel = () => {
        clearTimeout(currentTimeout);
        currentTimeout = undefined;
    };
    callFn.flush = () => {
        if (!isExecuting && currentTimeout) {
            clearTimeout();
            currentTimeout = undefined;
        }
        debouncedFn();
    };
    return callFn;
};
export class Event {
    constructor(type) {
        this.type = type;
    }
}
export class EventTarget {
    constructor() {
        this.listenersByEventType = new Map();
    }
    addEventListener(type, callback) {
        if (!this.listenersByEventType.has(type)) {
            this.listenersByEventType.set(type, []);
        }
        const listeners = this.listenersByEventType.get(type);
        if (!listeners.includes(callback)) {
            listeners.push(callback);
        }
    }
    removeEventListener(type, callback) {
        const listeners = this.listenersByEventType.get(type);
        if (listeners) {
            this.listenersByEventType.set(type, listeners.filter(x => x !== callback));
        }
    }
    dispatchEvent(event) {
        var _a;
        const listeners = (_a = this.listenersByEventType.get(event.type)) !== null && _a !== void 0 ? _a : [];
        for (const listener of listeners) {
            setTimeout(() => {
                listener(event);
            }, 0);
        }
    }
}
