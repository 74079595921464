import React from 'react';
import styled from 'styled-components/macro';

import Sidebar from '$cmp/sidebar/sidebar';

const drawerWidth = 300;

const Drawer = styled.div`
  height: 100%;

  ${(props) => props.theme.breakpoints.up('md')} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }

  ${(props) => props.theme.breakpoints.down('sm')} {
    width: 100%;
    flex-shrink: 0;
  }
`;

interface ProfileSidebarProps {
  launchCertificationModal: () => void;
  showCertificationUnlockModal: () => void;
  showCertificationDownloadModal: () => void;
  launchPdfDownloadModal: () => void;
  launchUnlockProtectedModeModal: () => void;
}

export const ProfileSidebar: React.FunctionComponent<ProfileSidebarProps> = (props) => {
  return (
    <Drawer>
      <Sidebar PaperProps={{ style: { width: '100%' } }} {...props} />
    </Drawer>
  );
};
