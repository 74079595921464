import React from 'react';
import type { Field } from '@quiet-sunset/leo-shared';

export interface HtmlDisplayFieldProps {
  field: Field;
}

export const HtmlDisplayField: React.FunctionComponent<HtmlDisplayFieldProps> = React.memo(
  (props) => {
    const { field } = props;

    // eslint-disable-next-line react/no-danger
    return <div dangerouslySetInnerHTML={{ __html: field.html_display_field?.html ?? '' }} />;
  }
);
