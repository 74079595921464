import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import {
  useAsyncMemo,
  useProfileContext,
  useProfileFieldGroupListItemsService,
  useProfileNavigatorContext,
  useProfileFieldGroupListItems,
  useProfilePageContext,
  useSave,
} from '@quiet-sunset/leo-shared';
import { LoadingSpinner } from '$cmp/loadingSpinner';
import { List, ListItem } from '$cmp/list';
import { ProfileContentViewport } from '../profileContentViewport';
import { useUpdateTrigger } from '$hooks/useUpdateTrigger';
import { RenameFieldGroupListItemDialog } from './renameFieldGroupListItemDialog';

export const ProfileFieldGroupListManager: React.FunctionComponent = () => {
  const history = useHistory();
  const [renamingItemId, setRenamingItemId] = useState<string>();

  const { baseRoute, profileId } = useProfileContext();
  const { menuItemId, rootFieldGroupId, rootFieldGroup, rootFieldGroupListItemId } =
    useProfilePageContext();
  const { reloadCurrentNavigatorState } = useProfileNavigatorContext();
  const [retrieveTitlesUpdateTrigger, triggerRetrieveTitles] = useUpdateTrigger();
  const save = useSave();

  const [
    profileFieldGroupListItems,
    _addProfileFieldGroupListItem,
    _removeProfileFieldGroupListItem,
  ] = useProfileFieldGroupListItems(rootFieldGroupId, rootFieldGroupListItemId);

  const ProfileFieldGroupListItemsService = useProfileFieldGroupListItemsService();

  const [profileFieldGroupListItemTitlesById] = useAsyncMemo(async () => {
    const titles =
      await ProfileFieldGroupListItemsService.getAllTitlesForProfileFieldGroupListItems(
        profileId,
        rootFieldGroupId
      );
    return new Map<string, string>(
      titles.map((x) => [x.profile_field_group_list_item_id, x.title])
    );
  }, [ProfileFieldGroupListItemsService, retrieveTitlesUpdateTrigger, profileId, rootFieldGroupId]);

  const addProfileFieldGroupListItem = useCallback(async () => {
    _addProfileFieldGroupListItem();
    await save();
    triggerRetrieveTitles();
    reloadCurrentNavigatorState();
  }, [_addProfileFieldGroupListItem, save, triggerRetrieveTitles, reloadCurrentNavigatorState]);

  const deleteProfileFieldGroupListItem = useCallback(
    async (itemId: string) => {
      _removeProfileFieldGroupListItem(itemId);
      await save();
      triggerRetrieveTitles();
      reloadCurrentNavigatorState();
    },
    [_removeProfileFieldGroupListItem, save, triggerRetrieveTitles, reloadCurrentNavigatorState]
  );

  const onRenameDialogClose = useCallback(() => {
    setRenamingItemId(undefined);
    triggerRetrieveTitles();
    reloadCurrentNavigatorState();
  }, [triggerRetrieveTitles, reloadCurrentNavigatorState]);

  const launchItem = useCallback(
    (itemId: string) => {
      history.push(`${baseRoute}/${menuItemId}/${rootFieldGroupId}?listItemId=${itemId}`);
    },
    [history, baseRoute, menuItemId, rootFieldGroupId]
  );

  if (!profileFieldGroupListItemTitlesById) {
    return <LoadingSpinner />;
  }

  return (
    <>
      {renamingItemId && (
        <RenameFieldGroupListItemDialog
          profileId={profileId}
          fieldGroupId={rootFieldGroupId}
          profileFieldGroupListItemId={renamingItemId}
          onClose={onRenameDialogClose}
        />
      )}
      <ProfileContentViewport>
        <h3>{rootFieldGroup.label}</h3>
        <List>
          {(profileFieldGroupListItems ?? []).map((item) => (
            <ListItem key={item.id} onClick={() => launchItem(item.id)}>
              <h5 style={{ flex: 1 }}>{profileFieldGroupListItemTitlesById.get(item.id)}</h5>
              {rootFieldGroup.title_field_id && (
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    setRenamingItemId(item.id);
                  }}
                >
                  <EditIcon />
                </IconButton>
              )}
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  deleteProfileFieldGroupListItem(item.id);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </ListItem>
          ))}
          <ListItem onClick={addProfileFieldGroupListItem}>
            <AddIcon />
            Add new
          </ListItem>
        </List>
      </ProfileContentViewport>
    </>
  );
};
