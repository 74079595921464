import { useEnvironment } from '$hooks/useEnvironment';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const EnvironmentLabel = styled.div`
  flex: 0 0 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #cc0;
`;

const Content = styled.div`
  flex: 1;
  overflow: auto;
`;

export const EnvironmentAlertWrapper: React.FunctionComponent = ({ children }) => {
  const environment = useEnvironment();

  return (
    <Container>
      {environment !== 'production' && (
        <EnvironmentLabel>
          <h3>
            You are currently in the <strong>{environment}</strong> environment
          </h3>
        </EnvironmentLabel>
      )}
      <Content>{children}</Content>
    </Container>
  );
};
