import React from 'react';

import { CardBackdrop } from '$cmp/cardBackdrop';
import { CardContainer } from '$cmp/cardContainer';
import { useCurrentUserContext } from '$contexts/currentUserContext';
import { LoadingSpinner } from '$cmp/loadingSpinner';
import { LeoProManagementCard } from '$routes/leoPro/leoProManagementCard';
import { ProfilesCard } from './profilesCard';

export const AccountsRoute: React.FunctionComponent = () => {
  const { currentUser } = useCurrentUserContext();

  if (!currentUser) {
    return <LoadingSpinner />;
  }

  return (
    <CardBackdrop>
      <CardContainer>
        {currentUser.is_single_subscriber_enabled && <ProfilesCard />}
        {currentUser.is_leo_pro_enabled && <LeoProManagementCard />}
      </CardContainer>
    </CardBackdrop>
  );
};
