import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { Field, FieldGroup, useFieldGroupsService } from '@quiet-sunset/leo-shared';

let nextClipboardItemId = 0;

export type ClipboardItemInput = { onCopyComplete?: () => void } & (
  | {
      type: 'field_group';
      fieldGroup: FieldGroup;
      fieldGroupItemId: string | null;
    }
  | {
      type: 'field';
      field: Field;
      fieldGroupItemId: string;
    }
);

export type ClipboardItem = ClipboardItemInput & { id: number };

export type ClipboardContents = ClipboardItem[];

export type ClipboardAction = 'move' | 'clone';

export type ProfileAdminClipboardContextType = {
  setClipboardAction: (action: ClipboardAction) => void;
  addToClipboard: (item: ClipboardItemInput) => void;
  removeFromClipboard: (item: ClipboardItem) => void;
  moveClipboardItem: (item: ClipboardItem, indexOffset: number) => void;
  pasteFieldGroupToMenuItem: (menuItemId: string) => Promise<void>;
  pasteFieldGroupItemsToFieldGroup: (fieldGroupId: string) => Promise<void>;
  clipboardAction: ClipboardAction;
  clipboardContents: ClipboardContents;
};

export const ProfileAdminClipboardContext = createContext<ProfileAdminClipboardContextType>(
  undefined!
);

export const useProfileAdminClipboardContext = () => useContext(ProfileAdminClipboardContext);

export const ProfileAdminClipboardContextProvider: React.FC = ({ children }) => {
  const [clipboardAction, setClipboardAction] = useState<ClipboardAction>('move');
  const [clipboardContents, setClipboardContents] = useState<ClipboardContents>([]);
  const FieldGroupsService = useFieldGroupsService();

  const addToClipboard = useCallback((item: ClipboardItemInput) => {
    setClipboardContents((prevClipboardContents) => [
      ...prevClipboardContents,
      { ...item, id: nextClipboardItemId++ },
    ]);
  }, []);

  const removeFromClipboard = useCallback((item: ClipboardItem) => {
    setClipboardContents((prevClipboardContents) =>
      prevClipboardContents.filter((_item) => _item.id !== item.id)
    );
  }, []);

  const moveClipboardItem = useCallback((item: ClipboardItem, indexOffset: number) => {
    setClipboardContents((prevClipboardContents) => {
      const itemIndex = prevClipboardContents.indexOf(item);
      if (itemIndex === -1) {
        return prevClipboardContents;
      }

      const itemToSwapWith = prevClipboardContents[itemIndex + indexOffset];
      if (!itemToSwapWith) {
        return prevClipboardContents;
      }

      return prevClipboardContents.map((_item) => {
        if (_item === item) {
          return itemToSwapWith;
        }
        if (_item === itemToSwapWith) {
          return item;
        }
        return _item;
      });
    });
  }, []);

  const pasteFieldGroupToMenuItem = useCallback(
    async (menuItemId: string) => {
      const [clipboardItem, ...otherClipboardItems] = clipboardContents;
      if (clipboardItem?.type !== 'field_group' || otherClipboardItems.length) {
        return;
      }

      await FieldGroupsService.moveFieldGroupToMenuItem(clipboardItem.fieldGroup.id, menuItemId);

      clipboardItem.onCopyComplete?.call(null);

      setClipboardAction('move');
      setClipboardContents([]);
    },
    [clipboardContents, FieldGroupsService]
  );

  const pasteFieldGroupItemsToFieldGroup = useCallback(
    async (fieldGroupId: string) => {
      await FieldGroupsService.moveFieldGroupItemsToFieldGroup(
        fieldGroupId,
        clipboardAction,
        clipboardContents.map((item) => {
          if (item.type === 'field') {
            return { field_group_item_id: item.fieldGroupItemId };
          }
          if (item.fieldGroupItemId) {
            return { field_group_item_id: item.fieldGroupItemId };
          }
          return { field_group_id: item.fieldGroup.id };
        })
      );

      for (const clipboardItem of clipboardContents) {
        clipboardItem.onCopyComplete?.call(null);
      }

      setClipboardAction('move');
      setClipboardContents([]);
    },
    [clipboardAction, clipboardContents, FieldGroupsService]
  );

  const contextValue = useMemo(
    () => ({
      setClipboardAction,
      addToClipboard,
      removeFromClipboard,
      moveClipboardItem,
      pasteFieldGroupToMenuItem,
      pasteFieldGroupItemsToFieldGroup,
      clipboardAction,
      clipboardContents,
    }),
    [
      setClipboardAction,
      addToClipboard,
      removeFromClipboard,
      moveClipboardItem,
      pasteFieldGroupToMenuItem,
      pasteFieldGroupItemsToFieldGroup,
      clipboardAction,
      clipboardContents,
    ]
  );

  return (
    <ProfileAdminClipboardContext.Provider value={contextValue}>
      {children}
    </ProfileAdminClipboardContext.Provider>
  );
};
