import React from 'react';
import { YesNoModal } from './yesNoModal';

export interface LeoProfileDeleteConfirmationModalProps {
  onYes: () => any;
  onNo: () => any;
}

export const LeoProfileDeleteConfirmationModal: React.FunctionComponent<LeoProfileDeleteConfirmationModalProps> =
  (props) => {
    const { onYes, onNo } = props;

    return (
      <YesNoModal
        title="Delete LEO-PRO profile"
        yesButtonVariant="danger"
        onYes={onYes}
        onNo={onNo}
      >
        <p>Are you sure you want to delete this profile?</p>
        <p>
          If a client has access to this profile, they will lose access permanently. If the client
          wishes to preserve access and take on billing ownership of this profile, they will need to
          perform that action after logging in and opening the profile.
        </p>
      </YesNoModal>
    );
  };
