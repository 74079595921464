import React from 'react';
import { Form, Field } from 'react-final-form';
import { LeoProOrganizationRegistrationRequestFormData } from './leoProOrganizationRegistrationRequestFormData';

export interface LeoProOrganizationRegistrationRequestFormProps {
  errors: string[];
  onSubmit: (formValues: LeoProOrganizationRegistrationRequestFormData) => any;
}

export const LeoProOrganizationRegistrationRequestForm: React.FunctionComponent<LeoProOrganizationRegistrationRequestFormProps> =
  React.memo(({ errors, onSubmit }) => {
    return (
      <Form<LeoProOrganizationRegistrationRequestFormData> onSubmit={onSubmit}>
        {({ handleSubmit }) => (
          <form className="form-signin form-horizontal" onSubmit={handleSubmit}>
            <div className="leo-login-container">
              <h2 className="leo-login-header text-center">Register for LEO-PRO</h2>
              {errors.length > 0 && (
                <div className="leo-login-row">
                  <div className="alert alert-danger">
                    <p>The following validation errors have occurred:</p>
                    <ul>
                      {errors.map((error) => (
                        <li key={error}>{error}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}

              <p className="text-center">
                Thank you for your interest in LEO-PRO! Please fill out this registration form to
                request a LEO-PRO account.
              </p>
              <div>
                <Field
                  name="organizationName"
                  component="input"
                  className="form-control text-center"
                  placeholder="Organization name"
                  required
                  autoFocus
                />
              </div>
              <div className="leo-login-row leo-login-buttons text-center">
                <button className="btn btn-primary" type="submit">
                  Submit request
                </button>
              </div>
            </div>
          </form>
        )}
      </Form>
    );
  });
