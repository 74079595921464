import React from 'react';
import { useFieldGroupItems } from '@quiet-sunset/leo-shared';
// eslint-disable-next-line import/no-cycle
import { ProfileContentFieldGroupVerticalFieldGroupItem } from './profileContentFieldGroupVerticalFieldGroupItem';

export interface ProfileContentFieldGroupVerticalProps {
  fieldGroupId: string;
  profileFieldGroupListItemId: string | null;
}

export const ProfileContentFieldGroupVertical: React.FunctionComponent<ProfileContentFieldGroupVerticalProps> =
  (props) => {
    const { fieldGroupId, profileFieldGroupListItemId } = props;

    const fieldGroupItems = useFieldGroupItems(fieldGroupId);

    if (!fieldGroupItems) {
      return null;
    }

    return (
      <>
        {fieldGroupItems.map((fieldGroupItem) => (
          <ProfileContentFieldGroupVerticalFieldGroupItem
            key={fieldGroupItem.id}
            fieldGroupItem={fieldGroupItem}
            profileFieldGroupListItemId={profileFieldGroupListItemId}
          />
        ))}
      </>
    );
  };
