import React, { useCallback, useState } from 'react';

import leoLogoWhite from '$assets/leo-logo-white.png';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useCurrentUserContext } from '$contexts/currentUserContext';
import { TipsModal } from './modals/tipsModal';
import { FaqsModal } from './modals/faqsModal';
import { GlossaryModal } from './modals/glossaryModal';
import { useSignOut } from '@sprice237/react-auth-dom';
import { FeatureFlags } from '$/featureFlags';

export const NavMenu: React.FunctionComponent = () => {
  const { isLoggedIn, currentUser, reloadCurrentUser } = useCurrentUserContext();
  const signOut = useSignOut();

  const canSeeExecutorProfiles = currentUser?.is_executor;
  const canSeeAdminMenu = currentUser?.is_admin;
  const canSeeAdminUsers = currentUser?.is_admin;
  const canSeeAdminUserRegistrations = currentUser?.is_admin;
  const canSeeAdminMenuItems = currentUser?.is_admin;
  const canSeeAdminFieldGroups = currentUser?.is_admin;
  const canSeeAdminFieldOptionLists = currentUser?.is_admin;
  const canSeeAdminInvitations = currentUser?.is_admin;
  const canSeeHelpMenu = true;
  const canSeeHelpSupport = true;
  const canSeeHelpTips = isLoggedIn;
  const canSeeHelpFaq = isLoggedIn;
  const canSeeHelpGlossary = isLoggedIn;
  const canSeeManagePaymentAccount = currentUser?.user_payment_account;

  const [tipsModalIsVisible, setTipsModalIsVisible] = useState(false);
  const [faqsModalIsVisible, setFaqsModalIsVisible] = useState(false);
  const [glossaryModalIsVisible, setGlossaryModalIsVisible] = useState(false);

  const openTips = useCallback(() => {
    setTipsModalIsVisible(true);
  }, []);

  const openFaq = useCallback(() => {
    setFaqsModalIsVisible(true);
  }, []);

  const openGlossary = useCallback(() => {
    setGlossaryModalIsVisible(true);
  }, []);

  const logout = useCallback(async () => {
    await signOut();
    await reloadCurrentUser();
  }, []);

  return (
    <Navbar expand="lg" variant="dark">
      {tipsModalIsVisible && <TipsModal onClose={() => setTipsModalIsVisible(false)} />}
      {faqsModalIsVisible && <FaqsModal onClose={() => setFaqsModalIsVisible(false)} />}
      {glossaryModalIsVisible && <GlossaryModal onClose={() => setGlossaryModalIsVisible(false)} />}
      <Navbar.Brand>
        <Nav.Link as={Link} to="/" style={{ display: 'unset', padding: 'unset' }}>
          <img alt="Life Estate Organizer" src={leoLogoWhite} style={{ height: '100%' }} />
        </Nav.Link>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link as={Link} to="/home">
            Home
          </Nav.Link>
          {canSeeExecutorProfiles && (
            <Nav.Link as={Link} to="/executorProfiles">
              Executor profiles
            </Nav.Link>
          )}
          {canSeeAdminMenu && (
            <NavDropdown title="Admin" id="admin-dropdown">
              {canSeeAdminUsers && (
                <NavDropdown.Item as={Link} to="/users">
                  Users
                </NavDropdown.Item>
              )}
              {canSeeAdminUserRegistrations && (
                <NavDropdown.Item as={Link} to="/user-registrations">
                  User Registrations
                </NavDropdown.Item>
              )}
              {canSeeAdminMenuItems && (
                <NavDropdown.Item as={Link} to="/menu-items">
                  Menu items
                </NavDropdown.Item>
              )}
              {canSeeAdminFieldGroups && (
                <NavDropdown.Item as={Link} to="/field-groups">
                  Field groups
                </NavDropdown.Item>
              )}
              {canSeeAdminFieldOptionLists && (
                <NavDropdown.Item as={Link} to="/field-option-lists">
                  Field option lists
                </NavDropdown.Item>
              )}
              {canSeeAdminInvitations && (
                <NavDropdown.Item as={Link} to="/invitations">
                  Invitations
                </NavDropdown.Item>
              )}
            </NavDropdown>
          )}
          {canSeeHelpMenu && (
            <NavDropdown title="Help" id="help-dropdown">
              {canSeeHelpSupport && (
                <NavDropdown.Item as={Link} to="/support">
                  Support
                </NavDropdown.Item>
              )}
              {canSeeHelpTips && <NavDropdown.Item onClick={openTips}>Tips</NavDropdown.Item>}
              {canSeeHelpFaq && <NavDropdown.Item onClick={openFaq}>FAQ</NavDropdown.Item>}
              {canSeeHelpGlossary && (
                <NavDropdown.Item onClick={openGlossary}>Glossary</NavDropdown.Item>
              )}
            </NavDropdown>
          )}
        </Nav>

        <Nav className="navbar-right">
          <Nav.Link href="https://www.lifeestateorganizer.com">Website</Nav.Link>
          {!currentUser && (
            <Nav.Link as={Link} to="/login">
              Login
            </Nav.Link>
          )}
          {currentUser && (
            <NavDropdown
              title={
                <>
                  <span>Manage Account</span>
                  <i
                    style={{ marginLeft: '5px', fontSize: '12pt' }}
                    className="far fa-user-circle"
                  />
                </>
              }
              id="manage-account-dropdown"
              className="make-dropdown-menu-right"
            >
              <NavDropdown.Item disabled>{currentUser.email}</NavDropdown.Item>
              <NavDropdown.Divider />
              {FeatureFlags.ActivateFeaturesMenuItem && (
                <NavDropdown.Item as={Link} to="/activate">
                  Activate features
                </NavDropdown.Item>
              )}
              {!FeatureFlags.ActivateFeaturesMenuItem && (
                <NavDropdown.Item as={Link} to="/accounts">
                  Account management
                </NavDropdown.Item>
              )}
              <NavDropdown.Item as={Link} to="/userSettings">
                User settings
              </NavDropdown.Item>
              {canSeeManagePaymentAccount && (
                <NavDropdown.Item as={Link} to="/manage-payment-account">
                  Manage payment account
                </NavDropdown.Item>
              )}
              <NavDropdown.Item onClick={logout}>Log out</NavDropdown.Item>
            </NavDropdown>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};
