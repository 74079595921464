import React, { useCallback, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';

import { LoadingSpinner } from '$cmp/loadingSpinner';
import type { ProfileFieldMultiFileValue } from '@quiet-sunset/leo-shared';

export interface FilesFieldProps {
  existingFileValues: ProfileFieldMultiFileValue[];
  onExistingFileValuesChange: (value: ProfileFieldMultiFileValue[]) => any;
  onFilesSelected: (value: File[]) => any;
  multiple: boolean;
  isDirty: boolean;
  disabled?: boolean;
}

export const FilesField: React.FunctionComponent<FilesFieldProps> = React.memo((props) => {
  const { existingFileValues, onExistingFileValuesChange, onFilesSelected, multiple, disabled } =
    props;

  const [isSelectingFiles, setIsSelectingFiles] = useState(false);
  const fileInputRef = useRef(null as null | HTMLInputElement);

  const removeExistingFileValue = useCallback(
    (fileValue: ProfileFieldMultiFileValue) => {
      const _existingFileValues = existingFileValues.filter((x) => x !== fileValue);
      onExistingFileValuesChange(_existingFileValues);
    },
    [existingFileValues, onExistingFileValuesChange]
  );

  const newFilesChange = useCallback(
    async (fileList: FileList | null) => {
      const _newFiles = fileList != null ? Array.from(fileList) : [];
      setIsSelectingFiles(true);
      await onFilesSelected(_newFiles);
      setIsSelectingFiles(false);

      if (fileInputRef.current != null) {
        fileInputRef.current.value = null as any;
      }
    },
    [onFilesSelected]
  );

  return (
    <>
      {isSelectingFiles && <LoadingSpinner />}
      {(multiple || existingFileValues.length > 0) &&
        existingFileValues.map((fileValue) => (
          <div key={fileValue.id}>
            <a href={fileValue.file_download_url} target="_blank" rel="noopener noreferrer">
              {fileValue.file_details?.name}
            </a>
            <Button onClick={() => removeExistingFileValue(fileValue)} disabled={disabled}>
              Remove
            </Button>
          </div>
        ))}
      {(multiple || existingFileValues.length === 0) && (
        <input
          type="file"
          ref={fileInputRef}
          className="input-field"
          disabled={disabled}
          onChange={(e) => newFilesChange(e.target.files)}
          {...(multiple ? { multiple: true } : {})}
        />
      )}
    </>
  );
});
