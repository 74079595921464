import { useCallback } from 'react';
import { useFieldGroupItemsService } from '@quiet-sunset/leo-shared';

export const useMoveFieldGroupItemUp = (
  fieldGroupItemId: string | undefined | null,
  onComplete: () => void
) => {
  const FieldGroupItemsService = useFieldGroupItemsService();

  return useCallback(async () => {
    if (!fieldGroupItemId) {
      return;
    }
    await FieldGroupItemsService.moveFieldGroupItemUp(fieldGroupItemId);
    onComplete();
  }, [FieldGroupItemsService, fieldGroupItemId, onComplete]);
};

export const useMoveFieldGroupItemDown = (
  fieldGroupItemId: string | undefined | null,
  onComplete: () => void
) => {
  const FieldGroupItemsService = useFieldGroupItemsService();

  return useCallback(async () => {
    if (!fieldGroupItemId) {
      return;
    }
    await FieldGroupItemsService.moveFieldGroupItemDown(fieldGroupItemId);
    onComplete();
  }, [FieldGroupItemsService, fieldGroupItemId, onComplete]);
};
