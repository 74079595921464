import React, { useCallback, useEffect, useState } from 'react';

import { LoadingSpinner } from '$cmp/loadingSpinner';
import { useUpdateTrigger } from '$hooks/useUpdateTrigger';
import type { PaymentCardInfo } from '@quiet-sunset/leo-shared';
import { usePaymentAccountService } from '@quiet-sunset/leo-shared';

import { EditableCardInformation } from './editableCardInformation';

type UserPaymentAccountEditableCardInformationProps = {
  onUpdate?: () => unknown;
};

export const UserPaymentAccountEditableCardInformation: React.FunctionComponent<UserPaymentAccountEditableCardInformationProps> =
  (props) => {
    const { onUpdate } = props;

    const PaymentAccountService = usePaymentAccountService();

    const [isLoaded, setIsLoaded] = useState(false);
    const [updateTrigger, triggerUpdate] = useUpdateTrigger();
    const [paymentCardInfo, setPaymentCardInfo] = useState<PaymentCardInfo | null>(null);

    const submit = useCallback(
      async (stripeSourceTokenId: string) => {
        setIsLoaded(false);
        try {
          await PaymentAccountService.putStripeTokenForCurrentUser(stripeSourceTokenId);
        } finally {
          setIsLoaded(true);
          triggerUpdate();
          onUpdate?.call(null);
        }
      },
      [onUpdate, triggerUpdate]
    );

    const removeCardInfo = useCallback(async () => {
      setIsLoaded(false);
      await PaymentAccountService.removeCardInfo();
      setIsLoaded(true);
      triggerUpdate();
      onUpdate?.call(null);
    }, [onUpdate, triggerUpdate]);

    useEffect(() => {
      void (async () => {
        setIsLoaded(false);
        const _paymentCardInfo = await PaymentAccountService.getCardInfo();
        setPaymentCardInfo(_paymentCardInfo);
        setIsLoaded(true);
      })();
    }, [onUpdate, updateTrigger]);

    if (!isLoaded) {
      return <LoadingSpinner />;
    }

    return (
      <EditableCardInformation
        paymentCardInfo={paymentCardInfo}
        onUpdate={submit}
        onRemoveCardInfo={removeCardInfo}
      />
    );
  };
