import React from 'react';
import { Field, Form } from 'react-final-form';
import { Button, Modal } from 'react-bootstrap';
import { MenuItemTreeField } from '$cmp/menuItemTreeField';

export interface EditUnprotectedAccessScreenProps {
  initialValues: EditUnprotectedAccessScreenForm;
  onClose: () => any;
  onBack: (formValues: EditUnprotectedAccessScreenForm) => any;
  onNext: (formValues: EditUnprotectedAccessScreenForm) => any;
}

export interface EditUnprotectedAccessScreenForm {
  disableUnprotectedEditing: boolean;
  unprotectedMenuItemIds: string[];
}

export const EditUnprotectedAccessScreen: React.FunctionComponent<EditUnprotectedAccessScreenProps> =
  (props) => {
    const { initialValues, onClose, onBack, onNext } = props;

    return (
      <Form<EditUnprotectedAccessScreenForm> onSubmit={onNext} initialValues={initialValues}>
        {({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Body>
              <div className="form-group">
                <label className="col-md-4 control-label">Accessible items</label>
                <div className="col-md-8">
                  <Field name="unprotectedMenuItemIds" component={MenuItemTreeField} />
                </div>
              </div>
              <div className="form-group">
                <label className="col-md-4 control-label">Access type</label>
                <div className="col-md-8">
                  <Field name="disableUnprotectedEditing" required>
                    {({ input }) => (
                      <select
                        className="form-control form-control-inline"
                        value={JSON.stringify(input.value)}
                        onChange={(e) => input.onChange(JSON.parse(e.target.value))}
                      >
                        <option value="true">View only</option>
                        <option value="false">View and edit</option>
                      </select>
                    )}
                  </Field>
                </div>
              </div>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="default" onClick={onClose}>
                Close
              </Button>
              <Button variant="default" onClick={() => onBack(values)}>
                Back
              </Button>
              <Button variant="primary" type="submit">
                Next
              </Button>
            </Modal.Footer>
          </form>
        )}
      </Form>
    );
  };
