import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { MenuItem, Select as MuiSelect } from '@mui/material';
import { spacing, SpacingProps } from '@mui/system';

import type { FieldOption } from '@quiet-sunset/leo-shared';
import { useFieldOptionListsCacheService } from '@quiet-sunset/leo-shared';

interface SelectMuiType extends SpacingProps {
  mt?: number;
}

const Select = styled(MuiSelect)<SelectMuiType>(spacing);

export interface SingleSelectFieldProps {
  value: string | null;
  fieldOptionListId: string;
  onChange: (value: string | null) => any;
  isDirty: boolean;
  disabled?: boolean;
}

export const SingleSelectField: React.FunctionComponent<SingleSelectFieldProps> = React.memo(
  (props) => {
    const { value, fieldOptionListId, onChange, disabled } = props;

    const FieldOptionListsCacheService = useFieldOptionListsCacheService();

    const [options, setOptions] = useState(null as FieldOption[] | null);

    useEffect(() => {
      void (async () => {
        const fieldOptionListsById = await FieldOptionListsCacheService.getFieldOptionListsById();
        const fieldOptionList = fieldOptionListsById.get(fieldOptionListId)!;
        const _options = fieldOptionList.field_options!.sort((a, b) =>
          a.order_index < b.order_index ? -1 : 1
        );
        setOptions(_options);
      })();
    }, [fieldOptionListId]);

    if (!options) {
      return <div>...</div>;
    }

    return (
      <Select
        disabled={disabled}
        value={JSON.stringify(value)}
        onChange={(e) => onChange(JSON.parse(e.target.value as string))}
        fullWidth
        variant="standard"
      >
        <MenuItem value="null">(none)</MenuItem>
        {options.map((option) => (
          <MenuItem key={option.id} value={JSON.stringify(option.id)}>
            {option.option_text}
          </MenuItem>
        ))}
      </Select>
    );
  }
);
