import React, { useState } from 'react';
import {
  EmailAuthProvider,
  getFirebaseErrorMessage,
  useLinkWithCredential,
  PasswordRegistrationForm,
} from '@sprice237/react-auth-dom';
import Modal from '@mui/material/Modal';
import { Box, Button, Card, Grid } from '@mui/material';
import { ButtonContainer } from '$cmp/buttonContainer';
import { LoadingSpinner } from '$cmp/loadingSpinner';

export interface LinkPasswordAuthProviderModalProps {
  onComplete: () => void;
  onCancel: () => void;
}

export const LinkPasswordAuthProviderModal: React.FunctionComponent<LinkPasswordAuthProviderModalProps> =
  ({ onComplete, onCancel }) => {
    const linkWithCredential = useLinkWithCredential();

    const [isInProgress, setIsInProgress] = useState(false);
    const [error, setError] = useState<string>();

    const submit = async ({ email, password }: { email: string; password: string }) => {
      try {
        setIsInProgress(true);
        const emailAuthCredential = EmailAuthProvider.credential(email, password);
        await linkWithCredential(emailAuthCredential);
        onComplete();
      } catch (e) {
        setError(getFirebaseErrorMessage(e));
      } finally {
        setIsInProgress(false);
      }
    };

    return (
      <>
        {isInProgress && <LoadingSpinner />}
        <Modal open>
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ height: '100%', width: '100%' }}
          >
            <Grid item style={{ width: '500px' }}>
              <Card>
                <Box m={5}>
                  <PasswordRegistrationForm error={error} onSubmit={submit} />
                  <ButtonContainer>
                    <Button type="button" color="secondary" onClick={onCancel}>
                      Cancel
                    </Button>
                  </ButtonContainer>
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Modal>
      </>
    );
  };
