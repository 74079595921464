import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
`;

const SidebarContainer = styled.div<{ sidebarWidth: number; isOpen: boolean }>`
  min-height: 100%;

  ${(props) => props.theme.breakpoints.up('md')} {
    width: ${({ sidebarWidth }) => sidebarWidth}px;
    flex-shrink: 0;
  }

  ${(props) => props.theme.breakpoints.down('sm')} {
    display: ${(props) => (props.isOpen ? 'block' : 'none')};
    width: 100%;
    flex-shrink: 0;
  }
`;

const ContentContainer = styled.div<{ sidebarWidth: number; isOpen: boolean }>`
  min-height: 100%;
  overflow: auto;
  width: 100%;

  ${(props) => props.theme.breakpoints.down('sm')} {
    display: ${(props) => (props.isOpen ? 'none' : 'block')};
  }
`;

export type ContextualHiddenSidebarContentContainerProps = {
  isOpen: boolean;
  sidebar: React.ReactNode;
  sidebarWidth: number;
};

export const ContextualHiddenSidebarContentContainer: React.FunctionComponent<ContextualHiddenSidebarContentContainerProps> =
  ({ isOpen, sidebar, sidebarWidth, children }) => {
    return (
      <Container>
        <SidebarContainer isOpen={isOpen} sidebarWidth={sidebarWidth}>
          {sidebar}
        </SidebarContainer>
        <ContentContainer isOpen={isOpen} sidebarWidth={sidebarWidth}>
          {children}
        </ContentContainer>
      </Container>
    );
  };
