import React from 'react';

import { NavMenu } from '$cmp/navMenu';
import { Router } from '$cmp/router';
import { useCurrentUserContext } from '$contexts/currentUserContext';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const NavMenuContainer = styled.div`
  flex: 0 0 50px;
`;

const RouterContainer = styled.div`
  flex: 1 0 0;
  overflow: auto;
`;

export const AppContent: React.FunctionComponent = () => {
  const { isLoggedIn } = useCurrentUserContext();

  return (
    <Container>
      {isLoggedIn && (
        <NavMenuContainer>
          <NavMenu />
        </NavMenuContainer>
      )}
      <RouterContainer>
        <Router />
      </RouterContainer>
    </Container>
  );
};
