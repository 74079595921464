import React from 'react';
import { Link } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import { useIsRoute } from '$hooks/useIsRoute';

export const CanceledAlert: React.VoidFunctionComponent = () => {
  const onManagePaymentAccountPage = useIsRoute('/manage-payment-account');

  return (
    <Alert severity="warning">
      <ul>
        <li>
          Your account is currently <strong>canceled</strong>.
        </li>
        {!onManagePaymentAccountPage && (
          <li>
            Visit the <Link to="/manage-payment-account">Manage Payment Account page</Link> to
            re-activate your account.
          </li>
        )}
        <li>Your account has been locked and you are not be able to access your account.</li>
      </ul>
    </Alert>
  );
};
