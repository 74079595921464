import { useCallback, useEffect, useState } from 'react';

export const useUpdateTrigger = (
  additionalUpdateTrigger?: Record<string, unknown>
): [Record<string, unknown>, () => void] => {
  const [updateTrigger, setUpdateTrigger] = useState({});

  const triggerUpdate = useCallback(() => setUpdateTrigger({}), []);

  useEffect(() => {
    if (additionalUpdateTrigger != null) {
      triggerUpdate();
    }
  }, [additionalUpdateTrigger, triggerUpdate]);

  return [updateTrigger, triggerUpdate];
};
