import React from 'react';
import { useHistory } from 'react-router';

import Typography from '@mui/material/Typography';
import { PasswordResetForm } from '@sprice237/react-auth-dom';
import { AuthContentWrapper } from '$cmp/authContentWrapper';
import { useQuery } from '$hooks/useQuery';
import { AuthContentContainer } from '$cmp/authContentContainer';
import { FeatureFlags } from '$/featureFlags';

export const PasswordResetRoute: React.VFC = React.memo(() => {
  const history = useHistory();
  const { oobCode } = useQuery<{ oobCode: string }>();

  if (!oobCode) {
    history.push('/');
    return null;
  }

  return (
    <AuthContentWrapper>
      <AuthContentContainer>
        <div
          style={
            FeatureFlags.NewLogin ? { width: '400px' } : { width: '520px', paddingTop: '40px' }
          }
        >
          <Typography component="h1" variant="h4" align="center" gutterBottom>
            Reset Password
          </Typography>
          <Typography component="h2" variant="body1" align="center">
            Please provide your new password
          </Typography>
          <PasswordResetForm loginRoutePath="/login" oobCode={oobCode} />
        </div>
      </AuthContentContainer>
    </AuthContentWrapper>
  );
});
