import React, { ReactNode, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import type { Profile } from '@quiet-sunset/leo-shared';
import { DefaultCheckmark } from '$cmp/defaultCheckmark';

const FullNameDisplay: React.FunctionComponent<{
  firstName: string | null;
  middleName: string | null;
  lastName: string | null;
}> = (props) => {
  const { firstName, middleName, lastName } = props;

  const fullName = useMemo(() => {
    const x = `${firstName ?? ''} ${middleName ?? ''} ${lastName ?? ''}`;
    return x.trim().length > 0 ? x : '(unnamed)';
  }, [firstName, middleName, lastName]);

  return <>{fullName}</>;
};

export interface ProfileTableItemProps {
  profile: Profile;
  showAccess: boolean;
  manager?: ReactNode;
  assistant?: ReactNode;
  isDefault: boolean;
  showDefaultActionsAndOptions: boolean;
  showLaunch: boolean;
  onMakeDefault?: () => unknown;
  onRemoveDefault?: () => unknown;
  onDelete?: () => unknown;
}

export const ProfileTableItem: React.FunctionComponent<ProfileTableItemProps> = (props) => {
  const {
    profile,
    showAccess,
    manager,
    assistant,
    isDefault,
    showDefaultActionsAndOptions,
    showLaunch,
    onMakeDefault,
    onRemoveDefault,
    onDelete,
  } = props;

  return (
    <tr>
      <td>
        <FullNameDisplay
          firstName={profile.head_of_household_name?.first_name ?? null}
          middleName={profile.head_of_household_name?.middle_name ?? null}
          lastName={profile.head_of_household_name?.last_name ?? null}
        />
      </td>
      {showAccess && <td>{profile.trusted_user_type ?? 'owner'}</td>}
      {showDefaultActionsAndOptions && <td>{isDefault && <DefaultCheckmark />}</td>}
      {manager !== undefined && <td>{manager}</td>}
      {assistant !== undefined && <td>{assistant}</td>}
      <td>
        {showDefaultActionsAndOptions && isDefault && (
          <Button variant="secondary" onClick={onRemoveDefault}>
            Remove default
          </Button>
        )}
        {showDefaultActionsAndOptions && !isDefault && (
          <Button variant="secondary" onClick={onMakeDefault}>
            Make default
          </Button>
        )}
        {showLaunch && (
          <Link to={`/profiles/${profile.id}`}>
            <Button variant="primary">Launch</Button>
          </Link>
        )}
        {onDelete != null && (
          <Button variant="danger" onClick={onDelete}>
            Delete
          </Button>
        )}
      </td>
    </tr>
  );
};
