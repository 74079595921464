import React from 'react';

import leoLogo from '$assets/leo-logo.png';
import { FeatureFlags } from '$/featureFlags';

export const LeoHeaderLogo = () => (
  <img
    src={leoLogo}
    style={{ width: '100%', maxWidth: FeatureFlags.NewLogin ? '452px' : '600px' }}
  />
);
