import React from 'react';

import { LoadingSpinner } from '$cmp/loadingSpinner';

export const UserTable: React.FunctionComponent = () => {
  return (
    <>
      <h3>Users</h3>

      <LoadingSpinner />

      <h4>Single subscriber users</h4>
      <h4>LEO-PRO users</h4>
      <h4>Invalid mode users</h4>
    </>
  );
};
