import React, { useState } from 'react';
import Button from '@mui/material/Button';
import {
  useUnlink,
  FacebookLoginButton,
  GoogleLoginButton,
  MicrosoftLoginButton,
} from '@sprice237/react-auth-dom';
import { useCurrentUserContext } from '$contexts/currentUserContext';
import { LinkPasswordAuthProviderModal } from '$cmp/modals/linkPasswordAuthProviderModal';
import { LoadingSpinner } from '$cmp/loadingSpinner';
import { FeatureFlags } from '$/featureFlags';

const getNiceProviderName = (providerId: string) => {
  switch (providerId) {
    case 'google.com':
      return 'Google';
    case 'microsoft.com':
      return 'Microsoft';
    case 'facebook.com':
      return 'Facebook';
    default:
      return '';
  }
};

export const LoginProviderContainer: React.FunctionComponent = () => {
  const { reloadCurrentUser, firebaseUser } = useCurrentUserContext();
  const unlink = useUnlink();

  const [isLoading, setIsLoading] = useState(false);
  const [isLinkPasswordAuthProviderModalVisible, setIsLinkPasswordAuthProviderModalVisible] =
    useState(false);

  if (!firebaseUser) {
    return null;
  }

  const passwordProvider = firebaseUser.providerData.find(
    (_provider) => _provider.providerId === 'password'
  );

  const nonPasswordProvider = firebaseUser.providerData.find(
    (_provider) => _provider.providerId !== 'password'
  );

  const unlinkPassword = async () => {
    if (!passwordProvider) {
      return;
    }
    try {
      setIsLoading(true);
      await unlink('password');
      await reloadCurrentUser();
    } finally {
      setIsLoading(false);
    }
  };

  const unlinkProvider = async () => {
    if (!nonPasswordProvider) {
      return;
    }
    try {
      setIsLoading(true);
      await unlink(nonPasswordProvider.providerId);
      await reloadCurrentUser();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <table className="table">
        <tbody>
          {FeatureFlags.PasswordAuth && (
            <tr>
              <th>Password</th>
              <td>
                {passwordProvider && !nonPasswordProvider && (
                  <p>Cannot remove without an alternate login method</p>
                )}
                {passwordProvider && nonPasswordProvider && (
                  <Button onClick={unlinkPassword} variant="contained" color="primary">
                    Remove password
                  </Button>
                )}
                {!passwordProvider && (
                  <>
                    <Button
                      onClick={() => setIsLinkPasswordAuthProviderModalVisible(true)}
                      variant="contained"
                      color="primary"
                    >
                      Add password
                    </Button>
                    {isLinkPasswordAuthProviderModalVisible && (
                      <LinkPasswordAuthProviderModal
                        onComplete={() => setIsLinkPasswordAuthProviderModalVisible(false)}
                        onCancel={() => setIsLinkPasswordAuthProviderModalVisible(false)}
                      />
                    )}
                  </>
                )}
              </td>
            </tr>
          )}
          {nonPasswordProvider && (
            <tr>
              <th>{getNiceProviderName(nonPasswordProvider.providerId)}</th>
              <td>
                {!passwordProvider && <p>Cannot remove without setting a password</p>}
                {passwordProvider && (
                  <Button onClick={unlinkProvider} variant="contained" color="primary">
                    Remove {getNiceProviderName(nonPasswordProvider.providerId)} account
                  </Button>
                )}
              </td>
            </tr>
          )}
          {!nonPasswordProvider && (
            <tr>
              <td colSpan={2}>
                {FeatureFlags.GoogleAuth && (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '300px',
                      height: '72px',
                    }}
                  >
                    <div style={{ margin: '0 auto' }}>
                      <GoogleLoginButton />
                    </div>
                  </div>
                )}
                {FeatureFlags.MicrosoftAuth && (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '300px',
                      height: '72px',
                    }}
                  >
                    <div style={{ margin: '0 auto' }}>
                      <MicrosoftLoginButton />
                    </div>
                  </div>
                )}
                {FeatureFlags.FacebookAuth && (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '300px',
                      height: '72px',
                    }}
                  >
                    <div style={{ margin: '0 auto' }}>
                      <FacebookLoginButton />
                    </div>
                  </div>
                )}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
};
