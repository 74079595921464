var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useRef } from 'react';
import { useAxiosService } from './axiosService';
import { PaymentAccountAggregateError } from './paymentAccountService';
export const useLeoProOrganizationsService = () => {
    const axios = useAxiosService();
    const { current: LeoProOrganizationsService } = useRef({
        getLeoProOrganizationsForCurrentUser() {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`leo-pro-organizations`);
                return data;
            });
        },
        getLeoProOrganizationById(leoProOrganizationId) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`leo-pro-organizations/${leoProOrganizationId}`);
                return data;
            });
        },
        createLeoProOrganization(leoProOrganization) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.post(`leo-pro-organizations`, leoProOrganization);
                return data;
            });
        },
        getLeoProOrganizationProfilesForLeoProOrganization(leoProOrganizationId) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`leo-pro-organizations/${leoProOrganizationId}/profiles`);
                return data;
            });
        },
        createLeoProOrganizationProfileForLeoProOrganization(leoProOrganizationId) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.post(`leo-pro-organizations/${leoProOrganizationId}/profiles`);
                return data;
            });
        },
        setBillingAdminForLeoProOrganization(leoProOrganizationId, leoProOrganizationAdministratorId) {
            return __awaiter(this, void 0, void 0, function* () {
                yield axios.put(`leo-pro-organizations/${leoProOrganizationId}/billing-admin`, { leoProOrganizationAdministratorId });
            });
        },
        getLeoProOrganizationBillingDetail(leoProOrganizationId) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`leo-pro-organizations/${leoProOrganizationId}/payment-account`);
                return data;
            });
        },
        getLeoProOrganizationBillingCardInfo(leoProOrganizationId) {
            var _a;
            return __awaiter(this, void 0, void 0, function* () {
                try {
                    const { data } = yield axios.get(`leo-pro-organizations/${leoProOrganizationId}/card-info`);
                    return data;
                }
                catch (e) {
                    if (((_a = e.response) === null || _a === void 0 ? void 0 : _a.status) === 404) {
                        return null;
                    }
                    throw e;
                }
            });
        },
        putStripeTokenForLeoProOrganization(leoProOrganizationId, stripe_token) {
            var _a;
            return __awaiter(this, void 0, void 0, function* () {
                try {
                    yield axios.put(`leo-pro-organizations/${leoProOrganizationId}/stripe-token`, {
                        stripe_token,
                    });
                }
                catch (e) {
                    if (((_a = e.response) === null || _a === void 0 ? void 0 : _a.status) >= 400) {
                        const { data } = e.response;
                        const errors = [];
                        if (data.statusCode === 400 &&
                            (data.message === 'card_declined' || data.message === 'incorrect_number')) {
                            errors.push('Your card was declined');
                        }
                        else if (data.statusCode === 400 && data.message === 'expired_card') {
                            errors.push('Your card is expired');
                        }
                        else if (data.statusCode === 400 && data.message === 'incorrect_cvc') {
                            errors.push('Your card security code is incorrect');
                        }
                        else {
                            errors.push('An unknown error occurred');
                        }
                        throw new PaymentAccountAggregateError(errors);
                    }
                }
            });
        },
        removeLeoProOrganizationBillingCardInfo(leoProOrganizationId) {
            return __awaiter(this, void 0, void 0, function* () {
                yield axios.delete(`leo-pro-organizations/${leoProOrganizationId}/card-info`);
            });
        },
        cancelLeoProOrganization(leoProOrganizationId) {
            return __awaiter(this, void 0, void 0, function* () {
                yield axios.post(`leo-pro-organizations/${leoProOrganizationId}/cancel-account`);
            });
        },
        restoreLeoProOrganization(leoProOrganizationId) {
            return __awaiter(this, void 0, void 0, function* () {
                yield axios.post(`leo-pro-organizations/${leoProOrganizationId}/restore-account`);
            });
        },
    });
    return LeoProOrganizationsService;
};
