import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import { ProfileScopeSelectionMenuItemRoute } from './profileScopeSelectionMenuItemRoute';
import { ProfileScopeSelectionRootMenuItemsRoute } from './profileScopeSelectionRootMenuItemsRoute';

export const ProfileScopeSelectionContent: React.FunctionComponent = () => {
  const { url: baseRoute } = useRouteMatch();

  return (
    <Switch>
      <Route
        exact
        path={`${baseRoute}/:menuItemId`}
        component={ProfileScopeSelectionMenuItemRoute}
      />
      <Route exact path={baseRoute} component={ProfileScopeSelectionRootMenuItemsRoute} />
      <Redirect to={baseRoute} />
    </Switch>
  );
};
