import React from 'react';
import { useHistory } from 'react-router';

import { CardBackdrop } from '$cmp/cardBackdrop';
import { CardContainer } from '$cmp/cardContainer';
import { useCurrentUserContext } from '$contexts/currentUserContext';
import { ProfilesCard } from './profilesCard';

export const ProfilesRoute: React.FunctionComponent = () => {
  const history = useHistory();
  const { currentUser } = useCurrentUserContext();

  if (!currentUser?.is_single_subscriber_enabled) {
    history.push('/activate');
    return null;
  }

  return (
    <CardBackdrop>
      <CardContainer>
        <ProfilesCard />
      </CardContainer>
    </CardBackdrop>
  );
};
