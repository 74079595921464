import React, { useCallback, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import { useLeoProOrganizationsService } from '@quiet-sunset/leo-shared';

import { LeoProOrganizationRegistrationRequestForm } from './leoProOrganizationRegistrationRequestForm';
import { LeoProOrganizationRegistrationRequestFormData } from './leoProOrganizationRegistrationRequestFormData';
import { LoadingSpinner } from '$cmp/loadingSpinner';

export const LeoProOrganizationRegistrationRequestRoute: React.FunctionComponent = () => {
  const LeoProOrganizationsService = useLeoProOrganizationsService();

  const [isLoadingSpinnerVisible, setIsLoadingSpinnerVisible] = useState(false);
  const [errors, setErrors] = useState([] as string[]);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const submit = useCallback(async (values: LeoProOrganizationRegistrationRequestFormData) => {
    setIsLoadingSpinnerVisible(true);
    try {
      setErrors([]);
      await LeoProOrganizationsService.createLeoProOrganization({
        id: uuid(),
        name: values.organizationName,
      });
      setIsSubmitted(true);
    } catch (e: any) {
      setErrors([e.toString()]);
    } finally {
      setIsLoadingSpinnerVisible(false);
    }
  }, []);

  return (
    <>
      {isLoadingSpinnerVisible && <LoadingSpinner />}
      {!isSubmitted && (
        <LeoProOrganizationRegistrationRequestForm onSubmit={submit} errors={errors} />
      )}
      {isSubmitted && <Redirect to="/leo-pro" />}
    </>
  );
};
