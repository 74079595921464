var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useRef } from 'react';
import { useAxiosService } from './axiosService';
export class PaymentAccountAggregateError extends Error {
    constructor(errors) {
        super(errors.join('\n'));
        this.errors = errors;
    }
}
export const usePaymentAccountService = () => {
    const axios = useAxiosService();
    const { current: PaymentAccountService } = useRef({
        getPaymentAccountForCurrentUser() {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`auth/payment-account`);
                return data;
            });
        },
        getActivePaymentAccountSubscriptionForCurrentUser() {
            var _a;
            return __awaiter(this, void 0, void 0, function* () {
                try {
                    const { data } = yield axios.get(`auth/active-payment-account-subscription`);
                    return data;
                }
                catch (e) {
                    if (((_a = e.response) === null || _a === void 0 ? void 0 : _a.status) === 404) {
                        return null;
                    }
                    throw e;
                }
            });
        },
        getCardInfo() {
            var _a;
            return __awaiter(this, void 0, void 0, function* () {
                try {
                    const { data } = yield axios.get(`auth/card-info`);
                    return data;
                }
                catch (e) {
                    if (((_a = e.response) === null || _a === void 0 ? void 0 : _a.status) === 404) {
                        return null;
                    }
                    throw e;
                }
            });
        },
        updatePaymentAccountInterval(interval) {
            return __awaiter(this, void 0, void 0, function* () {
                yield axios.put(`/auth/payment-account/interval`, { interval });
            });
        },
        removeCardInfo() {
            return __awaiter(this, void 0, void 0, function* () {
                yield axios.delete(`auth/card-info`);
            });
        },
        putStripeTokenForCurrentUser(stripe_token) {
            var _a;
            return __awaiter(this, void 0, void 0, function* () {
                try {
                    yield axios.put(`auth/payment-account/stripe-token`, { stripe_token });
                }
                catch (e) {
                    if (((_a = e.response) === null || _a === void 0 ? void 0 : _a.status) >= 400) {
                        const { data } = e.response;
                        const errors = [];
                        if (data.statusCode === 400 &&
                            (data.message === 'card_declined' || data.message === 'incorrect_number')) {
                            errors.push('Your card was declined');
                        }
                        else if (data.statusCode === 400 && data.message === 'expired_card') {
                            errors.push('Your card is expired');
                        }
                        else if (data.statusCode === 400 && data.message === 'incorrect_cvc') {
                            errors.push('Your card security code is incorrect');
                        }
                        else {
                            errors.push('An unknown error occurred');
                        }
                        throw new PaymentAccountAggregateError(errors);
                    }
                }
            });
        },
        cancelAccountForCurrentUser() {
            return __awaiter(this, void 0, void 0, function* () {
                yield axios.post(`auth/cancel-account`);
            });
        },
        restoreAccountForCurrentUser() {
            return __awaiter(this, void 0, void 0, function* () {
                yield axios.post(`auth/restore-account`);
            });
        },
    });
    return PaymentAccountService;
};
