import React from 'react';
import { Button } from 'react-bootstrap';

import { useFieldGroupItems, useProfileFieldGroupListItems } from '@quiet-sunset/leo-shared';

export interface ProfileContentFieldGroupHorizontalListFooterProps {
  fieldGroupId: string;
  profileFieldGroupListItemId: string | null;
}

export const ProfileContentFieldGroupHorizontalListFooter: React.FunctionComponent<ProfileContentFieldGroupHorizontalListFooterProps> =
  (props) => {
    const { fieldGroupId, profileFieldGroupListItemId: parentProfileFieldGroupListItemId } = props;

    const [, addProfileFieldGroupListItem] = useProfileFieldGroupListItems(
      fieldGroupId,
      parentProfileFieldGroupListItemId
    );

    const disableInputs = false;

    const fieldGroupItems = useFieldGroupItems(fieldGroupId);

    if (!fieldGroupItems) {
      return null;
    }

    return (
      <tfoot>
        <tr>
          <td colSpan={fieldGroupItems.length + 1}>
            {!disableInputs && <Button onClick={addProfileFieldGroupListItem}>Add</Button>}
          </td>
        </tr>
      </tfoot>
    );
  };
