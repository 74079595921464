import React, { useCallback, useMemo, useState } from 'react';
import type { TreeViewNodeModel } from './treeViewNodeModel';
// eslint-disable-next-line import/no-cycle
import { TreeViewNodeList } from './treeViewNodeList';
import { useTreeViewContext } from './treeViewContext';

export interface TreeViewNodeProps {
  treeViewNodeModel: TreeViewNodeModel<any>;
}

export const TreeViewNode: React.FunctionComponent<TreeViewNodeProps> = (props) => {
  const { treeViewNodeModel } = props;

  const [childNodes, setChildNodes] = useState(null as TreeViewNodeModel<any>[] | null);
  const [isExpanded, setIsExpanded] = useState(false);

  const {
    datasource,
    showCheckboxes,
    checkedItemsById,
    onItemChecked,
    onItemClicked,
    doItemTransformation,
  } = useTreeViewContext();

  const isChecked = checkedItemsById?.has(treeViewNodeModel.id) ?? false;

  const treeViewNodeCheckChanged = useCallback(() => {
    onItemChecked?.call(null, treeViewNodeModel.id, !isChecked);
  }, [onItemChecked, treeViewNodeModel, isChecked]);

  const treeViewNodeClicked = useCallback(async () => {
    if (childNodes == null) {
      const _childNodes = await datasource(treeViewNodeModel.id);
      setChildNodes(_childNodes);
      setIsExpanded(true);
    } else {
      setIsExpanded((x) => !x);
    }
    onItemClicked?.call(null, treeViewNodeModel);
  }, [datasource, childNodes, treeViewNodeModel, onItemClicked]);

  const transformedItem = useMemo(
    () => doItemTransformation(treeViewNodeModel),
    [doItemTransformation, treeViewNodeModel]
  );

  return (
    <>
      <div className="item">
        {showCheckboxes && (
          <input type="checkbox" checked={isChecked} onChange={treeViewNodeCheckChanged} />
        )}{' '}
        <div className="item-content" onClick={treeViewNodeClicked}>
          {transformedItem}
        </div>
      </div>

      {childNodes != null && (
        <TreeViewNodeList
          show={isExpanded}
          showMessageIfEmpty={false}
          treeViewNodeModels={childNodes}
        />
      )}
    </>
  );
};
