import React from 'react';
import { useFieldGroupItems } from '@quiet-sunset/leo-shared';
import type { FieldGroupColumnWidth } from '@quiet-sunset/leo-shared';
import { FieldGroupTitle } from '../fieldGroupTitle';
import { FieldTitle } from '../fieldTitle';

export interface ProfileContentFieldGroupHorizontalTableHeaderProps {
  fieldGroupId: string;
  fieldGroupColumnWidths: FieldGroupColumnWidth[];
  launchHelpTextDialog: (params: { title: string; body: string }) => any;
}

export const ProfileContentFieldGroupHorizontalTableHeader: React.FunctionComponent<ProfileContentFieldGroupHorizontalTableHeaderProps> =
  (props) => {
    const { fieldGroupId, fieldGroupColumnWidths, launchHelpTextDialog } = props;

    const fieldGroupItems = useFieldGroupItems(fieldGroupId);

    if (!fieldGroupItems) {
      return null;
    }

    return (
      <>
        <colgroup>
          {fieldGroupColumnWidths.map((fieldGroupColumnWidth, i) => (
            <col
              // eslint-disable-next-line react/no-array-index-key
              key={`${fieldGroupColumnWidth.width}_${i}`}
              style={{ width: fieldGroupColumnWidth.width }}
            />
          ))}
        </colgroup>
        <thead>
          <tr>
            {fieldGroupItems.map((fieldGroupItem) => (
              <td key={fieldGroupItem.id}>
                {fieldGroupItem.field_id != null && (
                  <FieldTitle
                    fieldId={fieldGroupItem.field_id}
                    launchHelpTextDialog={launchHelpTextDialog}
                  />
                )}
                {fieldGroupItem.field_group_id != null && (
                  <FieldGroupTitle
                    fieldGroupId={fieldGroupItem.field_group_id}
                    launchHelpTextDialog={launchHelpTextDialog}
                  />
                )}
              </td>
            ))}
          </tr>
        </thead>
      </>
    );
  };
