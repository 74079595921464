import React from 'react';
import { Field } from 'react-final-form';

export const LeoDigitalTrusteeAppointmentDocumentFormContents: React.FunctionComponent = () => {
  return (
    <>
      <p style={{ textAlign: 'center' }}>
        Life Estate Organizer Digital Trustee Appointment Document
      </p>
      <p style={{ textAlign: 'center' }}>For</p>
      <p style={{ textAlign: 'center' }}>Life Estate Organizer</p>
      <p style={{ textAlign: 'center' }}>For</p>
      <p style={{ textAlign: 'center' }}>Head of Household</p>
      <p style={{ textAlign: 'center' }}>
        <Field
          name="agreementModel.name"
          component="input"
          placeholder="Name"
          autoComplete="headOfHouseholdName"
          className="form-control form-control-inline"
        />
      </p>
      <p>
        I,{' '}
        <Field
          name="agreementModel.name"
          component="input"
          placeholder="Name"
          autoComplete="headOfHouseholdName"
          className="form-control form-control-inline"
        />
        , of{' '}
        <Field
          name="agreementModel.county"
          component="input"
          placeholder="County"
          autoComplete="headOfHouseholdCounty"
          className="form-control form-control-inline"
        />
        County,{' '}
        <Field
          name="agreementModel.state"
          component="input"
          placeholder="State"
          autoComplete="headOfHouseholdState"
          className="form-control form-control-inline"
        />
        ,
        <Field
          name="agreementModel.country"
          component="input"
          placeholder="Country"
          autoComplete="headOfHouseholdCountry"
          className="form-control form-control-inline"
        />
        do hereby make, publish and declare this to be my Life Estate Organizer. I hereby revoke all
        previous Life Estate Organizers or writings made by me.
      </p>
      <div>
        <span style={{ textDecoration: 'underline' }}>ITEM I.</span>

        <ol style={{ listStyle: 'upper-alpha' }}>
          <li>
            I nominate and appoint
            <Field
              name="agreementModel.trusteeName"
              component="input"
              placeholder="Name"
              autoComplete="trusteeName"
              className="form-control form-control-inline"
            />
            as Life Estate Organizer Digital Trustee on this date
            <Field
              name="agreementModel.trusteeDate"
              component="input"
              type="date"
              placeholder="Date"
              autoComplete="trusteeDate"
              className="form-control form-control-inline"
            />
          </li>
          <li>
            This appointment shall provide the Life Estate Organizer Digital Trustee the following:
            <ol>
              <li>
                The Life Estate Organizer Trustee will have access to all or partial sections of my
                Life Estate Organizer by using the user ID and password provided to access sections
                of my Life Estate Organizer.
              </li>
              <li>
                Sections will be represented in the Life Estate Organizer Digital Trustee Profile
                Scope Selection. The sections represented have been chosen by me, the Head of
                Household.
              </li>
              <li>
                This appointment authorizes the Life Estate Organizer Digital Trustee to access and
                review the data input to the represented sections outlined in the Digital Trustees
                Profile Scope Selection only.
              </li>
              <li>
                The Life Estate Organizer Digital Trustee does not have access to any other sections
                of the Life Estate Organizer other than those selected by me, Head of Household.
              </li>
            </ol>
          </li>
          <li>
            The Life Estate Organizer Digital Trustee will be required to perform duties as assigned
            by Head of Household executed and managed by my Executor upon death of Head of Household
            to access, administrate, relocate, consolidate, destroy, etc., digital equipment, other
            digital equipment, external drives, thumb drives, other storage devices, computer files,
            digital services, social media, internet providers, pin numbers, passwords, software and
            other digital related property and services. The Life Estate Organizer Digital Trustee
            will perform these duties under the direction of the Executor.
          </li>
          <li>
            In the event the Life Estate Organizer Digital Trustee has an issue with any information
            contained within the Head of Household’s Life Estate Organizer, the Trustee shall
            contact the Head of Household, if not deceased and if deceased, contact the Executor to
            address and resolve the issue.
          </li>
        </ol>
      </div>
      <p>
        I hereby check this block, type in my name and the date of agreement that this is my Life
        Estate Organizer, has been filled out by me to the best of my knowledge and ability, revoke
        all previous Life Estate Organizers or writings made by me and direct my Digital Trustee to
        work in conjunction with my Executor in the settlement of my digital assets that are part of
        my estate.
      </p>
    </>
  );
};
