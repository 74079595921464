import React, { createContext, useContext } from 'react';
import { TreeViewNodeModel } from './treeViewNodeModel';

interface TreeViewContextType {
  datasource: (treeViewNodeId: any) => Promise<TreeViewNodeModel<any>[]>;
  showCheckboxes: boolean;
  checkedItemsById: Map<string | number, TreeViewNodeModel<any>> | undefined;
  onItemChecked: ((itemId: any, value: boolean) => any) | undefined;
  onItemClicked: ((itemId: any) => any) | undefined;
  doItemTransformation: (item: TreeViewNodeModel<any>) => React.ReactChild;
}

export const TreeViewContext = createContext<TreeViewContextType>(undefined!);

export const useTreeViewContext = () => useContext(TreeViewContext);
