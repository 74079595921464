import React from 'react';
import styled from 'styled-components/macro';
import { rgba, darken } from 'polished';
import { Nav } from 'react-bootstrap';

import '$/material-app/vendor/perfect-scrollbar.css';

import { Chip, ListItem, ListItemText } from '@mui/material';

const LinkButton = styled(ListItem)<{
  component: typeof Nav.Link;
  onClick: () => void;
}>`
  padding-left: ${(props) => props.theme.spacing(10)} !important;
  padding-top: ${(props) => props.theme.spacing(2)} !important;
  padding-bottom: ${(props) => props.theme.spacing(2)} !important;

  svg {
    color: ${(props) => props.theme.sidebar.color};
    font-size: 16px;
    width: 16px;
    height: 16px;
    opacity: 0.5;
  }

  span {
    color: ${(props) => rgba(props.theme.sidebar.color, 0.7)};
  }

  &:hover span {
    color: ${(props) => rgba(props.theme.sidebar.color, 0.9)};
  }

  &:hover {
    background-color: ${(props) => darken(0.015, props.theme.sidebar.background)};
  }
`;

const LinkText = styled(ListItemText)`
  color: ${(props) => props.theme.sidebar.color};
  span {
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
  }
  margin-top: 0;
  margin-bottom: 0;
`;

const LinkBadge = styled(Chip)`
  font-size: 11px;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 28px;
  top: 8px;
  background: ${(props) => props.theme.sidebar.badge.background};

  span.MuiChip-label,
  span.MuiChip-label:hover {
    cursor: pointer;
    color: ${(props) => props.theme.sidebar.badge.color};
    padding-left: ${(props) => props.theme.spacing(2)}px;
    padding-right: ${(props) => props.theme.spacing(2)}px;
  }
`;

export type SidebarButtonPropsType = {
  name: string;
  onClick: () => void;
  badge?: React.ReactNode;
  noBadgeStyling?: boolean;
  icon?: React.ReactNode;
};

export const SidebarButton: React.FC<SidebarButtonPropsType> = ({
  name,
  onClick,
  badge,
  noBadgeStyling,
  icon,
}) => {
  return (
    <LinkButton component={Nav.Link} onClick={onClick}>
      <LinkText>
        {icon} <span style={{ marginLeft: '10px' }}>{name}</span>
      </LinkText>
      {badge && !noBadgeStyling ? <LinkBadge label={badge} /> : null}
      {badge && noBadgeStyling ? badge : null}
    </LinkButton>
  );
};
