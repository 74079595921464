import React from 'react';
import { Button, Modal } from 'react-bootstrap';

export interface AcknowledgeModalProps {
  title: string;
  message: string;
  acknowledgeText?: string;
  onAcknowledge: () => unknown;
}

export const AcknowledgeModal: React.FunctionComponent<AcknowledgeModalProps> = (props) => {
  const { title, message, acknowledgeText, onAcknowledge } = props;

  return (
    <Modal show backdrop="static" keyboard={false}>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>{message}</p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="default" onClick={onAcknowledge}>
          {acknowledgeText ?? 'Acknowledge'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
