import React from 'react';

export const DefaultCheckmark: React.FunctionComponent = () => (
  // why does this icon need to be wrapped with a div? well, removing the div throws the error:
  // "Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node."
  // when removing default status from a profile (not a LEO pro organization). no idea why
  <div>
    <i className="fas fa-check" style={{ color: 'green' }} />
  </div>
);
