import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { LoadingSpinner } from '$cmp/loadingSpinner';
import { YesNoModal } from '$cmp/modals/yesNoModal';

import type {
  LeoProOrganizationAdministrator,
  LeoProOrganizationManager,
  LeoProOrganizationAssistant,
} from '@quiet-sunset/leo-shared';

import {
  useLeoProOrganizationAdministratorsService,
  useLeoProOrganizationManagersService,
  useLeoProOrganizationAssistantsService,
} from '@quiet-sunset/leo-shared';

type Invitation = {
  id: string;
  leo_pro_organization_name: string;
  type: 'Administrator' | 'Manager' | 'Assistant';
};

export interface LeoProInvitationsListProps {
  onUpdate: () => any;
}

export const LeoProInvitationsList: React.FunctionComponent<LeoProInvitationsListProps> = ({
  onUpdate,
}) => {
  const LeoProOrganizationAdministratorsService = useLeoProOrganizationAdministratorsService();
  const LeoProOrganizationManagersService = useLeoProOrganizationManagersService();
  const LeoProOrganizationAssistantsService = useLeoProOrganizationAssistantsService();

  const [isLoaded, setIsLoaded] = useState(false);
  const [forceReloadTrigger, setForceReloadTrigger] = useState({});
  const [
    leoProOrganizationAdministratorInvitations,
    setLeoProOrganizationAdministratorInvitations,
  ] = useState(null as null | LeoProOrganizationAdministrator[]);
  const [leoProOrganizationManagerInvitations, setLeoProOrganizationManagerInvitations] = useState(
    null as null | LeoProOrganizationManager[]
  );
  const [leoProOrganizationAssistantInvitations, setLeoProOrganizationAssistantInvitations] =
    useState(null as null | LeoProOrganizationAssistant[]);
  const [invitationToDecline, setInvitationToDecline] = useState(null as null | Invitation);
  const [invitationToAccept, setInvitationToAccept] = useState(null as null | Invitation);

  useEffect(() => {
    void (async () => {
      setIsLoaded(false);
      try {
        try {
          const _leoProOrganizationAdministratorInvitations =
            await LeoProOrganizationAdministratorsService.getLeoProOrganizationAdministratorsInvitationsForCurrentUser();
          setLeoProOrganizationAdministratorInvitations(
            _leoProOrganizationAdministratorInvitations
          );
        } catch (e: any) {
          if (e.response?.status === 403) {
            setLeoProOrganizationAdministratorInvitations(null);
          } else {
            throw e;
          }
        }

        try {
          const _leoProOrganizationManagerInvitations =
            await LeoProOrganizationManagersService.getLeoProOrganizationManagersInvitationsForCurrentUser();
          setLeoProOrganizationManagerInvitations(_leoProOrganizationManagerInvitations);
        } catch (e: any) {
          if (e.response?.status === 403) {
            setLeoProOrganizationManagerInvitations(null);
          } else {
            throw e;
          }
        }

        try {
          const _leoProOrganizationAssistantInvitations =
            await LeoProOrganizationAssistantsService.getLeoProOrganizationAssistantsInvitationsForCurrentUser();
          setLeoProOrganizationAssistantInvitations(_leoProOrganizationAssistantInvitations);
        } catch (e: any) {
          if (e.response?.status === 403) {
            setLeoProOrganizationAssistantInvitations(null);
          } else {
            throw e;
          }
        }
      } finally {
        setIsLoaded(true);
      }
    })();
  }, [forceReloadTrigger]);

  const invitations: Invitation[] = useMemo(() => {
    return [
      ...(leoProOrganizationAdministratorInvitations ?? []).map((x) => ({
        id: x.id,
        leo_pro_organization_name: x.leo_pro_organization?.name ?? '',
        type: 'Administrator' as 'Administrator' | 'Manager' | 'Assistant',
      })),
      ...(leoProOrganizationManagerInvitations ?? []).map((x) => ({
        id: x.id,
        leo_pro_organization_name: x.leo_pro_organization?.name ?? '',
        type: 'Manager' as 'Administrator' | 'Manager' | 'Assistant',
      })),
      ...(leoProOrganizationAssistantInvitations ?? []).map((x) => ({
        id: x.id,
        leo_pro_organization_name: x.leo_pro_organization?.name ?? '',
        type: 'Assistant' as 'Administrator' | 'Manager' | 'Assistant',
      })),
    ];
  }, [
    leoProOrganizationAdministratorInvitations,
    leoProOrganizationAssistantInvitations,
    leoProOrganizationManagerInvitations,
  ]);

  const acceptInvitation = useCallback((invitation: Invitation) => {
    setInvitationToAccept(invitation);
  }, []);

  const confirmAcceptInvitation = useCallback(async () => {
    if (invitationToAccept == null) {
      return;
    }

    switch (invitationToAccept.type) {
      case 'Administrator':
        await LeoProOrganizationAdministratorsService.acceptLeoProOrganizationAdministratorsInvitation(
          invitationToAccept.id
        );
        break;
      case 'Manager':
        await LeoProOrganizationManagersService.acceptLeoProOrganizationManagersInvitation(
          invitationToAccept.id
        );
        break;
      case 'Assistant':
        await LeoProOrganizationAssistantsService.acceptLeoProOrganizationAssistantsInvitation(
          invitationToAccept.id
        );
        break;
      default:
    }

    setForceReloadTrigger({});
    setInvitationToAccept(null);
    onUpdate();
  }, [invitationToAccept, onUpdate]);

  const cancelAcceptInvitation = useCallback(() => {
    setInvitationToAccept(null);
  }, []);

  const declineInvitation = useCallback((invitation: Invitation) => {
    setInvitationToDecline(invitation);
  }, []);

  const confirmDeclineInvitation = useCallback(async () => {
    if (invitationToDecline == null) {
      return;
    }

    switch (invitationToDecline.type) {
      case 'Administrator':
        await LeoProOrganizationAdministratorsService.declineLeoProOrganizationAdministratorsInvitation(
          invitationToDecline.id
        );
        break;
      case 'Manager':
        await LeoProOrganizationManagersService.declineLeoProOrganizationManagersInvitation(
          invitationToDecline.id
        );
        break;
      case 'Assistant':
        await LeoProOrganizationAssistantsService.declineLeoProOrganizationAssistantsInvitation(
          invitationToDecline.id
        );
        break;
      default:
    }

    setForceReloadTrigger({});
    setInvitationToDecline(null);
  }, [invitationToDecline]);

  const cancelDeclineInvitation = useCallback(() => {
    setInvitationToDecline(null);
  }, []);

  return (
    <>
      {invitationToAccept != null && (
        <YesNoModal
          title="Accept LEO-PRO account invitation"
          message={`Do you want to accept your invitation to the ${invitationToAccept.leo_pro_organization_name} LEO-PRO account as ${invitationToAccept.type}?`}
          onYes={confirmAcceptInvitation}
          onNo={cancelAcceptInvitation}
        />
      )}
      {invitationToDecline != null && (
        <YesNoModal
          title="Decline LEO-PRO account invitation"
          message={`Do you want to decline your invitation to the ${invitationToDecline.leo_pro_organization_name} LEO-PRO account as ${invitationToDecline.type}?`}
          onYes={confirmDeclineInvitation}
          onNo={cancelDeclineInvitation}
        />
      )}
      {!isLoaded && <LoadingSpinner />}
      {isLoaded && invitations.length > 0 && (
        <>
          <h3>LEO-PRO invitations</h3>
          <Table>
            <thead>
              <tr>
                <th>LEO-PRO organization</th>
                <th>Type</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {invitations.map((invitation) => (
                <tr key={invitation.id}>
                  <td>{invitation.leo_pro_organization_name}</td>
                  <td>{invitation.type}</td>
                  <td>
                    <Button variant="primary" onClick={() => acceptInvitation(invitation)}>
                      Accept
                    </Button>
                    <Button variant="danger" onClick={() => declineInvitation(invitation)}>
                      Decline
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}
    </>
  );
};
