import React, { useEffect, useMemo, useState } from 'react';
import { TreeViewNodeList } from './treeViewNodeList';
import type { TreeViewNodeModel } from './treeViewNodeModel';
import { TreeViewContext } from './treeViewContext';

export interface TreeViewProps<TData = any, TKey = any> {
  showCheckboxes: boolean;
  datasource: (treeViewNodeId: any) => Promise<TreeViewNodeModel<TData>[]>;
  checkedItemIds?: TKey[];
  onItemChecked?: (itemId: TKey, value: boolean) => any;
  onItemClicked?: (item: TreeViewNodeModel<TData>) => any;
  doItemTransformation?: (item: TreeViewNodeModel<TData>) => React.ReactChild;
}

export const TreeView: React.FunctionComponent<TreeViewProps> = (props) => {
  const {
    showCheckboxes,
    datasource,
    checkedItemIds,
    onItemChecked,
    onItemClicked,
    doItemTransformation,
  } = props;

  const [treeViewNodeModels, setTreeViewNodeModels] = useState([] as TreeViewNodeModel<any>[]);
  const checkedItemsById = useMemo(
    () => (checkedItemIds != null ? new Map(checkedItemIds.map((x) => [x, x])) : undefined),
    [checkedItemIds]
  );

  const treeViewContextValue = useMemo(
    () => ({
      datasource,
      showCheckboxes,
      checkedItemsById,
      onItemChecked,
      onItemClicked,
      doItemTransformation: doItemTransformation ?? ((x) => x.name),
    }),
    [
      datasource,
      showCheckboxes,
      checkedItemsById,
      onItemChecked,
      onItemClicked,
      doItemTransformation,
    ]
  );

  useEffect(() => {
    void (async () => {
      const _treeViewNodeModels = await datasource(null);
      setTreeViewNodeModels(_treeViewNodeModels);
    })();
  }, [datasource]);

  return (
    <TreeViewContext.Provider value={treeViewContextValue}>
      <div className="tree-view">
        {treeViewNodeModels == null && <p>Loading...</p>}
        {treeViewNodeModels != null && (
          <TreeViewNodeList show showMessageIfEmpty treeViewNodeModels={treeViewNodeModels} />
        )}
      </div>
    </TreeViewContext.Provider>
  );
};
