var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext } from 'react';
const serviceContext = createContext(null);
export const useServiceContext = () => useContext(serviceContext);
export const ServiceContextProvider = (_a) => {
    var { children } = _a, contextValue = __rest(_a, ["children"]);
    return _jsx(serviceContext.Provider, Object.assign({ value: contextValue }, { children: children }), void 0);
};
