import React, { useCallback, useState } from 'react';
import { Button, Tab, Tabs } from 'react-bootstrap';
import { useProfileFieldGroupListItems } from '@quiet-sunset/leo-shared';
// eslint-disable-next-line import/no-cycle
import { ProfileContentFieldGroupVertical } from './profileContentFieldGroupVertical';

export interface ProfileContentFieldGroupVerticalListTabbedProps {
  fieldGroupId: string;
  profileFieldGroupListItemId: string | null;
}

export const ProfileContentFieldGroupVerticalListTabbed: React.FunctionComponent<ProfileContentFieldGroupVerticalListTabbedProps> =
  (props) => {
    const { fieldGroupId, profileFieldGroupListItemId: parentProfileFieldGroupListItemId } = props;

    const [
      profileFieldGroupListItems,
      _addProfileFieldGroupListItem,
      _removeProfileFieldGroupListItem,
    ] = useProfileFieldGroupListItems(fieldGroupId, parentProfileFieldGroupListItemId);

    const [activeTabIndex, setActiveTabIndex] = useState(0);

    const addProfileFieldGroupListItem = useCallback(() => {
      _addProfileFieldGroupListItem();
      const indexOfLastCurrentProfileFieldGroupListItem =
        (profileFieldGroupListItems ?? []).length - 1;
      const indexOfNewProfileFieldGroupListItem = indexOfLastCurrentProfileFieldGroupListItem + 1;
      setActiveTabIndex(indexOfNewProfileFieldGroupListItem);
    }, [profileFieldGroupListItems]);

    const removeProfileFieldGroupListItem = useCallback(
      (profileFieldGroupListItemId: string) => {
        _removeProfileFieldGroupListItem(profileFieldGroupListItemId);
        const indexOfLastCurrentProfileFieldGroupListItem =
          (profileFieldGroupListItems ?? []).length - 1;
        const indexOfLastProfileFieldGroupListItemAfterRemoval =
          indexOfLastCurrentProfileFieldGroupListItem - 1;
        setActiveTabIndex((oldActiveTabIndex) =>
          Math.min(indexOfLastProfileFieldGroupListItemAfterRemoval, oldActiveTabIndex)
        );
      },
      [profileFieldGroupListItems]
    );

    return (
      <>
        <Tabs
          activeKey={activeTabIndex.toString()}
          onSelect={(newTabIndex) => setActiveTabIndex(parseInt(newTabIndex ?? '0', 10))}
        >
          {(profileFieldGroupListItems ?? []).map((profileFieldGroupListItem, index) => (
            <Tab key={profileFieldGroupListItem.id} eventKey={index.toString()} title={index + 1}>
              <ProfileContentFieldGroupVertical
                fieldGroupId={fieldGroupId}
                profileFieldGroupListItemId={profileFieldGroupListItem.id}
              />
              <Button onClick={() => removeProfileFieldGroupListItem(profileFieldGroupListItem.id)}>
                Remove
              </Button>
            </Tab>
          ))}
        </Tabs>
        <Button onClick={addProfileFieldGroupListItem}>Add item</Button>
      </>
    );
  };
