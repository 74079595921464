import React from 'react';

import checkmarkImage from '$assets/checkmark-green.png';

type CheckListItemProps = {
  key: string;
};

type CheckListProps = {
  children?: React.ReactElement<CheckListItemProps>[] | React.ReactElement<CheckListItemProps>;
};

export const CheckList: React.FC<CheckListProps> = ({ children }) => {
  return (
    <ul style={{ padding: 0, margin: 0 }}>
      {React.Children.map(children, (child) => {
        if (!React.isValidElement(child)) {
          return null;
        }

        return (
          <li style={{ display: 'flex', marginTop: '10px', marginBottom: '10px' }}>
            <div style={{ flex: '0 0 0' }}>
              <img src={checkmarkImage} style={{ width: '30px', marginRight: '10px' }} />
            </div>
            <div key={child.props.key} style={{ flex: '1 0 0' }}>
              {child}
            </div>
          </li>
        );
      })}
    </ul>
  );
};
