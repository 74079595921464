import React, { useMemo } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useClientConfigService } from '@quiet-sunset/leo-shared';

export const StripeLoader: React.FunctionComponent = (props) => {
  const ClientConfigService = useClientConfigService();

  const stripePromise = useMemo(async () => {
    const clientConfig = await ClientConfigService.getClientConfig();
    const { stripePublicKey } = clientConfig;
    return await loadStripe(stripePublicKey);
  }, []);

  return <Elements stripe={stripePromise}>{props.children}</Elements>;
};
