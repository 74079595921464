import React, { VFC } from 'react';
import { useFormik } from 'formik';
import { FieldGroup } from '@quiet-sunset/leo-shared';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import { useUpdateFieldGroup } from './hooks';

export type FieldGroupEditDialogType = {
  fieldGroup: FieldGroup;
  onClose: () => void;
};

export const FieldGroupEditDialog: VFC<FieldGroupEditDialogType> = ({ fieldGroup, onClose }) => {
  const updateFieldGroup = useUpdateFieldGroup(onClose);

  const formik = useFormik({
    initialValues: {
      ...fieldGroup,
      is_list: fieldGroup.is_list ? 'yes' : 'no',
    },
    onSubmit: (values) => {
      updateFieldGroup({
        ...fieldGroup,
        ...values,
        is_list: values.is_list === 'yes',
      });
    },
  });

  return (
    <Dialog open fullWidth>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>Edit field group</DialogTitle>
        <DialogContent>
          <Stack gap={5}>
            <TextField
              label="Name"
              fullWidth
              variant="standard"
              name="label"
              value={formik.values.label}
              onChange={formik.handleChange}
            />
            <FormControl>
              <InputLabel>Is list</InputLabel>
              <Select
                variant="standard"
                name="is_list"
                value={formik.values.is_list}
                onChange={formik.handleChange}
              >
                <MenuItem value="yes">Yes</MenuItem>
                <MenuItem value="no">No</MenuItem>
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel>Display type</InputLabel>
              <Select
                variant="standard"
                name="display_type"
                value={formik.values.display_type}
                onChange={formik.handleChange}
              >
                <MenuItem value="horizontal">Horizontal</MenuItem>
                <MenuItem value="vertical">Vertical</MenuItem>
                <MenuItem value="vertical_stacked">Vertical stacked</MenuItem>
                <MenuItem value="vertical_tabbed">Vertical tabbed</MenuItem>
                <MenuItem value="sectioned">Sectioned</MenuItem>
                <MenuItem value="menu_item">Menu item</MenuItem>
              </Select>
            </FormControl>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit">Submit</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
