import React, { VFC } from 'react';
import { rgba } from 'polished';
import styled from 'styled-components';
import Chip from '@mui/material/Chip';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

const Container = styled(ListItem)`
  padding-left: ${(props) => props.theme.spacing(10)} !important;
  padding-top: ${(props) => props.theme.spacing(2)} !important;
  padding-bottom: ${(props) => props.theme.spacing(2)} !important;

  span {
    color: ${(props) => rgba(props.theme.sidebar.color, 0.7)};
  }
`;

const Text = styled(ListItemText)`
  color: ${(props) => props.theme.sidebar.color};
  span {
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
  }
  margin-top: 0;
  margin-bottom: 0;
  white-space: normal;
`;

const Badge = styled(Chip)`
  font-size: 11px;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 28px;
  top: 8px;
  background: ${(props) => props.theme.sidebar.badge.background};

  span.MuiChip-label,
  span.MuiChip-label:hover {
    cursor: pointer;
    color: ${(props) => props.theme.sidebar.badge.color};
    padding-left: ${(props) => props.theme.spacing(2)}px;
    padding-right: ${(props) => props.theme.spacing(2)}px;
  }
`;

type SidebarTextProps = {
  name: string;
  badge?: string | number;
  icon?: JSX.Element;
};

export const SidebarText: VFC<SidebarTextProps> = ({ name, badge, icon }) => {
  return (
    <Container dense>
      <Text>
        {icon} <span>{name}</span>
      </Text>
      {badge ? <Badge label={badge} /> : ''}
    </Container>
  );
};
