import React from 'react';
import { EmailVerificationForm } from '@sprice237/react-auth-dom';
import { AuthContentWrapper } from '$cmp/authContentWrapper';
import { LeoHeaderLogo } from '$cmp/leoHeaderLogo';
import { AuthContentContainer } from '$cmp/authContentContainer';

export const EmailVerificationRoute: React.FunctionComponent = () => {
  return (
    <AuthContentWrapper>
      <AuthContentContainer>
        <LeoHeaderLogo />
        <EmailVerificationForm />
      </AuthContentContainer>
    </AuthContentWrapper>
  );
};
