var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useRef } from 'react';
import { useAxiosService } from './axiosService';
class FieldGroupsService {
    constructor(axios) {
        this.axios = axios;
    }
    getFieldGroup(fieldGroupId) {
        return __awaiter(this, void 0, void 0, function* () {
            const { data } = yield this.axios.get(`field-groups/${fieldGroupId}`);
            return data;
        });
    }
    getFieldGroupWithNestedFields(fieldGroupId) {
        return __awaiter(this, void 0, void 0, function* () {
            const { data } = yield this.axios.get(`field-groups/${fieldGroupId}?includeNestedFields=true`);
            return data;
        });
    }
    createFieldGroup(fieldGroup) {
        return __awaiter(this, void 0, void 0, function* () {
            const { data } = yield this.axios.post(`field-groups`, fieldGroup);
            return data;
        });
    }
    updateFieldGroup(fieldGroup) {
        return __awaiter(this, void 0, void 0, function* () {
            const { data } = yield this.axios.put(`field-groups/${fieldGroup.id}`, fieldGroup);
            return data;
        });
    }
    deleteFieldGroup(fieldGroupId) {
        return __awaiter(this, void 0, void 0, function* () {
            const { data } = yield this.axios.delete(`field-groups/${fieldGroupId}`);
            return data;
        });
    }
    moveFieldGroupToMenuItem(fieldGroupId, menuItemId) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.axios.post(`field-groups/move-to-menu-item`, { field_group_id: fieldGroupId, menu_item_id: menuItemId });
        });
    }
    moveFieldGroupItemsToFieldGroup(fieldGroupId, action, items) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.axios.post(`field-groups/${fieldGroupId}/field-group-items`, { action, items });
        });
    }
}
export const useFieldGroupsService = () => {
    const axios = useAxiosService();
    const { current: service } = useRef(new FieldGroupsService(axios));
    return service;
};
