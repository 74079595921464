export const FeatureFlags = {
  NewLogin: JSON.parse(process.env.REACT_APP_ENABLE_NEW_LOGIN || 'false'),
  PasswordAuth: JSON.parse(process.env.REACT_APP_ENABLE_PASSWORD_AUTH || 'false'),
  GoogleAuth: JSON.parse(process.env.REACT_APP_ENABLE_GOOGLE_AUTH || 'false'),
  MicrosoftAuth: JSON.parse(process.env.REACT_APP_ENABLE_MICROSOFT_AUTH || 'false'),
  FacebookAuth: JSON.parse(process.env.REACT_APP_ENABLE_FACEBOOK_AUTH || 'false'),
  ActivateFeaturesMenuItem: JSON.parse(
    process.env.REACT_APP_ENABLE_ACTIVATE_FEATURES_MENU_ITEM || 'false'
  ),
};
