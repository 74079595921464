import React, { useCallback, useEffect, useState, VFC } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import { useAsyncMemo, useProfileFieldGroupListItemsService } from '@quiet-sunset/leo-shared';
import { LoadingSpinner } from '$cmp/loadingSpinner';

export type RenameFieldGroupListItemDialogProps = {
  profileId: string;
  fieldGroupId: string;
  profileFieldGroupListItemId: string;
  onClose: () => void;
};

export const RenameFieldGroupListItemDialog: VFC<RenameFieldGroupListItemDialogProps> = ({
  profileId,
  fieldGroupId,
  profileFieldGroupListItemId,
  onClose,
}) => {
  const [label, setLabel] = useState('');

  const ProfileFieldGroupListItemsService = useProfileFieldGroupListItemsService();
  const [initialValue, isResolved] = useAsyncMemo(
    () =>
      ProfileFieldGroupListItemsService.getTitleForProfileFieldGroupListItem(
        profileId,
        fieldGroupId,
        profileFieldGroupListItemId
      ),
    [profileId, fieldGroupId, profileFieldGroupListItemId]
  );

  useEffect(() => {
    if (initialValue) {
      setLabel(initialValue);
    }
  }, [initialValue]);

  const submit = useCallback(async () => {
    await ProfileFieldGroupListItemsService.setTitleForProfileFieldGroupListItem(
      profileId,
      fieldGroupId,
      profileFieldGroupListItemId,
      label
    );
    onClose();
  }, [profileId, fieldGroupId, profileFieldGroupListItemId, label]);

  return (
    <>
      {!isResolved && <LoadingSpinner />}
      <Dialog open fullWidth onClose={onClose}>
        <DialogTitle>Rename item</DialogTitle>
        <DialogContent>
          <Stack gap={5}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                submit();
              }}
            >
              <TextField
                label="Name"
                fullWidth
                variant="standard"
                value={label}
                onChange={(e) => setLabel(e.target.value)}
                autoFocus
              />
            </form>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={submit}>Submit</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
